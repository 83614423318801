/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/

import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { DynamicFormComponent } from 'src/app/shared/dynamic-form/dynamic-form.component';
import moment from 'moment';
import { GlobalMessageService } from 'src/app/shared/services/commonServices/global-message.service';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { GridService } from 'src/app/shared/services/gridServices/grid.service';

@Component({
  selector: 'fury-fs-duplicate-config',
  templateUrl: './fs-duplicate-config.component.html',
  styleUrls: ['./fs-duplicate-config.component.scss']
})
export class duplicateConfigComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<duplicateConfigComponent>,private gridService: GridService,
    @Inject(MAT_DIALOG_DATA) private data: any, public translate: TranslateService, private messageService: GlobalMessageService, private http: HttpClient) { }

  public heading: String = "Create Financial statement";

  public display: boolean = false
  public fundTypeList = [];
  public fundList = []
  public configNameList = []
  public reportStartDate: any;
  public reportEndDate: any;
  public basicDetailData: any = []
  @ViewChild(DynamicFormComponent) formEl;
  public formData = {};
  public formValid: boolean = false;
  public rowData: any = {}
  public rowDataSchedule: any = {}
  public prevConfigId: any;
  ngOnInit(): void {
    this.reportStartDate = moment(this.data.startDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
    this.reportEndDate = moment(this.data.endDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
    this.rowData = this.data
    this.rowDataSchedule = this.data.scheduleData
    this.prevConfigId = this.data.configId
    this.getformCOntrol()
  }

  basicDetails() {
    return [
      { id: 'fundId', type: [], controlType: 2, label: 'fund', viewname: 'fundId', name: 'fundId', order: 1, value: { id: this.rowData.fundId }, required: true, appearance: "fill", fill: this.fundList, disabled: true, readonly: false, class: "col-lg-12 mb-c" },
      { id: 'startDate', type: [], controlType: 3, label: 'start_date', viewname: 'startDate', name: 'startDate', order: 3, value: this.reportStartDate, required: true, appearance: "fill", class: "col-lg-6 mb-c" },
      { id: 'endDate', type: ['Private Equity Fund', 'Feeder Fund', 'Hedge Fund', 'Crypto Fund', 'Venture Capital Fund', 'Special Purpose Vehicle Fund', 'Mutual Fund'], controlType: 3, label: 'end_date', viewname: 'endDate', name: 'endDate', order: 3, value: this.reportEndDate, required: true, appearance: "fill", class: "col-lg-6 mb-c" },

    ]
  }

  formValidation(event: boolean) {
    this.formValid = this.formEl.form.status === "INVALID" ? true : false;
  }

  getformCOntrol() {
    this.fetchAllByEnterprise().subscribe((response) => {
      let fund = response.resultList && response.resultList.length ? response.resultList : [];
      this.fundList = fund.map(element => ({ label: element.fundAlias != null ? element.fundAlias : element.fundName, value: { "id": element.id } }));
      this.basicDetailData = this.basicDetails()

    })
  }

  OpenduplicatePopup() {
    this.getformCOntrol()
    this.display = true
  }

  dialogClose() {
    this.formEl.form.reset();
    this.dialogRef.close();
  }


  submit() {
    Object.assign(this.formData, this.formEl.form.value);
    let startDate = moment(this.formData['startDate'], 'YYYY-MM-DD').format('YYYY-MM-DD')
    let endDate = moment(this.formData['endDate'], 'YYYY-MM-DD').format('YYYY-MM-DD');
    if (endDate < startDate) {
      this.messageService.add({ sticky: true, severity: 'error', summary: this.translate.instant('error'), detail: this.translate.instant('end_date_should_be_greater_than_start_date') });
      return;
    }
    this.formValid = true
    let req = this.rowData
    req['startDate'] = moment(this.formData['startDate']).format('YYYY-MM-DD');
    req['endDate'] = moment(this.formData['endDate']).format('YYYY-MM-DD');
    req['assetClass'] = null;
    req['subsequentEventsPeriodFrom'] = null;
    req['subsequentEventsPeriodThrough'] = null;
    req['sooGroup'] = null;
    req['expenseBorneByGP'] = null;
    req['affiliatedMembersCapital'] = null;
    req['inKindContributions'] = null;
    req['cashLimit'] = null;
    req['sidePocket'] = null;
    req['stakingIncome'] = null;
    req['stakedAssets'] = null;
    // req['notesToAccount'] = null;
    delete req['id']
    delete req['configId']
    delete req['scheduleData']
    delete req['username']
    delete req['configName']
    delete req['updatedBy']
    delete req['updatedOn']
    delete req['insertedBy']
    delete req['insertedOn']
    req['locked'] = false;
    req['duplicate'] = true;
    req['configId'] = this.prevConfigId;
    this.setFsconfig(req).subscribe(response => {
      if(response && !response.success) {
        this.messageService.add({
          closable: true, sticky: true, severity: 'error', summary: this.translate.instant('failed'),
          detail: this.translate.instant(response.message)
        });
        this.formValid = false
        return;
      }
      if (response && response.data) {
        let setscheduleConfigReq = this.rowDataSchedule || {}
        // if (setscheduleConfigReq['sofc']) {
        //   delete setscheduleConfigReq['sofc']['roundingAdjustment']
        //   delete setscheduleConfigReq['sofc']['chartOfAccounts']
        //   delete setscheduleConfigReq['sofc']['headerAlias']
        // }
        // if (setscheduleConfigReq['asc815']) {
        //   delete setscheduleConfigReq['asc815']['headerAlias']
        // }
        // if (setscheduleConfigReq['cashFlow']) {
        //   delete setscheduleConfigReq['cashFlow']['headerAlias']
        //   delete setscheduleConfigReq['cashFlow']['footnotesToCashFlow']
        // }

        // if (setscheduleConfigReq['scna']) {
        //   delete setscheduleConfigReq['scna']['headerAlias']
        // }

        // if (setscheduleConfigReq['fihi']) {
        //   delete setscheduleConfigReq['fihi']['headerAlias']
        // }

        // if (setscheduleConfigReq['soo']) {
        //   delete setscheduleConfigReq['soo']['headerAlias']
        //   delete setscheduleConfigReq['soo']['expenseBorneByGP']
        //   delete setscheduleConfigReq['soo']['sooGroup']
        //   delete setscheduleConfigReq['soo']['fsGroupName']
        // }

        // if (setscheduleConfigReq['annexure']) {
        //   delete setscheduleConfigReq['annexure']['headerAlias']
        // }

        setscheduleConfigReq['configId'] = response.data.configId
        setscheduleConfigReq['fundId'] = response.data['fundId']
        delete setscheduleConfigReq['id']
        this.setscheduleConfig(setscheduleConfigReq).subscribe(res => {
          if (res && res.data) {
            let reqObj = {
              configId: response.data['configId'],
              prevConfigId: this.prevConfigId,
              fundId: response.data['fundId'],
              endDate: moment(response.data['endDate'],'YYYY-MM-DD').format('YYYY-MM-DD'),
            }
            this.gridService.copyChartOfAccountsConfig(reqObj).subscribe(res => {
              if (res) {
                this.gridService.copyFSPositionConfig(reqObj).subscribe(res => {
                  if (res) {
                    this.dialogRef.close(true);
                    this.messageService.add({
                      closable: true, sticky: true, severity: 'success', summary: this.translate.instant('success'),
                      detail: this.translate.instant('record_created')
                    });
                  }
                })
              }
            });

          } else {
            this.messageService.add({
              closable: true, sticky: true, severity: 'error', summary: this.translate.instant('failed'),
              detail: this.translate.instant(res.message)
            });
          }
          setTimeout(() => (this.formValid = false), 20);

        })
      } else {
        this.messageService.add({
          closable: true, sticky: true, severity: 'warn', summary: this.translate.instant('warning'),
          detail: this.translate.instant('fund_configuration_already_exist')
        });
        this.formValid = false
      }
    }, error => {
      this.formValid = false
      this.messageService.add({
        closable: true, sticky: true, severity: 'error', summary: this.translate.instant('error'),
        detail: this.translate.instant('record_creation_failed')
      });
    })
  }
  fetchAllByEnterprise(): Observable<any> {
    return this.http.get<any>('fundV2/fetchAllByEnterprise')
  }

  setFsconfig(data) {
    return this.http.post<any>('fSConfig/create', data);
  }
  setscheduleConfig(data) {
    return this.http.post<any>('scheduleConfig/create', data);
  }
}
