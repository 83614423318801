/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { apiConstants } from 'src/app/constants/apiConstants';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { GlobalMessageService } from '../../services/commonServices/global-message.service';
import { TranslateService } from '@ngx-translate/core';
import { GridService } from '../../services/gridServices/grid.service';
import { DataService } from '../../services/commonServices/data.service';
import { LocalStoreService } from '../../services/commonServices/local-store.service';
import { CookieService } from 'ngx-cookie-service';
import { CommonService } from '../../services/commonServices/common.service';

@Component({
  selector: 'fury-isd-button',
  templateUrl: './isd-button.component.html',
  styleUrls: ['./isd-button.component.scss']
})


export class IsdButtonComponent {

  public httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
    })
  };

  public params: any;
  public moduleName: any;
  public pdfMsg: any;
  public data: any;
  public isdValidatedData: any;
  public toolTipDescriptions: any;
  public documentLevel: any;
  public searchObj: {};
  public emailTemplateView: any;
  public currentUser: any;
  public isdAdhocInvestorAttachment:boolean;

  constructor(private http: HttpClient,
    public translate: TranslateService,
    private messageService: GlobalMessageService,
    private gridService: GridService,
    private dataService: DataService,
    private localService: LocalStoreService,
    private cookieService: CookieService,
    private commmonService: CommonService

    
  ) { }

  agInit(params: ICellRendererAngularComp): void {

    this.params = params;
    this.data = this.params.data
    this.emailTemplateView = this.params.data.emailTemplateView;
    this.documentLevel = (this.params.data.documentLevel == 'fundLevel' || this.params.data.documentLevel == 'notificationOnly') ? true : false
    this.moduleName = apiConstants[this.params.api.componentName]['moduleName'];
    this.toolTipDescriptions = this.params.data.documentLevel == 'fundLevel' ? 'Please Select Investor Specific Document' : '';
    this.currentUser = JSON.parse(this.localService.getItem('fss'));
    this.isdAdhocInvestorAttachment = this.currentUser.authList.indexOf('SEAMLESS_ISD_ADHOC_INVESTOR_SPECIFIC_ATTACHMENT') > -1 ? true : false;
  

  }


  pdfDownload() {
    if (this.moduleName == 'customizationISDistribution') {
      this.isdSingleFundPdfDownload()
    }
    if (this.moduleName == 'isdAdhocDistribution') {
      if(this.params.data.documentLevel == 'notificationOnly'){
        this.messageService.add({ closable: true, sticky: true, severity: 'error', summary: "Error", detail: 'This configuration is for notification purpose only.' });
      }
      else{
      this.isdAdhocPdfDownload()
      }
    }
    else {
      this.dataService.getIsdConsolidateReportData().subscribe(info => {
        if (info) {
          this.isdValidatedData = info;
        }
      })
      this.isdGroupPdfDownload(this.isdValidatedData)

    }
  }

  isdSingleFundPdfDownload() {
    let selectedList = [];
    this.searchObj = {};
    this.searchObj['fundId'] = this.params.data.fundId
    this.searchObj['investorId'] = this.params.data.seamlessInvestorId;
    this.searchObj['investorSrNo'] = this.params.data.seamlessInvestorSrNo
    selectedList.push(this.searchObj);

    let reportId = {
      "reportId": this.params.data.reportId,
      "investorsDetail": selectedList
    };
    this.gridService.previewIsdReportResponseDTOList(reportId).subscribe(res => {

      if (res.list) {
        res.list.forEach((element: any) => {
          this.onClickDownloadPdf(element)
        })
      }

    })


  }


  isdAdhocPdfDownload() {
    let selectedList = [];
    this.searchObj = {};
    this.searchObj['fundId'] = this.params.data.fundId
    this.searchObj['investorId'] = this.params.data.seamlessInvestorId;
    this.searchObj['investorSrNo'] = this.params.data.seamlessInvestorSrNo,
    selectedList.push(this.searchObj);

    let reportId = {
      "adhocId": this.params.data.adhocId.toString(),
      "investorsDetail": selectedList
    };
    this.downloadZipWithCsv('adhovc',reportId)
 
  }
  downloadZipWithCsv(componentName, searchObject) {
    let url = apiConstants['isdAdhocDistribution']['pdfDownload'].url;
    this.commmonService.GenericPostDownload(searchObject,url,'adhoc.zip')
    this.messageService.add({ sticky: true, severity: 'success', summary: 'Success', detail: 'Please wait while we generate the file.' });
    }
  
    isdGroupPdfDownload(data) {
    let selectedList = [];
    if (data.groupInvestorSrNo === "Yes") {
      let selectedInvestorSrNoList = [];
      let filterInvestorSrNoData = [];
      this.searchObj = {};
      this.searchObj['investorSrNo'] = this.params.data.seamlessInvestorSrNo;
      selectedInvestorSrNoList.push(this.searchObj)
      filterInvestorSrNoData = data.isdValidatedData.filter(function (o1) {
        return selectedInvestorSrNoList.some(function (o2) {
          return o1.seamlessInvestorSrNo == o2.investorSrNo.toString(); // return the ones with equal id
        });
      });

      filterInvestorSrNoData.forEach((element: any) => {
        this.searchObj = {};
        this.searchObj['fundId'] = element.fundId
        this.searchObj['investorId'] = element.seamlessInvestorId;
        this.searchObj['investorSrNo'] = element.seamlessInvestorSrNo;
        this.searchObj['reportId'] = element.reportId.toString();
        selectedList.push(this.searchObj)

      });
    }
    else {
      this.searchObj = {};
      this.searchObj['fundId'] = this.params.data.fundId
      this.searchObj['investorId'] = this.params.data.seamlessInvestorId;
      this.searchObj['investorSrNo'] = this.params.data.seamlessInvestorSrNo
      this.searchObj['reportId'] = this.params.data.reportId;
      selectedList.push(this.searchObj);

    }


    let reportId = {
      "groupId": this.params.data.groupId,
      "investorsDetail": selectedList
    };
    this.gridService.previewIsdGroupReportResponseDTOList(reportId).subscribe(res => {

      if (res.list) {
        res.list.forEach((element: any) => {
          this.onClickDownloadPdf(element)
        })
      }
    })

  }

  downloadPdf(base64String, fileName) {
    const source = `data:application/pdf;base64,${base64String}`;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${fileName}`
    link.click();
  }
  onClickDownloadPdf(ele) {
    if (ele.pdfMsg || ele.pdf) {
      if (ele.pdfMsg) {
        this.messageService.add({ sticky: true, severity: 'error', summary: 'Investor Statement Distribution', detail: ele.pdfMsg });
      }
      else {

        let base64String = ele.pdf;
        this.downloadPdf(base64String, ele.fileName);
      }
    }
    else {
      this.messageService.add({ sticky: true, severity: 'error', summary: 'Investor Statement Distribution', detail: 'PDF File Does Not Exist' });

    }
  }
  previewTemplateReport() {
    let selectedNode = this.params.node;
    let selectedData = selectedNode.data;

    if (this.params.previewTemplateReport instanceof Function) {
      const params = {
        event: event,
        rowData: selectedData
      }
      this.params.previewTemplateReport(params);
    }
  }

  investorSpecificAttDocument(evt) {
    if(this.documentLevel){
      return;
    }
    let selectedNode = this.params.node;
    let selectedData = selectedNode.data;
    if (this.params.investorSpecificDocument instanceof Function) {
      const params = {
        event: event,
        rowData: selectedData
      }

      this.params.investorSpecificDocument(params);
    }
  }
}
