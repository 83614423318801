/*******************************************************************************
 * FORMIDIUM Corp. COPYRIGHT STATEMENT
 *  __________________
 *
 *  2022 - 2023 FORMIDIUM Corp.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of FORMIDIUM Corp.
 * The intellectual and technical concepts contained
 * herein are proprietary to FORMIDIUM Corp.
 * and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret and/or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
 * by visiting www.formidium.com.
 ******************************************************************************/
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { DatePipe } from "@angular/common";
import * as _ from "lodash";
import moment from "moment";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { apiConstants } from "../../../constants/apiConstants";
import { TranslateService } from "@ngx-translate/core";
import { GlobalMessageService } from "./global-message.service";
import { LocalStoreService } from "./local-store.service";
import { CookieService } from "ngx-cookie-service";
import { DomSanitizer } from "@angular/platform-browser";
import { SidenavService } from "src/app/layout/sidenav/sidenav.service";
import { Router } from "@angular/router";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  public httpOptions = {
    headers: new HttpHeaders({
      "Access-Control-Allow-Origin": "*",
    }),
  };
  public moduleNameObs$: BehaviorSubject<any> = new BehaviorSubject("Trades");
  private _selectedTab = new BehaviorSubject<String>("AUA");
  currentUser: any;
 reportingType: any;
  fundPeriodLockDate: any;
  result: any;
  arrFunds: any;
  authList: any;
  userPerferenceList: any;
  payLoad: any = [];
  userSettingsList: any;
  minMaxYaxisChartValue = {};
  userInfo: any;
  footerData: any = {};

  public timeStamp = [
    {
      label: "1:59:59 AM",
      value: "1:59:59 AM",
      id: 1,
    },
    {
      label: "2:59:59 AM",
      value: "2:59:59 AM",
      id: 2,
    },
    {
      label: "3:59:59 AM",
      value: "3:59:59 AM",
      id: 3,
    },
    {
      label: "4:59:59 AM",
      value: "4:59:59 AM",
      id: 4,
    },
    {
      label: "5:59:59 AM",
      value: "5:59:59 AM",
      id: 5,
    },
    {
      label: "6:59:59 AM",
      value: "6:59:59 AM",
      id: 6,
    },
    {
      label: "7:59:59 AM",
      value: "7:59:59 AM",
      id: 7,
    },
    {
      label: "8:29:59 AM",
      value: "8:29:59 AM",
      id: 26,
    },
    {
      label: "8:59:59 AM",
      value: "8:59:59 AM",
      id: 8,
    },
    {
      label: "9:59:59 AM",
      value: "9:59:59 AM",
      id: 9,
    },
    {
      label: "10:59:59 AM",
      value: "10:59:59 AM",
      id: 10,
    },
    {
      label: "11:59:59 AM",
      value: "11:59:59 AM",
      id: 11,
    },
    {
      label: "12:59:59 AM",
      value: "12:59:59 AM",
      id: 12,
    },
    {
      label: "1:59:59 PM",
      value: "1:59:59 PM",
      id: 13,
    },
    {
      label: "2:59:59 PM",
      value: "2:59:59 PM",
      id: 14,
    },
    {
      label: "3:59:59 PM",
      value: "3:59:59 PM",
      id: 15,
    },
    {
      label: "4:59:59 PM",
      value: "4:59:59 PM",
      id: 16,
    },
    {
      label: "5:59:59 PM",
      value: "5:59:59 PM",
      id: 17,
    },
    {
      label: "6:59:59 PM",
      value: "6:59:59 PM",
      id: 18,
    },
    {
      label: "7:59:59 PM",
      value: "7:59:59 PM",
      id: 19,
    },
    {
      label: "8:59:59 PM",
      value: "8:59:59 PM",
      id: 20,
    },
    {
      label: "9:29:59 PM",
      value: "9:29:59 PM",
      id: 25,
    },
    {
      label: "9:59:59 PM",
      value: "9:59:59 PM",
      id: 21,
    },
    {
      label: "10:59:59 PM",
      value: "10:59:59 PM",
      id: 22,
    },
    {
      label: "11:59:59 PM",
      value: "11:59:59 PM",
      id: 23,
    },
    {
      label: "12:59:59 PM",
      value: "12:59:59 PM",
      id: 24,
    },
    {
      label: "10:29:59 PM",
      value: "10:29:59 PM",
      id: 25,
    },
    {
      label: '5:59:00 PM',
      value: '5:59:00 PM',
      id: 25
    }
  ];

  public dataSource = [
    {
      label: "CoinGecko",
      value: "CoinGecko",
    },
    {
      label: "Coinbase",
      value: "Coinbase",
    },
    {
      label: "Coin Market Cap",
      value: "Coin Market Cap",
    },
    {
      label: "Reuters",
      value: "Reuters",
    },
    {
      label: "Bloomberg",
      value: "Bloomberg",
    },
    {
      label: "CME",
      value: "CME",
    },
    {
      label: "Coin Market Cap 8:59:59 PM",
      value: "Coin Market Cap 8:59:59 PM",
    },
    {
      label: "Coin Market Cap 11:59:59 PM",
      value: "Coin Market Cap 11:59:59 PM",
    },
    {
      label: "Coin Market Cap 3:59:59 PM",
      value: "Coin Market Cap 3:59:59 PM",
    },
    {
      label: "CoinGecko 8:59:59 PM",
      value: "CoinGecko 8:59:59 PM",
    },
    {
      label: "CoinGecko 11:59:59 PM",
      value: "CoinGecko 11:59:59 PM",
    },
    {
      label: "CoinGecko 3:59:59 PM",
      value: "CoinGecko 3:59:59 PM",
    },
    {
      label: "CoinGecko 9:59:59 PM",
      value: "CoinGecko 9:59:59 PM",
    },
    {
      label: "CoinGecko 3:59:59 AM",
      value: "CoinGecko 3:59:59 AM",
    },
    {
      label: "Copper",
      value: "Copper",
    },
    {
      label: "Bitmex 00:00:00",
      value: "Bitmex 00:00:00",
    },
    {
      label: "Bitmex 04:00:00",
      value: "Bitmex 04:00:00",
    },
    {
      label: "FTX 00:00:00",
      value: "FTX 00:00:00",
    },
    {
      label: "Okex Futures 00:00:00",
      value: "Okex Futures 00:00:00",
    },
    {
      label: "Binance Futures 00:00:00",
      value: "Binance Futures 00:00:00",
    },
    {
      label: "QUODD",
      value: "QUODD",
    },
    {
      label: "Kraken",
      value: "Kraken",
    },
    {
      label: "KuCoin",
      value: "KuCoin",
    },
    {
      label: "Huobi",
      value: "Huobi",
    },
    {
      label: "Bybit",
      value: "Bybit",
    },
    {
      label: "Deribit",
      value: "Deribit",
    },
    {
      label: "FTX",
      value: "FTX",
    },
    {
      label: "Bitmex",
      value: "Bitmex",
    },
    {
      label: "Okex Futures",
      value: "Okex Futures",
    },
    {
      label: "Binance Futures",
      value: "Binance Futures",
    },
    {
      label: 'Bitget',
      value: 'Bitget',
    },
    {
      label: 'Crypto.com',
      value: 'Crypto.com',
    },
    {
      label: 'Vinter',
      value: 'Vinter',
    }
  ];

  public PeriodLockDateComponent: any = {
    smEquitiesList: ["dateOfRecordCreation"],
    rapidSmEquitiesList: ["dateOfRecordCreation"],
    globalSMEquitiesList: ["dateOfRecordCreation"],
    rapidCrypto: ["dateOfRecordCreation"],
    globalCrypto: ["dateOfRecordCreation"],
    smFixedIncomeList: ["dateOfRecordCreation"],
    rapidSmFixedIncomeList: ["dateOfRecordCreation"],
    globalSmFixedIncomeList: ["dateOfRecordCreation"],
    smOptionsList: ["dateOfRecordCreation"],
    RapidSmOptionsList: ["dateOfRecordCreation"],
    globalSmOptionsList: ["dateOfRecordCreation"],
    smFutureList: ["dateOfRecordCreation"],
    RapidSmFutureList: ["dateOfRecordCreation"],
    globalSmFutureList: ["dateOfRecordCreation"],
    smFxForwardList: ["dateOfRecordCreation"],
    rapidSmFxForwardList: ["dateOfRecordCreation"],
    smPrivateFundList: ["dateOfRecordCreation"],
    RapidSmPrivateFundList: ["dateOfRecordCreation"],
    globalSmPrivateFundList: ["dateOfRecordCreation"],
    RapidSmPools: ["dateOfRecordCreation"],
    globalSmPools: ["dateOfRecordCreation"],
    bondinterestadjustment: ["date"],
    rapidBondinterestadjustment: ["date"],
    caspinoff: ["effectiveDate"],
    dividendaccrual: ["exDate"],
    rapidDividend: ["exDate"],
    camerger: ["effectiveDate"],
    casplit: ["effectiveDate"],
    setupbondinterest: ["lastPayDate"],
    rapidSetupbondinterest: ["lastPayDate"],
    caspecialdividend: ["exDate"],
    dividendadjuctment: ["date"],
    RapidDividendadjuctment: ["date"],
    marketdata: ["valuationDateAndTime"],
    rapidMarketdata: ["valuationDateAndTime"],
    marketdatavar: ["valuationDateAndTime"],
    exchangerate: ["exchangeDate"],
    rapidExchangerate: ["exchangeDate"],
    benchmark: ["date"],
    rapidBenchmark: ["date"],
    benchmarkirr: ["jeDate"],
    rapidBenchmarkirr: ["jeDate"],
    capitalActivities: ["effectivedate"],
    rapidCapitalActivities: ["effectivedate"],
    nonTradeActivity: ["date"],
    rapidNonTradeActivity: ["date"],
    journalentry: ["jedate"],
    RapidJournalentry: ["jedate"],
    uploadallocationhistory: ["date"],
    rapidUploadallocationhistory: ["date"],
    sidepocketcreation: ["effectiveDate"],
    sidepocketredemption: ["redemptionDate"],
    rapidSidepocketredemption: ["redemptionDate"],
    rapidSidepocketcreation: ["effectiveDate"],
    externalROR: ["date"],
    rapidExternalROR: ["date"],
    midPeriodReset: ["resetDate"],
    specialAllocation: ["startDate"],
    rapidSpecialAllocation: ["startDate"],
    customRatio: ["effectiveDate"],
    excludeComposite: ["date"],
    tradingLevelChange: ["effectivedate"],
    rapidTradingLevelChange: ["effectivedate"],
    TLRegime: ["date"],
    rapidTLRegime: ["date"],
    feeRateChange: ["feeResetDate"],
    takeOverPerfBaseFee: ["date"],
    rapidTakeOverPerfBaseFee: ["date"],
    tradeList: ["tradeDate"],
    rapidTradeList: ["tradeDate"],
    orderList: ["tradeDate"],
    historicalwashsaleopenLot: ["pnlDate"],
    rapidHistoricalwashsaleopenLot: ["pnlDate"],
    dailyMargin: ["date"],
    rapidDailyMargin: ["date"],
    seamlessmarketdata: ["valuationDateAndTime"],
    RapidSeamlessmarketdata: ["valuationDateAndTime"],
    newIssues: ["issueStartDate"],
    rapidNewIssues: ["issueStartDate"],
    viewDistribution: ["distributionDate"],
    // "programHistoricalData": ["date"],
    Manager: ["ctaManagerStartDate"],
    viewCapitalCall: ["capitalCallDate"],
    rapidViewCapitalCall: ["capitalCallDate"],
    tradeEntryBroker: ["tradeDateTime"],
    rapidTradeEntryBroker: ["tradeDateTime"],
    positionBroker: ["pnlDate"],
    rapidPositionBroker: ["pnlDate"],
    cashBalanceBroker: ["date"],
    rapidCashBalanceBroker: ["date"],
    recurringTransaction: ["startdate"],
    rapidRecurringTransaction: ["startdate"],
    feeSharing: ["effectiveDateStart"],
    incomeDistribution: ["effectiveDateStart"],
    hurdleRate: ["date"],
    shareSeries: ["seriesDate"],
    sourceDestTradeTransferTaxLotInfo: ["transferDate"],
    riskMaster: ["valuationDate"],
    performanceAnalyticsSettings: ["fundVAMICalcSincePeriod"],
    omslist: ["tradeDateTime"],
    rapidOmslist: ["tradeDateTime"],
    positionByTaxlot: ["date"],
    tradeSucessListHV: ["tradeDateTime"],
    rapidTradeSucessListHV: ["tradeDateTime"],
    tradehv: ["tradeDateTime"],
    rapidhighVolume: ["tradeDateTime"],
    reBudgets: ["monthEndDate"],
    rapidReBudgets: ["monthEndDate"],
    paymentPayableData: ["paymentDate"],
    rapidPaymentPayableData: ["paymentDate"],
    bill: ["postingDate"],
    unpaidBills: ["postingDate"],
    rapidBill: ["postingDate"],
    rapidUnpaidBills: ["postingDate"],
    grossCashFlowAdjustment: ["date"],
    contributionDistributionAdjustment: ["date"],
    rapidDividendBroker : ["reportDate"]
  };

  private dataSource1 = new BehaviorSubject<any>(true);

  constructor(
    private datePipe: DatePipe,
    public translate: TranslateService,
    private messageService: GlobalMessageService,
    private http: HttpClient,
    private localService: LocalStoreService,
    private cookieService: CookieService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.authList = JSON.parse(this.localService.getItem("fss"))["authList"];
    this.currentUser = JSON.parse(this.localService.getItem("fss"));
    this.reportingType = this.currentUser.reportFrequency;
  }

  formatDataUsingGridHeaders(headers: any) {
    for (let i = 0; i < headers.length; i++) {
      switch (headers[i]["valueFormatter"]) {
        case "dateFormatter": {
          this.formatDate(headers[i]);
          break;
        }
        case "dateTimeFormatter": {
          this.formatDateTime(headers[i]);
          break;
        }
        case "longDateFormatter": {
          this.formatLongDate(headers[i]);
          break;
        }
        case "dateTimeSecFormatter": {
          this.formatDateTimeSec(headers[i]);
          break;
        }
        case "dateTimeLocalFormatter": {
          this.formatDateTimeLocal(headers[i]);
          break;
        }
        case "emailFormatter": {
          this.maskEmailFormatter(headers[i]);
          break;
        }
      }
    }
    return headers;
  }
  getRestCallNameForRorData(optionalReportingTypeParams?) {
    let reportingType = optionalReportingTypeParams || JSON.parse(this.reportingType);
    switch (reportingType) {
      case "Monthly": {
        return "getLatestRorDataMonthly"
      }
      case "Daily": {
        return "getLatestRorData"
      }
      case "Quarterly": {
        return "getLatestRorDataQuarterly"
      }
      case "Annually": {
        return "getLatestRorDataAnnually"
      }
      default: {
        return "getLatestRorData"
      }
    }
  }
  formatDate(headers: any) {
    let root = this;
    headers["valueFormatter"] = function (params: any) {
      if (params && params.value) {
        return root.convertDate(params.value.toString().split(" ")[0]);
      }
    }
  }


  shouldAddStart(startDate, selectedDate) {
    let startdate = this.getDateInSeconds(this.convertDateToString(new Date(startDate)));
    let selecteddate = this.getDateInSeconds(this.convertDateToString(new Date(selectedDate)));
    return startdate >= selecteddate ? true : false;
  }


  getValueAndLabels(resultList, value, label, name?) {
    let result = [];
    if (result && resultList != null && resultList.length) {
      resultList.forEach(element => {
        if (name) {
          result.push({ 'value': element[value], 'label': element[label], 'currency': element[name] })
        } else {
        result.push({ 'value': element[value], 'label': element[label] })
        }
      });
    }
    return result;
  }

  getEndForChart(date, lockingPeriod, Annually?) {
    let dateArray = date.split('-');
    let lockingperiod = lockingPeriod.split('-');
    if (Annually) {
      if (dateArray[0] === dateArray[0]) {
        return dateArray[0] + '-' + lockingperiod[1] + '-' + lockingperiod[2];
      } else {
        return dateArray[0] + '-12-31';
      }
    } else {
      if (dateArray[1] === lockingperiod[1] && dateArray[0] === dateArray[0]) {
        return dateArray[0] + '-' + dateArray[1] + '-' + lockingperiod[2];
      } else {
        return dateArray[0] + '-' + dateArray[1] + '-' + new Date(dateArray[0], dateArray[1], 0).getDate();
      }
    }
  }



  getDateInSeconds(dateValue) {
    let dateArray = dateValue.split('-');
    let date = new Date(dateArray[0], dateArray[1] - 1, dateArray[2], 0, 0, 0, 0);
    return date.getTime();
  }

  getLabels(list, id) {
    for (let i = 0; i < list.length; i++) {
      if (list[i]['value'] === id) {
        return list[i]['label']
      }
    }
  }


  convertDateToString(val, removedate?) {
    let month = moment(val).format('MM');
    let date = moment(val).format('DD');
    let year = moment(val).format('YYYY');
    return removedate ? year + '-' + month : year + '-' + month + '-' + date;
  }

  sortResultByDate(resultList) {
    resultList.sort(function (a, b) {
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    })
    return resultList;
  }
  maskEmailFormatter(headers: any) {
    headers["valueFormatter"] = function (params: any) {
      if (params && params.value) {
        let split = params.value.split("@");
        if (split[1] != (undefined || null)) {
          let splitdot = split[1].split(".");
          if (
            splitdot[1] != (undefined || null) &&
            split[1] != (undefined || null)
          ) {
            return (
              params.value.substr(0, 1) +
              new Array(split[0].length - 1).fill("x").join("") +
              "@" +
              split[1].substr(0, 1) +
              new Array(splitdot[0].length - 1).fill("x").join("") +
              "." +
              splitdot[1].substr(0, 1) +
              new Array(splitdot[1].length - 1).fill("x").join("")
            );
          }
        }
      }
    };
  }

  maskEmail(email) {
    if (email) {
      let split = email.split("@");
      if (split[1] != (undefined || null)) {
        let splitdot = split[1].split(".");
        if (
          splitdot[1] != (undefined || null) &&
          split[1] != (undefined || null)
        ) {
          return (
            email.substr(0, 1) +
            new Array(split[0].length - 1).fill("x").join("") +
            "@" +
            split[1].substr(0, 1) +
            new Array(splitdot[0].length - 1).fill("x").join("") +
            "." +
            splitdot[1].substr(0, 1) +
            new Array(splitdot[1].length - 1).fill("x").join("")
          );
        }
      }
    }
  }

  formatLongDate(headers: any) {
    let root = this;
    headers["valueFormatter"] = function (params: any) {
      if (params && params.value) {
        let resMnt = moment(new Date(params.value)).isValid();
        if (resMnt) {
          return moment(new Date(params.value)).format("MM/DD/YYYY");
        } else {
          return root.convertDate(params.value.toString().split(" ")[0]);
        }
      }
    };
  }

  formatDateTimeSec(headers: any) {
    let root = this;
    headers["valueFormatter"] = function (params: any) {
      if (params && params.value) {
        return root.convertDatetimeSec(params.value.toString());
      }
    };
  }

  formatDateTimeLocal(headers: any) {
    let root = this;
    headers["valueFormatter"] = function (params: any) {
      if (params && params.value) {
        return root.convertDatetimeLocal(params.value.toString());
      }
    };
  }

  formatDateTime(headers: any) {
    let root = this;
    headers["valueFormatter"] = function (params: any) {
      if (params && params.value) {
        return root.convertDatetime(params.value.toString());
      }
    };
  }

  convertDate(value: any) {
    if (value) {
      let isValidDate =
        value != null && new Date(value).toString() != "Invalid Date"
          ? true
          : false;
      if (!isValidDate) {
        return value;
      } else {
        return this.datePipe.transform(value, "MM/dd/yyyy");
      }
    } else {
      return this.datePipe.transform(value, "MM/dd/yyyy");
    }
  }

  convertDatetime(value: any) {
    if (value != (undefined || null)) {
      if (value.includes("undefined")) {
        return value;
      } else {
        return this.datePipe.transform(value, "MM/dd/yyyy h:mm a");
      }
    } else {
      return this.datePipe.transform(value, "MM/dd/yyyy h:mm a");
    }
  }

  convertDatetimeSec(value: any) {
    if (value != (undefined || null)) {
      if (value.includes("undefined") || value.includes("Invalid date")) {
        return value;
      } else {
        return this.datePipe.transform(value, "MM/dd/yyyy h:mm:ss a");
      }
    } else {
      return this.datePipe.transform(value, "MM/dd/yyyy h:mm:ss a");
    }
  }

  convertDatetimeLocal(value: any) {
    if (value != (undefined || null)) {
      if (value.includes("undefined")) {
        return value;
      } else {
        return this.datePipe.transform(value, "MM/dd/yyyy HH:mm:ss");
      }
    } else {
      return this.datePipe.transform(value, "MM/dd/yyyy HH:mm:ss");
    }
  }

  numFormat(params: any) {
    let num1 = Number(params.value);
    if (!Number.isNaN(num1)) {
      return num1 ? num1.toFixed(2) : "0.00";
    } else {
      return "0.0";
    }
  }

  numberformatTotal(params?: any) {
    let num1 = Number(params.value);
    if (!Number.isNaN(num1)) {
      let decimalValue =
        params.colDef.decimalValue != undefined
          ? params.colDef.decimalValue
          : 2;
      var parts: any = num1 ? num1.toFixed(decimalValue).toString() : "0.00";
      parts = parts.split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
    } else {
      return "0.0";
    }
  }

  fixedDecimalNumber(params: any) {
    if (!Number.isNaN(Number(params.value)) && params.value) {
      let value: any = String(params.value);
      var floatValue =
        value.split(".")[1] > 0 ? value + "0000" : params.value + ".00000";
      return String(floatValue)
        .split(".")
        .map(function (d, i) {
          return i ? d.substr(0, 2) : d;
        })
        .join(".");
    } else {
      return "0.00";
    }
  }

  numberformat(num: any, tobefixed: any, dynamicDecimal: any) {
    if (!num || num == "NaN") {
      var zero = "0.";
      for (var i = 0; i < dynamicDecimal; i++) {
        zero = zero + "0";
      }
      return zero;
    }
    if (tobefixed) {
      var parts = num.toFixed(dynamicDecimal).toString().split(".");
    } else {
      var parts = num.toString().split(".");
    }
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  numberformatFS(num: any) {
    if (!num || num == "NaN") {
      return "0";
    }
    var parts = Math.round(num)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts;
  }

  negativeConverter(data: any, dwdType?: any, percentage?: any) {
    if (percentage) {
      if (dwdType == "") {
        return data[0] == "-"
          ? "<span style='color:red'>(" + data.split("-")[1] + ")%</span>"
          : data + "%";
      } else {
        return data[0] == "-" ? "(" + data.split("-")[1] + ")%" : data + "%";
      }
    } else {
      if (dwdType == "") {
        return data[0] == "-"
          ? "<span style='color:red'>(" + data.split("-")[1] + ")</span>"
          : data;
      } else {
        return data[0] == "-" ? "(" + data.split("-")[1] + ")" : data;
      }
    }
  }

  numFormatuptoEightDigit(params: any) {
    let num1 = Number(params.value);
    if (!Number.isNaN(num1)) {
      return num1 ? num1.toFixed(8) : "0.00";
    } else {
      return "0.0";
    }
  }

  percentageFormat(params: any) {
    return params.value + "%";
  }

  percentageDecimalFormat(params: any) {
    return params.value ? params.value.toFixed(2) + "%" : "0.00 %";
  }

  percentageaddFormat(params: any) {
    return params.value * 100 + "%";
  }

  dateFormatworkflow(params: any) {
    return params.value
      ? moment(new Date(params.value)).format("YYYY-MM-DD")
      : "";
  }

  dateTimeFormatworkflow(params: any) {
    return params.value;
  }

  NegativeNumberColorChange(params: any) {
    if (params.value) {
      if (params.value.split(/()/)[0].toString() == "(") {
        return { color: "red" };
      } else {
        return { color: "none" };
      }
    }
  }

  HideShowCard(temparray: any) {
    this.authList = JSON.parse(this.localService.getItem("fss"))["authList"];
    temparray.forEach((obj: any) => {
      let list: any = [];
      obj.children.forEach((element: any) => {
        if (this.authList.indexOf(element["authView"]) > -1) {
          list.push(element);
        }
      });
      list.length != 0
        ? (obj["checkAuthCard"] = true)
        : (obj["checkAuthCard"] = false);
    });
    return temparray;
  }

  NegativeNumberbrackt(params: any) {
    if (params.value < 0) {
      let temp1;
      temp1 = params.value * -1;
      console.log(temp1);
      let temp: any;
      temp = "(" + temp1.toFixed(2) + ")";
      return temp;
    }
    if (params.value > 0) {
      let temp = params.value.toFixed(2);
      return temp;
    }
  }

  setDateFunction(day: any, today: any) {
    if (day == 1) {
      var friday = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - 3
      );
      return friday;
    } else {
      var odherdays = new Date(today.valueOf() - 1000 * 60 * 60 * 24);
      return odherdays;
    }
  }

  getRestObject(moduleName: string, callName: string, paramsAndBody?: any) {
    let resourceObject = apiConstants[moduleName][callName];
    let constructedUrl = resourceObject["url"];
    let returnObject = {
      url:
        paramsAndBody &&
          (!!paramsAndBody["params"] || !!paramsAndBody["urlParams"])
          ? this.constructUrl(resourceObject, paramsAndBody)
          : constructedUrl,
      method: resourceObject["method"],
      body: paramsAndBody && paramsAndBody["body"] ? paramsAndBody["body"] : {},
    };
    return returnObject;
  }

  constructUrl(resource: any, paramsAndBody?: any) {
    let splitUrl = resource["url"].split("/");
    if (paramsAndBody["params"]) {
      for (let key in paramsAndBody["params"]) {
        let index = splitUrl.indexOf(key);
        if (index > -1) {
          splitUrl[index] = paramsAndBody["params"][key];
        }
      }
    }
    return (
      splitUrl.join("/") + this.constructURLParams(paramsAndBody["urlParams"])
    );
  }

  constructURLParams(urlParamsObject: any) {
    if (
      urlParamsObject === undefined ||
      (urlParamsObject && Object.keys(urlParamsObject).length == 0)
    )
      return "";
    let result: any = "?";
    for (let key in urlParamsObject) {
      if (result != "?") result = result + "&";
      result = result + key + "=" + urlParamsObject[key];
    }
    return result;
  }

  dataExport(name: any, searchObj: any, url: any) {
    var reqObj = searchObj;
    var request = new XMLHttpRequest();
    var access_token = this.cookieService.get("access_token");
    request.open("POST", url, true);
    request.setRequestHeader("Content-Type", "application/json; charset=UTF-8");
    request.setRequestHeader("Authorization", "Bearer " + access_token);
    request.responseType = "blob";
    request.onload = function () { };
    request.addEventListener("loadend", () => {
      if (request.status === 200) {
        if (!request.response || !request.response.size) {
          this.messageService.add({
            sticky: true,
            severity: "error",
            summary: this.translate.instant("error"),
            detail: this.translate.instant("Somethig went wrong with download"),
          });
        } else {
          var fileName: any = name + ".csv";
          var disposition: any = request.getResponseHeader(
            "Content-Disposition"
          );
          if (disposition && disposition.indexOf("filename") !== -1) {
            var filenameRegex: any = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches: any = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              fileName = matches[1].replace(/['"]/g, "");
            }
          }
          var blob: any = request.response;
          var downloadLink: any = window.document.createElement("a");
          var contentTypeHeader: any = request.getResponseHeader("Content-Type");
          downloadLink.href = window.URL.createObjectURL(
            new Blob([blob], { type: contentTypeHeader })
          );
          downloadLink.download = fileName.trim();
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      } else {
        this.messageService.add({ sticky: true, severity: 'error', summary: this.translate.instant('error'), detail: this.translate.instant('export_incomplete_contact_support') });
      }
    });
    request.send(JSON.stringify(reqObj));
  }

  /**
   * @param event : triggers a event when the user types in the search box in the AG-Grid
   * @param gridOptions : contains the data to filter
   */
  getDataToFilter(event: any, gridOptions: any) {
    let obj: any = {};
    let filter: any = event.api.filterManager.allAdvancedFilters;
    for (var key of filter) {
      switch (key[1]["column"]["colDef"]["filter"]) {
        case "agTextColumnFilter": {
          if (
            gridOptions.getFilterInstance(key[0]) &&
            gridOptions.getFilterInstance(key[0])["appliedModel"]
          ) {
            obj[key[1]["column"]["colDef"]["field"]] =
              gridOptions.getFilterInstance(key[0])["appliedModel"]["filter"];
          }
          break;
        }
        case "agNumberColumnFilter": {
          if (
            gridOptions.getFilterModel(key[0])[key[0]] &&
            gridOptions.getFilterModel(key[0])[key[0]]["filterType"] &&
            gridOptions.getFilterModel(key[0])[key[0]]["filterType"] ===
            "number"
          ) {
            let val = gridOptions
              .getFilterModel(key[0])
            [key[0]]["filter"].toString();
            if (key[0] != "rating") {
              obj[key[1]["column"]["colDef"]["field"]] = val.includes(".")
                ? val + "LHRXJ10EWRdoubleZRHXJ10JFK"
                : val + "LHRXJ10EWRnumberZRHXJ10JFK";
            } else {
              obj[key[1]["column"]["colDef"]["field"]] = Number(val);
            }
          }
          break;
        }
        case "agDateColumnFilter": {
          if (
            gridOptions.getFilterModel(key[0])[key[0]] &&
            gridOptions.getFilterModel(key[0])[key[0]]["dateFrom"]
          ) {
            obj[key[1]["column"]["colDef"]["field"]] =
              this.getStartDate(
                gridOptions.getFilterModel(key[0])[key[0]]["dateFrom"]
              ) +
              "LHRXJ10EWRdateZRHXJ10JFK" +
              this.getEndDate(
                gridOptions.getFilterModel(key[0])[key[0]]["dateFrom"]
              );
          }
          if (
            gridOptions.getFilterModel(key[0])[key[0]] &&
            gridOptions.getFilterModel(key[0])[key[0]]["type"] === "inRange"
          ) {
            obj[key[1]["column"]["colDef"]["field"]] =
              this.getStartDate(
                gridOptions.getFilterModel(key[0])[key[0]]["dateFrom"]
              ) +
              "LHRXJ10EWRdateZRHXJ10JFK" +
              this.getEndDate(
                gridOptions.getFilterModel(key[0])[key[0]]["dateTo"]
              );
          }
          break;
        }
      }
    }
    return obj;
  }

  getEndDate(val: any) {
    let date = new Date(val);
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      23,
      59,
      59
    );
  }

  getStartDate(val: any) {
    let date = new Date(val);
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      0,
      0,
      0
    );
  }

  fetchRecordsOfUsers(): Observable<any> {
    return this.http.get<any>(
      "user/fetchAllRecordsByEnterprise/",
      this.httpOptions
    );
  }

  fetchFundList(): Observable<any> {
    return this.http.get<any>("fundV2/fetchAllByEnterprise", this.httpOptions);
  }

  getInvestorDetails(data: any): Observable<any> {
    return this.http.get("investorV2/filterByFundId?id=" + data);
  }

  getInvestorGroupDetails(data: any): Observable<any> {
    return this.http.get("InvestorGroupV2/getInvestorGroupByFund/" + data);
  }

  DividentdataExport(name: any, searchObj: any, url: any) {
    var reqObj = searchObj;
    var request = new XMLHttpRequest();
    var access_token = this.cookieService.get("access_token");
    request.open("POST", url, true);
    request.setRequestHeader("Content-Type", "application/json; charset=UTF-8");
    request.setRequestHeader("Authorization", "Bearer " + access_token);

    request.responseType = "blob";
    request.onload = function () { };
    request.addEventListener("loadend", () => {
      if (request.status === 200) {
        if (!request.response || !request.response.size) {
          this.messageService.add({
            sticky: true,
            severity: "success",
            summary: this.translate.instant("confirmed"),
            detail: this.translate.instant("download_request_taken_soon"),
          });
        } else {
          var fileName: any =
            name == "dividendhistory"
              ? name + ".xlsx"
              : "dividendhistory" + ".pdf"; //name + '.xlsx'
          var disposition: any = request.getResponseHeader(
            "Content-Disposition"
          );
          if (disposition && disposition.indexOf("filename") !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              fileName = matches[1].replace(/['"]/g, "");
            }
          }
          var blob = request.response;
          var downloadLink: any = window.document.createElement("a");
          var contentTypeHeader: any =
            request.getResponseHeader("Content-Type");
          downloadLink.href = window.URL.createObjectURL(
            new Blob([blob], { type: contentTypeHeader })
          );
          downloadLink.download = fileName;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      } else {
        this.messageService.add({ sticky: true, severity: 'error', summary: this.translate.instant('error'), detail: this.translate.instant('export_incomplete_contact_support') });
      }
    });
    request.send(JSON.stringify(reqObj));
  }

  getPeriodLockmessage(date: any, FundLockDate: any, UserLockDate: any) {
    if (FundLockDate != null && UserLockDate != null) {
      if (
        moment(FundLockDate).format("YYYY-MM-DD") >=
        moment(UserLockDate).format("YYYY-MM-DD") &&
        moment(date).format("YYYY-MM-DD") <=
        moment(FundLockDate).format("YYYY-MM-DD")
      ) {
        this.messageService.add({
          sticky: true,
          severity: "error",
          summary: this.translate.instant("Error"),
          detail:
            this.translate.instant(
              "date_should_be_greater_than_fund_lock_date"
            ) +
            `(` +
            moment(FundLockDate).format("MM-DD-YYYY") +
            ` )`,
        });

        return false;
      } else if (
        moment(FundLockDate).format("YYYY-MM-DD") <=
        moment(UserLockDate).format("YYYY-MM-DD") &&
        moment(date).format("YYYY-MM-DD") <=
        moment(UserLockDate).format("YYYY-MM-DD")
      ) {
        this.messageService.add({
          sticky: true,
          severity: "error",
          summary: this.translate.instant("Error"),
          detail:
            this.translate.instant("date_should_be_greater_than_lock_date") +
            `(` +
            moment(UserLockDate).format("MM-DD-YYYY") +
            ` )`,
        });

        return false;
      }
    } else if (UserLockDate != null) {
      if (
        moment(date).format("YYYY-MM-DD") <=
        moment(UserLockDate).format("YYYY-MM-DD")
      ) {
        this.messageService.add({
          sticky: true,
          severity: "error",
          summary: this.translate.instant("Error"),
          detail:
            this.translate.instant("date_should_be_greater_than_lock_date") +
            `(` +
            moment(UserLockDate).format("MM-DD-YYYY") +
            ` )`,
        });
        return false;
      }
    } else if (FundLockDate != null) {
      if (
        moment(date).format("YYYY-MM-DD") <=
        moment(FundLockDate).format("YYYY-MM-DD")
      ) {
        this.messageService.add({
          sticky: true,
          severity: "error",
          summary: this.translate.instant("Error"),
          detail:
            this.translate.instant(
              "date_should_be_greater_than_fund_lock_date"
            ) +
            `(` +
            moment(FundLockDate).format("MM-DD-YYYY") +
            ` )`,
        });
        return false;
      }
    }
  }

  getFundPeriodLockDate(fundId: any) {
    this.arrFunds = JSON.parse(this.localService.getItem("fss"))["fundlist"];
    this.arrFunds = this.arrFunds && this.arrFunds.length ? this.arrFunds : [];
    for (var v = 0; v < this.arrFunds.length; v++) {
      if (this.arrFunds[v].id == fundId) {
        this.fundPeriodLockDate = this.arrFunds[v].fundPeriodLockDate;
      }
    }
  }

  getCellFundPeriodLockDate(fundId: any) {
    this.arrFunds = JSON.parse(this.localService.getItem("fss"))["fundlist"];
    this.arrFunds = this.arrFunds && this.arrFunds.length ? this.arrFunds : [];
    for (var v = 0; v < this.arrFunds.length; v++) {
      if (this.arrFunds[v].id == fundId) {
        this.fundPeriodLockDate = this.arrFunds[v].fundPeriodLockDate;
      }
    }
    return this.fundPeriodLockDate;
  }

  getmultiFundPeriodLock(data: any, componentName: any) {
    this.fundPeriodLockDate = null;
    let periodlockstring1 = this.PeriodLockDateComponent[componentName][0];
    for (var v = 0; v < data.length; v++) {
      var fundId;
      if (data[v].fundId != null || data[v].fundId != undefined) {
        fundId = data[v].fundId;
      }
      if (data[v].fundName?.id != null || data[v].fundName?.id != undefined) {
        fundId = data[v].fundName.id;
      }
      if (data[v].fund?.id != undefined || data[v].fund?.id != null) {
        fundId = data[v].fund.id;
      }

      if (
        data[v].sourceFundId != undefined ||
        data[v].sourceFundId != null ||
        data[v].destFundId != undefined ||
        data[v].destFundId != null
      ) {
        if (data[v].sourceFundId != undefined || data[v].sourceFundId != null) {
          fundId = data[v].sourceFundId;
        } else {
          fundId = data[v].destFundId;
        }
      }
      this.arrFunds = JSON.parse(this.localService.getItem("fss"))["fundlist"];
      this.arrFunds =
        this.arrFunds && this.arrFunds.length ? this.arrFunds : [];
      for (var i = 0; i < this.arrFunds.length; i++) {
        let enterpriseDate = moment(data[v][periodlockstring1]).format(
          "YYYY-MM-DD"
        );
        if (this.arrFunds[i].id == fundId) {
          if (
            (this.arrFunds[i].fundPeriodLockDate > enterpriseDate &&
              this.arrFunds[i].fundPeriodLockDate > this.fundPeriodLockDate) ||
            (this.fundPeriodLockDate == null &&
              this.arrFunds[i].fundPeriodLockDate > enterpriseDate)
          ) {
            this.fundPeriodLockDate = this.arrFunds[i].fundPeriodLockDate;
          }
        }
      }
    }
  }

  getPeriodLockmessagefordelete(data: any, componentName: any, type: any) {
    if (this.PeriodLockDateComponent[componentName] == undefined) {
      return true;
    } else {
      let periodlockstring: any =
        this.PeriodLockDateComponent[componentName][0];
      this.currentUser = JSON.parse(this.localService.getItem("fss"));
      if (type == "singleRowDelete") {
        if (data.fundId != null || data.fundId != undefined) {
          this.getFundPeriodLockDate(data.fundId);
        }
        if (data.fundName?.id != null || data.fundName?.id != undefined) {
          this.getFundPeriodLockDate(data.fundName.id);
        }
        if (data.fund?.id != undefined || data.fund?.id != null) {
          this.getFundPeriodLockDate(data.fund.id);
        }
        if (data.sourceFundId != undefined || data.sourceFundId != null) {
          this.getFundPeriodLockDate(data.sourceFundId);
        }
        if (data.destFundId != undefined || data.destFundId != null) {
          this.getFundPeriodLockDate(data.destFundId);
        }

        if (
          componentName == "bill" ||
          componentName == "unpaidBills" ||
          componentName == "rapidBill" ||
          componentName == "rapidUnpaidBills"
        ) {
          for (const key in data) {
            if (data.hasOwnProperty(periodlockstring)) {
              if (key == periodlockstring) {
                for (var i = 0; i < data.entityDetails.length; i++) {
                  data.entityDetails[i][periodlockstring] =
                    data[periodlockstring];
                }
              }
            }
          }
          this.getmultiFundPeriodLock(data.entityDetails, componentName);
        }
        var effectiveDate: any;
        if (componentName == "reBudgets" || componentName == "rapidReBudgets") {
          effectiveDate = data.yearDetails[0].monthEndDate;
          data.yearDetails.forEach((ele: any) => {
            if (ele.monthEndDate <= effectiveDate) {
              effectiveDate = ele.monthEndDate;
            }
          });
        } else {
          if (data[periodlockstring] == null) {
            return true;
          } else {
            effectiveDate = new Date(data[periodlockstring]);
          }
          if (
            componentName == "reBudgets" ||
            componentName == "rapidReBudgets"
          ) {
            data.yearDetails.forEach((ele: any) => {
              if (ele.monthEndDate <= effectiveDate) {
                effectiveDate = ele.monthEndDate;
              }
            });
          }
        }
      } else if (
        type == "deleteFiltered" ||
        type == "deleteSelected" ||
        type == "deleteAll"
      ) {
        var fixDate = this.currentUser.periodLockDate;
        var temp;
        if (componentName == "reBudgets" || componentName == "rapidReBudgets") {
          data.forEach((element: any) => {
            element.yearDetails.forEach((obj: any) => {
              temp = moment(obj[periodlockstring]).format("YYYY-MM-DD");
              if (fixDate >= temp) {
                fixDate = temp;
              }
            });
          });
        } else {
          data.forEach((element: any) => {
            temp = moment(element[periodlockstring]).format("YYYY-MM-DD");
            if (fixDate >= temp) {
              fixDate = temp;
            }
          });
        }
        this.currentUser.periodLockDate == fixDate
          ? (effectiveDate = temp)
          : (effectiveDate = fixDate);
        if (componentName == "reBudgets" || componentName == "rapidReBudgets") {
          let allrecord = [];
          data.forEach((ele: any) => {
            allrecord.push(...ele.yearDetails);
          });
          this.getmultiFundPeriodLock(allrecord, componentName);
        } else if (
          componentName == "bill" ||
          componentName == "unpaidBills" ||
          componentName == "rapidBill" ||
          componentName == "rapidUnpaidBills"
        ) {
          const entityDetails = [];
          data.forEach((ele: any) => {
            ele.entityDetails.forEach((el: any) => {
              el[periodlockstring] = ele[periodlockstring];
            });
            entityDetails.push(...ele.entityDetails);
          });
          this.getmultiFundPeriodLock(entityDetails, componentName);
        } else {
          this.getmultiFundPeriodLock(data, componentName);
        }
      } else {
        return true;
      }
      if (effectiveDate == null) {
        return true;
      } else {
        this.result = this.getPeriodLockmessage(
          effectiveDate,
          this.fundPeriodLockDate,
          this.currentUser.periodLockDate
        );
        this.fundPeriodLockDate = null;
      }
      return this.result;
    }
  }

  downloadZipWithCsv(componentName, searchObject) {
    let url =
      apiConstants[componentName].exportURL.url +
      "?moduleName=" +
      apiConstants[componentName].moduleName;
    var request = new XMLHttpRequest();
    var access_token = this.cookieService.get("access_token");
    request.open("POST", url, true);
    request.setRequestHeader("Content-Type", "application/zip");
    request.setRequestHeader("Authorization", "Bearer " + access_token);
    request.responseType = "arraybuffer";
    request.onload = function () { };
    request.addEventListener("loadend", () => {
      if (request.status === 200) {
        if (!request.response) {
          this.messageService.add({
            closable: true,
            sticky: true,
            severity: "error",
            summary: "confirmed",
            detail: "Something went wrong with download, please try again.",
          });
        } else {
          var fileName =
            apiConstants[componentName].moduleName + "Filtered.zip";
          var disposition = request.getResponseHeader("Content-Disposition");
          if (disposition && disposition.indexOf("filename") !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              fileName = matches[1].replace(/['"]/g, "");
            }
          }
          var blob = request.response;
          var downloadLink = window.document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(
            new Blob([blob], { type: "application/zip" })
          );
          downloadLink.download = fileName.trim();
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      } else {
        this.messageService.add({ sticky: true, severity: 'error', summary: this.translate.instant('error'), detail: this.translate.instant('export_incomplete_contact_support') });
      }
    });
    this.messageService.add({
      closable: true,
      sticky: true,
      severity: "success",
      summary: "Confirmed",
      detail: "Download Request taken. Your download will start soon.",
    });
    request.send(JSON.stringify(searchObject));
  }

  userPerferenceData(data: any): Observable<any> {
    this.payLoad = [];
    this.userSettingsList = apiConstants.userSettingsList;
    this.userSettingsList.forEach((el) => {
      this.payLoad.push(el);
    });
    return this.http.get(
      "userPerferenceV2/searchV2?view=" + this.payLoad + "&userName=" + data
    );
  }

  showPopupApis(data: any): Observable<any> {
    return this.http.get("resignedUrl/getUrl?moduleName=" + data);
  }

  fetchAmcId(fundId: any): Observable<any> {
    return this.http.get<any>(
      "fundV2/fetchAmcByFundId?fundId=" + fundId,
      this.httpOptions
    );
  }

  numFormatuptoEightDigits(params: any) {
    let num1 = Number(params.value);
    if (!Number.isNaN(num1)) {
      return num1 ? num1.toFixed(8) : "0.00";
    } else {
      return "0.00000000";
    }
  }

  getChartData(resultList) {
    let result = {
      xAxesData: [],
      yAxesData: [],
    };
    if (resultList.length) {
      resultList.forEach((element) => {
        if (
          element["vami"] != null &&
          element["vami"] != undefined &&
          element["vami"] != ""
        ) {
          result["xAxesData"].push(element["vamiDate"]);
          result["yAxesData"].push(Number(element["vami"].toFixed(2)));
        }
      });
    }
    return result;
  }

  getChartOptions(xAxesData, yAxesData) {
    return {
      title: {
        text: "",
        bottom: "1%",
        left: "6.4%",
        subtext: "Index (Base 1000)",
      },
      tooltip: {
        trigger: "axis",
      },
      toolbox: {
        show: false,
        feature: {
          mark: { show: true },
          dataView: { show: true, readOnly: false },
          magicType: { show: true, type: ["line", "bar"] },
          restore: { show: true },
          saveAsImage: { show: true },
        },
      },
      calculable: true,
      xAxis: [
        {
          type: "category",
          data: xAxesData,
          boundaryGap: false,
        },
      ],
      yAxis: [
        {
          type: "value",
          min: Math.round(Math.min(...yAxesData)),
          max: Math.round(Math.max(...yAxesData)),
        },
      ],
      series: [
        {
          color: "#69719d",
          data: yAxesData,
          type: "line",
          smooth: true,
          areaStyle: {},
          markLine: {},
        },
      ],
    };
  }

  getMinMaxYaxisValue(yAxesData, type) {
    if (yAxesData.length) {
      var minValue = Math.round(Math.min(...yAxesData));
      var maxValue = Math.round(Math.max(...yAxesData));
      let resultList = [];
      yAxesData.forEach((element) => {
        resultList.push(Number(element));
      });
      if (type === "min") {
        let rem = minValue % 10;
        minValue = minValue - rem;
        this.minMaxYaxisChartValue["min"] = minValue;
        return minValue;
      } else {
        let rem = maxValue % 10;
        maxValue = maxValue + (10 - rem);
        this.minMaxYaxisChartValue["max"] = maxValue;
        return maxValue;
      }
    }
  }

  getChartInterval() {
    return Math.round(
      (this.minMaxYaxisChartValue["max"] - this.minMaxYaxisChartValue["min"]) /
      7
    );
  }

  getButtonDisable(res) {
    return new Promise((resolve) => {
      var a = document.createElement("a");
      var blob = new Blob([res], { type: res.ContentType });
      a.href = URL.createObjectURL(blob);
      a.download = "Quantity and Cost Roll Forward.xlsx";
      a.click();
      resolve(true);
    });
  }

  downloadCSV(data, file) {
    this.messageService.add({ sticky: true, severity: 'success', summary: 'Confirmed', detail: 'Download Request taken. Your download will start soon.' });
    var link = document.createElement('a');
    link.href = data;
    link.download = file;
    if (window.location.hostname == "localhost") {
      link.href = link.href.replace("4200", "8081");
    }
    link.click();
    window.URL.revokeObjectURL(link.href);
  }

  downloadFile(fileUrl): Observable<any> {
    return this.http.get<any>(fileUrl, { responseType: "blob" as "json" });
  }

  downloadfileData(searchObj, fileUrl): Observable<any> {
    return this.http.post<any>(fileUrl, searchObj, {
      responseType: "blob" as "json",
    });
  }

  getIFrameFileDownload(fileUrl): Observable<any> {
    return this.http.get<any>(fileUrl, {
      observe: "response",
      responseType: "blob" as "json",
    });
  }

  // Method to make the HTTP GET request and retrieve the content type
  getBlobResponse(url: string): Observable<{ blob: Blob, contentType: string, filename: string }> {
    // Set responseType to 'blob' in the options
    return this.http.get(url, { responseType: 'blob', observe: 'response' })
      .pipe(
        map((response: HttpResponse<Blob>) => {
          const contentType = response.headers.get('Content-Type') || '';
          const contentDisposition = response.headers.get('Content-Disposition') || '';
          let filename = '';
          const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
          }
          return { blob: response.body, contentType, filename };
        })
      );
  }

  GenericDownload(
    url,
    subComponent?,
    msgName?: string,
    detailMessage?: string
  ) {
    var request = new XMLHttpRequest();
    var access_token = this.cookieService.get("access_token");
    let message = msgName ? msgName : "confirmed";
    let detailMsg = detailMessage
      ? detailMessage
      : "download_request_taken_soon";

    let tmessage = message.toLocaleLowerCase();
    let infos = null;
    if (tmessage.includes("fail")) {
      message = "Info";
      infos = "warn";
    }

    request.open("GET", url, true);
    request.setRequestHeader("Content-Type", "application/json; charset=UTF-8");
    request.setRequestHeader("Authorization", "Bearer " + access_token);
    request.responseType = "blob";
    request.onload = function () { };
    this.messageService.add({
      sticky: true,
      severity: infos != null ? infos : "success",
      summary: this.translate.instant(message),
      detail: this.translate.instant(detailMsg),
    });
    request.addEventListener("loadend", () => {
      if (request.status === 200) {
        const responseMessage = request.getResponseHeader('Message');

        if (!request.response || !request.response.size) {
          this.messageService.add({ closable: true, sticky: true, severity: 'error', summary: "confirmed", detail: 'Something went wrong with download, please try again.' });
        }
        else if (responseMessage) {
          this.messageService.add({ sticky: true, severity: 'error', summary: this.translate.instant('error'), detail: this.translate.instant(responseMessage) });
        }
        else {
          var fileName = (subComponent || "test") + ".csv";
          var disposition = request.getResponseHeader("Content-Disposition");
          if (disposition && disposition.indexOf("filename") !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              fileName = matches[1].replace(/['"]/g, "");
            }
          }
          var blob = request.response;
          var downloadLink = window.document.createElement("a");
          var contentTypeHeader = request.getResponseHeader("Content-Type");
          downloadLink.href = window.URL.createObjectURL(
            new Blob([blob], { type: contentTypeHeader })
          );
          downloadLink.download = fileName.trim();
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      } else {
        this.messageService.add({ sticky: true, severity: 'error', summary: this.translate.instant('error'), detail: this.translate.instant('export_incomplete_contact_support') });
      }
    });
    request.send(null);
  }

  GenericPostDownload(searchObj, url, file?) {
    var reqObj = searchObj;
    var request = new XMLHttpRequest();
    var access_token = this.cookieService.get("access_token");
    request.open("POST", url, true);
    request.setRequestHeader("Content-Type", "application/json; charset=UTF-8");
    request.setRequestHeader("Authorization", "Bearer " + access_token);
    request.responseType = "blob";
    request.onload = function () { };
    request.addEventListener("loadend", () => {
      if (request.status === 200) {
        const responseMessage = request.getResponseHeader('Message');

        if (!request.response || !request.response.size) {
          this.messageService.add({ closable: true, sticky: true, severity: 'error', summary: "confirmed", detail: 'Something went wrong with download, please try again.' });
        }
        else if (responseMessage) {
          this.messageService.add({ sticky: true, severity: 'error', summary: this.translate.instant('error'), detail: this.translate.instant(responseMessage) });
        } 
         else  {
          if(request.getResponseHeader("filename")){
            var fileNameDatamonitring = request.getResponseHeader("filename").split('=')[1].toString();}
            var fileName: any =  file ? file : fileNameDatamonitring;
          var disposition: any = request.getResponseHeader('Content-Disposition');
          if (disposition && disposition.indexOf("filename") !== -1) {
            var filenameRegex: any = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches: any = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              fileName = matches[1].replace(/['"]/g, "");
            }
          }
          
          var blob: any = request.response;
          var downloadLink: any = window.document.createElement("a");
          var contentTypeHeader: any = request.getResponseHeader("Content-Type");
          downloadLink.href = window.URL.createObjectURL(
            new Blob([blob], { type: contentTypeHeader })
          );
          downloadLink.download = fileName.trim();
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      } else {
        this.messageService.add({ sticky: true, severity: 'error', summary: this.translate.instant('error'), detail: this.translate.instant('export_incomplete_contact_support') });
      }
    });
    request.send(JSON.stringify(reqObj));
  }

  genericREPostDownload(searchObj, url, name, responseType?) {
    var request = new XMLHttpRequest();
    var access_token = this.cookieService.get("access_token");
    request.open("POST", url, true);
    request.setRequestHeader(
      "Content-Type",
      "application/json; application/zip; charset=UTF-8"
    );
    request.setRequestHeader("Authorization", "Bearer " + access_token);
    request.responseType = responseType ? responseType : "blob";
    request.onload = function () { };
    request.addEventListener("loadend", () => {
      if (request.status === 200) {
        if (!request.response || !request.response.size) {
          this.messageService.add({
            sticky: true,
            severity: "error",
            summary: this.translate.instant("error"),
            detail: this.translate.instant("Somethig went wrong with download"),
          });
        } else {
          let extType = '.csv';
          var fileName: any = name + extType;
          var disposition: any = request.getResponseHeader(
            "Content-Disposition"
          );
          if (disposition && disposition.indexOf("filename") !== -1) {
            var filenameRegex: any = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches: any = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              fileName = matches[1].replace(/['"]/g, "");
            }
          }
          var blob: any = request.response;
          var downloadLink: any = window.document.createElement("a");
          var contentTypeHeader: any =
            request.getResponseHeader("Content-Type");
          downloadLink.href = window.URL.createObjectURL(
            new Blob([blob], { type: contentTypeHeader })
          );
          downloadLink.download = fileName.trim();
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      } else {
        this.messageService.add({ sticky: true, severity: 'error', summary: this.translate.instant('error'), detail: this.translate.instant('export_incomplete_contact_support') });
      }
    });
    request.send("data=" + searchObj);
  }

  genericIFrameDownloadPost(searchObj, url, filename?, extensionType?) {
    this.postIFrameFileDownload(searchObj, url).subscribe((response) => {
      var link = document.createElement("a");
      var blob = new Blob([response.body], { type: response.body.ContentType });
      link.href = URL.createObjectURL(blob);
      var fileName = filename + "." + extensionType.toLowerCase();
      const contentDisposition = response.headers.get("Content-Disposition");
      if (contentDisposition && contentDisposition.indexOf("filename") !== -1) {
        const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = fileNameRegex.exec(contentDisposition);
        if (matches != null && matches[1]) {
          fileName = matches[1].replace(/['"]/g, "");
        }
      }
      link.download = fileName.trim();
      link.click();
    }),
      (err) => {
        console.error(err);
      };
  }

  getIFrameReportDownload(fileUrl): Observable<any> {
    return this.http.get<any>(fileUrl);
  }

  postIFrameReportDownload(searchObj, fileUrl): Observable<any> {
    return this.http.post<any>(fileUrl, searchObj);
  }

  setUserInfo(userInfo: Object) {
    this.userInfo = userInfo;
  }

  getUserInfo() {
    return this.userInfo;
  }

  setFooterData(footerData: Object) {
    this.footerData = footerData;
  }

  setSelectedTab(selectedTab: String) {
    this._selectedTab.next(selectedTab);
  }

  getFooterData() {
    return this.footerData;
  }

  setIsLogin(isLogin: any) {
    this.dataSource1.next(isLogin);
  }

  getIsLogin() {
    return this.dataSource1.asObservable();
  }

  dataURItoBlob(b64Data) {
    var sliceSize = 512;
    b64Data = b64Data.replace(/^[^,]+,/, "");
    b64Data = b64Data.replace(/\s/g, "");
    var byteCharacters = window.atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }
    let blob = new Blob(byteArrays, { type: "application/pdf" });
    // data should be your response data in base64 format

    const url = URL.createObjectURL(blob);
    return url;
  }

  getBlobFileDownload(fileUrl): Observable<any> {
    return this.http.get<any>(fileUrl);
  }

  downloadPdf(base64String, fileName) {
    let blobData = this.dataURItoBlob(base64String);
    const link = document.createElement("a");
    link.href = blobData;
    link.download = `${fileName}`;
    link.click();
    window.URL.revokeObjectURL(link.href);
  }

  // Method to make the HTTP POST request and retrieve the content type
  postBlobResponse(url: any, reqObj: any): Observable<{ blob: Blob, contentType: string, filename: string }> {
    // Set responseType to 'blob' in the options
    return this.http.post(url, reqObj, { responseType: 'blob', observe: 'response' })
      .pipe(
        map((response: HttpResponse<Blob>) => {
          const contentType = response.headers.get('Content-Type') || '';
          const contentDisposition = response.headers.get('Content-Disposition') || '';
          let filename = '';
          const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
          }
          return { blob: response.body, contentType, filename };
        })
      );
  }

  postIFrameFileDownload(searchObj, fileUrl): Observable<any> {
    return this.http.post<any>(fileUrl, searchObj, { observe: 'response', responseType: 'blob' as 'json' });
  }

  getFileName(res, fileName?) {
    var fileName = fileName;
    const contentDisposition = res.headers.get("Content-Disposition");
    if (contentDisposition && contentDisposition.indexOf("filename") !== -1) {
      const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = fileNameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        let file = matches[1].replace(/['"]/g, "");
        fileName = file.split(".")[0];
      }
    }
    return fileName.trim();
  }

  createObjectURLIFrame(res) {
    var link = document.createElement('a');
    let body = res.body != null ? res.body : res;
    var blob = new Blob([body], { type: 'application/pdf' });
    link.href = URL.createObjectURL(blob);
    return this.sanitizer.bypassSecurityTrustResourceUrl(link.href);
  }

  getGenericIFrameDownload(response) {
    var link = document.createElement("a");
    let body = response.hasOwnProperty("body") ? response.body : response;
    let contentType = response.hasOwnProperty("type")
      ? response.type
      : response.body?.ContentType;

    var blob = new Blob([body], { type: contentType });
    link.href = URL.createObjectURL(blob);
    var fileName = "test.csv";
    const contentDisposition = response.headers.get("Content-Disposition");
    if (contentDisposition && contentDisposition.indexOf("filename") !== -1) {
      const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = fileNameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, "");
      }
    }
    link.download = fileName.trim();
    link.click();
  }

  public subCategoryDataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  setSubCategory(value: any) {
    this.subCategoryDataSubject.next(value);
  }

  getSubCategory(): Observable<any> {
    return this.subCategoryDataSubject.asObservable();
  }
}
