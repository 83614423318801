import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { AuthGaurd } from './shared/services/commonServices/auth.gaurd';
import { Role } from './shared/services/commonServices/role';
import { UsersComponent } from './views/managerSide/managermodule/manager/users/users.component';
import { UserResolver } from './pages/authentication/login/logindata.resolver';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/authentication/login/login.module').then(m => m.LoginModule),
    resolve: { users: UserResolver }
  },
  {
    path: 'generatePassword',
    loadChildren: () => import('./pages/authentication/generate-password/generate-password.module').then(m => m.GeneratePasswordModule),
  },
  {
    path: 'changePassword',
    loadChildren: () => import('./pages/authentication/change-password/change-password.module').then(m => m.ChangePasswordModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/authentication/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
  },
  {
    path: 'otp-password',
    loadChildren: () => import('./pages/authentication/otp-password/otp-password.module').then(m => m.OtpPasswordModule),
  },
  // {
  //   path: 'coming-soon',
  //   loadChildren: () => import('./pages/coming-soon/coming-soon.module').then(m => m.ComingSoonModule),
  // },
  {
    path: '!',
    loadChildren: () => import('./pages/authentication/login/login.module').then(m => m.LoginModule),
  },
  {
    path: '!/login',
    loadChildren: () => import('./pages/authentication/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'verifyIdentity',
    loadChildren: () => import('./pages/authentication/MFA/verify-identity/verify-identity.module').then(m => m.VerifyIdentityModule),
    resolve: { users: UserResolver }
  },
  {
    path: '!/generatePassword',
    loadChildren: () => import('./pages/authentication/generate-password/generate-password.module').then(m => m.GeneratePasswordModule),
  },
  {
    path: 'enableMfa',
    loadChildren: () => import('./pages/authentication/MFA/enable-mfa/enable-mfa.module').then(m => m.EnableMfaModule),
  },
  {
    path: 'googleAuthenticator',
    loadChildren: () => import('./pages/authentication/MFA/google-authenticator/google-authenticator.module').then(m => m.GoogleAuthenticatorModule),
  },
  {
    path: 'smsVerification',
    loadChildren: () => import('./pages/authentication/MFA/sms-vefication/sms-verification.module').then(m => m.SmsVerificationModule),
  },
  {
    path: 'twilioAuthy',
    loadChildren: () => import('./pages/authentication/MFA/twilio-authy/twilio-authy.module').then(m => m.TwilioAuthyModule),
  },
  {
    path: 'chooseMfaType',
    loadChildren: () => import('./pages/authentication/MFA/choose-mfa-type/choose-mfa-type.module').then(m => m.ChooseMfaTypeModule),
  },
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '', redirectTo: '/trades', pathMatch: 'full',
      },
      {
        path: 'reportingPortal',
        loadChildren: () => import('./views/reporting-portal/reporting-portal.module').then(m => m.ReportingPortalModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.Reporting],
          auth: ''
        },
      },
      {
        path: 'enableMfaSetup',
        loadChildren: () => import('./pages/authentication/MFA/enable-mfa/enable-mfa.module').then(m => m.EnableMfaModule),
        canActivate: [AuthGaurd],
        data: {
          auth: ''
        },
      },
      {
        path: 'googleAuthenticatorSetup',
        loadChildren: () => import('./pages/authentication/MFA/google-authenticator/google-authenticator.module').then(m => m.GoogleAuthenticatorModule),
        canActivate: [AuthGaurd],
        data: {
          auth: ''
        },
      },
      {
        path: 'smsVerificationSetup',
        loadChildren: () => import('./pages/authentication/MFA/sms-vefication/sms-verification.module').then(m => m.SmsVerificationModule),
        canActivate: [AuthGaurd],
        data: {
          auth: ''
        },
      },
      {
        path: 'twilioAuthySetup',
        loadChildren: () => import('./pages/authentication/MFA/twilio-authy/twilio-authy.module').then(m => m.TwilioAuthyModule),
        canActivate: [AuthGaurd],
        data: {
          auth: ''
        },
      },
      {
        path: 'chooseSetupMfaType',
        loadChildren: () => import('./pages/authentication/MFA/choose-mfa-type/choose-mfa-type.module').then(m => m.ChooseMfaTypeModule),
        canActivate: [AuthGaurd],
        data: {
          auth: ''
        },
      },
      // {
      //   path: 'dashboard',
      //   loadChildren: () => import('./views/fury/dashboard/dashboard.module').then(m => m.DashboardModule),
      //   canActivate: [AuthGaurd],
      //   data: {
      //     role: [Role.User, Role.Manager],
      //     auth: 'SEAMLESS_DASHBOARD'
      //   },
      // },

      {
        path: 'new-dashboard',
        loadChildren: () => import('./views/fury/new-dashboard/new-dashboard.module').then(m => m.NewDashboardModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'SEAMLESS_DASHBOARD'
        },
      },



      {
        path: 'dashboard',
        loadChildren: () => import('./views/fury/dashboard-carousel/dashboard-carousel.module').then(m => m.CarouselDashboardModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'SEAMLESS_DASHBOARD'
        },
      },


      //**trade module start**//
     
      //trade start
      {
        path: 'trades',
        loadChildren: () => import('./views/trades/trades.module').then(m => m.TradesModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'SIDEMENU_TRADES'
        },
      },
      {
        path: 'fs',
        loadChildren: () => import('./views/finance-statement/finance-statement.module').then(m => m.FinanceStatementModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'REPORTS_FINANCIAL_STATEMENT',
          authsibebar: 'SEAMLESS_FINANCIAL_STATEMENT'
        },
      },
      {
        path: 'template-configuration-preview',
        loadChildren: () => import('./views/finance-statement/fs-config/fs-config-entry/template-configuration/template-configuration-preview/template-configuration-preview.module').then(m => m.TemplateConfigurationPreviewModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'REPORTS_FINANCIAL_STATEMENT',
          authsibebar: 'SEAMLESS_FINANCIAL_STATEMENT'
        },
      },
      {
        path: 'ta',
        loadChildren: () => import('./views/tax-setup/Tax Automation/tax-automation.module').then(m => m.TaxAutomationModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'SEAMLESS_TAXATION_TAXRETURNDETAILS_CREATE',
          authsibebar: 'SEAMLESS_TAXATION_SETUP'
        },
      },
      {
        path: 'reconciliation',
        loadChildren: () => import('./views/Reconciliation/reconciliation.module').then(m => m.reconciliationModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'SIDEMENU_RECONCILIATION'
        },
      },
      {
        path: 'trades/trade-list',
        loadChildren: () => import('./views/trades/trade-list/trade-list.module').then(m => m.TradesListModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'TRADEENTRY_VIEW',
          authsibebar: 'SIDEMENU_TRADES'
        },
      },
      {
        path: 'trades/trade-archive-list',
        loadChildren: () => import('./views/trades/trade-archive-list/trade-archive-list.module').then(m => m.TradesArchiveListModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'TRADEENTRY_ARCHIVE_LIST_VIEW',
          authsibebar: 'SIDEMENU_TRADES'
        },
      },
      {
        path: 'trades/trade-archive',
        loadChildren: () => import('./views/trades/trade-archive/trade-archive.module').then(m => m.TradeArchiveModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'TRADEENTRY_VIEW',
          authsibebar: 'SIDEMENU_TRADES'
        },
      },
      {
        path: 'trades/auto-trade-creation',
        loadChildren: () => import('./views/trades/auto-creation-trades/auto-creation-trades.module').then(m => m.AutoCraetionTradeModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'AUTO_TRADES_CREATIONS',
          authsibebar: 'SIDEMENU_TRADES'
        },
      },
      {
        path: 'trades/trade-unarchive',
        loadChildren: () => import('./views/trades/trade-unarchive/trade-unarchive.module').then(m => m.TradesUnarchiveModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'TRADEENTRY_VIEW',
          authsibebar: 'SIDEMENU_TRADES'
        },
      },
      {
        path: 'trades/trade-list/upload',
        loadChildren: () => import('./views/trades/trade-upload/trade-upload.module').then(m => m.TradesUploadModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'TRADEENTRY_CREATE',
          authsibebar: 'SIDEMENU_TRADES'
        },
      },
      {

        path: 'trades/trade-list/:flag',
        loadChildren: () => import('./views/trades/trade-entry/trade-entry.module').then(m => m.TradesEntryModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'TRADEENTRY_CREATE',
          authsibebar: 'SIDEMENU_TRADES'
        },
      },
      {
        path: 'trades/transfer-trade-raw-data-history/upload',
        loadChildren: () => import('./views/trades/transfer-trade-history-upload/transfer-trade-history-upload.module').then(m => m.TransferTradeHistoryUploadModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'TRADEENTRY_TRANSFERTRADE_RAWDATA',
          authsibebar: 'SIDEMENU_TRADES'
        },
      },
      {
        path: 'trades/transfer-trade-raw-data-history/:flag',
        loadChildren: () => import('./views/trades/transfer-trade-raw-entry/transfer-trade-raw-entry.module').then(m => m.TransferTradeRawEntryModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: '',
          authsibebar: 'SIDEMENU_TRADES'
        },
      },
      //trade end
      //order start
      {
        path: 'trades/order-list',
        loadChildren: () => import('./views/trades/order-list/order-list.module').then(m => m.TradesOrderListModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'ORDER_VIEW',
          authsibebar: 'SIDEMENU_TRADES'
        },
      },
      {
        path: 'trades/oms/:flag',
        loadChildren: () => import('./views/trades/create-order/create-order.module').then(m => m.TradesCreateOrderModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'OMSENTRY_CREATE',
          authsibebar: 'SIDEMENU_TRADES'
        },
      },
      //order end
      //new-issue start
      {
        path: 'trades/new-issues',
        loadChildren: () => import('./views/trades/new-issues/new-issues.module').then(m => m.TradesNewIssuesModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'NEWISSUES_VIEW',
          authsibebar: 'SIDEMENU_TRADES'
        },
      },
      {
        path: 'trades/transfer-trade-history',
        loadChildren: () => import('./views/trades/transfer-trade/transfer-trade.module').then(m => m.TransferTradeModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'TRADEENTRY_TRANSFERTRADE_NEWVERSION',
          authsibebar: 'SIDEMENU_TRADES'
        },
      },
      {
        path: 'trades/tax-lot-status',
        loadChildren: () => import('./views/trades/tax-lot-status/tax-lot-status.module').then(m => m.TaxLotProcessModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'TRADE_TAXLOTSTATUS',
          authsibebar: 'SIDEMENU_TRADES'
        },
      },
      {
        path: 'trades/transfer-trade/:flag',
        loadChildren: () => import('./views/trades/transfer-trade-entry/transfer-trade-entry.module').then(m => m.TransferTradeEntryModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'TRADEENTRY_TRANSFERTRADE_PROCESSREVIEW',
          authsibebar: 'SIDEMENU_TRADES'
        },
      },
      {
        path: 'trades/transfertradeHistoryRawDataUpload',
        loadChildren: () => import('./views/trades/transfer-trade-history-upload/transfer-trade-history-upload.module').then(m => m.TransferTradeHistoryUploadModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'TRADEENTRY_TRANSFERTRADE_RAWDATA',
          authsibebar: 'SIDEMENU_TRADES'
        },
      },
      {
        path: 'trades/transfertradeHistoryUpload',
        loadChildren: () => import('./views/trades/transfer-trade-upload/transfer-trade-upload.module').then(m => m.TransferTradeUploadModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'TRADE_ENTRY_TRANSFER_TRADE_NEW_VERSION_UPLOAD',
          authsibebar: 'SIDEMENU_TRADES'
        },
      },
      {
        path: 'trades/new-issues/:flag',
        loadChildren: () => import('./views/trades/new-issues-entry/new-issues-entry.module').then(m => m.TradesNewIssuesEntryModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'NEWISSUES_CREATE',
          authsibebar: 'SIDEMENU_TRADES'
        },
      },
      {
        path: 'trades/transfer-trade-raw-data-history',
        loadChildren: () => import('./views/trades/transfer-trade-raw-data-history/transfer-trade-raw-data-history.module').then(m => m.TransferTradeRawDataHistoryModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'TRADEENTRY_TRANSFERTRADE_RAWDATA',
          authsibebar: 'SIDEMENU_TRADES'
        },
      },
      {
        path: 'trades/sequential-process-status-review',
        loadChildren: () => import('./views/trades/sequential-process-status-review/sequential-process-status-review.module').then(m => m.SequentialProcessStatusReviewModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'TRADEENTRY_TRANSFERTRADE_PROCESSREVIEW',
          authsibebar: 'SIDEMENU_TRADES'
        },
      },
      {
        path: 'trades/daily-margin',
        loadChildren: () => import('./views/trades/daily-margin/daily-margin.module').then(m => m.TradesDailyMarginModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'DAILYMARGIN_VIEW',
          authsibebar: 'SIDEMENU_TRADES'
        },
      },
      {
        path: 'notes-and-Comments-entry',
        loadChildren: () => import('./views/managerSide/managermodule/manager/notes-comments-entry/notes-comments-entry.module').then(m => m.NotesCommentsEntryModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: '',
        },
      },
      {
        path: 'uploadVendors',
        loadChildren: () => import('./views/managerSide/managermodule/manager/vendors-upload/vendorupload.module').then(m => m.VendoruploadModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: '',
        },
      },
      {
        path: 'vendorcreate',
        loadChildren: () => import('./views/accounting/vendorcreate/vendorcreate/vendorcreate.module').then(m => m.VendorcreateModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: '',
        },
      },
      {
        path: 'trades/daily-margin/upload',
        loadChildren: () => import('./views/trades/daily-margin-upload/daily-margin-upload.module').then(m => m.TradesDailyMarginUploadModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'DAILYMARGIN_VIEW',
          authsibebar: 'SIDEMENU_TRADES'
        },
      },
      {
        path: 'trades/daily-margin/:flag',
        loadChildren: () => import('./views/trades/daily-margin-entry/daily-margin-entry.module').then(m => m.TradesDailyMarginEntryModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'NON_TRADING_TRANSACTION_CREATE',
          authsibebar: 'SIDEMENU_TRADES'
        },
      },
      //daily-margin end
      //trade-allocation-config start
      {
        path: 'trades/trade-allocation-config',
        loadChildren: () => import('./views/trades/trade-allocation-config/trade-allocation-config.module').then(m => m.TradesAllocationConfigModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'TRADEALLOCATIONCONFIG_VIEW',
          authsibebar: 'SIDEMENU_TRADES'
        },
      },
      {
        path: 'trades/trade-allocation-config/:flag',
        loadChildren: () => import('./views/trades/trade-allocation-config-entry/trade-allocation-config-entry.module').then(m => m.TradesAllocationConfigEntryModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'TRADEALLOCATIONCONFIG_CREATE',
          authsibebar: 'SIDEMENU_TRADES'
        },
      },
      {
        path: 'trades/tradeAllocationConfigUpload',
        loadChildren: () => import('./views/trades/trade-allocation-config-upload/trade-allocation-config-upload.module').then(m => m.TradesAllocationConfigUploadModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'TRADEALLOCATIONCONFIG_CREATE',
          authsibebar: 'SIDEMENU_TRADES'
        },
      },
      //trade-allocation-config end
      //view-closed start
      {
        path: 'trades/view-closed',
        loadChildren: () => import('./views/trades/view-closed-taxlots/view-closed-taxlots.module').then(m => m.TradesViewClosedTaxlotsModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'TAXLOTCLOSE_VIEW',
          authsibebar: 'SIDEMENU_TRADES'
        },
      },
      //view-closed end
      //historical-wash-sale-openlot start
      {
        path: 'trades/historical-wash',
        loadChildren: () => import('./views/trades/historical-wash-sale-openlot/historical-wash-sale-openlot.module').then(m => m.TradesHistoricalWashSaleOpenlotModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'TRADEENTRY_HISTORICAL_WASHSALE_OPENLOT',
          authsibebar: 'SIDEMENU_TRADES'
        },
      },
      {
        path: 'trades/historical-wash/upload',
        loadChildren: () => import('./views/trades/historic-wash-sale-upload/historic-wash-sale-upload.module').then(m => m.TradesHistoricWashSaleUploadModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'TRADEENTRY_HISTORICAL_WASHSALE_OPENLOT',
          authsibebar: 'SIDEMENU_TRADES'
        },
      },
      //historical-wash-sale-openlot end
      //washsale start
      {
        path: 'trades/wash-salet-ext-report',
        loadChildren: () => import('./views/trades/wash-sale/wash-sale.module').then(m => m.TradesWashSaleModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'TRADEENTRY_WASHSALE',
          authsibebar: 'SIDEMENU_TRADES'
        },
      },
      {
        path: 'trades/wash-sale-adjusted-open-lot',
        loadChildren: () => import('./views/trades/wash-sale-adjusted-open-lot/wash-sale-adjusted-open-lot.module').then(m => m.TradesWashSaleAdjustedOpenLotModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'TRADEENTRY_WASHSALE_ADJUSTED_OPENLOT',
          authsibebar: 'SIDEMENU_TRADES'
        },
      },
      //washsale end
      //trade-blotter-fixed-income start
      {
        path: 'trades/trade-blotter-fixed-income',
        loadChildren: () => import('./views/trades/trade-blotter-fixed-income/trade-blotter-fixed-income.module').then(m => m.TradesBlotterFixedIncomeModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'TRADEENTRY_TRADEBLOTTERFIXEDINCOME',
          authsibebar: 'SIDEMENU_TRADES'
        },
      },
      //trade-blotter-fixed-income end
      //OpenTaxlots start
      {
        path: 'trades/create-open-tax-lots',
        loadChildren: () => import('./views/trades/create-open-tax-lots/create-open-tax-lots.module').then(m => m.TradesCreateOpenTaxLotsModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'OPENTAXLOTS_CREATE',
          authsibebar: 'SIDEMENU_TRADES'
        },
      },
      {
        path: 'trades/trade-hv',
        loadChildren: () => import('./views/trades/trade-hv/trade-hv.module').then(m => m.TradesHVModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'TRADE_UPLOAD_HV_VIEW',
          authsibebar: 'SIDEMENU_TRADES'
        },
      },
      {
        path: 'trades/batch-work-hv',
        loadChildren: () => import('./views/trades/batch-work-hv/batch-work-hv.module').then(m => m.TradesBatchWorkHVModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'BATCH_PROGRESS_SCREEN_VIEW',
          authsibebar: 'SIDEMENU_TRADES'
        },
      },
      {
        path: 'trades/trade-hv/upload',
        loadChildren: () => import('./views/trades/trade-upload-hv/trade-upload-hv.module').then(m => m.TradesUploadHVModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'TRADE_UPLOAD_HV',
          authsibebar: 'SIDEMENU_TRADES'
        },
      },
      {

        path: 'trades/batch-session',
        loadChildren: () => import('./views/trades/batch-session/batch-session.module').then(m => m.TradesBatchSessionModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: '',
          authsibebar: 'SIDEMENU_TRADES'
        },
      },

      {
        path: 'trades/batch-work-entry/:flag',
        loadChildren: () => import('./views/trades/batch-work-entry/batch-work-entry.module').then(m => m.TradesBatchWorkEntryModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'DAILYMARGIN_VIEW',
          authsibebar: 'SIDEMENU_TRADES'
        },
      },
      {
        path: 'trades/trade-hv',
        loadChildren: () => import('./views/trades/trade-hv/trade-hv.module').then(m => m.TradesHVModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'TRADE_UPLOAD_HV_VIEW',
          authsibebar: 'SIDEMENU_TRADES'
        },
      },
      {
        path: 'trades/batch-work-hv',
        loadChildren: () => import('./views/trades/batch-work-hv/batch-work-hv.module').then(m => m.TradesBatchWorkHVModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'BATCH_PROGRESS_SCREEN_VIEW',
          authsibebar: 'SIDEMENU_TRADES'
        },
      },
      {
        path: 'trades/trade-hv/upload',
        loadChildren: () => import('./views/trades/trade-upload-hv/trade-upload-hv.module').then(m => m.TradesUploadHVModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'TRADE_UPLOAD_HV',
          authsibebar: 'SIDEMENU_TRADES'
        },
      },
      {
        path: 'trades/batch-session',
        loadChildren: () => import('./views/trades/batch-session/batch-session.module').then(m => m.TradesBatchSessionModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: '',
          authsibebar: 'SIDEMENU_TRADES'
        },
      },

      {
        path: 'trades/batch-work-entry/:flag',
        loadChildren: () => import('./views/trades/batch-work-entry/batch-work-entry.module').then(m => m.TradesBatchWorkEntryModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'DAILYMARGIN_VIEW',
          authsibebar: 'SIDEMENU_TRADES'
        },
      },
      {
        path: 'trades/refresh-open-tax-lots',
        loadChildren: () => import('./views/trades/refresh-open-tax-lots/refresh-open-tax-lots.module').then(m => m.TradesRefreshOpenTaxLotsModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'OPENTAXLOTS_REFRESH',
          authsibebar: 'SIDEMENU_TRADES'
        },
      },
      {
        path: 'trades/trade-upload-entry-hv',
        loadChildren: () => import('./views/trades/trade-upload-entry-hv/trade-upload-entry-hv.module').then(m => m.TradeUploadEntryHvModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'TRADE_UPLOAD_ENTRY_HV_VIEW',
          authsibebar: 'SIDEMENU_TRADES'
        },
      },
      {
        path: 'trades/trade-sucess-list-hv',
        loadChildren: () => import('./views/trades/trade-success-list-hv/trade-success-list-hv.module').then(m => m.TradeSucessListHvModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: '',
          authsibebar: 'SIDEMENU_TRADES'
        },
      },
      {
        path: 'trades/run-view-open-taxlots',
        loadChildren: () => import('./views/trades/run-view-open-taxlots/run-view-open-taxlots.module').then(m => m.TradesRunViewOpenTaxlotsModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'OPENTAXLOTS_VIEW',
          authsibebar: 'SIDEMENU_TRADES'
        },
      },
      {
        path: 'trades/reset-open-tax-lots',
        loadChildren: () => import('./views/trades/reset-open-taxlots/reset-open-taxlots.module').then(m => m.TradesResetOpenTaxlotsModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'RESET_OPENTAXLOTS',
          authsibebar: 'SIDEMENU_TRADES'
        },
      },
      {
        path: 'trades/reset-open-taxlots-fund',
        loadChildren: () => import('./views/trades/reset-open-taxlots-fund/reset-open-taxlots-fund.module').then(m => m.TradesResetOpenTaxlotsFundModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'RESET_TAXLOTS_BYFUND_VIEW',
          authsibebar: 'SIDEMENU_TRADES'
        },
      },
      //OpenTaxlots end
      //runtaxlotclosing start
      {
        path: 'trades/run-tax-lot-closing',
        loadChildren: () => import('./views/trades/run-tax-lot-closing/run-tax-lot-closing.module').then(m => m.TradesRunTaxLotClosingModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'TAXLOTCLOSE_RUN',
          authsibebar: 'SIDEMENU_TRADES'
        },
      },
      {
        path: 'trades/run-taxlots-closing-multiple-broker',
        loadChildren: () => import('./views/trades/run-taxlots-closing-multiple-broker/run-taxlots-closing-multiple-broker.module').then(m => m.TradesRunTaxlotsClosingMultipleBrokerModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'SIDEMENU_TRADES_RUN_TAXLOTS_CLOSING_MULTIPLE_BROKER_VIEW',
          authsibebar: 'SIDEMENU_TRADES'
        },
      },
      {
        path: 'trades/run-taxlots-process',
        loadChildren: () => import('./views/trades/run-taxlots-process/run-taxlots-process.module').then(m => m.TradesRunTaxlotsProcessModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'SIDEMENU_TRADES_RUN_TAXLOTS_PROCESS',
          authsibebar: 'SIDEMENU_TRADES'
        },
      },
      //runtaxlotclosing end
      //oms start
      {
        path: 'trades/oms',
        loadChildren: () => import('./views/trades/oms/oms.module').then(m => m.TradesOmsModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'OMSENTRY_VIEW',
          authsibebar: 'SIDEMENU_TRADES'
        },
      },
      //oms end
      //**trade module end**//
      //** setting mdule start **/
      //trade-allocation-config start
      {
        path: 'setting/trade-allocation-config',
        loadChildren: () => import('./views/trades/trade-allocation-config/trade-allocation-config.module').then(m => m.TradesAllocationConfigModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'TRADEALLOCATIONCONFIG_VIEW',
          authsibebar: 'SIDEMENU_SETTINGS'
        },
      },
      {
        path: 'setting/trade-allocation-config-entry/:flag',
        loadChildren: () => import('./views/trades/trade-allocation-config-entry/trade-allocation-config-entry.module').then(m => m.TradesAllocationConfigEntryModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'TRADEALLOCATIONCONFIG_CREATE',
          authsibebar: 'SIDEMENU_SETTINGS'
        },
      },
      {
        path: 'setting/tradeAllocationConfigUpload',
        loadChildren: () => import('./views/trades/trade-allocation-config-upload/trade-allocation-config-upload.module').then(m => m.TradesAllocationConfigUploadModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'TRADEALLOCATIONCONFIG_VIEW',
          authsibebar: 'SIDEMENU_SETTINGS'
        },
      },
      //trade-allocation-config end
      //** setting mdule end **/
      {
        path: 'portfolio',
        loadChildren: () => import('./views/portfoliomodule/portfolio.module').then(m => m.PortfolioModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'SIDEMENU_PORTFOLIO_RISK'
        },
      },
      {
        path: 'accounting',
        loadChildren: () => import('./views/accounting/accounting.module').then(m => m.AccountingModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'SIDEMENU_FUNDACCOUNTING'
        },
      },
      {
        path: 'investor',
        loadChildren: () => import('./views/investor-module/investor-module.module').then(m => m.InvestorModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'SIDEMENU_INVESTOR'
        },
      },
      {
        path: 'setting',
        loadChildren: () => import('./views/settingmodule/settingmodule.module').then(m => m.SettingModule),
        canActivate: [AuthGaurd],
        data: {
          auth: 'SIDEMENU_SETTINGS'
        },
      },
      {
        path: 'workflow',
        loadChildren: () => import('./views/workflow/workflow.module').then(m => m.WorkflowModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'SIDEMENU_DAILY_WORK_FLOW'
        },
      },
      {
        path: 'reports',
        loadChildren: () => import('./views/seamlessReports/reports.module').then(m => m.ReportsModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'LEFTPANEL_REPORTS_NAV'
        },
      },
      {
        path: 'query',
        loadChildren: () => import('./views/query/query.module').then(m => m.QueryModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'SIDEMENU_QUERY'
        },
      },
      {
        path: 'batchTracking',
        loadChildren: () => import('./views/batch-tracking/batch-tracking.module').then(m => m.BatchTrackingModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'SIDEMENU_REPORTS'
        },
      },
      {
        path: 'create/trades',
        loadChildren: () => import('./views/trades/trades.module').then(m => m.TradesModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: ''
        },
      },
      {
        path: 'create/accounting',
        loadChildren: () => import('./views/accounting/accounting.module').then(m => m.AccountingModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: ''
        },
      },
      {
        path: 'create/portfolio',
        loadChildren: () => import('./views/portfoliomodule/portfolio.module').then(m => m.PortfolioModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: ''
        },
      },
      {
        path: 'create/investor',
        loadChildren: () => import('./views/investor-module/investor-module.module').then(m => m.InvestorModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: ''
        },
      },
      {
        path: 'view/trades',
        loadChildren: () => import('./views/trades/trades.module').then(m => m.TradesModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: ''
        },
      },
      {
        path: 'view/accounting',
        loadChildren: () => import('./views/accounting/accounting.module').then(m => m.AccountingModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: ''
        },
      },
      {
        path: 'view/portfolio',
        loadChildren: () => import('./views/portfoliomodule/portfolio.module').then(m => m.PortfolioModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: ''
        },
      },
      {
        path: 'view/investor',
        loadChildren: () => import('./views/investor-module/investor-module.module').then(m => m.InvestorModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: ''
        },
      },
      {
        path: 'upload/trades',
        loadChildren: () => import('./views/trades/trades.module').then(m => m.TradesModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: ''
        },
      },
      {
        path: 'upload/accounting',
        loadChildren: () => import('./views/accounting/accounting.module').then(m => m.AccountingModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: ''
        },
      },
      {
        path: 'upload/portfolio',
        loadChildren: () => import('./views/portfoliomodule/portfolio.module').then(m => m.PortfolioModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: ''
        },
      },
      {
        path: 'upload/investor',
        loadChildren: () => import('./views/investor-module/investor-module.module').then(m => m.InvestorModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: ''
        },
      },
      {
        path: 'library',
        loadChildren: () => import('./views/library/library.module').then(m => m.LibraryModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: ''
        },
      },
      {
        path: 'processing',
        loadChildren: () => import('./views/commonmodule/processingmodule/processingmodule.module').then(m => m.ProcessingmoduleModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'SIDEMENU_DAILY_PROCESSING'
        },
      },
      {
        path: 'process-log',
        loadChildren: () => import('./views/porcess-log/process-log.module').then(m => m.ProcessLogModule),
        canActivate: [AuthGaurd],
      },
      {
        path: 'distribution',
        loadChildren: () => import('./views/distribution/distribution.module').then(m => m.DistributionModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'SEAMLESS_SIDEMENU_DISTRIBUTION'
        },
      },
      {
        path: 'accounting-dashboard',
        loadChildren: () => import('./views/accounting-dashboard/accounting-dashboard.module').then(m => m.AccountingDashboardModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'SEAMLESS_SIDEMENU_ACCOUNTING_DASHBOARD_CLIENT'
        },
      },
      {
        path: 'processing/manager',
        loadChildren: () => import('./views/commonmodule/processingmodule/processingmodule.module').then(m => m.ProcessingmoduleModule),
        canActivate: [AuthGaurd],
        data: {
          role: Role.User,
          auth: ''
        },
      },
      {
        path: 'audit-request',
        loadChildren: () => import('./views/audit-request/audit-request.module').then(m => m.AuditRequestModule),
        canActivate: [AuthGaurd],
        //     data: {
        //     role: Role.User           
        // },
      },
      {
        path: 'process-log-archive',
        loadChildren: () => import('./views/process-log-archive/process-log.module').then(m => m.ProcessLogModule),
        canActivate: [AuthGaurd],
      },
      {
        path: 'newhome',
        loadChildren: () => import('./views/newhome/newhome.module').then(m => m.NewHomeModule),
      },
      {
        path: 'manager',
        loadChildren: () => import('./views/managerSide/managermodule/managermodule.module').then(m => m.ManagermoduleModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.Manager],
          auth: 'SEAMLESS_SIDEMENU_ADMIN'
        },
      },
      {
        path: 'createmanager/manager',
        loadChildren: () => import('./views/managerSide/managermodule/managermodule.module').then(m => m.ManagermoduleModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.Manager],
          auth: ''
        },
      },
      {
        path: 'uploadmanager/manager',
        loadChildren: () => import('./views/managerSide/managermodule/managermodule.module').then(m => m.ManagermoduleModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.Manager],
          auth: ''
        },
      },
      {
        path: 'viewmanager/manager',
        loadChildren: () => import('./views/managerSide/managermodule/managermodule.module').then(m => m.ManagermoduleModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.Manager],
          auth: ''
        },
      },
      {
        path: 'systemmanager',
        loadChildren: () => import('./views/systemManagerScreen/manager/managermodule.module').then(m => m.ManagermoduleModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.SystemManager],
          auth: ''
        },
      },
      {
        path: 'Entities',
        loadChildren: () => import('./views/systemManagerScreen/system-all-access/system-all-access.module').then(m => m.SystemAccessModuleModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.SystemManager],
          auth: 'SEAMLESS_SIDEMENU_ENTITIES',
          authsibebar: 'SEAMLESS_SIDEMENU_ENTITIES'
        },
      },
      {
        path: 'MIS',
        loadChildren: () => import('./views/systemManagerScreen/MIS/mis.module').then(m => m.MISModuleModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.SystemManager],
          auth: ''
        },
      },
      {
        path: 'api-management',
        loadChildren: () => import('./views/systemManagerScreen/api-management/api-management.module').then(m => m.ApiManagementModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.SystemManager],
          auth: ''
        },
      },
      {
        path: 'global-security-master',
        loadChildren: () => import('./views/systemManagerScreen/global-security-master/global-security-master.module').then(m => m.globalSecurityMasterModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.SystemManager],
          auth: ''
        },
      },

      {
        path: 'product',
        loadChildren: () => import('./views/systemManagerScreen/product/product.module').then(m => m.ProductModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.SystemManager],
          auth: 'DIV_PORTFOLIO_RISK_SECURITIES_MASTER'
        },
      },
      {
        path: 'processingsystemmanager',
        loadChildren: () => import('./views/commonmodule/processingmodule/processingmodule.module').then(m => m.ProcessingmoduleModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.SystemManager],
          auth: ''
        },
      },
      {
        path: 'managerprocessing',
        loadChildren: () => import('./views/commonmodule/processingmodule/processingmodule.module').then(m => m.ProcessingmoduleModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.Manager],
          auth: ''
        },
      },
      {
        path: 'processingsystemmanager/manager',
        loadChildren: () => import('./views/commonmodule/processingmodule/processingmodule.module').then(m => m.ProcessingmoduleModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.SystemManager],
          auth: ''
        },
      },
      {
        path: 'setup',
        loadChildren: () => import('./views/rapid/setup/setup.module').then(m => m.SetupModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: ''
          //auth: 'SEAMLESS_SIDEMENU_SETUP'
        },
      },
      {
        path: 'taxSetup',
        loadChildren: () => import('./views/tax-setup/tax-setup.module').then(m => m.TaxSetupModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'SEAMLESS_TAXATION_SETUP'
        },
      },
      {
        path: 'user-management',
        loadChildren: () => import('./views/rapid/user-management/user-management.module').then(m => m.UserManagementModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'SEAMLESS_SIDEMENU_USERMANAGEMENT'
        },
      },
      {
        path: 'customization',
        loadChildren: () => import('./views/rapid/customization/customization.module').then(m => m.CustomizationModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'SEAMLESS_SIDEMENU_CUSTOMIZATION'
        },
      },
      {
        path: 'rapid-processing',
        loadChildren: () => import('./views/rapid/processing/processing.module').then(m => m.ProcessingModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'SEAMLESS_SIDEMENU_PROCESSING'
        },
      },
      {
        path: 'input',
        loadChildren: () => import('./views/rapid/input/input.module').then(m => m.InputModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'SEAMLESS_SIDEMENU_INPUT'
        },
      },
      {
        path: 'user-setup',
        loadChildren: () => import('./views/user-setup/user-setup.module').then(m => m.UserSetupModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'SEAMLESS_SIDEMENU_CLIENT_SETUP'
        },
      },
      {
        path: 'activity-upload-review',
        loadChildren: () => import('./views/activity-upload-review/activity-upload-review.module').then(m => m.ActivityUploadReviewModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'SEAMLESS_SIDEMENU_ACITIVITIES_CLIENT'
        },
      },
      {
        path: 'users',
        component: UsersComponent,
        canActivate: [AuthGaurd],
        data: {
          auth: '',
          authsibebar: 'USER_CREATE'
        },
      },
      {
        path: 'legacy',
        loadChildren: () => import('./views/rapid/legacy/legacy.module').then(m => m.legacyModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'SEAMLESS_SIDEMENU_LEGACY_ALL_BUTTONS'
        },
      },
      {
        path: 'api-reference',
        loadChildren: () => import('./views/api-documentation/api-documentation.module').then(m => m.ApiDocumentationModule),
        canActivate: [AuthGaurd],
        data: {
          role: [Role.User, Role.Manager],
          auth: 'SEAMLESS_SETTINGS_API_DOC_VIEW',
        },
      },
    ]
  },
  {
    path: '**',
    loadChildren: () => import('./pages/blank/blank.module').then(m => m.BlankModule),
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    // preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    relativeLinkResolution: 'legacy',
    useHash: true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
