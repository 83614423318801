/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class UserSearchService {
  public searchOpen: boolean;
  constructor() { }

  userTabs = [
    {
      Button: [
        { id: 5, name: 'trade_allocation_config', viewRouterlink: "/trades/trade-allocation-config", createRouterlink: '/trades/trade-allocation-config', uploadRouterlink: '/trades/tradeAllocationConfigUpload', auth: 'TRADEALLOCATIONCONFIG_VIEW', authCreate: 'TRADEALLOCATIONCONFIG_CREATE', authUpload: 'TRADEALLOCATIONCONFIG_CREATE' },
        { id: 30, name: 'Upload and Process Transfer', viewRouterlink: "/trades/transfer-trade-raw-data-history", createRouterlink: 'transfer-trade-raw', uploadRouterlink: '/trades/transfertradeHistoryRawDataUpload', auth: 'TRADEENTRY_TRANSFERTRADE_RAWDATA', authCreate: '', authUpload: 'TRADEENTRY_TRANSFERTRADE_RAWDATA' },

      ], authsibebar: 'SIDEMENU_TRADES'
    },

    {
      Button: [
        //----portfolio----
        // { id: 3, name: 'Deribit Data', auth: 'DERIBITDATA_VIEW', viewRouterlink: '/portfolio/view-deribit-data',createRouterlink: '',uploadRouterlink: '', authUpload: '', authCreate: '' },
        // { id: 5, name: 'Alert Threshold', auth: 'ALERTTHRESHOLD_VIEW', createRouterlink: '/portfolio/alert-threshold-entry',uploadRouterlink: '', viewRouterlink: '/portfolio/alert-threshold', authUpload: '', authCreate: 'ALERTTHRESHOLD_CREATE' },
        // { id: 7, name: 'Alert Threshold Log', auth: 'AlertThresholdLog_VIEW', viewRouterlink: '/portfolio/alert-threshold-log',createRouterlink: '',uploadRouterlink: '', },
        // { id: 8, name: 'Market Data Var', auth: 'MARKETDATAVAR_VIEW', createRouterlink: '/portfolio/market-data-var-entry', viewRouterlink: '/portfolio/market-data-var', uploadRouterlink: '', authUpload: 'MARKETDATAVAR_CREATE', authCreate: 'MARKETDATAVAR_CREATE' },
        // { id: 9, name: 'Fetch Global Market Data', auth: '', viewRouterlink: '/portfolio/fetch-global-market-data',createRouterlink: '',uploadRouterlink: '', },
        // { id: 11, name: 'Fetch Trade Data', auth: '', viewRouterlink: '/portfolio/fetch-trade-data',createRouterlink: '', uploadRouterlink: '',},
        // { id: 13, name: 'API Data', auth: 'FUNDAMERICA_API', viewRouterlink: '/portfolio/api-data',createRouterlink: '',uploadRouterlink: '', },
        { id: 15, name: 'crypto_currency', auth: 'CRYPTOCURRENCY_VIEW', viewRouterlink: '/portfolio/crypto-currency', createRouterlink: '', uploadRouterlink: '', authUpload: '', authCreate: '' },

        { id: 14, name: 'Run VAR', auth: 'REPORTS_ACCOUNTING_RUNVAR', viewRouterlink: '/portfolio/run-var', createRouterlink: '', uploadRouterlink: '', authUpload: '', authCreate: '' },

        // { id: 20, name: 'Sub Sectors', auth: 'SUBSECTOR_VIEW', createRouterlink: '/portfolio/sub-sectors', viewRouterlink: '/portfolio/sub-sectors', uploadRouterlink: '/portfolio/uploadSubsectors', authUpload: 'SUBSECTOR_CREATE', authCreate: 'SUBSECTOR_CREATE' },
      ], authsibebar: 'SIDEMENU_PORTFOLIO_RISK'
    },
    // -----Accounting----
    {
      Button: [

        {
          id: 1,
          name: "payments",
          cardAuthName: "DIV_FUNDACCOUNTING_ACCOUNTSPAYABLE",
          auth: "SEAMLESS_ACCOUNTING_ACCOUNTSPAYABLE_PAYMENT",
          catCreatAuth: "DIV_FUNDACCOUNTING_ACCOUNTSPAYABLE",
          catViewAuth: "DIV_FUNDACCOUNTING_ACCOUNTSPAYABLE",
          viewRouterlink: "/accounting/payment-data",
          createRouterlink: '/accounting/payment',
          authCreate: 'SEAMLESS_ACCOUNTING_ACCOUNTSPAYABLE_PAYMENT_CREATE',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 2,
          name: "bills",
          cardAuthName: "DIV_FUNDACCOUNTING_ACCOUNTSPAYABLE",
          auth: "SEAMLESS_ACCOUNTING_ACCOUNTSPAYABLE_BILL",
          catCreatAuth: "DIV_FUNDACCOUNTING_ACCOUNTSPAYABLE",
          catViewAuth: "DIV_FUNDACCOUNTING_ACCOUNTSPAYABLE",
          viewRouterlink: "/accounting/bill",
          createRouterlink: '/accounting/bill',
          authCreate: 'SEAMLESS_ACCOUNTING_ACCOUNTSPAYABLE_BILL_CREATE',
          uploadRouterlink: '',
          authUpload: '',

        },

        {
          id: 17,
          name: "view_perf_fees",
          auth: "PERFORMANCE_BASED_FEE_CALCULATION_VIEW",
          viewRouterlink: "/accounting/viewperffees",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },

        {
          id: 22,
          name: "allocation",
          cardAuthName: "DIV_FUNDACCOUNTING_OTHERPROCESSES",
          auth: "CTA_ALLOCATION_VIEW",
          viewRouterlink: "/accounting/ctaallocation",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },



        {
          id: 24,
          name: "side_pocket_total_income",
          auth: "SIDE_POCKET_TOTAL_INCOME_VIEW",
          viewRouterlink: "/accounting/sidepockettotalincome",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },

        {
          id: 2,
          name: "exclude_composite",
          auth: "TRADING_PROGRAM_COMPOSITE_VIEW",
          viewRouterlink: "/accounting/excludeComposite",
          createRouterlink: '/accounting/excludeComposite-entry',
          authCreate: 'TRADING_PROGRAM_COMPOSITE_CREATE',
          uploadRouterlink: '/accounting/excludeCompositeUpload',
          authUpload: 'EXCLUDECOMPOSITE_UPLOAD',


        },


      ], authsibebar: 'SIDEMENU_FUNDACCOUNTING'
    },
    {
      Button: [
        //--- user-setup --
        { id: 1, name: 'fund_property', cardAuthName: "SEAMLESS_SETUP_CLIENT_FUND_VIEW", auth: 'SEAMLESS_SETUP_CLIENT_FUND_VIEW', createRouterlink: '', viewRouterlink: 'user-setup/fund-property-list', uploadRouterlink: '', authUpload: '', authCreate: '', authView: 'SEAMLESS_SETUP_CLIENT_FUND_VIEW' },
        { id: 2, name: 'chart_of_accounts_pm', cardAuthName: "SEAMLESS_CHARTOFACCOUNT_CLIENT_VIEW", auth: 'SEAMLESS_CHARTOFACCOUNT_CLIENT_VIEW', createRouterlink: 'user-setup/user-coa/', viewRouterlink: 'user-setup/user-coa-list', uploadRouterlink: 'user-setup/user-coa-upload', authUpload: 'SEAMLESS_CHARTOFACCOUNT_CLIENT_CREATE', authCreate: 'SEAMLESS_CHARTOFACCOUNT_CLIENT_CREATE', authView: 'SEAMLESS_CHARTOFACCOUNT_CLIENT_VIEW' },
      ], authsibebar: 'SEAMLESS_SIDEMENU_CLIENT_SETUP'
    },

    //----investor----
    {
      Button: [
        //  { id: 1, name: 'Doc Management', auth: 'DOCUMENTMANAGEMENT_VIEW', viewRouterlink: "/investor/upload", createRouterlink: '' ,  uploadRouterlink: '', authUpload: '',  authCreate: ''  },
      ], authsibebar: 'SIDEMENU_INVESTOR'
    },
    {
      Button: [
        //--- processing --
        // { id: 2, name: 'input_data_integrity_check', auth: 'USER_Input_Data_Integrity_Check', viewRouterlink: "/processing/inputDataIntegrityCheck", createRouterlink: '', uploadRouterlink: '', authUpload: '', authCreate: '' },
        // { id: 4, name: 'data_import_status', auth: 'SEAMLESS_INPUT_DIV_IMPORT', viewRouterlink: "/processing/workflowStatus", createRouterlink: '', uploadRouterlink: '', authUpload: '', authCreate: '' },
        // { id: 6, name: 'workflow', auth: 'DAILYPROCESSING_VIEW', viewRouterlink: "/processing/workflow-tree", createRouterlink: '', uploadRouterlink: '', add: false, upload: false, authUpload: '', authCreate: '' }, 
      ], authsibebar: 'SIDEMENU_DAILY_PROCESSING'
    },
    //--- Query --- 
    {
      Button: [
        {
          id: 1,
          name: "position_by_taxlot",
          auth: "POSITIONBYTAXLOT_VIEW",
          viewRouterlink: "/query/position-By-Taxlot-List",
          createRouterlink: '/query/position-By-Taxlot-Entry',
          authCreate: 'POSITIONBYTAXLOT_CREATE',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 1,
          name: "trial_balance_report",
          auth: "TRIAL_BALANCE_REPORT_VIEW",
          viewRouterlink: "/query/trial-balance-report",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 2,
          name: "balance_sheet_report",
          auth: "BALANCE_SHEET_REPORT_VIEW",
          viewRouterlink: "/query/balance-sheet-report",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 3,
          name: "income_statement",
          auth: "INCOME_STATEMENT_VIEW",
          viewRouterlink: "/query/income-statement",
          upload: false,
          add: false,
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 4,
          name: "assets_base_fee_report",
          auth: "ASSETS_BASE_FEE_REPORT_VIEW",
          viewRouterlink: "/query/asset-based-fee-report",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 5,
          name: "performance_based_fee_report",
          auth: "PERFORMANCE_BASED_FEE_REPORT_VIEW",
          viewRouterlink: "/query/performance-based-fee-report",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 6,
          name: "trader_report",
          auth: "TRADER_REPORT_VIEW",
          viewRouterlink: "/query/trader-report",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 7,
          name: "cash_balance_report",
          auth: "CASH_BALANCE_REPORT_VIEW",
          viewRouterlink: "/query/cash-balance-report",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 8,
          name: "trader_asset_based_fee_calculation",
          auth: "TRADER_ASSET_BASED_FEE_CALCULATION_VIEW",
          viewRouterlink: "/query/trader-asset-based-fee-report",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 9,
          name: "trader_performance_based_fee_calculation",
          auth: "TRADER_PERFORMANCE_BASED_FEE_CALCULATION_VIEW",
          viewRouterlink: "/query/trader-performance-based-fee-report",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 10,
          name: "income_allocation_total",
          auth: "INCOME_ALLOCATION_TOTAL_VIEW",
          viewRouterlink: "/query/income-allocation-total",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 11,
          name: "post_recurring_transaction_JE",
          auth: "POSTRECURRINGTRANSACTION_VIEW",
          viewRouterlink: "/query/post-recurring-transaction",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 1,
          name: "cash_transaction",
          auth: "QUERY_CASHTRANSCATION_VIEW",
          viewRouterlink: "/query/cash-transaction",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 2,
          name: "export_income_statement_with_multiple_fund",
          auth: "REPORTS_ACCOUNTING_INCOME_STATEMENT_MULTI_FUND",
          viewRouterlink: "/query/export-income-statement-multifund",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 3,
          name: "investor_statement2",
          auth: "INVESTOR_STATEMENT_REPORTS_2_VIEW",
          viewRouterlink: "/query/investor-statement-report2",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 4,
          name: "income_allocation_summary_check",
          auth: "REPORTS_ACCOUNTING_INVESTORCAPITALSUMMARY_PE",
          viewRouterlink: "/query/income-allocation-summary",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },


        // {
        //   id: 2,
        //   name: "investor_tag_monthly_summary",
        //   auth: "INVESTOR_TAG_MONTHLY_SUMMARY",
        //   viewRouterlink: "/query/investor-tag-summary",
        //   createRouterlink: '',
        //   authCreate: '',
        //   uploadRouterlink: '',
        //   authUpload: '',

        // },
        // {
        //   id: 1,
        //   name: "posted_fund_transactions",
        //   auth: "REPORTS_QUERY_POSTED_FUND_TRANSACTION",
        //   viewRouterlink: "/query/posted-fund-transaction",
        //   createRouterlink: '',
        //   authCreate: '',
        //   uploadRouterlink: '',
        //   authUpload: '',

        // },
        // {
        //   id: 2,
        //   name: "fund_activity_by_fund",
        //   auth: "QUERY_REPORT_FUNDACTIVITYBYFUND",
        //   viewRouterlink: "/query/fund-activity-fund",
        //   createRouterlink: '',
        //   authCreate: '',
        //   uploadRouterlink: '',
        //   authUpload: '',

        // },
        // {
        //   id: 3,
        //   name: "fund_activity_by_dealer",
        //   auth: "QUERY_REPORT_FUNDACTIVITYBYDEALER",
        //   viewRouterlink: "/query/fund-activity-dealer",
        //   createRouterlink: '',
        //   authCreate: '',
        //   uploadRouterlink: '',
        //   authUpload: '',

        // },
        // {
        //   id: 1,
        //   name: "BS",
        //   auth: "QUERY_REPORT_BALANCESHEETNFM_VIEW",
        //   viewRouterlink: "/query/balance-sheet-report-nfm",
        //   createRouterlink: '',
        //   authCreate: '',
        //   uploadRouterlink: '',
        //   authUpload: '',

        // },
        // {
        //   id: 2,
        //   name: "IS",
        //   auth: "QUERY_REPORT_INCOME_STATEMENTNFM",
        //   viewRouterlink: "/query/income-sheet-report-nfm",
        //   createRouterlink: '',
        //   authCreate: '',
        //   uploadRouterlink: '',
        //   authUpload: '',


        // },
        // {
        //   id: 3,
        //   name: "DST_fanmail_output",
        //   auth: "SIDEMENU_QUERY_FANMAILOUTPUT",
        //   viewRouterlink: "/query/dst-fanmail-output",
        //   createRouterlink: '',
        //   authCreate: '',
        //   uploadRouterlink: '',
        //   authUpload: '',

        // },
        // {
        //   id: 4,
        //   name: "investor_income_summary",
        //   auth: "SIDEMENU_QUERY_INVESTOR_INCOME_SUMMARY",
        //   viewRouterlink: "/query/investor-income-summary",
        //   createRouterlink: '',
        //   authCreate: '',
        //   uploadRouterlink: '',
        //   authUpload: '',

        // },
        // {
        //   id: 1,
        //   name: "daily_activity_by_fund",
        //   auth: "REPORTS_QUERY_DAILY_ACTIVITY_BY_FUND_VIEW",
        //   viewRouterlink: "/query/daily-activity-by-fund",
        //   createRouterlink: '',
        //   authCreate: '',
        //   uploadRouterlink: '',
        //   authUpload: '',
        // },
        // {
        //   id: 1,
        //   name: "TB",
        //   auth: "QUERY_REPORT_TRIALBANANCENFM",
        //   viewRouterlink: "/query/trial-balance-nfm",
        //   createRouterlink: '',
        //   authCreate: '',
        //   uploadRouterlink: '',
        //   authUpload: '',
        // },
        // {
        //   id: 2,
        //   name: "asset_summary_frontier",
        //   auth: "SIDEMENU_QUERY_REPORT_ASSETSUMMARY_VIEW",
        //   viewRouterlink: "/query/asset-summary-frontier",
        //   createRouterlink: '',
        //   authCreate: '',
        //   uploadRouterlink: '',
        //   authUpload: '',

        // },
        // {
        //   id: 2,
        //   name: "statement_Of_income_loss",
        //   auth: "QUERY_REPORT_STATEMENTOFINCOMELOSS",
        //   viewRouterlink: "/query/statement-of-income",
        //   createRouterlink: '',
        //   authCreate: '',
        //   uploadRouterlink: '',
        //   authUpload: '',

        // },
        // {
        //   id: 1,
        //   name: "424B_report",
        //   auth: "SIDEMENU_QUERY_424BREPORT",
        //   viewRouterlink: "/query/b-report",
        //   createRouterlink: '',
        //   authCreate: '',
        //   uploadRouterlink: '',
        //   authUpload: '',

        // },
        // {
        //   id: 2,
        //   name: "daily_performance_report",
        //   auth: "SIDEMENU_QUERY_DAILY_PERFORMANCE_REPORT",
        //   viewRouterlink: "/query/daily-performance-report",
        //   createRouterlink: '',
        //   authCreate: '',
        //   uploadRouterlink: '',
        //   authUpload: '',

        // },
        {
          id: 3,
          name: "download_investor_statement",
          auth: "DOWNLOAD_INVESTOR_STATEMENT_REPORTS_VIEW",
          viewRouterlink: "/query/download-investor-statement",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '/query/download-investor-statement-upload',
          authUpload: 'DOCUMENTMANAGEMENT_UPLOAD',

        },
        {
          id: 2,
          name: "policy_gain_loss_report",
          auth: "SIDEMENU_QUERY_POLICYGAINLOSS",
          viewRouterlink: "/query/policy-gainloss-report",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 24,
          name: "orion_data_files_report",
          auth: "QUERY_ORIONDATAFILES_VIEW",
          viewRouterlink: "/query/orion-data-files-report",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 25,
          name: "annual_impact_note_statement",
          auth: "IREPORTS_INVESTOR_ANNUALIMPACTNOTESTATEMENT",
          viewRouterlink: "/query/annual-impact-note-statement",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 26,
          name: "open_lot_wtl",
          auth: "OPEN_LOTS_WTL",
          viewRouterlink: "/query/open-lot-wtl",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 2,
          name: "notes",
          auth: "REPORTS_ACCOUNTING_NOTESDETAILS_VIEW",
          viewRouterlink: "/query/notes-list",
          createRouterlink: '/query/notes-entry',
          authCreate: 'REPORTS_ACCOUNTING_NOTESDETAILS_CREATE',
          uploadRouterlink: '/query/notes-upload',
          authUpload: '',

        },
        {
          id: 1,
          name: "investor_capital_summary_custom",
          auth: "QUERY_INVESTOR_CAPITAL_SUMMARY_CUSTOM",
          viewRouterlink: "/query/investor-capital-summary-custom",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 2,
          name: "historical_investor_capital_summary",
          auth: "QUERY_HISTORICAL_INVESTOR_CAPITAL_SUMMARY_UPLOAD",
          viewRouterlink: "/query/historical-investor-capital-summary",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '/query/historical-investor-capital-summary-upload',
          authUpload: 'QUERY_HISTORICAL_INVESTOR_CAPITAL_SUMMARY_UPLOAD',

        },
        {
          id: 3,
          name: "historical_investor_capital_summary_upload",
          auth: "QUERY_HISTORICAL_INVESTOR_CAPITAL_SUMMARY_UPLOAD",
          viewRouterlink: "/query/historical-investor-capital-summary-upload",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '/query/historical-investor-capital-summary-upload',
          authUpload: 'QUERY_HISTORICAL_INVESTOR_CAPITAL_SUMMARY_UPLOAD',

        },
        {
          id: 3,
          name: "investor_statement2",
          auth: "INVESTOR_STATEMENT_REPORTS_2_VIEW",
          viewRouterlink: "/query/investor-statement-report2",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 4,
          name: "income_allocation_summary_check",
          auth: "REPORTS_ACCOUNTING_INVESTORCAPITALSUMMARY_PE",
          viewRouterlink: "/query/income-allocation-summary",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 1,
          name: "cost_roll_forward",
          auth: "REPORTS_ACCOUNTING_COST_ROLL_FORWARD",
          viewRouterlink: "/reports/cost_roll_forward",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
	{
          id: 1,
          name: "notes_payable",
          auth: "SEAMLESS_REPORT_PORTFOLIO_NOTES_PAYABLE",
          viewRouterlink: "/reports/notes-payable",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 1,
          name: "Quantity and Cost Roll Forward - Deal Wise",
          cardAuthName: "DIV_REPORTS_PORTFOLIO_REPORTS",
          auth: "SEAMLESS_REPORTS_PORTFOLIO_QUANTITYANDCOSTROLLFORWARD_DEALWISE",
          viewRouterlink: "/reports/cost-roll-forward-deal-wise",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 1,
          name: "Performance Attributes",
          cardAuthName: "DIV_REPORTS_PORTFOLIO_REPORTS",
          auth: "SEAMLESS_REPORTS_PORTFOLIO_PERFORMANCE_ATTRIBUTES",
          viewRouterlink: "/reports/perfromance-attributes",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 2,
          name: "ticker_ledger_A/c",
          auth: "REPORTS_ACCOUNTING_AMOUNT_DAY_TICKER_COA",
          viewRouterlink: "/query/ticker-ledger-acc",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 3,
          name: "Daily PNL",
          auth: "IREPORTS_QUERY_DAILYPNL_VIEW",
          viewRouterlink: "/query/daily-pnl",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 3,
          name: "shareholder_register",
          auth: "REPORT_SHAREHOLDERS_REGISTER",
          viewRouterlink: "/query/shareholder-register",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 3,
          name: "Realized Gain/Loss Report",
          auth: "REPORTS_TRADING_GAIN_LOSS_REALIZED_GAIN_LOSS",
          viewRouterlink: "/query/realized-gain-loss-calculation",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 3,
          name: "Daily PNL 2",
          auth: "IREPORTS_QUERY_DAILYPNLTWO_VIEW",
          viewRouterlink: "/query/daily-pnl2",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 3,
          name: "Fidelity Investor Reports",
          auth: "QUERY_FIDELITYINVESTORREPORTS_VIEW",
          viewRouterlink: "/query/fidelity-investor-report",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 2,
          name: "investor_tag_monthly_summary",
          auth: "INVESTOR_TAG_MONTHLY_SUMMARY",
          viewRouterlink: "/query/investor-tag-summary",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 1,
          name: "posted_fund_transactions",
          auth: "REPORTS_QUERY_POSTED_FUND_TRANSACTION",
          viewRouterlink: "/query/posted-fund-transaction",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 2,
          name: "fund_activity_by_fund",
          auth: "QUERY_REPORT_FUNDACTIVITYBYFUND",
          viewRouterlink: "/query/fund-activity-fund",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 3,
          name: "fund_activity_by_dealer",
          auth: "QUERY_REPORT_FUNDACTIVITYBYDEALER",
          viewRouterlink: "/query/fund-activity-dealer",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 1,
          name: "balance_sheet",
          auth: "QUERY_REPORT_BALANCESHEETNFM_VIEW",
          viewRouterlink: "/query/balance-sheet-report-nfm",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 2,
          name: "income_statement",
          auth: "QUERY_REPORT_INCOME_STATEMENTNFM",
          viewRouterlink: "/query/income-sheet-report-nfm",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',


        },
        {
          id: 3,
          name: "DST_fanmail_output",
          auth: "SIDEMENU_QUERY_FANMAILOUTPUT",
          viewRouterlink: "/query/dst-fanmail-output",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 4,
          name: "investor_income_summary",
          auth: "SIDEMENU_QUERY_INVESTOR_INCOME_SUMMARY",
          viewRouterlink: "/query/investor-income-summary",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 1,
          name: "daily_activity_by_fund",
          auth: "REPORTS_QUERY_DAILY_ACTIVITY_BY_FUND_VIEW",
          viewRouterlink: "/query/daily-activity-by-fund",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 1,
          name: "TB",
          auth: "QUERY_REPORT_TRIALBANANCENFM",
          viewRouterlink: "/query/trial-balance-nfm",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 2,
          name: "asset_summary_frontier",
          auth: "SIDEMENU_QUERY_REPORT_ASSETSUMMARY_VIEW",
          viewRouterlink: "/query/asset-summary-frontier",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 2,
          name: "statement_Of_income_loss",
          auth: "QUERY_REPORT_STATEMENTOFINCOMELOSS",
          viewRouterlink: "/query/statement-of-income",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 1,
          name: "424B_report",
          auth: "SIDEMENU_QUERY_424BREPORT",
          viewRouterlink: "/query/b-report",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 1,
          name: "Trade Reconciled Report",
          auth: "IREPORTS_QUERY_TRADERECONCILED_VIEW",
          viewRouterlink: "/query/trade-reconciled",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 1,
          name: "Instrument Report",
          auth: "REPORTS_TRADINGGAINLOSS_INSTRUMENT",
          viewRouterlink: "/query/instrument",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 1,
          name: "Dividend History",
          auth: "REPORTS_TRADE_TRADEDIVIDEND",
          viewRouterlink: "/query/dividend-history",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },

        {
          id: 2,
          name: "daily_performance_report",
          auth: "SIDEMENU_QUERY_DAILY_PERFORMANCE_REPORT",
          viewRouterlink: "/query/daily-performance-report",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        //   id: 3,
        //   name: "download_investor_statement",
        //   auth: "DOWNLOAD_INVESTOR_STATEMENT_REPORTS_VIEW",
        //   viewRouterlink: "/query/download-investor-statement",
        //   createRouterlink: '',
        //   authCreate: '',
        //   uploadRouterlink: '/query/download-investor-statement-upload',
        //   authUpload: 'DOCUMENTMANAGEMENT_UPLOAD',

        // },
        // {
        //   id: 2,
        //   name: "policy_gain_loss_report",
        //   auth: "SIDEMENU_QUERY_POLICYGAINLOSS",
        //   viewRouterlink: "/query/policy-gainloss-report",
        //   createRouterlink: '',
        //   authCreate: '',
        //   uploadRouterlink: '',
        //   authUpload: '',

        // },
        // {
        //   id: 2,
        //   name: "notes",
        //   auth: "REPORTS_ACCOUNTING_NOTESDETAILS_VIEW",
        //   viewRouterlink: "/query/notes-list",
        //   createRouterlink: '/query/notes-entry',
        //   authCreate: 'REPORTS_ACCOUNTING_NOTESDETAILS_CREATE',
        //   uploadRouterlink: '',
        //   authUpload: '',

        // },
        // {
        //   id: 1,
        //   name: "investor_capital_summary_custom",
        //   auth: "QUERY_INVESTOR_CAPITAL_SUMMARY_CUSTOM",
        //   viewRouterlink: "/query/investor-capital-summary-custom",
        //   createRouterlink: '',
        //   authCreate: '',
        //   uploadRouterlink: '',
        //   authUpload: '',

        // },
        // {
        //   id: 2,
        //   name: "historical_investor_capital_summary",
        //   auth: "QUERY_HISTORICAL_INVESTOR_CAPITAL_SUMMARY_UPLOAD",
        //   viewRouterlink: "/query/historical-investor-capital-summary",
        //   createRouterlink: '',
        //   authCreate: '',
        //   uploadRouterlink: '/query/historical-investor-capital-summary-upload',
        //   authUpload: 'QUERY_HISTORICAL_INVESTOR_CAPITAL_SUMMARY_UPLOAD',

        // },
        // {
        //   id: 3,
        //   name: "historical_investor_capital_summary_upload",
        //   auth: "QUERY_HISTORICAL_INVESTOR_CAPITAL_SUMMARY_UPLOAD",
        //   viewRouterlink: "/query/historical-investor-capital-summary-upload",
        //   createRouterlink: '',
        //   authCreate: '',
        //   uploadRouterlink: '/query/historical-investor-capital-summary-upload',
        //   authUpload: 'QUERY_HISTORICAL_INVESTOR_CAPITAL_SUMMARY_UPLOAD',

        // },
        {
          id: 4,
          name: "redemption_report",
          auth: "REPORTS_INVESTOR_REDEMPTION_REPORT",
          viewRouterlink: "/query/redemption-report",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
      ], authsibebar: 'SIDEMENU_QUERY'
    },
    //---Reconcilation--
    {
      Button: [





      ], authsibebar: 'SIDEMENU_RECONCILIATION'
    },
    //-----Setting Buttons-----//
    {
      Button: [
        {
          name: "lookup",
          viewRouterlink: "/setting/lookups",
          createRouterlink: "",
          uploadRouterlink: '',
          auth: "LOOKUP_VIEW",
          authCreate: '',
          authUpload: ''
        },
        {
          name: "currencies",
          viewRouterlink: "/setting/currencies",
          createRouterlink: "/setting/currencies",
          uploadRouterlink: '',
          auth: "CURRENCY_VIEW",
          authCreate: 'CURRENCY_CREATE',
          authUpload: ''
        },
        {
          name: "regulators",
          viewRouterlink: "/setting/regulators",
          uploadRouterlink: '',
          createRouterlink: "/setting/regulators",
          auth: "SECURITYREGULATOR_VIEW",
          authCreate: 'SECURITYREGULATOR_CREATE',
          authUpload: ''
        },
        {
          name: "exchanges",
          viewRouterlink: "/setting/exchanges",
          uploadRouterlink: '',
          createRouterlink: "/setting/exchanges",
          auth: "EXCHANGE_VIEW",
          "isEnt": 'true',
          authCreate: 'EXCHANGE_CREATE',
          authUpload: ''
        },
        {
          name: "identifiers",
          viewRouterlink: "/setting/identifiers",
          createRouterlink: "/setting/identifiers",
          uploadRouterlink: '/setting/identifiers/upload',
          auth: "SECURITYIDENTIFIERS_VIEW",
          authCreate: 'SECURITYIDENTIFIERS_CREATE',
          authUpload: 'SECURITYIDENTIFIERS_CREATE'
        },
        {
          name: "calendar",
          viewRouterlink: "/setting/calendar",
          createRouterlink: "/setting/calendar",
          uploadRouterlink: '',
          auth: "CALENDAR_VIEW",
          authCreate: 'CALENDAR_CREATE',
          authUpload: ''
        },
        {
          name: "upload_data_config",
          viewRouterlink: "/setting/upload-data-config",
          createRouterlink: "",
          uploadRouterlink: '',
          auth: "DATACONFIGURATION_VIEW",
          authCreate: '',
          authUpload: ''
        },
        {
          name: "doc_management",
          viewRouterlink: "/setting/doc-management",
          createRouterlink: "",
          uploadRouterlink: '',
          auth: "SETTING_CONFIG_DOC_MANAGEMENT",
          authCreate: '',
          authUpload: ''
        },
        {
          name: "Report Delivery Configuration",
          viewRouterlink: "/setting/report-delivery-config",
          createRouterlink: "/setting/report-delivery-config",
          uploadRouterlink: '/setting/report-delivery-config/upload',
          auth: "ENT_WORKFLOW_CONFIG",
          authCreate: 'ENT_WORKFLOW_CONFIG',
          authUpload: 'ENT_WORKFLOW_CONFIG'
        },
        {
          name: "investor_statement_footer_customization",
          cardAuthName: "DIV_SETTINGS_CONFIGURATION",
          viewRouterlink: "/setting/investor-statement-footer-customization",
          createRouterlink: "/setting/investor-statement-footer-customization-entry",
          uploadRouterlink: '',
          auth: "SETTING_CONFIG_INVESTOR_STATEMENT_FOOTER_CUSTOMIZATION",
          authCreate: 'SETTING_CONFIG_INVESTOR_STATEMENT_FOOTER_CUSTOMIZATION',
          authUpload: ''
        },
        {
          name: "vendors",
          viewRouterlink: "/setting/vendors",
          createRouterlink: "/setting/vendors",
          uploadRouterlink: '/setting/vendors/upload',
          auth: "VENDOR_VIEW",
          authCreate: 'VENDOR_CREATE',
          authUpload: 'VENDOR_CREATE'
        },
        {
          name: "fund_addresses",
          createRouterlink: "/setting/fund-addresses",
          viewRouterlink: "/setting/fund-addresses",
          uploadRouterlink: '',
          auth: "FUNDADDRESS_VIEW",
          authCreate: 'FUNDADDRESS_CREATE',
          authUpload: ''
        },
        {
          name: "trading_programs",
          createRouterlink: "/setting/trading-programs",
          viewRouterlink: "/setting/trading-programs",
          uploadRouterlink: '',
          auth: "TRADINGPROGRAM_VIEW",
          authCreate: 'TRADINGPROGRAM_CREATE',
          authUpload: ''
        },
        {
          name: "configure_notes",
          createRouterlink: "/setting/configure-notes-entry",
          viewRouterlink: "/setting/configure-notes",
          auth: "CONFIGURENOTES_VIEW",
          uploadRouterlink: '',
          authCreate: 'CONFIGURENOTES_CREATE',
          authUpload: ''
        },
        {
          name: "asset_based_fees",
          viewRouterlink: "/setting/asset-based-fee",
          createRouterlink: "/setting/asset-based-fee",
          uploadRouterlink: '',
          auth: "ASSETBASEDFEE_VIEW",
          authCreate: 'ASSETBASEDFEE_CREATE',
          authUpload: ''
        },
        {
          name: "performance_based_fee",
          createRouterlink: "/setting/performanc-based-fee",
          viewRouterlink: "/setting/performanc-based-fee",
          uploadRouterlink: '',
          auth: "MANAGERPERFBASEDFEE_VIEW",
          authCreate: 'MANAGERPERFBASEDFEE_CREATE',
          authUpload: ''
        },
        {
          name: "income_distribution_base",
          createRouterlink: "/setting/income-distribution-base",
          viewRouterlink: "/setting/income-distribution-base",
          uploadRouterlink: '',
          auth: "INCOMEDISTRIBUTIONBASE_VIEW",
          authCreate: 'INCOMEDISTRIBUTIONBASE_CREATE',
          authUpload: ''
        },
        {
          name: "fee_sharing",
          createRouterlink: "/setting/Fee-sharing",
          viewRouterlink: "/setting/Fee-sharing",
          auth: "FEESHARINGSETUP_VIEW",
          uploadRouterlink: '',
          authCreate: 'FEESHARINGSETUP_CREATE',
          authUpload: ''
        },
        {
          name: "recurring_transaction",
          viewRouterlink: "/setting/recurring-transaction",
          createRouterlink: "/setting/recurring-transaction",
          uploadRouterlink: '',
          auth: "RECURRINGTRANSACTION_VIEW",
          authCreate: 'RECURRINGTRANSACTION_CREATE',
          authUpload: ''
        },

        {
          name: "set_up_expense_amortization",
          createRouterlink: "/setting/setup-expense-amortization",
          viewRouterlink: "/setting/setup-expense-amortization",
          uploadRouterlink: '',
          auth: "SETUPEXPENSEAMORTIZATION_VIEW",
          authCreate: 'SETUPEXPENSEAMORTIZATION_CREATE',
          authUpload: ''
        },
        {
          name: "chart_of_accounts",
          cardAuthName: "DIV_SETTINGS_ACCOUNTING",
          createRouterlink: "/setting/chart-of-accounts-entry",
          viewRouterlink: "/setting/chart-of-accounts",
          uploadRouterlink: '/setting/chart-of-accounts-upload',
          auth: "CHARTOFACCOUNTS_VIEW",
          authCreate: 'CHARTOFACCOUNTS_CREATE',
          authUpload: 'CHARTOFACCOUNTS_CREATE'
        },
        {
          name: "review_chart_of_accounts",
          cardAuthName: "DIV_SETTINGS_ACCOUNTING",
          createRouterlink: "",
          viewRouterlink: "/setting/review-chart-of-accounts",
          uploadRouterlink: '/setting/review-chart-of-accounts-upload',
          auth: "SEAMLESS_SETTINGS_REVIEW_CHARTOFACCOUNTS_VIEW",
          authCreate: '',
          authUpload: 'SEAMLESS_SETTINGS_REVIEW_CHARTOFACCOUNTS_UPLOAD'
        },
        {
          name: "chart_of_accounts_update",
          cardAuthName: "DIV_SETTINGS_ACCOUNTING",
          viewRouterlink: "/setting/chart-of-accounts-update",
          uploadRouterlink: '/setting/chart-of-accounts-update',
          auth: "TAX_CHARTOFACCOUNT_UPDATE",
          authUpload: 'TAX_CHARTOFACCOUNT_UPDATE'
        },
        {
          name: "predefined_je",
          createRouterlink: "/setting/predefinedJe",
          viewRouterlink: "/setting/predefinedJe",
          auth: "PREDEFINED_JOURNAL_ENTRY_VIEW",
          uploadRouterlink: '/setting/predefinedJe/upload',
          authCreate: 'PREDEFINED_JOURNAL_ENTRY_CREATE',
          authUpload: 'PREDEFINED_JOURNAL_ENTRY_CREATE'
        },
        {
          name: "performance_analytics_settings",
          createRouterlink: "/setting/performance-analytics-settings",
          viewRouterlink: "/setting/performance-analytics-settings",
          auth: "PERFORMANCE_ANALYTICS_SETTINGS_VIEW",
          uploadRouterlink: '/setting/performance-analytics-settings/upload',
          authCreate: 'PERFORMANCE_ANALYTICS_SETTINGS_CREATE',
          authUpload: 'PERFORMANCE_ANALYTICS_SETTINGS_CREATE'
        },
        {
          name: "hurdle_tier_incentive_setup",
          createRouterlink: "/setting/hurdle-tire-incentive",
          viewRouterlink: "/setting/hurdle-tire-incentive",
          auth: "HURDLE_TIER_INCENTIVE_VIEW",
          uploadRouterlink: '',
          authCreate: 'HURDLE_TIER_INCENTIVE_CREATE',
          authUpload: ''
        },
        {
          name: "hurdle",
          createRouterlink: "/setting/hurdle",
          viewRouterlink: "/setting/hurdle",
          auth: "HURDLE_VIEW",
          uploadRouterlink: '',
          authCreate: 'HURDLE_CREATE',
          authUpload: ''
        },
        {
          name: "Fund Waterfall",
          createRouterlink: "/setting/waterfall-entry",
          viewRouterlink: "",
          auth: "SEAMLESS_SETTINGS_ACCOUNTING_FUNDWATERFALL",
          uploadRouterlink: '',
          authCreate: 'SEAMLESS_SETTINGS_ACCOUNTING_FUNDWATERFALL',
          authUpload: ''
        },

        // {
        //   name: "asset_based_fees",
        //   viewRouterlink: "/setting/asset-based-fee",
        //   createRouterlink: "/setting/asset-based-fee-entry",
        //   uploadRouterlink: '',
        //   auth: "ASSETBASEDFEE_VIEW",
        //   authCreate: 'ASSETBASEDFEE_CREATE',
        //   authUpload: ''
        // },
        // {
        //   name: "performance_based_fee",
        //   createRouterlink: "/setting/performanc-based-fee-entry",
        //   viewRouterlink: "/setting/performanc-based-fee",
        //   uploadRouterlink: '',
        //   auth: "MANAGERPERFBASEDFEE_VIEW",
        //   authCreate: 'MANAGERPERFBASEDFEE_CREATE',
        //   authUpload: ''
        // },
        // {
        //   name: "income_distribution_base",
        //   createRouterlink: "/setting/income-distribution-base-entry",
        //   viewRouterlink: "/setting/income-distribution-base",
        //   uploadRouterlink: '',
        //   auth: "INCOMEDISTRIBUTIONBASE_VIEW",
        //   authCreate: 'INCOMEDISTRIBUTIONBASE_CREATE',
        //   authUpload: ''
        // },
        // {
        //   name: "fee_sharing",
        //   createRouterlink: "/setting/Fee-sharing-entry",
        //   viewRouterlink: "/setting/Fee-sharing",
        //   auth: "FEESHARINGSETUP_VIEW",
        //   uploadRouterlink: '',
        //   authCreate: 'FEESHARINGSETUP_CREATE',
        //   authUpload: ''
        // },
        // {
        //   name: "recurring_transaction",
        //   viewRouterlink: "/setting/recurring-transaction",
        //   createRouterlink: "/setting/recurring-transaction-entry",
        //   uploadRouterlink: '/setting/recurring-transaction-upload',
        //   auth: "RECURRINGTRANSACTION_VIEW",
        //   authCreate: 'RECURRINGTRANSACTION_CREATE',
        //   authUpload: 'RECURRINGTRANSACTION_CREATE'
        // },

        // {
        //   name: "set_up_expense_amortization",
        //   createRouterlink: "/setting/setup-expense-amortization-entry",
        //   viewRouterlink: "/setting/setup-expense-amortization",
        //   uploadRouterlink: '',
        //   auth: "SETUPEXPENSEAMORTIZATION_VIEW",
        //   authCreate: 'SETUPEXPENSEAMORTIZATION_CREATE',
        //   authUpload: ''
        // },
        // {
        //   name: "chart_of_accounts",
        //   createRouterlink: "/setting/chart-of-accounts-entry",
        //   viewRouterlink: "/setting/chart-of-accounts",
        //   uploadRouterlink: '/setting/chart-of-accounts-upload',
        //   auth: "CHARTOFACCOUNTS_VIEW",
        //   authCreate: 'CHARTOFACCOUNTS_CREATE',
        //   authUpload: 'CHARTOFACCOUNTS_CREATE'
        // },
        // {
        //   name: "predefined_je",
        //   createRouterlink: "/setting/predefinedJe-entry",
        //   viewRouterlink: "/setting/predefinedJe",
        //   auth: "PREDEFINED_JOURNAL_ENTRY_VIEW",
        //   uploadRouterlink: '/setting/predefinedJe-upload',
        //   authCreate: 'PREDEFINED_JOURNAL_ENTRY_CREATE',
        //   authUpload: 'PREDEFINED_JOURNAL_ENTRY_CREATE'
        // },
        // {
        //   name: "performance_analytics_settings",
        //   createRouterlink: "/setting/performance-analytics-settings-entry",
        //   viewRouterlink: "/setting/performance-analytics-settings",
        //   auth: "PERFORMANCE_ANALYTICS_SETTINGS_VIEW",
        //   uploadRouterlink: '/setting/performance-analytics-settings-upload',
        //   authCreate: 'PERFORMANCE_ANALYTICS_SETTINGS_CREATE',
        //   authUpload: 'PERFORMANCE_ANALYTICS_SETTINGS_CREATE'
        // },
        // {
        //   name: "hurdle_tier_incentive_setup",
        //   createRouterlink: "/setting/hurdle-tire-incentive-entry",
        //   viewRouterlink: "/setting/hurdle-tire-incentive",
        //   auth: "HURDLE_TIER_INCENTIVE_VIEW",
        //   uploadRouterlink: '',
        //   authCreate: 'HURDLE_TIER_INCENTIVE_CREATE',
        //   authUpload: ''
        // },
        // {
        //   name: "hurdle",
        //   createRouterlink: "/setting/hurdle-entry",
        //   viewRouterlink: "/setting/hurdle",
        //   auth: "HURDLE_VIEW",
        //   uploadRouterlink: '',
        //   authCreate: 'HURDLE_CREATE',
        //   authUpload: ''
        // },

        {
          name: "asset_class",
          viewRouterlink: "/setting/asset-class",
          createRouterlink: "/setting/asset-class",
          uploadRouterlink: '',
          auth: "ASSETCLASS_VIEW",
          authCreate: 'ASSETCLASS_CREATE',
          authUpload: ''
        },
        {
          name: "commision",
          createRouterlink: "/setting/commission",
          viewRouterlink: "/setting/commission",
          uploadRouterlink: '',
          auth: "COMMISSION_VIEW",
          authCreate: 'COMMISSION_CREATE',
          authUpload: ''
        },
        {
          name: "trade_allocation_config",
          createRouterlink: "/setting/trade-allocation-config",
          viewRouterlink: "/setting/trade-allocation-config",
          uploadRouterlink: "/setting/tradeAllocationConfigUpload",
          auth: "TRADEALLOCATIONCONFIG_VIEW",
          authCreate: 'TRADEALLOCATIONCONFIG_CREATE',
          authUpload: 'TRADEALLOCATIONCONFIG_CREATE'
        },
        {
          name: "common_income_expense_config",
          createRouterlink: "/setting/common-income-expense-config-entry/create",
          viewRouterlink: "/setting/common-income-expense-config",
          auth: "SEAMLESS_SETTINGS_ACCOUNTING_COMMON_INCOME_EXPENSE_CONFIG_VIEW",
          uploadRouterlink: '/setting/upload-common-income-expense-config',
          authCreate: 'SEAMLESS_SETTINGS_ACCOUNTING_COMMON_INCOME_EXPENSE_CONFIG_CREATE',
          authUpload: 'SEAMLESS_SETTINGS_ACCOUNTING_COMMON_INCOME_EXPENSE_CONFIG_CREATE'
        },
        {
          name: "trade_allocation",
          createRouterlink: "/setting/trade-allocation",
          viewRouterlink: "/setting/trade-allocation",
          uploadRouterlink: '',
          auth: "TRADEALLOCATION_VIEW",
          authCreate: 'TRADEALLOCATION_CREATE',
          authUpload: ''
        },
        {
          name: "closing_methods",
          viewRouterlink: "/setting/closing-methods",
          createRouterlink: "/setting/closing-methods",
          uploadRouterlink: '',
          auth: "TAXLOTCLOSINGMETHOD_VIEW",
          authCreate: 'TAXLOTCLOSINGMETHOD_CREATE',
          authUpload: ''
        },
        {
          name: "risk_master",
          createRouterlink: "/setting/risk-master",
          viewRouterlink: "/setting/risk-master",
          uploadRouterlink: '/setting/risk-master/upload',
          auth: "RISKMASTER_VIEW",
          authCreate: 'RISKMASTER_CREATE',
          authUpload: 'RISKMASTER_CREATE'
        },
        {
          name: "sm_update",
          viewRouterlink: "/setting/sm-update",
          cardAuthName: "DIV_SETTINGS_PORTFOLIO",
          createRouterlink: "",
          uploadRouterlink: '/setting/sm-update',
          auth: "SETTINGS_PORTFOLIO_SMUPDATE",
          authCreate: '',
          authUpload: 'SETTINGS_PORTFOLIO_SMUPDATE'
        },
        {
          name: "sm_upload",
          viewRouterlink: "/setting/sm-upload",
          cardAuthName: "DIV_SETTINGS_PORTFOLIO",
          createRouterlink: "",
          uploadRouterlink: '/setting/sm-upload',
          auth: "SETTINGS_PORTFOLIO_SMUPDATE_FS",
          authCreate: '',
          authUpload: 'SETTINGS_PORTFOLIO_SMUPDATE_FS'
        },
        {
          name: "share_classes",
          viewRouterlink: "/setting/share-classes",
          createRouterlink: "/setting/share-classes",
          uploadRouterlink: '',
          auth: "FUNDCLASS_VIEW",
          authCreate: 'FUNDCLASS_CREATE',
          authUpload: ''
        },
        {
          name: "share_series",
          createRouterlink: "/setting/share-series",
          viewRouterlink: "/setting/share-series",
          uploadRouterlink: '',
          auth: "SHARESERIES_VIEW",
          authCreate: 'SHARESERIES_CREATE',
          authUpload: ''
        },
        {
          name: "redemption_fees",
          createRouterlink: "/setting/redemption-fees",
          viewRouterlink: "/setting/redemption-fees",
          uploadRouterlink: '',
          auth: "REDEMPTION_VIEW",
          authCreate: 'REDEMPTION_CREATE',
          authUpload: ''
        },
        {
          name: "hurdle_rate",
          viewRouterlink: "/setting/hurdle-rate",
          createRouterlink: "/setting/hurdle-rate",
          uploadRouterlink: '',
          auth: "HURDLERATE_VIEW",
          authCreate: 'HURDLERATE_CREATE',
          authUpload: ''
        },
        {
          name: "investor_update",
          cardAuthName: "DIV_SETTINGS_INVESTOR",
          viewRouterlink: "/setting/investor-update",
          createRouterlink: '',
          uploadRouterlink: '/setting/investor-update',
          auth: "SETTINGS_INVESTORSETTINGS_INVESTOR_UPDATE_TAX",
          authCreate: 'SETTINGS_INVESTORSETTINGS_INVESTOR_UPDATE_TAX',
          authUpload: 'SETTINGS_INVESTORSETTINGS_INVESTOR_UPDATE_TAX'
        },
        {
          name: "api_key",
          cardAuthName: "DIV_SETTINGS_API_MANAGEMENT",
          viewRouterlink: "/setting/api-key",
          createRouterlink: '/setting/api-key-entry',
          uploadRouterlink: '',
          auth: "SEAMLESS_SETTINGS_API_KEY_VIEW",
          authCreate: 'SEAMLESS_SETTINGS_API_KEY_CREATE',
          authUpload: ''
        },
        {
          name: "connect_key_plan",
          cardAuthName: "DIV_SETTINGS_API_MANAGEMENT",
          viewRouterlink: "/setting/api-key-usage/create",
          createRouterlink: '/setting/api-key-usage',
          uploadRouterlink: '',
          auth: "SEAMLESS_SETTINGS_API_USAGE_PLAN_MAPPING",
          authCreate: 'SEAMLESS_SETTINGS_API_USAGE_PLAN_MAPPING',
          authUpload: ''
        },
        {
          name: "api_reference",
          cardAuthName: "DIV_SETTINGS_API_MANAGEMENT",
          viewRouterlink: "/api-reference",
          createRouterlink: '',
          uploadRouterlink: '',
          auth: "SEAMLESS_SETTINGS_API_DOC_VIEW",
          authCreate: '',
          authUpload: ''
        },
        {
          name: "fs_transaction_code_update",
          cardAuthName: "DIV_SETTINGS_CONFIGURATION",
          viewRouterlink: "",
          createRouterlink: "",
          uploadRouterlink: '/setting/fs-transaction-code-update',
          auth: "SEAMLESS_SETTINGS_FS_TRAXN_UPDATE_UPLOAD",
          authCreate: 'undefined',
          authUpload: 'SEAMLESS_SETTINGS_FS_TRAXN_UPDATE_UPLOAD',
          isUploadAuth: true,
        },
      ], authsibebar: 'SIDEMENU_SETTINGS'
    },

    {
      Button: [
        {
          name: "Step by Step Workflow",
          viewRouterlink: "/workflow",
          createRouterlink: "",
          uploadRouterlink: '',
          auth: "SIDEMENU_DAILY_WORK_FLOW",
          authCreate: '',
          authUpload: ''
        },
      ], authsibebar: 'SIDEMENU_DAILY_WORK_FLOW'
    },
    {
      Button: [
        // {
        //   name: "batch_tracking",
        //   viewRouterlink: "/rapid-processing/batchTracking",
        //   createRouterlink: "",
        //   uploadRouterlink: '',
        //   auth: "SEAMLESS_PROCESSING_DIV_BATCH",
        //   authCreate: '',
        //   authUpload: ''
        // },
      ], authsibebar: 'SEAMLESS_SIDEMENU_PROCESSING'
    },
    {
      Button: [
        // ---Reports---
        // accounting 
        { id: 1, name: "balance_sheet", auth: "REPORTS_ACCOUNTING_BALANCESHEET_ALT_VER", viewRouterlink: "/reports/balance-sheet", createRouterlink: '', authCreate: '', uploadRouterlink: '', authUpload: '' },
        { id: 2, name: "income_statement", auth: "REPORTS_ACCOUNTING_INCOME_STATEMENT_ALT_VER", viewRouterlink: "/reports/income-statement", createRouterlink: '', authCreate: '', uploadRouterlink: '', authUpload: '', },
        { id: 3, name: "trial_balance", auth: "REPORTS_ACCOUNTING_TRIAL_BALANCE_ALT_VER", viewRouterlink: "/reports/trial-balance", createRouterlink: '', authCreate: '', uploadRouterlink: '', authUpload: '', },
        {
          id: 3,
          name: "account_trial_balance",
          auth: "SEAMLESS_RE_ACCOUNT_TRIAL_BALANCE_REPORT",
          viewRouterlink: "/reports/account-trial-balance",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 3,
          name: "account_balance_sheet",
          auth: "SEAMLESS_RE_ACCOUNT_BALANCE_SHEET",
          viewRouterlink: "/reports/account-balance-sheet",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 6,
          name: "income_statement_account",
          auth: "SEAMLESS_RE_ACCOUNT_INCOME_STATEMENT",
          link: "/reports/account-income-statement",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 3,
          name: "account_statement_report",
          auth: "SEAMLESS_RE_ACCOUNT_STATEMENT_REPORT",
          viewRouterlink: "/reports/account-statement-report",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 18,
          name: "account_position_report",
          auth: "SEAMLESS_RE_ACCOUNT_POSITION_REPORT",
          viewRouterlink: "/reports/account-postion-report",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        { id: 4, name: "account_statement", auth: "REPORTS_ACCOUNTING_ACCOUNT_STATEMENT", viewRouterlink: "/reports/account-statement", createRouterlink: '', authCreate: '', uploadRouterlink: '', authUpload: '', },
        { id: 5, name: "management_fee", auth: "REPORTS_ACCOUNTING_MANAGEMENTFEE", viewRouterlink: "/reports/management-fee", createRouterlink: '', authCreate: '', uploadRouterlink: '', authUpload: '', },
        { id: 6, name: "perfromance_fee", auth: "REPORTS_ACCOUNTING_PERFORMANCEFEE", viewRouterlink: "/reports/perfromance-fee", createRouterlink: '', authCreate: '', uploadRouterlink: '', authUpload: '', },
        { id: 7, name: "return", auth: "REPORTS_ACCOUNTING_DAILYROR_VIEW", viewRouterlink: "/reports/return", createRouterlink: '', authCreate: '', uploadRouterlink: '', authUpload: '', },
        { id: 8, name: "cash_ledger", auth: "REPORTS_ACCOUNTING_CASHLEDGER_REPORT", viewRouterlink: "/reports/cash-ledger", createRouterlink: '', authCreate: '', uploadRouterlink: '', authUpload: '', },
        { id: 9, name: "glreport", auth: "REPORTS_ACCOUNTING_GENERAL_LEDGER_REPORT", viewRouterlink: "/reports/gl-report", createRouterlink: '', authCreate: '', uploadRouterlink: '', authUpload: '', },
        { id: 9, name: "gl_ac", auth: "REPORTS_ACCOUNTING_GENERAL_LEDGER_CUSTOM_REPORT", viewRouterlink: "/reports/gl-ac", createRouterlink: '', authCreate: '', uploadRouterlink: '', authUpload: '', },
        { id: 10, name: "operating_expense", auth: "REPORTS_ACCOUNTING_OPERATINGEXPENSES_VIEW", viewRouterlink: "/reports/operating-expense", createRouterlink: '', authCreate: '', uploadRouterlink: '', authUpload: '', },
        { id: 11, name: "commission", auth: "REPORTS_ACCOUNTING_COMMISSION", viewRouterlink: "/reports/commision", createRouterlink: '', authCreate: '', uploadRouterlink: '', authUpload: '', },
        { id: 12, name: "performance-fee-report-hurdle", auth: "SEAMLESS_REPORTS_ACCOUNTING_PERFORMANCEFEE_REPORT_HURDLE", viewRouterlink: "/reports/performance-fee-report-hurdle", createRouterlink: '', authCreate: '', uploadRouterlink: '', authUpload: '', },
        {
          id: 13,
          name: "general_ledger_account",
          auth: "SEAMLESS_RE_GENERAL_LEDGER_ACCOUNT_REPORT",
          viewRouterlink: "/reports/general-ledger-account",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 14,
          name: "account_general_ledger",
          auth: "SEAMLESS_RE_ACCOUNT_GENERAL_LEDGER_REPORT",
          viewRouterlink: "/reports/account-general-ledger",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 15,
          name: "account_cash_ledger",
          auth: "SEAMLESS_RE_ACCOUNT_CASH_LEDGER_REPORT",
          viewRouterlink: "/reports/account-cash-ledger",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 16,
          name: "account_operting_expense",
          auth: "SEAMLESS_RE_ACCOUNT_OPERATING_EXPENSES_REPORT",
          viewRouterlink: "/reports/account-operating-expense",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 17,
          name: "account_activity_report",
          auth: "SEAMLESS_RE_ACCOUNT_ACTIVITY_REPORT",
          viewRouterlink: "/reports/activity-account",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 18,
          name: "navs_package_report",
          auth: "SEAMLESS_ACCOUNTING_REPORT_PACKAGE",
          viewRouterlink: "/reports/navpackagereport",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 19,
          name: "Transaction Drill Down",
          auth: "SEAMLESS_RE_DRILLDOWN",
          viewRouterlink: "/reports/transaction-drillDown",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 20,
          name: "Entity Relationship",
          auth: "SEAMLESS_RE_ENTITY_RELATIONSHIP",
          viewRouterlink: "/reports/entity-relationship",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 20,
          name: "TL_Performance_report",
          cardAuthName: "DIV_REPORTS_ACCOUNTING_REPORTS",
          auth: "SEAMLESS_REPORTS_TL_PERFORMANCE",
          viewRouterlink: "/reports/TL-performance-report",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 19,
          name: "Grouping Configuration",
          auth: "SEAMLESS_SETTINGS_RE_GROUPING_CONFIG",
          viewRouterlink: "/setting/re-report-config",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 19,
          name: "investor_statement_footer_customization",
          auth: "SETTING_CONFIG_INVESTOR_STATEMENT_FOOTER_CUSTOMIZATION",
          viewRouterlink: "/setting/investor-statement-footer-customization",
          createRouterlink: "/setting/investor-statement-footer-customization-entry",
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        // portfolio 
        {
          id: 1,
          name: "position_report",
          auth: "SEAMLESS_PORTFOLIO_POSITION_REPORT",
          viewRouterlink: "/reports/position-report",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 10,
          name: "position_report_detailed",
          auth: "REPORTS_PORTFOLIO_ POSITIONREPORTDETAILED",
          cardAuthName: "DIV_REPORTS_PORTFOLIO_REPORTS",
          viewRouterlink: "/reports/position-report-detailed",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 2,
          name: "open_tax_lots_report",
          auth: "IREPORTS_QUERY_OPENTAX_VIEW",
          viewRouterlink: "/reports/open-tax-lots-report",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 2,
          name: "Investment Details - Lot Wise",
          cardAuthName: "DIV_REPORTS_PORTFOLIO_REPORTS",
          auth: "REPORTS_PORTFOLIO_INVESTMENTDETAILSREPORT_LOTWISE",
          viewRouterlink: "/reports/investment-details-lot-wise",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 3,
          name: "tax_lot_appraisal_report",
          auth: "TAX_LOT_APPRAISAL_VIEW",
          viewRouterlink: "/reports/tax-lot-appraisal-report",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 3,
          name: "Variance Report",
          auth: "SEAMLESS_RE_VARIANCE_REPORT",
          viewRouterlink: "/reports/variance-report",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 4,
          name: "port_data",
          auth: "IREPORTS_PORTFOLIO_PORTDATA",
          viewRouterlink: "/reports/port-data",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 14,
          name: "position_summary",
          cardAuthName: "DIV_REPORTS_CHECKS_REPORTS",
          auth: "IREPORTS_PORTFOLIO_POSITION_SUMMARY",
          viewRouterlink: "/reports/position-summary-report",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 4,
          name: "totalgainloss_with_fx_gain_loss_on_cash",
          cardAuthName: "DIV_REPORTS_PORTFOLIO_REPORTS",
          auth: "SEAMLESS_PORTFOLIO_FXGLONCASH_REPORT",
          viewRouterlink: "/reports/total-gain-loss-fx",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 5,
          name: "portfolio_turnover_ratio",
          auth: "IREPORTS_Portfolio_Portfolio_Turnover_Ratio",
          viewRouterlink: "/reports/portfolio-turnover-ratio",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 6,
          name: "performance_detailed",
          auth: "IREPORTS_PORTFOLIO_PERFORMANCE_DETAILED",
          viewRouterlink: "/reports/performance-detailed",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 7,
          name: "margin_by_equity_by_fund",
          auth: "SEAMLESS_PORTFOLIO_MARGINBYEQUITYBYFUND",
          viewRouterlink: "/reports/margin-by-equity-by-fund",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 15,
          name: "deal_summary_report",
          auth: "SEAMLESS_REPORTS_PORTFOLIO_DEAL_SUMMARY",
          viewRouterlink: "/reports/deal-summary-report",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 8,
          name: "assest_allocation_report",
          auth: "REPORTS_PORTFOLIO_ASSET_ALLOCATION_DB",
          viewRouterlink: "/reports/assest-allocation-report",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 9,
          name: "dividend",
          auth: "SEAMLESS_PORTFOLIO_DIVIDEND",
          viewRouterlink: "/reports/dividend",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 10,
          name: "price_analysis",
          auth: "SEAMLESS_PRICE_ANALYTICS_REPORT",
          viewRouterlink: "/reports/price-analysis",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 12,
          name: "position-analytics",
          auth: "SEAMLESS_POSITION_ANALYTICS_REPORT",
          viewRouterlink: "/reports/position-analytics",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 13,
          name: "attribution_report",
          auth: "SEAMLESS_ATTRIBUTION_REPORT",
          viewRouterlink: "/reports/attribution-report",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 14,
          name: "portfolio_exposer_report",
          auth: "SEAMLESS_PORTFOLIO_EXPOSURE_REPORT",
          viewRouterlink: "/reports/portfolio-exposure-report",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        //tax
        {
          id: 1,
          name: "1099-Report",
          auth: "SEAMLESS_TAXATION_REPORTS_1099REPORT",
          viewRouterlink: "/taxSetup/report1099",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        // {
        //   id: 1,
        //   name: "tax_return_package",
        //   auth: "SEAMLESS_TAXATION_REPORTS_TAXRETURNPACKAGE",
        //   viewRouterlink: "/taxSetup/generate",
        //   createRouterlink: '',
        //   authCreate: '',
        //   uploadRouterlink: '',
        //   authUpload: '',
        // },
        {
          id: 1,
          name: "tax_return_package_two",
          auth: "SEAMLESS_TAXATION_REPORTS_TAXRETURNPACKAGENEW",
          viewRouterlink: "/taxSetup/generate-tax-reports-new",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 1,
          name: "dividend_distribution",
          auth: "SEAMLESS_TAXATION_REPORTS_DIVIDENDDISTRIBUTION",
          viewRouterlink: "/taxSetup/distribution-report",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 1,
          name: "ytd_distribution_base",
          cardAuthName: "DIV_REPORTS_TAXATION",
          auth: "SEAMLESS_TAXATION_REPORTS_YTDDISTRIBUTIONBASE",
          viewRouterlink: "/taxSetup/ytd-distribution-base",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 4,
          name: "Monthly Columnar Report",
          auth: "SEAMLESS_TAXATION_REPORTS_MONTLYCOLUMNAR",
          viewRouterlink: "/taxSetup/monthly-columnar-report",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 2,
          name: "product_tax_category_classification",
          auth: "SEAMLESS_TAXATION_REPORTS_PTCC",
          viewRouterlink: "/taxSetup/product-tax-category-classification",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        // investor 
        {
          id: 1,
          name: "investor_capital_summary",
          auth: "REPORTS_INVESTOR_INVESTOR_CAPITAL_SUMMARY",
          viewRouterlink: "/reports/investor-capital-summary",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 2,
          name: "investor_statement_dynamic",
          auth: "INVESTOR_STATEMENT_MULTIPLE_DOWNLOADS",
          viewRouterlink: "/reports/investor-statement",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 2,
          name: "investor_statment_aif_ror2",
          cardAuthName: "DIV_REPORTS_INVESTOR_REPORTS",
          auth: "SEAMLESS_REPORTS_INVESTORSTATEMENTAIFWITHROR2_VIEW",
          viewRouterlink: "/reports/investor-statement-aif-ror2",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 2,
          name: "investor_statment_aif_ror",
          auth: "IREPORT_INVESTOR_INVESTORSTATEMENTAIFWITHROR_VIEW",
          viewRouterlink: "/reports/investor-statement-aif-ror",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 10,
          name: "investor_statment_aif_with_class",
          cardAuthName: "DIV_REPORTS_INVESTOR_REPORTS",
          auth: "SEAMLESS_REPORTS_INVESTORSTATEMENTAIFWITHCLASS",
          viewRouterlink: "/reports/investor-statement-aif-with-class",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 3,
          name: "Investor_capital_schedule",
          auth: "IREPORTS_INVESTOR_INVESTORCAPITALSCHEDULE",
          viewRouterlink: "/reports/Investor-capital-schedule",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 4,
          name: "capital_schedule",
          auth: "IREPORTS_INVESTOR_CAPITALSCHEDULE",
          viewRouterlink: "/reports/capital-schedule",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 5,
          name: "investor_summary_reports",
          auth: "REPORTS_ACCOUNTING_INVESTMENTSUMMARYREPORTS",
          viewRouterlink: "/reports/investor-summary-reports",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 6,
          name: "equity_allocation",
          auth: "IREPORTS_CTA_EQTYALLCTION",
          viewRouterlink: "/reports/equity-allocation",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 7,
          name: "econmic_allocation_report",
          auth: "SEAMLESS_INVESTOR_ECONOMICALLOCATIONDETAIL",
          viewRouterlink: "/reports/econmic-allocation-report",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 8,
          name: "Investor statement consolidate detailed",
          auth: "SEAMLESS_REPORT_INVESTOR_INVESTOR_STATEMENT_CONSOLIDATE_DETAILED",
          viewRouterlink: "/reports/investor-statement-consolidatedetailed",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 8,
          name: "Investor Statement Consolidated Portfolio Level",
          cardAuthName: "DIV_REPORTS_INVESTOR_REPORTS",
          auth: "SEAMLESS_REPORTS_INVESTOR_INVESTOR_STATEMENT_CONSOLIDATED_PORTFOLIO_LEVEL",
          viewRouterlink: "/reports/investor-statement-consolidatedetailed-portfolio-level",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 16,
          name: "Investor Transaction Report",
          auth: "SEAMLESS_REPORTS_INVESTOR_TRANSACTIONREPORT",
          viewRouterlink: "/reports/investor-transaction-report",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 18,
          name: "investor_statement_for_individual_share_class",
          auth: "SEAMLESS_REPORTS_INVESTORSTATEMENTFORINDIVIDUALSHARECLASS",
          viewRouterlink: "/reports/investor-statement-for-individual-share-class",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 7,
          name: "investor_roll_forward_report",
          auth: "SEAMLESS_INVESTOR_ROLL_FORWARD_REPORT",
          viewRouterlink: "/reports/investor-roll-forward-report",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 7,
          name: "interest_report",
          auth: "SEAMLESS_REPORTS_PORTFOLIO_INTEREST_REPORT",
          viewRouterlink: "/reports/interest-report",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 7,
          name: "Fx Gain Loss",
          cardAuthName: "DIV_REPORTS_PORTFOLIO_REPORTS",
          auth: "SEAMLESS_REPORTS_PORTFOLIO_FXGAINLOSS",
          viewRouterlink: "/reports/fxgainloss-report",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 8,
          name: "dividend_report",
          auth: "IREPORTS_DIVIDEND",
          viewRouterlink: "/reports/dividend-report",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 9,
          name: "capital_pl_investor",
          auth: "REPORTS_CAPITAL_AND_PL_BY_INVESTOR",
          viewRouterlink: "/reports/capital-pl-investor",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 10,
          name: "equity_schedule",
          cardAuthName: "DIV_REPORTS_INVESTOR_REPORTS",
          auth: "SEAMLESS_REPORTS_INVESTOR_EQUITY_SCHEDULE_SUMMARY",
          viewRouterlink: "/reports/equity",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        // gainloss 
        {
          id: 1,
          name: "realized_gl",
          auth: "REPORTS_TRADING_GAIN_LOSS_REALIZED_GAIN_LOSS",
          viewRouterlink: "/reports/realized-gl",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 2,
          name: "unrealized_gl",
          auth: "REPORTS_TRADING GAIN_LOSS_UNREALIZED_GAIN_LOSS",
          viewRouterlink: "/reports/unrealized-gl",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 3,
          name: "transaction_report",
          auth: "SEAMLESS_TRADING_GAINLOSS_TRANSACTION_HISTORY_VIEW",
          viewRouterlink: "/reports/transaction-report",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 4,
          name: "pnl_attribution",
          auth: "REPORTS_INTERACTIVE_PNLATTRIBUTION",
          viewRouterlink: "/reports/pnl-attribution",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 5,
          name: "pnl_contribution_ror",
          auth: "REPORTS_ACCOUNTING_PNLCONTRIBUTIONWITHROR",
          viewRouterlink: "/reports/pnl-contribution-ror",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 6,
          name: "pl_attribution_feeders",
          auth: "REPORTS_INTERACTIVE_PNLATTRIBUTIONFEEDER",
          viewRouterlink: "/reports/pl-attribution-feeders",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 7,
          name: "comprehensive_pl",
          auth: "SEAMLESS_TRADING_GAIN_LOSS_COMPREHENSIVE_PNL",
          viewRouterlink: "/reports/comprehensive-pl",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 8,
          name: "pnl_by_symbol",
          auth: "REPORTS_ACCOUNTING_PNLBYSYMBOL",
          viewRouterlink: "/reports/pnl-by-symbol",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 9,
          name: "comprehensive_p_and_l_by_symbol",
          auth: "SEAMLESS_REPORTS_GAINLOSS_COMPREHENSIVE_P&L_BY_SYMBOL",
          viewRouterlink: "/reports/comprehensive-p-and-l-by-symbol",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 10,
          name: "realized_tax_lot_report",
          auth: "SEAMLESS_REPORTS_GAIN/LOSS_REALIZEDTAXLOTREPORT",
          viewRouterlink: "/reports/realized-tax-lot-report",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        // checkReport 
        {
          id: 1,
          name: "Daily Checks",
          auth: "IREPORTS_CHECKS_DAILY_CHECKS_VIEW",
          viewRouterlink: "/reports/daily-checks",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 2,
          name: "EOD Position",
          auth: "IREPORTS_QUERY_EODPOSITION_VIEW",
          viewRouterlink: "/reports/eod-position",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 3,
          name: "EOD Checks Sheet",
          auth: "REPORTS_ACCOUNTING_EOD_CHECKS",
          viewRouterlink: "/reports/eod-checks-sheet",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 4,
          name: "EOD Checks by Account",
          auth: "REPORTS_ACCOUNTING_EOD_CHECKS_BY_AACOUNT",
          viewRouterlink: "/reports/eod-checks-account",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 4,
          name: "input_data_integrity_check",
          auth: "USER_Input_Data_Integrity_Check",
          viewRouterlink: "/reports/input-data-integrity-check",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        // reconcillation
        {
          id: 1,
          name: "Position Rec Summary",
          auth: "REPORTS_RECONCILIATIONREPORTS_POSITIONRECREPORT_SUMMARY",
          viewRouterlink: "/reports/position-rec-summary",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 1,
          name: "Position Rec Report",
          auth: "REPORTS_RECONCILIATIONREPORTS_POSITIONRECREPORT",
          viewRouterlink: "/reports/position-rec-report",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 2,
          name: "Cash Balance Reconciliation Report",
          auth: "REPORTS_RECONCILIATIONREPORTS_CASHBALANCERECREPORT",
          viewRouterlink: "/reports/cash-balance-rec",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 3,
          name: "trade_break_report",
          auth: "IREPORTS_RECONCILIATION_TRADE_BREAK_REPORT",
          viewRouterlink: "/reports/trade-break-report",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 4,
          name: "cash_recon_multi_party",
          auth: "REPORTS_RECONCILIATIONREPORTS_CASH_REC_MULTIPARTY",
          viewRouterlink: "/reports/cash-recon-multi-trade",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        // perfReport
        {
          id: 1,
          name: "Performance Table ",
          auth: "REPORTS_CTA_COMPOSITECUSTOM_VIEW",
          viewRouterlink: "/reports/perf-table",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 2,
          name: "Invoice",
          auth: "I-REPORTS_CTA_INVOICE_VIEW",
          viewRouterlink: "/reports/invoice",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 2,
          name: "CTA Invoice",
          auth: "SEAMLESS_CTA_INVOICE_REPORT",
          viewRouterlink: "/reports/cta-invoice",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 3,
          name: "Performance Table Account",
          auth: "MULTIPLE_FUND_REPORT_VIEW",
          viewRouterlink: "/reports/performance-table-account",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 4,
          name: "CTA Invoice QTD",
          cardAuthName: "DIV_REPORTS_PERFORMANCE",
          auth: "SEAMLESS_CTA_INVOICE_QTD_REPORT",
          viewRouterlink: "/reports/cta-invoice-qtd",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 5,
          name: "Date Range Table All ACCT",
          cardAuthName: "DIV_REPORTS_PERFORMANCE",
          auth: "REPORTS_PERFORMANCE_CUSTOM_DATE_RANGE_ALL_ACCT",
          viewRouterlink: "/reports/date-range-all-acct-report",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        // customReport
        //  {
        //    id: 1,
        //    name: "Investor Statement AIF",
        //    auth: "FUNDMENULIST_REPORTS_INVESTOR_STATEMENT_ALT_VIEW",
        //    viewRouterlink: "/reports/investor-statment-aif",
        //    createRouterlink: '',
        //    authCreate: '',
        //    uploadRouterlink: '',
        //    authUpload: '',
        //  },
        {
          id: 2,
          name: "fund_nav_daily",
          auth: "IREPORTS_OTHER_FUNDNAVDAILY",
          viewRouterlink: "/reports/fund-nav-daily",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 9,
          name: "roundTurnPerMillion",
          cardAuthName: "DIV_REPORTS_CUSTOM",
          auth: "SEAMLESS_CUSTOM_ROUND_TURN_PER_MILLION_REPORT",
          viewRouterlink: "/reports/round-turns-per-millions",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 3,
          name: "holding_maturity",
          auth: "IREPORTS_QUERY_HOLDING_MATURITY",
          viewRouterlink: "/reports/holding-maturity",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 4,
          name: "man_fee_report_preq",
          auth: "REPORTS_ACCOUNTING_MANAGEMENTFEE_PREQ",
          viewRouterlink: "/reports/management-fee-report-preq",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 5,
          name: "customized_position",
          auth: "IREPORTS_PORTFOLIO_CUSTOMIZED_POSITION",
          viewRouterlink: "/reports/customized-position",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 6,
          name: "Holding Performance Report",
          auth: "REPORTS_PORTFOLIO_HOLDING_PERFORMANCE_REPORT",
          viewRouterlink: "/reports/holding-performance-report",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 7,
          name: "pnl_allocation",
          auth: "IREPORTS_CTA_P&LALLOCATION",
          viewRouterlink: "/reports/pndl-allocation",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 8,
          name: "man_fee_report_commitment",
          auth: "REPORTS_QUERY_MANAGEMENTFEEREPORT",
          viewRouterlink: "/reports/management-fee-report-commitment",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 9,
          name: "Performance Capsule - Program",
          auth: "AMC_CAPSULE_REPORT_VIEW",
          viewRouterlink: "/reports/performance-capsule-program",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 10,
          name: "Performance Table Program",
          auth: "COMPOSITE_PERFORMANCE_TABLE_VIEW",
          viewRouterlink: "/reports/performance-table-program",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 11,
          name: "Side Pocket Summary",
          auth: "REPORTS_ACCOUNTING_SIDE_POCKET_SUMMARY",
          viewRouterlink: "/reports/side-pocket-summary",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 12,
          name: "Investor Capital Summary Side Pocket",
          auth: "INVESTOR_CAPITAL_SUMMARY_SIDE_POCKET",
          viewRouterlink: "/reports/investor-capital-summary-side-pocket",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 13,
          name: "factsheet_fund",
          auth: "FACTSHEET_FUND_SRO",
          viewRouterlink: "/reports/factsheet-fund-report",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 13,
          name: "composite-perf",
          auth: "COMPOSITE_PERF",
          viewRouterlink: "/reports/composite-perf",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },

        {
          id: 14,
          name: "factsheet_investor",
          auth: "FACTSHEET_INVESTOR_SRO",
          viewRouterlink: "/reports/factsheet-investor-report",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 15,
          name: "financial_leverage",
          auth: "REPORTS_FINANCIAL_LEVERAGE_REPORT",
          viewRouterlink: "/reports/financial-leverage",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 16,
          name: "maturity_aging",
          auth: "REPORTS_MATURITY_AGING_REPORT",
          viewRouterlink: "/reports/maturity-aging",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 17,
          name: "investment_summary",
          auth: "REPORTS_INVESTMENT_SUMMARY",
          viewRouterlink: "/reports/investment-summary",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 18,
          name: "capital_call_report",
          auth: "SEAMLESS_REPORTS_CUSTOM_CAPITAL_CALL_REPORT",
          viewRouterlink: "/reports/capital-call-report",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 19,
          name: "call_and_dist",
          auth: "SEAMLESS_REPORTS_CUSTOM_CALL_AND_DIST",
          viewRouterlink: "/reports/call-and-dist",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 19,
          name: "equity_schedule_pe",
          auth: "SEAMLESS_REPORTS_CUSTOM_Equity_Schedule_PE",
          viewRouterlink: "/reports/equity-schedule-pe",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 18,
          name: "loan_report",
          auth: "REPORTS_CUSTOM_LOAN_REPORT",
          viewRouterlink: "/reports/loan-report",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 18,
          name: "capital-summary-commitment",
          auth: "SEAMLESS_CUSTOM_CAPITAL_SUMMARY_COMMITMENT_REPORT",
          viewRouterlink: "/reports/capital-summary-commitment",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        // consolidate 
        {
          id: 2,
          name: "investor_statement_aif_new",
          auth: "IREPORTS_INVESTORS_INVESTOR_STATEMENT_AIF_NEW",
          viewRouterlink: "/reports/investor-statement-aif-new",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 2,
          name: "investor_statement_consolidate",
          auth: "IREPORT_INVESTOR_INVESTORSTATEMENTAIFCONSOLIDATE_VIEW",
          viewRouterlink: "/reports/investor-statement-consolidate",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 3,
          name: "position_recon_multi_party",
          auth: "SEAMLESS_POSITION_RECON_MULTIPARTY",
          viewRouterlink: "/reports/position-recon-multi-party",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 3,
          name: "market_value_by_sector",
          auth: "SEAMLESS_MARKET_VALUE_BY_SECTOR_SUBSECTOR",
          viewRouterlink: "/reports/market-value-by-sector",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 2,
          name: "investor_statement_aif_by_fund",
          auth: "IREPORT_INVESTOR_INVESTOR_STATEMENT_AIF_BY_FUND",
          viewRouterlink: "/reports/investor-statement-aif-by-fund",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },

      ], authsibebar: 'LEFTPANEL_REPORTS_NAV'
    },
    //-----financial statement-----//
    {
      Button: [
        {
          id: 1,
          name: "Financial Statement",
          cardAuthName: "REPORTS_FINANCIAL_STATEMENT",
          auth: "REPORTS_FINANCIAL_STATEMENT",
          viewRouterlink: "/fs",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        }
      ],
      authsibebar: 'SEAMLESS_FINANCIAL_STATEMENT'
    },
    //-----Input Buttons-----//
    {
      Button: [
        { id: 1, name: 'data_import', cardAuthName: 'SEAMLESS_SIDEMENU_INPUT', auth: 'SEAMLESS_INPUT_DIV_IMPORT', viewRouterlink: "/input/import/executeWorkflow", createRouterlink: '', uploadRouterlink: '', authUpload: '', authCreate: '' },
        { id: 2, name: 'trade', cardAuthName: "SEAMLESS_SIDEMENU_INPUT", viewRouterlink: "/input/view/trade-list", createRouterlink: '/input/create/trade-entry', uploadRouterlink: '/input/upload/trade-list-upload', auth: 'SEAMLESS_INPUT_VIEW_TRADES_TRADE', authCreate: 'SEAMLESS_INPUT_CREATE_TRADES_TRADE', authUpload: 'SEAMLESS_INPUT_CREATE_TRADES_TRADE', catCreatAuth: "SEAMLESS_INPUT_CREATE_TRADES", catViewAuth: "SEAMLESS_INPUT_VIEW_TRADES" },
        { id: 3, name: 'daily_margin', cardAuthName: "SEAMLESS_SIDEMENU_INPUT", viewRouterlink: "/input/view/daily-margin", createRouterlink: '/input/create/daily-margin-entry', uploadRouterlink: '/input/upload/daily-margin-upload', auth: 'SEAMLESS_INPUT_VIEW_TRADES_DAILYMARGIN', authCreate: 'SEAMLESS_INPUT_CREATE_TRADES_DAILYMARGIN', authUpload: 'SEAMLESS_INPUT_CREATE_TRADES_DAILYMARGIN', catCreatAuth: "SEAMLESS_INPUT_CREATE_TRADES", catViewAuth: "SEAMLESS_INPUT_VIEW_TRADES" },
        { id: 4, name: 'new_issues', cardAuthName: "SEAMLESS_SIDEMENU_INPUT", viewRouterlink: "/input/view/new-issues", createRouterlink: '/input/create/new-issues-entry', uploadRouterlink: '', auth: 'SEAMLESS_INPUT_VIEW_TRADES_NEWISSUES', authCreate: 'SEAMLESS_INPUT_CREATE_TRADES_NEWISSUES', authUpload: '', catCreatAuth: "SEAMLESS_INPUT_CREATE_TRADES", catViewAuth: "SEAMLESS_INPUT_VIEW_TRADES" },
        { id: 5, name: "non_trade_activity", cardAuthName: "SEAMLESS_SIDEMENU_INPUT", viewRouterlink: "/input/view/nontradeactivity", createRouterlink: "/input/create/nontradeactivity-entry", uploadRouterlink: "/input/upload/nonTradeActivity-upload", authCreate: 'SEAMLESS_INPUT_CREATE_ACCOUNTING_NONTRADEACTIVITY', auth: "SEAMLESS_INPUT_VIEW_ACCOUNTING_NONTRADEACTIVITY", authUpload: 'SEAMLESS_INPUT_CREATE_ACCOUNTING_NONTRADEACTIVITY', catCreatAuth: "SEAMLESS_INPUT_CREATE_ACCOUNTING", catViewAuth: "SEAMLESS_INPUT_VIEW_ACCOUNTING" },
        { id: 6, name: 'capital_activities', cardAuthName: "SEAMLESS_SIDEMENU_INPUT", auth: 'SEAMLESS_INPUT_VIEW_INVESTORS_CAPITALACTIVITY', viewRouterlink: "/input/view/capital-activities", createRouterlink: '/input/create/capital-activity-entry', uploadRouterlink: '/input/upload/capital-activities-upload', authUpload: 'SEAMLESS_INPUT_CREATE_INVESTORS_CAPITALACTIVITY', authCreate: 'SEAMLESS_INPUT_CREATE_INVESTORS_CAPITALACTIVITY', catCreatAuth: "SEAMLESS_INPUT_CREATE_INVESTORS", catViewAuth: "SEAMLESS_INPUT_VIEW_INVESTORS" },
        { id: 7, name: 'investor_groups', cardAuthName: "SEAMLESS_SIDEMENU_INPUT", auth: 'SEAMLESS_INPUT_VIEW_INVESTORS_INVESTORGROUP', viewRouterlink: "/input/view/investor-group", createRouterlink: '/input/create/investor-group-entry', uploadRouterlink: '', authUpload: '', authCreate: 'SEAMLESS_INPUT_CREATE_INVESTORS_INVESTORGROUP', catCreatAuth: "SEAMLESS_INPUT_CREATE_INVESTORS", catViewAuth: "SEAMLESS_INPUT_VIEW_INVESTORS" },
        { id: 8, name: 'investors', cardAuthName: "SEAMLESS_SIDEMENU_INPUT", auth: 'SEAMLESS_INPUT_VIEW_INVESTORS_INVESTORS', viewRouterlink: "/input/view/investor", createRouterlink: '/input/create/investor-entry', uploadRouterlink: '/input/upload/investor-upload', authUpload: 'SEAMLESS_INPUT_CREATE_INVESTORS_INVESTORS', authCreate: 'SEAMLESS_INPUT_CREATE_INVESTORS_INVESTORS', catCreatAuth: "SEAMLESS_INPUT_CREATE_INVESTORS", catViewAuth: "SEAMLESS_INPUT_VIEW_INVESTORS" },
        { id: 9, name: 'fetch_exchange_rate', isQueryString: true, queryParam: { openModel: true }, cardAuthName: "SEAMLESS_SIDEMENU_INPUT", auth: 'SEAMLESS_INPUT_VIEW_PORTFOLIO_EXCHANGERATE', viewRouterlink: "/input/view/exchange-rate", createRouterlink: '', uploadRouterlink: '', authUpload: '', authCreate: '', catViewAuth: "SEAMLESS_INPUT_VIEW_PORTFOLIO" },
        { id: 10, name: 'setup_bond_interest', cardAuthName: "SEAMLESS_SIDEMENU_INPUT", auth: 'SEAMLESS_INPUT_VIEW_PORTFOLIO_BONDINTEREST', createRouterlink: '/input/create/setup-bond-interest-entry', viewRouterlink: '/input/view/setup-bond-interest', uploadRouterlink: '/input/upload/setup-bond-interest-upload', authUpload: 'SEAMLESS_INPUT_CREATE_PORTFOLIO_BONDINTEREST', authCreate: 'SEAMLESS_INPUT_CREATE_PORTFOLIO_BONDINTEREST', catCreatAuth: "SEAMLESS_INPUT_CREATE_PORTFOLIO", catViewAuth: "SEAMLESS_INPUT_VIEW_PORTFOLIO" },
        { id: 11, name: 'bond_interest_adjustment', cardAuthName: "SEAMLESS_SIDEMENU_INPUT", auth: 'SEAMLESS_INPUT_VIEW_PORTFOLIO_BONDINTERESTADJUSTMENT', createRouterlink: '/input/create/bond-interest-adjustment-entry', viewRouterlink: '/input/view/bond-interest-adjustment', authCreate: 'SEAMLESS_INPUT_CREATE_PORTFOLIO_BONDINTERESTADJUSTMENT', uploadRouterlink: '/input/upload/bond-interest-adjustment-upload', authUpload: 'SEAMLESS_INPUT_CREATE_PORTFOLIO_BONDINTERESTADJUSTMENT', catCreatAuth: "SEAMLESS_INPUT_CREATE_PORTFOLIO", catViewAuth: "SEAMLESS_INPUT_VIEW_PORTFOLIO" },
        { id: 12, name: 'sub_sectors', cardAuthName: "SEAMLESS_SIDEMENU_INPUT", auth: 'SEAMLESS_INPUT_VIEW_PORTFOLIO_SUB_SECTORS', createRouterlink: '/input/create/sub-sectors-entry', viewRouterlink: '/input/view/sub-sectors', uploadRouterlink: '/input/upload/sub-sectors-upload', authUpload: 'SEAMLESS_INPUT_CREATE_PORTFOLIO_SUB_SECTORS', authCreate: 'SEAMLESS_INPUT_CREATE_PORTFOLIO_SUB_SECTORS', catCreatAuth: "SEAMLESS_INPUT_CREATE_PORTFOLIO", catViewAuth: "SEAMLESS_INPUT_VIEW_PORTFOLIO" },
        { id: 12, name: 'dividend_accrual', cardAuthName: "SEAMLESS_SIDEMENU_INPUT", auth: 'SEAMLESS_INPUT_VIEW_PORTFOLIO_DIVIDEND', createRouterlink: '/input/create/dividend-entry', viewRouterlink: '/input/view/dividend', uploadRouterlink: '/input/upload/dividend-upload', authUpload: 'SEAMLESS_INPUT_CREATE_PORTFOLIO_DIVIDEND', authCreate: 'SEAMLESS_INPUT_CREATE_PORTFOLIO_DIVIDEND', catCreatAuth: "SEAMLESS_INPUT_CREATE_PORTFOLIO", catViewAuth: "SEAMLESS_INPUT_VIEW_PORTFOLIO" },
        { id: 13, name: 'dividend_adjustment', cardAuthName: "SEAMLESS_SIDEMENU_INPUT", auth: 'SEAMLESS_INPUT_VIEW_PORTFOLIO_DIVIDENDADJUSTMENT', createRouterlink: '/input/create/dividend-adjustment-entry', viewRouterlink: '/input/view/dividend-adjustment', uploadRouterlink: '/input/upload/dividend-adjustment-upload', authUpload: 'SEAMLESS_INPUT_CREATE_PORTFOLIO_DIVIDENDADJUSTMENT', authCreate: 'SEAMLESS_INPUT_CREATE_PORTFOLIO_DIVIDENDADJUSTMENT', catCreatAuth: "SEAMLESS_INPUT_CREATE_PORTFOLIO", catViewAuth: "SEAMLESS_INPUT_VIEW_PORTFOLIO" },
        { id: 14, name: 'market_data', cardAuthName: "SEAMLESS_SIDEMENU_INPUT", auth: 'SEAMLESS_INPUT_VIEW_PORTFOLIO_MARKETDATA', createRouterlink: '/input/create/market-data-entry', viewRouterlink: '/input/view/market-data', uploadRouterlink: '/input/upload/market-data-upload', authUpload: 'SEAMLESS_INPUT_CREATE_PORTFOLIO_MARKETDATA', authCreate: 'SEAMLESS_INPUT_CREATE_PORTFOLIO_MARKETDATA', catCreatAuth: "SEAMLESS_INPUT_CREATE_PORTFOLIO", catViewAuth: "SEAMLESS_INPUT_VIEW_PORTFOLIO" },
        { id: 15, name: 'seamless_market_data', cardAuthName: "SEAMLESS_SIDEMENU_INPUT", auth: 'SEAMLESS_INPUT_VIEW_PORTFOLIO_SEAMLESSMARKETDATA', viewRouterlink: '/input/view/seamless-market-data', createRouterlink: '', uploadRouterlink: '/input/upload/seamless-market-data-upload', authUpload: 'SEAMLESS_INPUT_CREATE_PORTFOLIO_SEAMLESSMARKETDATA', authCreate: '', catCreatAuth: "SEAMLESS_INPUT_CREATE_PORTFOLIO", catViewAuth: "SEAMLESS_INPUT_VIEW_PORTFOLIO" },
        { id: 16, name: 'crypto_market_data', cardAuthName: "SEAMLESS_SIDEMENU_INPUT", auth: 'SEAMLESS_INPUT_VIEW_PORTFOLIO_CRYPTOMARKETDATA', viewRouterlink: '/input/view/crypto-market-data', createRouterlink: '', uploadRouterlink: '/input/upload/crypto-market-data-upload', authUpload: 'SEAMLESS_INPUT_VIEW_PORTFOLIO_CRYPTOMARKETDATA', authCreate: '', catCreatAuth: "SEAMLESS_INPUT_CREATE_PORTFOLIO", catViewAuth: "SEAMLESS_INPUT_VIEW_PORTFOLIO" },
        { id: 17, name: 'sm_equities', cardAuthName: "SEAMLESS_SIDEMENU_INPUT", auth: 'SEAMLESS_INPUT_VIEW_SECURITYMASTER_EQUITIES', createRouterlink: '/input/create/sm-equities-entry', viewRouterlink: '/input/view/sm-equities', uploadRouterlink: '/input/upload/sm-equities-upload', authUpload: 'SEAMLESS_INPUT_CREATE_SECURITYMASTER_EQUITIES', authCreate: 'SEAMLESS_INPUT_CREATE_SECURITYMASTER_EQUITIES', catCreatAuth: "SEAMLESS_INPUT_CREATE_SECURITYMASTER", catViewAuth: "SEAMLESS_INPUT_VIEW_SECURITYMASTER" },
        { id: 18, name: 'sm_options', cardAuthName: "SEAMLESS_SIDEMENU_INPUT", auth: 'SEAMLESS_INPUT_VIEW_SECURITYMASTER_OPTIONS', createRouterlink: '/input/create/sm-options-entry', viewRouterlink: '/input/view/sm-options', uploadRouterlink: '/input/upload/sm-options-upload', authUpload: 'SEAMLESS_INPUT_CREATE_SECURITYMASTER_OPTIONS', authCreate: 'SEAMLESS_INPUT_CREATE_SECURITYMASTER_OPTIONS', catCreatAuth: "SEAMLESS_INPUT_CREATE_SECURITYMASTER", catViewAuth: "SEAMLESS_INPUT_VIEW_SECURITYMASTER" },
        { id: 19, name: 'sm_fixed_income', cardAuthName: "SEAMLESS_SIDEMENU_INPUT", auth: 'SEAMLESS_INPUT_VIEW_SECURITYMASTER_FIXEDINCOME', createRouterlink: '/input/create/sm-fixed-income-entry', viewRouterlink: '/input/view/sm-fixed-income', uploadRouterlink: '/input/upload/sm-fixed-income-upload', authUpload: 'SEAMLESS_INPUT_CREATE_SECURITYMASTER_FIXEDINCOME', authCreate: 'SEAMLESS_INPUT_CREATE_SECURITYMASTER_FIXEDINCOME', catCreatAuth: "SEAMLESS_INPUT_CREATE_SECURITYMASTER", catViewAuth: "SEAMLESS_INPUT_VIEW_SECURITYMASTER" },
        { id: 20, name: 'sm_fx_forward', cardAuthName: "SEAMLESS_SIDEMENU_INPUT", auth: 'SEAMLESS_INPUT_VIEW_SECURITYMASTER_FXFORWARD', createRouterlink: '/input/create/sm-fx-forward-entry', viewRouterlink: '/input/view/sm-fx-forward', uploadRouterlink: '/input/upload/sm-fx-forward-upload', authUpload: 'SEAMLESS_INPUT_CREATE_SECURITYMASTER_FXFORWARD', authCreate: 'SEAMLESS_INPUT_CREATE_SECURITYMASTER_FXFORWARD', catCreatAuth: "SEAMLESS_INPUT_CREATE_SECURITYMASTER", catViewAuth: "SEAMLESS_INPUT_VIEW_SECURITYMASTER" },
        { id: 21, name: 'sm_private_fund', cardAuthName: "SEAMLESS_SIDEMENU_INPUT", auth: 'SEAMLESS_INPUT_VIEW_SECURITYMASTER_PRIVATEFUND', createRouterlink: '/input/create/sm-private-fund-entry', viewRouterlink: '/input/view/sm-private-fund', uploadRouterlink: '/input/upload/sm-private-fund-upload', authUpload: 'SEAMLESS_INPUT_CREATE_SECURITYMASTER_PRIVATEFUND', authCreate: 'SEAMLESS_INPUT_CREATE_SECURITYMASTER_PRIVATEFUND', catCreatAuth: "SEAMLESS_INPUT_CREATE_SECURITYMASTER", catViewAuth: "SEAMLESS_INPUT_VIEW_SECURITYMASTER" },
        { id: 22, name: 'sm_futures', cardAuthName: "SEAMLESS_SIDEMENU_INPUT", auth: 'SEAMLESS_INPUT_VIEW_SECURITYMASTER_FUTURES', createRouterlink: '/input/create/sm-future-entry', viewRouterlink: '/input/view/sm-future', uploadRouterlink: '/input/upload/sm-future-upload', authUpload: 'SEAMLESS_INPUT_CREATE_SECURITYMASTER_FUTURES', authCreate: 'SEAMLESS_INPUT_CREATE_SECURITYMASTER_FUTURES', catCreatAuth: "SEAMLESS_INPUT_CREATE_SECURITYMASTER", catViewAuth: "SEAMLESS_INPUT_VIEW_SECURITYMASTER" },
        { id: 23, name: 'sm_real_estate', auth: 'SEAMLESS_INPUT_VIEW_SECURITYMASTER_REALESTATE', createRouterlink: '/input/create/sm-real-estate-entry', viewRouterlink: '/input/view/sm-real-estate', uploadRouterlink: '/input/upload/sm-real-estate-upload', authUpload: 'SEAMLESS_INPUT_CREATE_SECURITYMASTER_REALESTATE', authCreate: 'SEAMLESS_INPUT_CREATE_SECURITYMASTER_REALESTATE', catCreatAuth: "SEAMLESS_INPUT_CREATE_SECURITYMASTER", catViewAuth: "SEAMLESS_INPUT_VIEW_SECURITYMASTER" },
        { id: 24, name: 'pools', cardAuthName: "SEAMLESS_SIDEMENU_INPUT", auth: 'SEAMLESS_INPUT_VIEW_SECURITYMASTER_POOLS', createRouterlink: '/input/create/sm-pools-entry', viewRouterlink: '/input/view/sm-pools', uploadRouterlink: '/input/upload/sm-pools-upload', authUpload: 'SEAMLESS_INPUT_CREATE_SECURITYMASTER_POOLS', authCreate: 'SEAMLESS_INPUT_CREATE_SECURITYMASTER_POOLS', catCreatAuth: "SEAMLESS_INPUT_CREATE_SECURITYMASTER", catViewAuth: "SEAMLESS_INPUT_VIEW_SECURITYMASTER" },
        { id: 25, name: 'crypto', cardAuthName: "SEAMLESS_SIDEMENU_INPUT", auth: 'SEAMLESS_INPUT_VIEW_SECURITYMASTER_CRYPTO', createRouterlink: '/input/create/sm-crypto-entry', viewRouterlink: '/input/view/sm-crypto', uploadRouterlink: '/input/upload/sm-crypto-upload', authUpload: 'SEAMLESS_INPUT_CREATE_SECURITYMASTER_CRYPTO', authCreate: 'SEAMLESS_INPUT_CREATE_SECURITYMASTER_CRYPTO', catCreatAuth: "SEAMLESS_INPUT_CREATE_SECURITYMASTER", catViewAuth: "SEAMLESS_INPUT_VIEW_SECURITYMASTER" },
        { id: 26, name: 'exchange_rate', cardAuthName: "SEAMLESS_SIDEMENU_INPUT", auth: 'SEAMLESS_INPUT_VIEW_PORTFOLIO_EXCHANGERATE', createRouterlink: '/input/create/exchange-rate-entry', viewRouterlink: '/input/view/exchange-rate', uploadRouterlink: '/input/upload/exchange-rate-upload', authUpload: 'SEAMLESS_INPUT_CREATE_PORTFOLIO_EXCHANGERATE', authCreate: 'SEAMLESS_INPUT_CREATE_PORTFOLIO_EXCHANGERATE', catCreatAuth: "SEAMLESS_INPUT_CREATE_PORTFOLIO", catViewAuth: "SEAMLESS_INPUT_VIEW_PORTFOLIO" },
        { id: 27, name: 'fetch_market_data', isQueryString: true, queryParam: { openModel: true }, cardAuthName: "SEAMLESS_SIDEMENU_INPUT", auth: 'SEAMLESS_INPUT_VIEW_PORTFOLIO_MARKETDATA', viewRouterlink: '/input/view/market-data', createRouterlink: '', uploadRouterlink: '', authUpload: '', authCreate: '', catViewAuth: "SEAMLESS_INPUT_VIEW_PORTFOLIO" },
        { id: 28, name: "journal_entry", cardAuthName: "SEAMLESS_SIDEMENU_INPUT", viewRouterlink: "/input/view/journalEntry", createRouterlink: "/input/create/journalEntry-entry", uploadRouterlink: "/input/upload/journalEntry-upload", authCreate: 'SEAMLESS_INPUT_CREATE_ACCOUNTING_JOURNALENTRY', auth: "SEAMLESS_INPUT_VIEW_ACCOUNTING_JOURNALENTRY", authUpload: 'SEAMLESS_INPUT_CREATE_ACCOUNTING_JOURNALENTRY', catCreatAuth: "SEAMLESS_INPUT_CREATE_ACCOUNTING", catViewAuth: "SEAMLESS_INPUT_VIEW_ACCOUNTING" },
        { id: 29, name: "csd_bank_transaction", cardAuthName: "SEAMLESS_SIDEMENU_INPUT", viewRouterlink: "/input/view/fetchcsdbanktransaction", createRouterlink: "", uploadRouterlink: "", authCreate: '', auth: "SEAMLESS_INPUT_VIEW_ACCOUNTING_CSDBANKTRANSACTION", authUpload: '', catCreatAuth: "", catViewAuth: "SEAMLESS_INPUT_VIEW_ACCOUNTING" },
        { id: 29, name: 'oms', cardAuthName: "SEAMLESS_SIDEMENU_INPUT", viewRouterlink: "/input/view/oms", createRouterlink: '', uploadRouterlink: '', auth: 'SEAMLESS_INPUT_VIEW_TRADES_OMS', authCreate: '', authUpload: '', catViewAuth: "SEAMLESS_INPUT_VIEW_TRADES" },
        { id: 30, name: 'create_order', viewRouterlink: "/input/view/oms", createRouterlink: '/input/create/oms-entry', uploadRouterlink: '', auth: 'SEAMLESS_INPUT_VIEW_TRADES_OMS', authCreate: 'SEAMLESS_INPUT_CRAETE_TRADES_ORDERMANAGEMENTSYSTEM', authUpload: '', catCreatAuth: "SEAMLESS_INPUT_CREATE_TRADES", catViewAuth: "SEAMLESS_INPUT_VIEW_TRADES" },
        { id: 31, name: 'historical_wash_sale_openlot', cardAuthName: "SEAMLESS_SIDEMENU_INPUT", viewRouterlink: "/input/view/historical-wash", createRouterlink: '', uploadRouterlink: '/input/upload/historical-wash-upload', auth: 'SEAMLESS_INPUT_VIEW_TRADES_HISTORICAL_WASHSALE_OPENLOTS', authCreate: '', authUpload: 'SEAMLESS_INPUT_UPLOAD_TRADES_HISTORICAL_WASHSALE_OPENLOTS', catCreatAuth: "SEAMLESS_INPUT_CREATE_TRADES", catViewAuth: "SEAMLESS_INPUT_VIEW_TRADES" },
        { id: 32, name: 'transfer_trade', cardAuthName: "SEAMLESS_SIDEMENU_INPUT", viewRouterlink: "/input/view/transfer-trade-history", createRouterlink: '/trades/transfer-trade/', uploadRouterlink: '/input/upload/transfer-trade-history-upload', auth: 'SEAMLESS_INPUT_VIEW_TRADES_TRANSFERTRADE_RAWDATA', authCreate: 'TRADE_ENTRY_TRANSFER_TRADE_NEW_VERSION_CREATE', authUpload: 'SEAMLESS_INPUT_UPLOAD_TRADES_TRANSFERTRADE', catCreatAuth: "SEAMLESS_INPUT_CREATE_TRADES", catViewAuth: "SEAMLESS_INPUT_VIEW_TRADES" },
        { id: 33, name: "itd_subscription_and_redemption", cardAuthName: "SEAMLESS_SIDEMENU_INPUT", viewRouterlink: "/input/view/itd-subscription-redemption", createRouterlink: "", uploadRouterlink: "/input/upload/itd-subscription-redemption-upload", authCreate: '', auth: "SEAMLESS_INPUT_VIEW_INVESTORS_ITDSUBSCRIPTIONANDREDEMPTION", authUpload: 'SEAMLESS_INPUT_VIEW_INVESTORS_ITDSUBSCRIPTIONANDREDEMPTION', catCreatAuth: "SEAMLESS_INPUT_CREATE_INVESTORS", catViewAuth: "SEAMLESS_INPUT_VIEW_INVESTORS" },
        { id: 9, name: "tl_regime", cardAuthName: "SEAMLESS_SIDEMENU_INPUT", auth: "SEAMLESS_INPUT_VIEW_ACCOUNTING_TLREGIME", viewRouterlink: "/input/view/TLRegime", createRouterlink: '/input/create/TLRegime-entry', authCreate: 'SEAMLESS_INPUT_CREATE_ACCOUNTING_TLREGIME', uploadRouterlink: '/input/upload/TLRegime-upload', authUpload: 'SEAMLESS_INPUT_CREATE_ACCOUNTING_TLREGIME', catCreatAuth: "SEAMLESS_INPUT_CREATE_ACCOUNTING", catViewAuth: "SEAMLESS_INPUT_VIEW_ACCOUNTING" },
        { id: 4, name: "trading_level_change", cardAuthName: "SEAMLESS_SIDEMENU_INPUT", auth: "SEAMLESS_INPUT_VIEW_ACCOUNTING_TRADINGLEVELCHANGE", viewRouterlink: "/input/view/tradingLevelChange", createRouterlink: '/input/create/tradingLevelChange-entry', authCreate: 'SEAMLESS_INPUT_CREATE_ACCOUNTING_TRADINGLEVELCHANGE', uploadRouterlink: '/input/upload/tradingLevelChange-upload', authUpload: 'SEAMLESS_INPUT_CREATE_ACCOUNTING_TRADINGLEVELCHANGE', catCreatAuth: "SEAMLESS_INPUT_CREATE_ACCOUNTING", catViewAuth: "SEAMLESS_INPUT_VIEW_ACCOUNTING" },
        { id: 2, name: "upload_allocation_history", cardAuthName: "SEAMLESS_SIDEMENU_INPUT", auth: "SEAMLESS_INPUT_VIEW_ACCOUNTING_ALLOCATION_HISTORY", viewRouterlink: "/input/view/uploadallocationhistory", createRouterlink: '', authCreate: '', uploadRouterlink: '/input/upload/uploadallocationhistory-upload', authUpload: 'SEAMLESS_INPUT_UPLOAD_ACCOUNTING_ALLOCATION_HISTORY', catCreatAuth: "SEAMLESS_INPUT_CREATE_ACCOUNTING", catViewAuth: "SEAMLESS_INPUT_VIEW_ACCOUNTING" },
        { id: 21, name: "special_allocation", cardAuthName: "SEAMLESS_SIDEMENU_INPUT", auth: "SEAMLESS_INPUT_VIEW_ACCOUNTING_SPECIALALLOCATION", viewRouterlink: "/input/view/specialAllocation", createRouterlink: '/input/create/specialAllocation-entry', authCreate: 'SEAMLESS_INPUT_CREATE_ACCOUNTING_SPECIALALLOCATION', uploadRouterlink: '/input/upload/specialAllocation-upload', authUpload: 'SEAMLESS_INPUT_CREATE_ACCOUNTING_SPECIALALLOCATION', catCreatAuth: "SEAMLESS_INPUT_CREATE_ACCOUNTING", catViewAuth: "SEAMLESS_INPUT_VIEW_ACCOUNTING" },
        { id: 9, name: "custom_ratio", cardAuthName: "SEAMLESS_SIDEMENU_INPUT", auth: "SEAMLESS_INPUT_VIEW_ACCOUNTING_CUSTOM_RATIO", viewRouterlink: "/input/view/custom-ratio", createRouterlink: '/input/create/custom-ratio-entry', authCreate: 'SEAMLESS_INPUT_CREATE_ACCOUNTING_CUSTOM_RATIO', uploadRouterlink: '/input/upload/custom-ratio-upload', authUpload: 'SEAMLESS_INPUT_CREATE_ACCOUNTING_CUSTOM_RATIO', catCreatAuth: "SEAMLESS_INPUT_CREATE_ACCOUNTING", catViewAuth: "SEAMLESS_INPUT_VIEW_ACCOUNTING" },
        { id: 9, name: "program_historical_data", cardAuthName: "SEAMLESS_SIDEMENU_INPUT", auth: "SEAMLESS_INPUT_VIEW_ACCOUNTING_PROGRAMHISTORICALDATA", viewRouterlink: "/input/view/programHistoricalData", createRouterlink: '/input/create/programHistoricalData-entry', authCreate: 'SEAMLESS_INPUT_CREATE_ACCOUNTING_PROGRAMHISTORICALDATA', uploadRouterlink: '/input/upload/programHistoricalData-upload', authUpload: 'SEAMLESS_INPUT_CREATE_ACCOUNTING_PROGRAMHISTORICALDATA', catCreatAuth: "SEAMLESS_INPUT_CREATE_ACCOUNTING", catViewAuth: "SEAMLESS_INPUT_VIEW_ACCOUNTING" },
        { id: 9, name: "side_pocket_creation", cardAuthName: "SEAMLESS_SIDEMENU_INPUT", auth: "SEAMLESS_INPUT_VIEW_ACCOUNTING_SIDEPOCKET", viewRouterlink: "/input/view/side-pocket-creation", createRouterlink: '/input/create/side-pocket-creation-entry', authCreate: 'SEAMLESS_INPUT_CREATE_ACCOUNTING_SIDEPOCKET', uploadRouterlink: '/input/upload/side-pocket-creation-upload', authUpload: 'SEAMLESS_INPUT_CREATE_ACCOUNTING_SIDEPOCKET', catCreatAuth: "SEAMLESS_INPUT_CREATE_ACCOUNTING", catViewAuth: "SEAMLESS_INPUT_VIEW_ACCOUNTING" },
        { id: 9, name: "side_pocket_redemption", cardAuthName: "SEAMLESS_SIDEMENU_INPUT", auth: "SEAMLESS_INPUT_VIEW_ACCOUNTING_SIDEPOCKET_REDEMPTION", viewRouterlink: "/input/view/side-pocket-redemption", createRouterlink: '/input/create/side-pocket-redemption-entry', authCreate: 'SEAMLESS_INPUT_CREATE_ACCOUNTING_SIDEPOCKET_REDEMPTION', uploadRouterlink: '', authUpload: '', catCreatAuth: "SEAMLESS_INPUT_CREATE_ACCOUNTING", catViewAuth: "SEAMLESS_INPUT_VIEW_ACCOUNTING" },
        { id: 9, name: "mid_period_reset", cardAuthName: "SEAMLESS_SIDEMENU_INPUT", auth: "SEAMLESS_INPUT_VIEW_ACCOUNTING_MID_PERIOD_RESET", viewRouterlink: "/input/view/mid-period-reset", createRouterlink: '/input/create/mid-period-reset-entry', authCreate: 'SEAMLESS_INPUT_CREATE_ACCOUNTING_MID_PERIOD_RESET', uploadRouterlink: '', authUpload: '', catCreatAuth: "SEAMLESS_INPUT_CREATE_ACCOUNTING", catViewAuth: "SEAMLESS_INPUT_VIEW_ACCOUNTING" },
        { id: 13, name: 'vendors_update', cardAuthName: "SEAMLESS_SIDEMENU_INPUT", auth: 'SEAMLESS_INPUT_CREATE_ACCOUNTING_VENDORS_UPDATE', viewRouterlink: 'vendorcreate', uploadRouterlink: '/input/upload/uploadVendors-upload', createRouterlink: '/input/create/vendorcreate-entry', authUpload: 'SEAMLESS_INPUT_CREATE_ACCOUNTING_VENDORS_UPDATE', },
        { id: 10, name: 'benchmark_irr', cardAuthName: "SEAMLESS_SIDEMENU_INPUT", auth: 'SEAMLESS_INPUT_VIEW_PORTFOLIO_BENCHMARK_IRR', createRouterlink: '', viewRouterlink: '/input/view/bench-mark-irr', uploadRouterlink: '/input/upload/bench-mark-irr-upload', authUpload: 'SEAMLESS_INPUT_UPLOAD_PORTFOLIO_BENCHMARK_IRR', authCreate: '', catCreatAuth: "SEAMLESS_INPUT_CREATE_PORTFOLIO", catViewAuth: "SEAMLESS_INPUT_VIEW_PORTFOLIO" },
        { id: 10, name: 'cash_balance', cardAuthName: "SEAMLESS_SIDEMENU_INPUT", auth: 'SEAMLESS_INPUT_VIEW_RECONCILIATION_CASHBALANCE_LIST', createRouterlink: '', viewRouterlink: '/input/view/cash-balance-broker', uploadRouterlink: '/input/upload/upload-cash-balance', authUpload: 'SEAMLESS_INPUT_UPLOAD_RECONCILIATION_CASHBALANCE', authCreate: '', catCreatAuth: "SEAMLESS_INPUT_UPLOAD_RECONCILIATION", catViewAuth: "SEAMLESS_INPUT_VIEW_RECONCILIATION" },
        { id: 10, name: 'position_broker', cardAuthName: "SEAMLESS_SIDEMENU_INPUT", auth: 'SEAMLESS_INPUT_VIEW_RECONCILIATION_POSITION_BROKER_LIST', createRouterlink: 'input/create/position-broker-entry', viewRouterlink: '/input/view/position-broker', uploadRouterlink: '/input/upload/upload-position-broker', authUpload: 'SEAMLESS_INPUT_UPLOAD_RECONCILIATION_POSITION_BROKER', authCreate: 'SEAMLESS_INPUT_UPLOAD_RECONCILIATION_POSITION_BROKER', catCreatAuth: "SEAMLESS_INPUT_UPLOAD_RECONCILIATION", catViewAuth: "SEAMLESS_INPUT_VIEW_RECONCILIATION" },
        { id: 10, name: 'dividend_broker', cardAuthName: "SEAMLESS_SIDEMENU_INPUT", auth: 'SEAMLESS_INPUT_VIEW_RECONCILIATION_DIVIDEND_BROKER', createRouterlink: '', viewRouterlink: '/input/view/dividend-broker', uploadRouterlink: '/input/upload/dividend-broker-upload', authUpload: 'SEAMLESS_INPUT_UPLOAD_RECONCILIATION_DIVIDEND_BROKER', authCreate: '', catCreatAuth: "SEAMLESS_INPUT_UPLOAD_RECONCILIATION", catViewAuth: "SEAMLESS_INPUT_VIEW_RECONCILIATION" },
        { id: 5, name: "takeover_perf_base_fee", cardAuthName: "SEAMLESS_SIDEMENU_INPUT", viewRouterlink: "/input/view/take-over-perf-base-fee", createRouterlink: "/input/create/takeover-perf-fee-entry", uploadRouterlink: "/input/upload/take-over-perf-base-fee-upload", authCreate: 'SEAMLESS_INPUT_CREATE_ACCOUNTING_TAKEOVER_PERFORMANCE_BASED_FEE', auth: "SEAMLESS_INPUT_VIEW_ACCOUNTING_TAKEOVER_PERFORMANCE_BASED_FEE", authUpload: 'SEAMLESS_INPUT_CREATE_ACCOUNTING_TAKEOVER_PERFORMANCE_BASED_FEE', catCreatAuth: "SEAMLESS_INPUT_CREATE_ACCOUNTING", catViewAuth: "SEAMLESS_INPUT_VIEW_ACCOUNTING" },
        { id: 5, name: "mgt_incentive_fee_adj", cardAuthName: "SEAMLESS_SIDEMENU_INPUT", viewRouterlink: "/input/view/mgt-incentive-fee-adj", createRouterlink: "/input/create/mgt-incentive-fee-adj-entry", uploadRouterlink: "/input/upload/mgt-incentive-fee-adj-upload", authCreate: 'SEAMLESS_INPUT_CREATE_ACCOUNTING_MGT_AND_INCENTIVE_FEE_ADJUSTMENT', auth: "SEAMLESS_INPUT_VIEW_ACCOUNTING_MGT_AND_INCENTIVE_FEE_ADJUSTMENT", authUpload: 'SEAMLESS_INPUT_CREATE_ACCOUNTING_MGT_AND_INCENTIVE_FEE_ADJUSTMENT', catCreatAuth: "SEAMLESS_INPUT_CREATE_ACCOUNTING", catViewAuth: "SEAMLESS_INPUT_VIEW_ACCOUNTING" },
        { id: 14, name: 'benchmark', cardAuthName: "SEAMLESS_SIDEMENU_INPUT", auth: 'SEAMLESS_INPUT_VIEW_PORTFOLIO_BENCHMARK', createRouterlink: '/input/create/benchmark-entry', viewRouterlink: '/input/view/benchmark', uploadRouterlink: '/input/upload/upload-benchmark', authUpload: 'SEAMLESS_INPUT_CREATE_PORTFOLIO_BENCHMARK', authCreate: 'SEAMLESS_INPUT_CREATE_PORTFOLIO_BENCHMARK', catCreatAuth: "SEAMLESS_INPUT_CREATE_PORTFOLIO", catViewAuth: "SEAMLESS_INPUT_VIEW_PORTFOLIO" },
        { id: 5, name: "external_ror", cardAuthName: "SEAMLESS_SIDEMENU_INPUT", viewRouterlink: "/input/view/external-ror", createRouterlink: "", uploadRouterlink: "/input/upload/external-ror-upload", authCreate: '', auth: "SEAMLESS_INPUT_VIEW_ACCOUNTING_EXTERNAL_ROR", authUpload: 'SEAMLESS_INPUT_UPLOAD_ACCOUNTING_EXTERNAL_ROR', catCreatAuth: "SEAMLESS_INPUT_CREATE_ACCOUNTING", catViewAuth: "SEAMLESS_INPUT_VIEW_ACCOUNTING" },
        { id: 2, name: 'high_volume_trades', cardAuthName: "SEAMLESS_SIDEMENU_INPUT", viewRouterlink: "/input/view/high-volume-trade/tradeUploadHV", createRouterlink: '', uploadRouterlink: '', auth: 'SEAMLESS_INPUT_VIEW_TRADES_TRADE_UPLOAD_HV', authCreate: '', authUpload: '', catCreatAuth: "SEAMLESS_INPUT_CREATE_TRADES", catViewAuth: "SEAMLESS_INPUT_VIEW_TRADES" },
        { id: 2, name: 'trades_upload_status', cardAuthName: "SEAMLESS_SIDEMENU_INPUT", viewRouterlink: "/input/view/high-volume-trade/tradeUploadEntry", createRouterlink: '', uploadRouterlink: '/input/upload/trade-upload-hv', auth: 'SEAMLESS_INPUT_VIEW_TRADES_TRADE_UPLOAD_HV', authCreate: '', authUpload: 'SEAMLESS_INPUT_UPLOAD_TRADES_TRADES_HIGH_VOLUME', catCreatAuth: "SEAMLESS_INPUT_CREATE_TRADES", catViewAuth: "SEAMLESS_INPUT_VIEW_TRADES" },
        { id: 10, name: 'trade_entry_broker', cardAuthName: "SEAMLESS_SIDEMENU_INPUT", auth: 'SEAMLESS_INPUT_VIEW_RECONCILIATION_TRADE_ENTRY_BROKER', createRouterlink: '', viewRouterlink: '/input/view/trade-entry-broker', uploadRouterlink: '', authUpload: '', authCreate: '', catCreatAuth: "", catViewAuth: "SEAMLESS_INPUT_VIEW_RECONCILIATION" },
        { id: 55, name: "account_summary", cardAuthName: "SEAMLESS_SIDEMENU_INPUT", auth: "SEAMLESS_INPUT_VIEW_BANKRECONCILIATION_ACCOUNTSUMMARY", viewRouterlink: "/input/view/account-summary", createRouterlink: '', authCreate: '', uploadRouterlink: '', authUpload: '', catCreatAuth: "SEAMLESS_INPUT_CREATE_RECONCILIATION", catViewAuth: "SEAMLESS_INPUT_VIEW_RECONCILIATION" },
        { id: 56, name: "Reconcile", cardAuthName: "SEAMLESS_SIDEMENU_INPUT", auth: "SEAMLESS_INPUT_CREATE_BANKRECONCILIATION_ACCOUNTSUMMARY", viewRouterlink: "/input/create/account-summary-entry/create", createRouterlink: '/input/create/account-summary-entry', authCreate: 'SEAMLESS_INPUT_CREATE_BANKRECONCILIATION_ACCOUNTSUMMARY', uploadRouterlink: '', authUpload: 'SEAMLESS_INPUT_UPLOAD_RECONCILIATION', catCreatAuth: "SEAMLESS_INPUT_CREATE_RECONCILIATION", catViewAuth: "SEAMLESS_INPUT_VIEW_RECONCILIATION" },
        { id: 12, name: 'gross_cash_flow', cardAuthName: "SEAMLESS_SIDEMENU_INPUT", auth: 'SEAMLESS_INPUT_VIEW_CUSTOM_GROSS_CASHFLOW', createRouterlink: 'input/create/gross-cash-flow-entry', viewRouterlink: '/input/view/gross-cash-flow', uploadRouterlink: '/input/upload/gross-cash-flow-upload', authUpload: 'SEAMLESS_INPUT_CREATE_CUSTOM_GROSS_CASHFLOW_CREATE', authCreate: 'SEAMLESS_INPUT_CREATE_CUSTOM_GROSS_CASHFLOW_CREATE', catCreatAuth: "SEAMLESS_INPUT_VIEW_CUSTOM", catViewAuth: "SEAMLESS_INPUT_VIEW_CUSTOM" },
        { id: 13, name: 'contribution_distribution', cardAuthName: "SEAMLESS_SIDEMENU_INPUT", auth: 'SEAMLESS_INPUT_VIEW_CUSTOM_CONTRIBUTIONANDDISTRIBUTION', createRouterlink: 'input/create/contribution-distribution-entry', viewRouterlink: '/input/view/contribution-distribution', uploadRouterlink: '/input/upload/upload-contribution-distribution', authUpload: 'SEAMLESS_INPUT_CREATE_CUSTOM_CONTRIBUTIONANDDISTRIBUTION_CREATE', authCreate: 'SEAMLESS_INPUT_CREATE_CUSTOM_CONTRIBUTIONANDDISTRIBUTION_CREATE', catCreatAuth: "SEAMLESS_INPUT_VIEW_CUSTOM", catViewAuth: "SEAMLESS_INPUT_VIEW_CUSTOM" },
        { id: 14, name: 'transferor_Inv_Statements', cardAuthName: "SEAMLESS_SIDEMENU_INPUT", auth: 'SEAMLESS_INPUT_CUSTOM_VIEW_TRANSFEROR_NUMBERS_FOR_INVESTOR_STATEMENTS', createRouterlink: '', viewRouterlink: '/input/view/TransferorNoForInvStatement', uploadRouterlink: '/input/upload/TransferorNoForInvStatement', authUpload: 'SEAMLESS_INPUT_CUSTOM_UPLOAD_TRANSFEROR_NUMBERS_FOR_INVESTOR_STATEMENTS', authCreate: '', catCreatAuth: "", catViewAuth: "SEAMLESS_INPUT_CUSTOM_VIEW_TRANSFEROR_NUMBERS_FOR_INVESTOR_STATEMENTS" },
        { id: 5, name: "book_common_income_expense", cardAuthName: "SEAMLESS_SIDEMENU_INPUT", viewRouterlink: "/input/view/book-income-expense", createRouterlink: "/input/create/book-income-expense-entry", uploadRouterlink: "", authCreate: '', auth: "SEAMLESS_INPUT_VIEW_ACCOUNTING_COMMON_INCOME_EXPENSE_VIEW", authUpload: '', catCreatAuth: "SEAMLESS_INPUT_CREATE_ACCOUNTING", catViewAuth: "SEAMLESS_INPUT_VIEW_ACCOUNTING" },
        {
          id: 2,
          name: "payments",
          cardAuthName: "SEAMLESS_SIDEMENU_INPUT",
          auth: "SEAMLESS_INPUT_CREATE_ACCOUNTSPAYABLE_PAYMENT",
          catCreatAuth: "SEAMLESS_INPUT_CREATE_ACCOUNTSPAYABLE_PAYMENT",
          catViewAuth: "SEAMLESS_INPUT_VIEW_ACCOUNTSPAYABLE_PAYMENT",
          viewRouterlink: "/input/view/payments",
          createRouterlink: '/input/create/payments-entry',
          authCreate: 'SEAMLESS_INPUT_CREATE_ACCOUNTSPAYABLE_PAYMENT',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 3,
          name: "bills",
          cardAuthName: "SEAMLESS_SIDEMENU_INPUT",
          auth: "SEAMLESS_INPUT_CREATE_ACCOUNTSPAYABLE_BILL",
          catCreatAuth: "SEAMLESS_INPUT_CREATE_ACCOUNTSPAYABLE_BILL",
          catViewAuth: "SEAMLESS_INPUT_VIEW_ACCOUNTSPAYABLE_BILL",
          viewRouterlink: "/input/view/bills",
          createRouterlink: '/input/create/bill-entry',
          authCreate: 'SEAMLESS_INPUT_CREATE_ACCOUNTSPAYABLE_BILL',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 3,
          name: "budget_data_entry_&_view",
          cardAuthName: "SEAMLESS_SIDEMENU_INPUT",
          auth: "SEAMLESS_INPUT_CREATE_BUDGETING_BUDGETS",
          catCreatAuth: "SEAMLESS_INPUT_CREATE_BUDGETING_BUDGETS",
          catViewAuth: "SEAMLESS_INPUT_VIEW_BUDGETING_BUDGETS",
          viewRouterlink: "/input/view/budgets",
          createRouterlink: '/input/create/budgets-create',
          authCreate: 'SEAMLESS_INPUT_CREATE_BUDGETING_BUDGETS',
          uploadRouterlink: '/input/upload/budgets-upload',
          authUpload: 'SEAMLESS_INPUT_CREATE_BUDGETING_BUDGETS',

        },

        {
          id: 5,
          name: "disable_auto_update",
          cardAuthName: "SEAMLESS_SIDEMENU_INPUT",
          auth: "SEAMLESS_INPUT_VIEW_INVESTORS_DISABLEAUTOUPDATEVIAALLOCATIONUPDATE",
          viewRouterlink: "/input/view/disableAutoUpdateViaAllocationUpdate",
          createRouterlink: '',
          uploadRouterlink: '/input/upload/disableAutoUpdateViaAllocation-upload',
          authUpload: 'SEAMLESS_INPUT_UPLOAD_INVESTORS_DISABLEAUTOUPDATEVIAALLOCATIONUPDATE',
          authCreate: '',
          catCreatAuth: "",
          catViewAuth: "SEAMLESS_INPUT_VIEW_INVESTORS_DISABLEAUTOUPDATEVIAALLOCATIONUPDATE",
        },
        {
          id: 6,
          name: "redemption_activity",
          cardAuthName: "SEAMLESS_SIDEMENU_INPUT",
          auth: "SEAMLESS_INPUT_VIEW_INVESTORS_REDEMPTIONACTIVITYUPDATE",
          viewRouterlink: "/input/view/redemptionActivityUpdate",
          createRouterlink: '',
          uploadRouterlink: '/input/upload/redemptionActivityUpdate-upload',
          authUpload: 'SEAMLESS_INPUT_UPLOAD_INVESTORS_REDEMPTIONACTIVITYUPDATE',
          authCreate: '',
          catCreatAuth: "",
          catViewAuth: "SEAMLESS_INPUT_VIEW_INVESTORS_REDEMPTIONACTIVITYUPDATE"
        },
        {
          id: 7,
          name: "subscription_activity",
          cardAuthName: "SEAMLESS_SIDEMENU_INPUT",
          auth: "SEAMLESS_INPUT_VIEW_INVESTORS_SUBSCRIPTIONACTIVITYUPDATE",
          viewRouterlink: "/input/view/subscriptionActivityUpdate",
          createRouterlink: '',
          uploadRouterlink: '/input/upload/subscriptionActivityUpdate-upload',
          authUpload: 'SEAMLESS_INPUT_UPLOAD_INVESTORS_SUBSCRIPTIONACTIVITYUPDATE',
          authCreate: '',
          catCreatAuth: "",
          catViewAuth: "SEAMLESS_INPUT_VIEW_INVESTORS_SUBSCRIPTIONACTIVITYUPDATE"
        },
        {
          id: 8,
          name: "csd_bank_transaction_review",
          cardAuthName: "SEAMLESS_PROCESSING_DIV_CUSTOMWORKFLOW",
          auth: "SEAMLESS_PROCESSING_CUSTOMWORKFLOW_ACCOUNTING_CSDBANKTRANSACTION_VIEW",
          viewRouterlink: "/input/pendingForReview/fetchcsdbanktransactionReview",
          createRouterlink: '',
          uploadRouterlink: '',
          authUpload: '',
          authCreate: '',
          catCreatAuth: "",
          catViewAuth: "SEAMLESS_PROCESSING_CUSTOMWORKFLOW_ACCOUNTING"
        }
      ], authsibebar: 'SEAMLESS_SIDEMENU_INPUT'
    },
    //-----processing Export View-----//
    {
      Button: [
        { id: 24, name: 'run_view_open_taxlots', cardAuthName: "SEAMLESS_SIDEMENU_PROCESSING", viewRouterlink: "/rapid-processing/export-view/run-view-open-taxlots", createRouterlink: '', uploadRouterlink: '', auth: 'SEAMLESS_PROCESSING_EXPORTVIEW_TRADES_OPENTAXLOTS_VIEW', authCreate: '', authUpload: '', catViewAuth: "SEAMLESS_PROCESSING_EXPORTVIEW_TRADES" },
        { id: 25, name: 'trade_blotter_fixed_income', cardAuthName: "SEAMLESS_SIDEMENU_PROCESSING", viewRouterlink: "/rapid-processing/export-view/trade-blotter-fixed-income", createRouterlink: '', uploadRouterlink: '', auth: 'SEAMLESS_PROCESSING_EXPORTVIEW_TRADES_TRADEBLOTTERFIXEDINCOME', authCreate: '', authUpload: '', catViewAuth: "SEAMLESS_PROCESSING_EXPORTVIEW_TRADES" },
        { id: 27, name: 'tax_lot_status', cardAuthName: "SEAMLESS_SIDEMENU_PROCESSING", auth: 'SEAMLESS_PROCESSING_EXPORTVIEW_TRADES_PROCESSEDTAXLOTS_STATUS', viewRouterlink: '/rapid-processing/export-view/tax-lot-procees-status', createRouterlink: '', uploadRouterlink: '', authUpload: '', authCreate: '', catViewAuth: "SEAMLESS_PROCESSING_EXPORTVIEW_TRADES" },
        { id: 27, name: 'wash_sale_adjusted_openlot', cardAuthName: "SEAMLESS_SIDEMENU_PROCESSING", auth: 'SEAMLESS_PROCESSING_EXPORTVIEW_TRADES_WASHSALE_ADJUSTEDOPENLOTS', viewRouterlink: '/rapid-processing/export-view/wash-sale-adjusted-open-lot', createRouterlink: '', uploadRouterlink: '', authUpload: '', authCreate: '', catViewAuth: "SEAMLESS_PROCESSING_EXPORTVIEW_TRADES" },
        { id: 15, name: 'closed_taxlot', cardAuthName: "SEAMLESS_SIDEMENU_PROCESSING", viewRouterlink: "/rapid-processing/export-view/closed-taxlots", auth: 'SEAMLESS_PROCESSING_EXPORTVIEW_TRADES_TAXLOTSCLOSE_VIEW', createRouterlink: '', uploadRouterlink: '', authUpload: '', authCreate: '', catViewAuth: "SEAMLESS_PROCESSING_EXPORTVIEW_TRADES" },
        { id: 16, name: 'income_allocation_series', cardAuthName: "SEAMLESS_SIDEMENU_PROCESSING", viewRouterlink: "/rapid-processing/export-view/income-allocation/series", auth: 'SEAMLESS_ACCOUNTING_INCOMEALLOCATION_SERIES_EXPORT', createRouterlink: '', uploadRouterlink: '', authUpload: '', authCreate: '', catViewAuth: "SEAMLESS_PROCESSING_EXPORTVIEW_TRADES" },
        { id: 6, name: 'configure_and_execute', cardAuthName: 'SEAMLESS_SIDEMENU_PROCESSING', auth: 'SEAMLESS_PROCESSING_DIV_AUTOMATEPROCESS', viewRouterlink: "/rapid-processing/automate-process/dailyProcessingView", createRouterlink: '/rapid-processing/daily-processing-view', uploadRouterlink: '', add: false, upload: false, authUpload: '', authCreate: 'SEAMLESS_PROCESSING_AUTOMATEPROCESS_CREATE' },
        { id: 33, name: "Step by Step Workflow", viewRouterlink: "/rapid-processing/detailed-workflow", createRouterlink: "", uploadRouterlink: '', auth: "SEAMLESS_PROCESSING_DIV_DETAILEDWORKFLOW", authCreate: '', authUpload: '' },
        { id: 21, name: 'view_total_gl', cardAuthName: "SEAMLESS_SIDEMENU_PROCESSING", auth: 'SEAMLESS_PROCESSING_EXPORTVIEW_PORTFOLIO_TOTALGAINLOSS_REPORTS_VIEW', viewRouterlink: '/rapid-processing/export-view/view-total-gl', createRouterlink: '', uploadRouterlink: '', authUpload: '', authCreate: '', catViewAuth: "SEAMLESS_PROCESSING_EXPORTVIEW_PORTFOLIO" },
        { id: 4, name: 'one_button_process_log', cardAuthName: 'DIV_PROCESSING', auth: 'DAILYPROCESSING_VIEW', viewRouterlink: "/rapid-processing/automate-process/dailyProcessingStatus", createRouterlink: '', uploadRouterlink: '', authUpload: '', authCreate: '' },
        { id: 31, name: 'Transfer Process Status Day Wise', cardAuthName: "SEAMLESS_SIDEMENU_PROCESSING", viewRouterlink: "/rapid-processing/export-view/transfer/transferProcessStatusDayWise", createRouterlink: '', uploadRouterlink: '', auth: 'SEAMLESS_PROCESSING_EXPORTVIEW_TRADES_TRANSFERTRADE_PROCESSREVIEW', authCreate: '', authUpload: '', catViewAuth: "SEAMLESS_PROCESSING_EXPORTVIEW_TRADES" },
        { id: 31, name: 'transfer_trade_history', cardAuthName: "SEAMLESS_SIDEMENU_PROCESSING", viewRouterlink: "/rapid-processing/export-view/transfer/transferTradeHistory", createRouterlink: '', uploadRouterlink: '', auth: 'SEAMLESS_PROCESSING_EXPORTVIEW_TRADES_TRANSFERTRADE_HISTORY', authCreate: '', authUpload: '', catViewAuth: "SEAMLESS_PROCESSING_EXPORTVIEW_TRADES" },
        { id: 8, name: "view_perf_based_fees", cardAuthName: "SEAMLESS_SIDEMENU_PROCESSING", auth: "SEAMLESS_PROCESSING_EXPORTVIEW_ACCOUNTING_PERFORMANCE_FEE_CALCULATION_VIEW", viewRouterlink: "/rapid-processing/export-view/viewPerfBasedFees", createRouterlink: '', authCreate: '', uploadRouterlink: '', authUpload: '', catViewAuth: "SEAMLESS_PROCESSING_EXPORTVIEW_ACCOUNTING" },
        { id: 6, name: "asset_based_fees_list", cardAuthName: "SEAMLESS_SIDEMENU_PROCESSING", auth: "SEAMLESS_PROCESSING_EXPORTVIEW_ACCOUNTING_ASSETBASEDFEE_CALCULATION_VIEW", viewRouterlink: "/rapid-processing/export-view/asset-based/assetBasedFeeList", createRouterlink: '', authCreate: '', uploadRouterlink: '', authUpload: '', catViewAuth: "SEAMLESS_PROCESSING_EXPORTVIEW_ACCOUNTING" },
        { id: 7, name: "asset_based_fees_calculation_list", cardAuthName: "SEAMLESS_SIDEMENU_PROCESSING", auth: "SEAMLESS_PROCESSING_EXPORTVIEW_ACCOUNTING_ASSETBASEDFEE_CALCULATION_VIEW", viewRouterlink: "/rapid-processing/export-view/asset-based/assetBasedFeeCalculation", createRouterlink: '', authCreate: '', uploadRouterlink: '', authUpload: '', catViewAuth: "SEAMLESS_PROCESSING_EXPORTVIEW_ACCOUNTING" },
        { id: 7, name: "calculation_hurdle", cardAuthName: "SEAMLESS_SIDEMENU_PROCESSING", auth: "SEAMLESS_PROCESSING_EXPORTVIEW_ACCOUNTING_HURDLE_CALCULATION_VIEW", viewRouterlink: "/rapid-processing/export-view/hurdle/hurdle-calculation", createRouterlink: '', authCreate: '', uploadRouterlink: '', authUpload: '', catViewAuth: "SEAMLESS_PROCESSING_EXPORTVIEW_ACCOUNTING" },
        { id: 9, name: "view_je_summary", cardAuthName: "SEAMLESS_SIDEMENU_PROCESSING", auth: "SEAMLESS_PROCESSING_EXPORTVIEW_ACCOUNTING_JE_SUMMARY_VIEW", viewRouterlink: "/rapid-processing/export-view/jesummary/viewjesummary", createRouterlink: '', authCreate: '', uploadRouterlink: '', authUpload: '', catViewAuth: "SEAMLESS_PROCESSING_EXPORTVIEW_ACCOUNTING" },
        { id: 10, name: "view_je_summary_by_account", cardAuthName: "SEAMLESS_SIDEMENU_PROCESSING", auth: "SEAMLESS_PROCESSING_EXPORTVIEW_ACCOUNTING_JE_SUMMARY_BY_ACCOUNT", viewRouterlink: "/rapid-processing/export-view/jesummary/viewjesummaryByAccount", createRouterlink: '', authCreate: '', uploadRouterlink: '', authUpload: '', catViewAuth: "SEAMLESS_PROCESSING_EXPORTVIEW_ACCOUNTING" },
        { id: 16, name: 'wash_sale', cardAuthName: "SEAMLESS_PROCESSING_DIV_CUSTOMWORKFLOW", viewRouterlink: "/rapid-processing/custom-workflow/wash-sale", createRouterlink: '', uploadRouterlink: '', auth: 'SEAMLESS_PROCESSING_CUSTOMWORKFLOW_TRADES_WASHSALE', authCreate: '', authUpload: '', catViewAuth: "SEAMLESS_PROCESSING_CUSTOMWORKFLOW_TRADES" },
        { id: 28, name: 'trade_archive_list', cardAuthName: "SEAMLESS_PROCESSING_DIV_CUSTOMWORKFLOW", viewRouterlink: "/rapid-processing/custom-workflow/trade-archive-list", createRouterlink: '', uploadRouterlink: '', auth: 'SEAMLESS_PROCESSING_CUSTOMWORKFLOW_TRADES_TRADEARCHIVELIST_VIEW', authCreate: '', authUpload: '', catViewAuth: "SEAMLESS_PROCESSING_CUSTOMWORKFLOW_TRADES" },
        { id: 14, name: 'run_taxlot_closing', cardAuthName: "SEAMLESS_PROCESSING_DIV_CUSTOMWORKFLOW", viewRouterlink: "/rapid-processing/custom-workflow/run-tax-lot-closing", createRouterlink: '', uploadRouterlink: '', auth: 'SEAMLESS_PROCESSING_CUSTOMWORKFLOW_TRADES_TAXLOTSCLOSING_MANUAL', authCreate: '', authUpload: '', catViewAuth: "SEAMLESS_PROCESSING_CUSTOMWORKFLOW_TRADES" },
        { id: 13, name: 'run_taxLot', cardAuthName: "SEAMLESS_PROCESSING_DIV_CUSTOMWORKFLOW", viewRouterlink: "/rapid-processing/custom-workflow/run-taxlots-closing-multiple-broker", createRouterlink: '', uploadRouterlink: '', auth: 'SEAMLESS_PROCESSING_CUSTOMWORKFLOW_TRADES_TAXLOTSCLOSING_MULTIPLEBROKER_VIEW', authCreate: '', authUpload: '', catViewAuth: "SEAMLESS_PROCESSING_CUSTOMWORKFLOW_TRADES" },
        { id: 29, name: 'reset_open_tax_lots_fund', cardAuthName: "SEAMLESS_PROCESSING_DIV_CUSTOMWORKFLOW", viewRouterlink: "/rapid-processing/custom-workflow/tax-lots/reset-open-taxlots-fund", createRouterlink: '', uploadRouterlink: '', auth: 'SEAMLESS_PROCESSING_CUSTOMWORKFLOW_TRADES_OPENTAXLOTS_RESETBYFUND', authCreate: '', authUpload: '', catViewAuth: "SEAMLESS_PROCESSING_CUSTOMWORKFLOW_TRADES" },
        { id: 12, name: 'reset_open_taxlot', cardAuthName: "SEAMLESS_PROCESSING_DIV_CUSTOMWORKFLOW", viewRouterlink: "/rapid-processing/custom-workflow/tax-lots/reset-open-taxlots", createRouterlink: '', uploadRouterlink: '', auth: 'SEAMLESS_PROCESSING_CUSTOMWORKFLOW_TRADES_OPENTAXLOTS_RESETBYACCOUNT', authCreate: '', authUpload: '', catViewAuth: "SEAMLESS_PROCESSING_CUSTOMWORKFLOW_TRADES" },
        { id: 13, name: 'performance_analytics', cardAuthName: "SEAMLESS_PROCESSING_DIV_CUSTOMWORKFLOW", viewRouterlink: "/rapid-processing/custom-workflow/performance-analytics", createRouterlink: '', uploadRouterlink: '', auth: 'SEAMLESS_PROCESSING_CUSTOMWORKFLOW_ACCOUNTING_PERFORMANCE_ANALYTICS', authCreate: '', authUpload: '', catViewAuth: "SEAMLESS_PROCESSING_CUSTOMWORKFLOW_ACCOUNTING" },
        { id: 14, name: 'program_composite', cardAuthName: "SEAMLESS_PROCESSING_DIV_CUSTOMWORKFLOW", viewRouterlink: "/rapid-processing/custom-workflow/program-composite", createRouterlink: '', uploadRouterlink: '', auth: 'SEAMLESS_PROCESSING_CUSTOMWORKFLOW_ACCOUNTING_PROGRAMCOMPOSITE', authCreate: '', authUpload: '', catViewAuth: "SEAMLESS_PROCESSING_CUSTOMWORKFLOW_ACCOUNTING" },
        { id: 14, name: 'total_gain_loss_by_fund', cardAuthName: "SEAMLESS_PROCESSING_DIV_CUSTOMWORKFLOW", viewRouterlink: "/rapid-processing/custom-workflow/total-gain-loss-by-fund", createRouterlink: '', uploadRouterlink: '', auth: 'SEAMLESS_PROCESSING_CUSTOMWORKFLOW_PORTFOLIO_TOTALGAINLOSS_BYFUND', authCreate: '', authUpload: '', catViewAuth: "SEAMLESS_PROCESSING_CUSTOMWORKFLOW_PORTFOLIO" },
        { id: 15, name: 'fetch_csd_bank_transaction', cardAuthName: "SEAMLESS_PROCESSING_DIV_CUSTOMWORKFLOW", viewRouterlink: "/rapid-processing/custom-workflow/fetch-bank-transaction", createRouterlink: '', uploadRouterlink: '', auth: 'SEAMLESS_PROCESSING_CUSTOMWORKFLOW_ACCOUNTING_CSDBANKTRANSACTION_VIEW', authCreate: '', authUpload: '', catViewAuth: "SEAMLESS_PROCESSING_CUSTOMWORKFLOW_ACCOUNTING" },
        { id: 21, name: 'refresh_open_taxlots', cardAuthName: "SEAMLESS_PROCESSING_DIV_CUSTOMWORKFLOW", viewRouterlink: "/rapid-processing/custom-workflow/open-tax-lots/refresh-open-tax-lots", createRouterlink: '', uploadRouterlink: '', auth: 'SEAMLESS_PROCESSING_CUSTOMWORKFLOW_TRADES_OPENTAXLOTS_REFRESH', authCreate: '', authUpload: '', catViewAuth: "SEAMLESS_PROCESSING_CUSTOMWORKFLOW_PORTFOLIO" },
        { id: 10, name: 'open_taxlot', cardAuthName: "SEAMLESS_PROCESSING_DIV_CUSTOMWORKFLOW", viewRouterlink: "/rapid-processing/custom-workflow/open-tax-lots/create-open-tax-lots", createRouterlink: '', uploadRouterlink: '', auth: 'SEAMLESS_PROCESSING_CUSTOMWORKFLOW_TRADES_OPENTAXLOTS_CREATE', authCreate: '', authUpload: '', catViewAuth: "SEAMLESS_PROCESSING_CUSTOMWORKFLOW_PORTFOLIO" },
        { id: 15, name: 'post_JE_trading', cardAuthName: "SEAMLESS_PROCESSING_DIV_CUSTOMWORKFLOW", viewRouterlink: "/rapid-processing/custom-workflow/jeposting/trading", createRouterlink: '', uploadRouterlink: '', auth: 'SEAMLESS_ACCOUNTING_JEPOSTING_TRADING', authCreate: '', authUpload: '', catViewAuth: "SEAMLESS_PROCESSING_CUSTOMWORKFLOW_ACCOUNTING" },
        { id: 16, name: 'post_JE_master', cardAuthName: "SEAMLESS_PROCESSING_DIV_CUSTOMWORKFLOW", viewRouterlink: "/rapid-processing/custom-workflow/jeposting/master", createRouterlink: '', uploadRouterlink: '', auth: 'SEAMLESS_ACCOUNTING_JEPOSTING_MASTER', authCreate: '', authUpload: '', catViewAuth: "SEAMLESS_PROCESSING_CUSTOMWORKFLOW_ACCOUNTING" },
        { id: 17, name: 'post_JE_ticker', cardAuthName: "SEAMLESS_PROCESSING_DIV_CUSTOMWORKFLOW", viewRouterlink: "/rapid-processing/custom-workflow/jeposting/ticker", createRouterlink: '', uploadRouterlink: '', auth: 'SEAMLESS_ACCOUNTING_JEPOSTING_TICKER', authCreate: '', authUpload: '', catViewAuth: "SEAMLESS_PROCESSING_CUSTOMWORKFLOW_ACCOUNTING" },
        { id: 18, name: 'run_investor_custodian_je', cardAuthName: "SEAMLESS_PROCESSING_DIV_CUSTOMWORKFLOW", viewRouterlink: "/rapid-processing/custom-workflow/jeposting/investorCustodain", createRouterlink: '', uploadRouterlink: '', auth: 'SEAMLESS_ACCOUNTING_JEPOSTING_INVESTORCUSTODIAN', authCreate: '', authUpload: '', catViewAuth: "SEAMLESS_PROCESSING_CUSTOMWORKFLOW_ACCOUNTING" },
        { id: 9, name: "view_distribution", cardAuthName: "SEAMLESS_SIDEMENU_PROCESSING", auth: "SEAMLESS_PROCESSING_EXPORTVIEW_ACCOUNTING_DISTRIBUTION_VIEW", viewRouterlink: "/rapid-processing/export-view/view-distribution", createRouterlink: '', authCreate: '', uploadRouterlink: '', authUpload: '', catViewAuth: "SEAMLESS_PROCESSING_EXPORTVIEW_ACCOUNTING" },
        { id: 9, name: "run_distribution", cardAuthName: "SEAMLESS_PROCESSING_DIV_CUSTOMWORKFLOW", auth: "SEAMLESS_PROCESSING_CUSTOMWORKFLOW_ACCOUNTING_DISTRIBUTION", viewRouterlink: "/rapid-processing/custom-workflow/run-distribution", createRouterlink: '', authCreate: '', uploadRouterlink: '', authUpload: '', catViewAuth: "SEAMLESS_PROCESSING_CUSTOMWORKFLOW_ACCOUNTING" },
        { id: 15, name: "income_allocation_Hurdle", cardAuthName: "SEAMLESS_SIDEMENU_PROCESSING", auth: "SEAMLESS_ACCOUNTING_INCOMEALLOCATION_HURDLE_EXPORT", viewRouterlink: "/rapid-processing/export-view/income-allocation/hurdle", createRouterlink: '', authCreate: '', uploadRouterlink: '', authUpload: '', catViewAuth: "SEAMLESS_PROCESSING_EXPORTVIEW_ACCOUNTING" },
        { id: 16, name: "income_allocation_side_pocket", cardAuthName: "SEAMLESS_SIDEMENU_PROCESSING", auth: "SEAMLESS_ACCOUNTING_INCOMEALLOCATION_SIDEPOCKET_EXPORT", viewRouterlink: "/rapid-processing/export-view/income-allocation/side-pocket", createRouterlink: '', authCreate: '', uploadRouterlink: '', authUpload: '', catViewAuth: "SEAMLESS_PROCESSING_EXPORTVIEW_ACCOUNTING" },
        { id: 9, name: "view_capital_call", cardAuthName: "SEAMLESS_SIDEMENU_PROCESSING", auth: "SEAMLESS_PROCESSING_EXPORTVIEW_ACCOUNTING_CAPITALCALL_VIEW", viewRouterlink: "/rapid-processing/export-view/view-capitalCall", createRouterlink: '', authCreate: '', uploadRouterlink: '', authUpload: '', catViewAuth: "SEAMLESS_PROCESSING_EXPORTVIEW_ACCOUNTING" },
        { id: 9, name: "run_capital_call", cardAuthName: "SEAMLESS_PROCESSING_DIV_CUSTOMWORKFLOW", auth: "SEAMLESS_PROCESSING_CUSTOMWORKFLOW_ACCOUNTING_CAPITALCALL", viewRouterlink: "/rapid-processing/custom-workflow/run-capitalCall", createRouterlink: '', authCreate: '', uploadRouterlink: '', authUpload: '', catViewAuth: "SEAMLESS_PROCESSING_CUSTOMWORKFLOW_ACCOUNTING" },
        { id: 17, name: "hurdle_base_Amount_calculation", cardAuthName: "SEAMLESS_SIDEMENU_PROCESSING", auth: "SEAMLESS_PROCESSING_EXPORTVIEW_ACCOUNTING_HURDLE_CALCULATION_VIEW", viewRouterlink: "/rapid-processing/export-view/hurdle/hurdle-base-amount", createRouterlink: '', authCreate: '', uploadRouterlink: '', authUpload: '', catViewAuth: "SEAMLESS_PROCESSING_EXPORTVIEW_ACCOUNTING" },
        { id: 10, name: 'generate_bench_mark_irr', cardAuthName: "SEAMLESS_PROCESSING_DIV_CUSTOMWORKFLOW", auth: 'SEAMLESS_PROCESSING_CUSTOMWORKFLOW_PORTFOLIO_IRR', viewRouterlink: "/rapid-processing/custom-workflow/internal-rate/generate-bench-mark-irr", createRouterlink: '', uploadRouterlink: '', authUpload: '', authCreate: '', catViewAuth: "SEAMLESS_PROCESSING_CUSTOMWORKFLOW_PORTFOLIO" },
        { id: 11, name: 'irr_report', cardAuthName: "SEAMLESS_PROCESSING_DIV_CUSTOMWORKFLOW", auth: 'SEAMLESS_PROCESSING_CUSTOMWORKFLOW_PORTFOLIO_IRR', viewRouterlink: "/rapid-processing/custom-workflow/internal-rate/generate-irr", createRouterlink: '', uploadRouterlink: '', authUpload: '', authCreate: '', catViewAuth: "SEAMLESS_PROCESSING_CUSTOMWORKFLOW_PORTFOLIO" },
        { id: 13, name: 'custom_irr', cardAuthName: "SEAMLESS_PROCESSING_DIV_CUSTOMWORKFLOW", auth: 'SEAMLESS_PROCESSING_CUSTOMWORKFLOW_PORTFOLIO_IRR', viewRouterlink: '/rapid-processing/custom-workflow/internal-rate/generate-custom-irr', createRouterlink: '', uploadRouterlink: '', authUpload: '', authCreate: '', catViewAuth: "SEAMLESS_PROCESSING_CUSTOMWORKFLOW_PORTFOLIO" },
        { id: 15, name: 'investor_irr', cardAuthName: "SEAMLESS_PROCESSING_DIV_CUSTOMWORKFLOW", auth: 'SEAMLESS_PROCESSING_CUSTOMWORKFLOW_PORTFOLIO_IRR', viewRouterlink: '/rapid-processing/custom-workflow/internal-rate/generate-investor-irr', createRouterlink: '', uploadRouterlink: '', authUpload: '', authCreate: '', catViewAuth: "SEAMLESS_PROCESSING_CUSTOMWORKFLOW_PORTFOLIO" },
        { id: 19, name: 'irr_with_fund_group_custom', cardAuthName: "SEAMLESS_PROCESSING_DIV_CUSTOMWORKFLOW", auth: 'SEAMLESS_PROCESSING_CUSTOMWORKFLOW_PORTFOLIO_IRR', viewRouterlink: '/rapid-processing/custom-workflow/internal-rate/generate-irr-fund-group-custom', createRouterlink: '', uploadRouterlink: '', authUpload: '', authCreate: '', catViewAuth: "SEAMLESS_PROCESSING_CUSTOMWORKFLOW_PORTFOLIO" },
        { id: 3, name: 'irr_moic_irr', cardAuthName: "SEAMLESS_PROCESSING_DIV_CUSTOMWORKFLOW", auth: 'SEAMLESS_PROCESSING_CUSTOMWORKFLOW_PORTFOLIO_IRR', createRouterlink: '', viewRouterlink: '/rapid-processing/custom-workflow/internal-rate/irr-and-moc-irr', uploadRouterlink: '', authUpload: '', authCreate: '', catViewAuth: "SEAMLESS_PROCESSING_CUSTOMWORKFLOW_PORTFOLIO" },
        { id: 3, name: 'custom_irr_combine', cardAuthName: "SEAMLESS_PROCESSING_DIV_CUSTOMWORKFLOW", auth: 'SEAMLESS_PROCESSING_CUSTOMWORKFLOW_PORTFOLIO_IRR', createRouterlink: '', viewRouterlink: '/rapid-processing/custom-workflow/internal-rate/irr-and-moc-irr', uploadRouterlink: '', authUpload: '', authCreate: '', catViewAuth: "SEAMLESS_PROCESSING_CUSTOMWORKFLOW_PORTFOLIO" },
        { id: 4, name: "recon_trade_manually", cardAuthName: "SEAMLESS_SIDEMENU_PROCESSING", auth: "SEAMLESS_PROCESSING_EXPORTVIEW_RECONCILIATION_TRADE_RECONCILIATION_VIEW", viewRouterlink: "/rapid-processing/export-view/recon-trade-manually", createRouterlink: '', authCreate: '', uploadRouterlink: '', authUpload: '', catViewAuth: "SEAMLESS_PROCESSING_EXPORTVIEW_RECONCILIATION" },
        { id: 2, name: "run_trade_recon", cardAuthName: "SEAMLESS_SIDEMENU_PROCESSING", isQueryString: true, queryParam: { openModel: true }, auth: "SEAMLESS_RECONCILIATION_TRADE_RECONCILIATION_RECONCILE_TRADES", viewRouterlink: "/rapid-processing/export-view/recon-trade-manually", createRouterlink: '', authCreate: '', uploadRouterlink: '', authUpload: '', catViewAuth: "SEAMLESS_PROCESSING_EXPORTVIEW_RECONCILIATION" },
        { id: 5, name: "run_position_recon_multi", cardAuthName: "SEAMLESS_PROCESSING_DIV_CUSTOMWORKFLOW", auth: "SEAMLESS_PROCESSING_CUSTOMWORKFLOW_MULTI_PARTY_RECON_VIEW", viewRouterlink: "/rapid-processing/custom-workflow/multi-party-reconciliation/run-position-recon-multi", upload: false, add: false, createRouterlink: '', authCreate: '', uploadRouterlink: '', authUpload: '', catViewAuth: "SEAMLESS_PROCESSING_CUSTOMWORKFLOW_RECONCILIATION" },
        { id: 2, name: "run_cash_recon_multi", cardAuthName: "SEAMLESS_PROCESSING_DIV_CUSTOMWORKFLOW", auth: "SEAMLESS_PROCESSING_CUSTOMWORKFLOW_MULTI_PARTY_RECON_VIEW", viewRouterlink: "/rapid-processing/custom-workflow/multi-party-reconciliation/run-cash-recon-multi", createRouterlink: '', authCreate: '', uploadRouterlink: '', authUpload: '', catViewAuth: "SEAMLESS_PROCESSING_CUSTOMWORKFLOW_RECONCILIATION" },
        { id: 5, name: "View PNL Contribution", cardAuthName: "SEAMLESS_SIDEMENU_PROCESSING", auth: "SEAMLESS_PROCESSING_EXPORTVIEW_ACCOUNTING_PNL_CONTRIBUTION_LIST", viewRouterlink: "/rapid-processing/export-view/viewPnlDistribution", createRouterlink: '', authCreate: '', uploadRouterlink: '', authUpload: '', catViewAuth: "SEAMLESS_PROCESSING_EXPORTVIEW_ACCOUNTING" },
        { id: 15, name: 'inv_level_je_transfer', cardAuthName: "SEAMLESS_PROCESSING_DIV_CUSTOMWORKFLOW", viewRouterlink: "/rapid-processing/custom-workflow/jeposting/investorLevelJETransfer", createRouterlink: '', uploadRouterlink: '', auth: 'SEAMLESS_WORFKLOW_JETRANSFER_INVESTOR_LEVEL', authCreate: '', authUpload: '', catViewAuth: "SEAMLESS_PROCESSING_CUSTOMWORKFLOW_ACCOUNTING" },
        {
          name: "batch_tracking",
          viewRouterlink: "/rapid-processing/batchTracking",
          createRouterlink: "",
          uploadRouterlink: '',
          auth: "SEAMLESS_PROCESSING_DIV_BATCH",
          cardAuthName: "SEAMLESS_SIDEMENU_PROCESSING",
          catViewAuth: "SEAMLESS_PROCESSING_DIV_BATCH",
          authCreate: '',
          authUpload: ''
        },
        { id: 9, name: "rollup_consolidation", cardAuthName: "SEAMLESS_PROCESSING_DIV_CUSTOMWORKFLOW", auth: "SEAMLESS_PROCESSING_CUSTOMWORKFLOW_ACCOUNTING_ROLLUPCONSOLIDATION", viewRouterlink: "/rapid-processing/custom-workflow/rollup-consolidation", createRouterlink: '', authCreate: '', uploadRouterlink: '', authUpload: '', catViewAuth: "SEAMLESS_PROCESSING_CUSTOMWORKFLOW_ACCOUNTING" },
      ], authsibebar: 'SEAMLESS_SIDEMENU_PROCESSING'
    },
    //-----LEGACY Buttons-----//
    {
      Button: [
        { id: 2, name: 'trading_advisors', cardAuthName: "SEAMLESS_LEGACY_ADMIN", catCreatAuth: "SEAMLESS_LEGACY_ADMIN", catViewAuth: "SEAMLESS_LEGACY_ADMIN", auth: 'SMEQUITIESOPTIONS_VIEW', createRouterlink: '/legacy/trading-advisor', viewRouterlink: '/legacy/trading-advisor', uploadRouterlink: '', authUpload: 'SMEQUITIESOPTIONS_CREATE', authCreate: 'SMEQUITIESOPTIONS_CREATE' },
        { id: 2, name: 'auto_trades_treation', cardAuthName: "SEAMLESS_LEGACY_TRADES_AND_PORTFOLIO", catCreatAuth: "SEAMLESS_LEGACY_TRADES_AND_PORTFOLIO", catViewAuth: "SEAMLESS_LEGACY_TRADES_AND_PORTFOLIO", viewRouterlink: "/legacy/auto-trade-creation", createRouterlink: '', uploadRouterlink: '', auth: 'AUTO_TRADES_CREATIONS', authCreate: '', authUpload: '' },
        { id: 2, name: 'order', cardAuthName: "SEAMLESS_LEGACY_TRADES_AND_PORTFOLIO", catCreatAuth: "SEAMLESS_LEGACY_TRADES_AND_PORTFOLIO", catViewAuth: "SEAMLESS_LEGACY_TRADES_AND_PORTFOLIO", viewRouterlink: "/legacy/order-list", createRouterlink: '', uploadRouterlink: '', auth: 'ORDER_VIEW', authCreate: '', authUpload: '' },
        { id: 26, name: 'batch_work_hv', cardAuthName: "SEAMLESS_LEGACY_TRADES_AND_PORTFOLIO", catCreatAuth: "SEAMLESS_LEGACY_TRADES_AND_PORTFOLIO", catViewAuth: "SEAMLESS_LEGACY_TRADES_AND_PORTFOLIO", viewRouterlink: "/legacy/batch-work-hv", createRouterlink: '', uploadRouterlink: '', auth: 'BATCH_PROGRESS_SCREEN_VIEW', authCreate: '', authUpload: '' },

        { id: 27, name: 'run_taxlots_process', cardAuthName: "SEAMLESS_LEGACY_TRADES_AND_PORTFOLIO", catCreatAuth: "SEAMLESS_LEGACY_TRADES_AND_PORTFOLIO", catViewAuth: "SEAMLESS_LEGACY_TRADES_AND_PORTFOLIO", viewRouterlink: "/legacy/run-taxlots-process", createRouterlink: '', uploadRouterlink: '', auth: 'SIDEMENU_TRADES_RUN_TAXLOTS_PROCESS', authCreate: 'SIDEMENU_TRADES_RUN_TAXLOTS_PROCESS_CREATE', authUpload: '' },
        { id: 1, name: 'CA_split', cardAuthName: "SEAMLESS_LEGACY_TRADES_AND_PORTFOLIO", catCreatAuth: "SEAMLESS_LEGACY_TRADES_AND_PORTFOLIO", catViewAuth: "SEAMLESS_LEGACY_TRADES_AND_PORTFOLIO", auth: 'CASPLIT_VIEW', createRouterlink: '/legacy/ca-split', viewRouterlink: '/legacy/ca-split', uploadRouterlink: '', authUpload: '', authCreate: 'CASPLIT_CREATE' },
        { id: 3, name: 'CA_spin_off', cardAuthName: "SEAMLESS_LEGACY_TRADES_AND_PORTFOLIO", catCreatAuth: "SEAMLESS_LEGACY_TRADES_AND_PORTFOLIO", catViewAuth: "SEAMLESS_LEGACY_TRADES_AND_PORTFOLIO", auth: 'CASPINOFF_VIEW', createRouterlink: '/legacy/ca-spin-off', viewRouterlink: '/legacy/ca-spin-off', uploadRouterlink: '', authUpload: '', authCreate: 'CASPINOFF_CREATE' },
        { id: 5, name: 'CA_merger', cardAuthName: "SEAMLESS_LEGACY_TRADES_AND_PORTFOLIO", catCreatAuth: "SEAMLESS_LEGACY_TRADES_AND_PORTFOLIO", catViewAuth: "SEAMLESS_LEGACY_TRADES_AND_PORTFOLIO", auth: 'CAMERGER_VIEW', createRouterlink: '/legacy/ca-merger', viewRouterlink: '/legacy/ca-merger', authCreate: 'CAMERGER_CREATE', uploadRouterlink: '', authUpload: '' },
        { id: 7, name: 'CA_special_dividend', cardAuthName: "SEAMLESS_LEGACY_TRADES_AND_PORTFOLIO", catCreatAuth: "SEAMLESS_LEGACY_TRADES_AND_PORTFOLIO", catViewAuth: "SEAMLESS_LEGACY_TRADES_AND_PORTFOLIO", auth: 'CASPECIALDIVIDEND_VIEW', createRouterlink: '/legacy/ca-special-dividend', viewRouterlink: '/legacy/ca-special-dividend', authCreate: 'CASPECIALDIVIDEND_CREATE', uploadRouterlink: '', authUpload: '' },
        { id: 1, name: 'calculate_dividend', cardAuthName: "SEAMLESS_LEGACY_TRADES_AND_PORTFOLIO", catCreatAuth: "SEAMLESS_LEGACY_TRADES_AND_PORTFOLIO", catViewAuth: "SEAMLESS_LEGACY_TRADES_AND_PORTFOLIO", auth: 'DIVIDEND_INCOME_CALCULATION_VIEW', viewRouterlink: "/legacy/calculate-dividend", createRouterlink: '', uploadRouterlink: '', authUpload: '', authCreate: '' },
        { id: 2, name: 'calculate_bond_interest', cardAuthName: "SEAMLESS_LEGACY_TRADES_AND_PORTFOLIO", catCreatAuth: "SEAMLESS_LEGACY_TRADES_AND_PORTFOLIO", catViewAuth: "SEAMLESS_LEGACY_TRADES_AND_PORTFOLIO", auth: 'BOND_INTEREST_INCOME_CALCULATION_VIEW', viewRouterlink: '/legacy/calculate-bond-interest', createRouterlink: '', uploadRouterlink: '', authUpload: '', authCreate: '' },
        { id: 3, name: 'run_total_gl', cardAuthName: "SEAMLESS_LEGACY_TRADES_AND_PORTFOLIO", catCreatAuth: "SEAMLESS_LEGACY_TRADES_AND_PORTFOLIO", catViewAuth: "SEAMLESS_LEGACY_TRADES_AND_PORTFOLIO", auth: 'TOTAL_GAIN_LOSS_RUN', viewRouterlink: '/legacy/run-view-total-gl', createRouterlink: '', uploadRouterlink: '', authUpload: '', authCreate: '' },

        { id: 1, name: "run_fx_gl", cardAuthName: "SEAMLESS_LEGACY_ACCOUNTING_AND_INVESTORS", catCreatAuth: "SEAMLESS_LEGACY_ACCOUNTING_AND_INVESTORS", catViewAuth: "SEAMLESS_LEGACY_ACCOUNTING_AND_INVESTORS", auth: "FX_GAIN_LOSS_VIEW", viewRouterlink: "/legacy/runfxgl", createRouterlink: '', authCreate: '', uploadRouterlink: '', authUpload: '', },
        { id: 3, name: "post_JE_fees", cardAuthName: "SEAMLESS_LEGACY_ACCOUNTING_AND_INVESTORS", catCreatAuth: "SEAMLESS_LEGACY_ACCOUNTING_AND_INVESTORS", catViewAuth: "SEAMLESS_LEGACY_ACCOUNTING_AND_INVESTORS", auth: "JOURNAL_ENTRY_POSTING_INVESTMANAGER_VIEW", viewRouterlink: "/legacy/postjefees", createRouterlink: '', authCreate: '', uploadRouterlink: '', authUpload: '', },
        { id: 5, name: "post_JE_feeder", cardAuthName: "SEAMLESS_LEGACY_ACCOUNTING_AND_INVESTORSG", catCreatAuth: "SEAMLESS_LEGACY_ACCOUNTING_AND_INVESTORS", catViewAuth: "SEAMLESS_LEGACY_ACCOUNTING_AND_INVESTORS", auth: "JOURNAL_ENTRY_POSTING_FEEDER_FUND_VIEW", viewRouterlink: "/legacy/postjefeeder", createRouterlink: '', authCreate: '', uploadRouterlink: '', authUpload: '', },
        { id: 6, name: "post_JE_custodian", cardAuthName: "SEAMLESS_LEGACY_ACCOUNTING_AND_INVESTORS", catCreatAuth: "SEAMLESS_LEGACY_ACCOUNTING_AND_INVESTORS", catViewAuth: "SEAMLESS_LEGACY_ACCOUNTING_AND_INVESTORS", auth: "JOURNAL_ENTRY_POSTING_CUSTODIAN_VIEW", viewRouterlink: "/legacy/postjecustodian", createRouterlink: '', authCreate: '', uploadRouterlink: '', authUpload: '', },
        { id: 8, name: "run_je_summary", cardAuthName: "SEAMLESS_LEGACY_ACCOUNTING_AND_INVESTORS", catCreatAuth: "SEAMLESS_LEGACY_ACCOUNTING_AND_INVESTORS", catViewAuth: "SEAMLESS_LEGACY_ACCOUNTING_AND_INVESTORS", auth: "JOURNAL_ENTRY_SUMMARY", viewRouterlink: "/legacy/runjesummary", createRouterlink: '', authCreate: '', uploadRouterlink: '', authUpload: '', },

        { id: 13, name: "run_je_transfer_fundlevel", cardAuthName: "SEAMLESS_LEGACY_ACCOUNTING_AND_INVESTORS", catCreatAuth: "SEAMLESS_LEGACY_ACCOUNTING_AND_INVESTORS", catViewAuth: "SEAMLESS_LEGACY_ACCOUNTING_AND_INVESTORS", auth: "ACCOUNTING_JOURNALENTRY_RUNJETRANSFERFUNDLEVEL", viewRouterlink: "/legacy/runjetransferfundlevel", createRouterlink: '', authCreate: '', uploadRouterlink: '', authUpload: '', },

        { id: 3, name: 'investors_addresses', cardAuthName: "SEAMLESS_LEGACY_ACCOUNTING_AND_INVESTORS", catCreatAuth: "SEAMLESS_LEGACY_ACCOUNTING_AND_INVESTORS", catViewAuth: "SEAMLESS_LEGACY_ACCOUNTING_AND_INVESTORS", auth: 'INVESTORADDRESS_VIEW', viewRouterlink: "/legacy/investor-addresses", createRouterlink: '/legacy/investor-addresses', uploadRouterlink: '', authUpload: '', authCreate: 'INVESTORADDRESS_CREATE' },



        {
          id: 5, name: "view_asset_fees", cardAuthName: "SEAMLESS_LEGACY_ACCOUNTING_AND_INVESTORS", catCreatAuth: "SEAMLESS_LEGACY_ACCOUNTING_AND_INVESTORS", catViewAuth: "SEAMLESS_LEGACY_ACCOUNTING_AND_INVESTORS", auth: "ASSETS_BASE_FEE_CALCULATION_VIEW", viewRouterlink: "/legacy/viewassetfees", upload: false, add: false, createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },

        {
          id: 10,
          name: "run_income_allocation_series_mf",
          cardAuthName: "SEAMLESS_LEGACY_ACCOUNTING_AND_INVESTORS",
          catCreatAuth: "SEAMLESS_LEGACY_ACCOUNTING_AND_INVESTORS",
          catViewAuth: "SEAMLESS_LEGACY_ACCOUNTING_AND_INVESTORS",
          auth: "RUN_INCOME_ALLOCATION_SERIES_VIEW",
          viewRouterlink: "/legacy/runincomeallocationSeries",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 15,
          name: "run_income_allocation",
          cardAuthName: "SEAMLESS_LEGACY_ACCOUNTING_AND_INVESTORS",
          catCreatAuth: "SEAMLESS_LEGACY_ACCOUNTING_AND_INVESTORS",
          catViewAuth: "SEAMLESS_LEGACY_ACCOUNTING_AND_INVESTORS",
          auth: "RUN_INCOME_ALLOCATION_VIEW",
          viewRouterlink: "/legacy/runincomeallocation",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 22,
          name: "allocation",
          cardAuthName: "SEAMLESS_LEGACY_ACCOUNTING_AND_INVESTORS",
          catCreatAuth: "SEAMLESS_LEGACY_ACCOUNTING_AND_INVESTORS",
          catViewAuth: "SEAMLESS_LEGACY_ACCOUNTING_AND_INVESTORS",
          auth: "CTA_ALLOCATION_VIEW",
          viewRouterlink: "/legacy/ctaallocation",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },

        {
          id: 24,
          name: "side_pocket_total_income",
          cardAuthName: "SEAMLESS_LEGACY_ACCOUNTING_AND_INVESTORS",
          catCreatAuth: "SEAMLESS_LEGACY_ACCOUNTING_AND_INVESTORS",
          catViewAuth: "SEAMLESS_LEGACY_ACCOUNTING_AND_INVESTORS",
          auth: "SIDE_POCKET_TOTAL_INCOME_VIEW",
          viewRouterlink: "/legacy/sidepockettotalincome",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },

        {
          id: 2,
          name: "exclude_composite",
          cardAuthName: "SEAMLESS_LEGACY_ACCOUNTING_AND_INVESTORS",
          catCreatAuth: "SEAMLESS_LEGACY_ACCOUNTING_AND_INVESTORS",
          catViewAuth: "SEAMLESS_LEGACY_ACCOUNTING_AND_INVESTORS",
          auth: "TRADING_PROGRAM_COMPOSITE_VIEW",
          viewRouterlink: "/legacy/excludeComposite",
          createRouterlink: '/legacy/excludeComposite-entry',
          authCreate: 'TRADING_PROGRAM_COMPOSITE_CREATE',
          uploadRouterlink: '/legacy/excludeCompositeUpload',
          authUpload: 'EXCLUDECOMPOSITE_UPLOAD',


        },
        {
          id: 3,
          name: "perf_table_extract",
          cardAuthName: "SEAMLESS_LEGACY_ACCOUNTING_AND_INVESTORS",
          catCreatAuth: "SEAMLESS_LEGACY_ACCOUNTING_AND_INVESTORS",
          catViewAuth: "SEAMLESS_LEGACY_ACCOUNTING_AND_INVESTORS",
          auth: "PERFORMANCETABLEREPORT_VIEW",
          viewRouterlink: "/legacy/perftableextract",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },



        {
          id: 10,
          name: "manager",
          cardAuthName: "SEAMLESS_LEGACY_ACCOUNTING_AND_INVESTORS",
          catCreatAuth: "SEAMLESS_LEGACY_ACCOUNTING_AND_INVESTORS",
          catViewAuth: "SEAMLESS_LEGACY_ACCOUNTING_AND_INVESTORS",
          auth: "CTAMANAGER_VIEW",
          viewRouterlink: "/legacy/Manager-entry",
          createRouterlink: '/legacy/Manager-entry',
          authCreate: 'CTAMANAGER_CREATE',
          uploadRouterlink: '/legacy/managerUpload',
          authUpload: 'CTAMANAGER_CREATE',

        },

        {
          id: 1,
          name: "fee_rate_change",
          cardAuthName: "SEAMLESS_LEGACY_ACCOUNTING_AND_INVESTORS",
          catCreatAuth: "SEAMLESS_LEGACY_ACCOUNTING_AND_INVESTORS",
          catViewAuth: "SEAMLESS_LEGACY_ACCOUNTING_AND_INVESTORS",
          auth: "FEE_RATE_CHANGE_VIEW",
          viewRouterlink: "/legacy/fee-rate-change-entry",
          createRouterlink: '/legacy/fee-rate-change-entry',
          authCreate: 'FEE_RATE_CHANGE_CREATE',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 3,
          name: "run_multi_recons",
          cardAuthName: "SEAMLESS_LEGACY_RECONCILIATION_AND_REPORTS",
          catCreatAuth: "SEAMLESS_LEGACY_RECONCILIATION_AND_REPORTS",
          catViewAuth: "SEAMLESS_LEGACY_RECONCILIATION_AND_REPORTS",
          auth: "MULTIPARTYRECONCILE_VIEW",
          viewRouterlink: "/legacy/run-multi-recon",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 5,
          name: "multi_recon_manually",
          cardAuthName: "SEAMLESS_LEGACY_RECONCILIATION_AND_REPORTS",
          catCreatAuth: "SEAMLESS_LEGACY_RECONCILIATION_AND_REPORTS",
          catViewAuth: "SEAMLESS_LEGACY_RECONCILIATION_AND_REPORTS",
          auth: "MULTIPARTYRECONCILE_VIEW",
          viewRouterlink: "/legacy/multi-recon-manually",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 6,
          name: "run_trade_recon_summary",
          cardAuthName: "SEAMLESS_LEGACY_RECONCILIATION_AND_REPORTS",
          catCreatAuth: "SEAMLESS_LEGACY_RECONCILIATION_AND_REPORTS",
          catViewAuth: "SEAMLESS_LEGACY_RECONCILIATION_AND_REPORTS",
          auth: "TRADE_RECONCILE_VIEW",
          viewRouterlink: "/legacy/run-trade-recon-summary",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },

        {
          id: 2,
          name: "run_position_recon",
          cardAuthName: "SEAMLESS_LEGACY_RECONCILIATION_AND_REPORTS",
          catCreatAuth: "SEAMLESS_LEGACY_RECONCILIATION_AND_REPORTS",
          catViewAuth: "SEAMLESS_LEGACY_RECONCILIATION_AND_REPORTS",
          auth: "POSITION_RECONCILE_VIEW",
          viewRouterlink: "/legacy/run-position-recon",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },


        {
          id: 3,
          name: "run_cash_recon",
          cardAuthName: "SEAMLESS_LEGACY_RECONCILIATION_AND_REPORTS",
          catCreatAuth: "SEAMLESS_LEGACY_RECONCILIATION_AND_REPORTS",
          catViewAuth: "SEAMLESS_LEGACY_RECONCILIATION_AND_REPORTS",
          auth: "CASHBALANCE_RECONCILE_VIEW",
          viewRouterlink: "/legacy/run-cash-recon",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },

        {
          id: 1,
          name: "run_top_recon",
          cardAuthName: "SEAMLESS_LEGACY_RECONCILIATION_AND_REPORTS",
          catCreatAuth: "SEAMLESS_LEGACY_RECONCILIATION_AND_REPORTS",
          catViewAuth: "SEAMLESS_LEGACY_RECONCILIATION_AND_REPORTS",
          auth: "SUMMARY_RECONCILE_VIEW",
          viewRouterlink: "/legacy/run-top-recon",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 2,
          name: "custodian_recon",
          cardAuthName: "SEAMLESS_LEGACY_RECONCILIATION_AND_REPORTS",
          catCreatAuth: "SEAMLESS_LEGACY_RECONCILIATION_AND_REPORTS",
          catViewAuth: "SEAMLESS_LEGACY_RECONCILIATION_AND_REPORTS",
          auth: "RECON_CUSTODIAN_VIEW",
          viewRouterlink: "/legacy/custodian-recon",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 2,
          name: "recon",
          cardAuthName: "SEAMLESS_LEGACY_RECONCILIATION_AND_REPORTS",
          catCreatAuth: "SEAMLESS_LEGACY_RECONCILIATION_AND_REPORTS",
          catViewAuth: "SEAMLESS_LEGACY_RECONCILIATION_AND_REPORTS",
          auth: "RECON_NEW_VIEW",
          viewRouterlink: "/legacy/recon",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 1,
          name: "view_non_trade_trans_tp",
          cardAuthName: "SEAMLESS_LEGACY_RECONCILIATION_AND_REPORTS",
          catCreatAuth: "SEAMLESS_LEGACY_RECONCILIATION_AND_REPORTS",
          catViewAuth: "SEAMLESS_LEGACY_RECONCILIATION_AND_REPORTS",
          auth: "VIEW_NON_TRADE_TRANSACTION_THIRD_PARTY",
          viewRouterlink: "/legacy/view-non-trade-trans-tp",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 2,
          name: "je_third_party",
          cardAuthName: "SEAMLESS_LEGACY_RECONCILIATION_AND_REPORTS",
          catCreatAuth: "SEAMLESS_LEGACY_RECONCILIATION_AND_REPORTS",
          catViewAuth: "SEAMLESS_LEGACY_RECONCILIATION_AND_REPORTS",
          auth: "VIEW_JOURNAL_ENTRY_REVISED_THIRD_PARTY",
          viewRouterlink: "/legacy/je-third-party",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 3,
          name: "activity_recon",
          cardAuthName: "SEAMLESS_LEGACY_RECONCILIATION_AND_REPORTS",
          catCreatAuth: "SEAMLESS_LEGACY_RECONCILIATION_AND_REPORTS",
          catViewAuth: "SEAMLESS_LEGACY_RECONCILIATION_AND_REPORTS",
          auth: "ACTIVITYRECONCILIATION_VIEW",
          viewRouterlink: "/legacy/activity-recon",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 8,
          name: "pnl_by_symbol",
          cardAuthName: "SEAMLESS_LEGACY_RECONCILIATION_AND_REPORTS",
          catCreatAuth: "SEAMLESS_LEGACY_RECONCILIATION_AND_REPORTS",
          catViewAuth: "SEAMLESS_LEGACY_RECONCILIATION_AND_REPORTS",
          auth: "REPORTS_ACCOUNTING_PNLBYSYMBOL",
          viewRouterlink: "/legacy/pnl-by-symbol",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },

      ], authsibebar: 'SEAMLESS_SIDEMENU_LEGACY_ALL_BUTTONS'
    },
  ];

  //-----LEGACY Buttons-----//
  entTabs = [
    { id: 1, name: 'users', auth: 'SMEQUITIES_VIEW', createRouterlink: '/manager/users', viewRouterlink: '/manager/users', uploadRouterlink: '', authUpload: 'SMEQUITIES_CREATE', authCreate: 'TRADEENTRY_VIEW' },
    { id: 3, name: 'connect_user_fund', auth: '', createRouterlink: '/manager/connect-user-fund', viewRouterlink: '/manager/connect-user-fund', uploadRouterlink: '/manager/connect-user-fund/upload', authUpload: '', authCreate: '' },
    { id: 4, name: 'funds_client_accounts', auth: 'SMFXFORWARD_VIEW', createRouterlink: '/manager/fund-client-account', viewRouterlink: '/manager/fund-client-account', uploadRouterlink: '/manager/fund-client-account/upload', authUpload: 'SMFXFORWARD_CREATE', authCreate: 'SMFXFORWARD_CREATE' },
    { id: 5, name: 'connect_user_investor', auth: '', createRouterlink: '/manager/connect-user-investor', viewRouterlink: '/manager/connect-user-investor', uploadRouterlink: '/manager/connect-user-investor/upload', authUpload: '', authCreate: '', },
    { id: 6, name: 'custodians', auth: 'SMFUTURES_VIEW', createRouterlink: '/manager/custodians', viewRouterlink: '/manager/custodians', uploadRouterlink: '/manager/custodians/upload', authUpload: 'SMFUTURES_CREATE', authCreate: 'SMFUTURES_CREATE' },
    { id: 7, name: 'connections', auth: '', createRouterlink: '', link: '/manager/connections', viewRouterlink: '/manager/connections', uploadRouterlink: '', authUpload: '', authCreate: 'BROKERACCOUNT_CREATE' },
    { id: 8, name: 'connect_custodian_investor', auth: '', createRouterlink: '/manager/connect-custodian-investor', viewRouterlink: '/manager/connect-custodian-investor', uploadRouterlink: '', authUpload: 'LOOK_THRU_LIST_CREATE' },
    { id: 9, name: 'investors', auth: '', createRouterlink: '/manager/investors', viewRouterlink: '/manager/investors', uploadRouterlink: '/manager/investors/upload', authUpload: 'LOOK_THRU_LIST_CREATE' },
    { id: 10, name: 'amc_im_programs', auth: '', createRouterlink: '/manager/amc-im-program', viewRouterlink: '/manager/amc-im-program', uploadRouterlink: '', authUpload: 'LOOK_THRU_LIST_CREATE' },
    { id: 11, name: 'non_custodian_workflow', auth: '', createRouterlink: '/manager/non-custodian-workflow', viewRouterlink: '/manager/non-custodian-workflow', uploadRouterlink: '', authUpload: 'LOOK_THRU_LIST_CREATE' },

    { id: 1, name: 'report_delivery_process', auth: 'ENT_REPORTDELIVERYPROCESS', createRouterlink: '', viewRouterlink: '/manager/report-delivery-process', uploadRouterlink: '', authUpload: '', authCreate: '' },
    { id: 2, name: 'checker_pending_request', auth: 'ENT_CHECKERPENDINGREQUEST', createRouterlink: '', viewRouterlink: '/manager/checker-pending-request', authCreate: '', uploadRouterlink: '', authUpload: '' },
    { id: 3, name: 'report_notification_status', auth: 'ENT_REPORTNOTIFICATIONSTATUS', createRouterlink: '', viewRouterlink: '/manager/report-notification-status', uploadRouterlink: '', authUpload: '', authCreate: '' },
    { id: 1, name: 'je_transfer_logs', auth: 'SEAMLESS_SIDEMENU_ADMIN_OTHERS_ROLLUPCONSOLIDATION_LOG', viewRouterlink: '/manager/je-transfer-log', createRouterlink: '', uploadRouterlink: '', authUpload: '', authCreate: '' },
    { id: 1, name: 'doc_management', auth: 'GETMARKETDATA_FROMGOLBALMARKETDATA', viewRouterlink: '/manager/doc-management', createRouterlink: '', uploadRouterlink: '', authUpload: '', authCreate: '' },
    { id: 2, name: 'ror_config', auth: 'GETMARKETDATA_FROMGOLBALMARKETDATA', viewRouterlink: '/manager/ror-config', uploadRouterlink: '', createRouterlink: '/manager/ror-config', authUpload: '', authCreate: '' },
    { id: 3, name: 'setup_je_transfer_fund_level', auth: 'DERIBITDATA_VIEW', createRouterlink: '/manager/setup-je-transfer-fund-level', viewRouterlink: '/manager/setup-je-transfer-fund-level', uploadRouterlink: '', authUpload: '', authCreate: '' },
    { id: 4, name: 'report_notification', auth: 'PORTFOLIO_SEAMLESS_MARKET_DATA_VIEW', createRouterlink: '', viewRouterlink: '', uploadRouterlink: '', authUpload: 'PORTFOLIO_SEAMLESS_MARKET_DATA_CREATE', authCreate: '', },
    { id: 5, name: 'report_approval_rule', auth: 'ALERTTHRESHOLD_VIEW', createRouterlink: '/manager/report-approval-rule', uploadRouterlink: '', link: '/manager/report-approval-rule', authUpload: '', authCreate: 'ALERTTHRESHOLD_CREATE' },
    { id: 6, name: 'fund_groups', auth: 'MARKETDATA_VIEW', createRouterlink: '/manager/fund-group', uploadRouterlink: '', viewRouterlink: '/manager/fund-group', authUpload: 'MARKETDATA_CREATE', authCreate: 'MARKETDATA_CREATE' },
    { id: 7, name: 'notes_and_comments', auth: 'AlertThresholdLog_VIEW', viewRouterlink: '/manager/notes-and-comments', uploadRouterlink: '', createRouterlink: '' },
    { id: 8, name: 'investor_portal_user', auth: 'MARKETDATAVAR_VIEW', createRouterlink: '/manager/investor-portal-user', viewRouterlink: '/manager/investor-portal-user', uploadRouterlink: '/manager/investor-portal-user/upload', authUpload: 'MARKETDATAVAR_CREATE', authCreate: 'MARKETDATAVAR_CREATE' },
    { id: 9, name: 'reporting_portal_user', auth: 'REPORTING_PORTAL_USER_VIEW', viewRouterlink: '/manager/reporting-portal-user', createRouterlink: '/manager/reporting-portal-user', uploadRouterlink: '/manager/reporting-portal-user/upload', authCreate: 'REPORTING_PORTAL_USER_CREATE', authUplaod: 'REPORTING_PORTAL_USER_CREATE' },
    { id: 10, name: 'investor_groups', auth: 'PORTFOLIO_MARKETDATA_CRYPTOMARKETDATA_VIEW', createRouterlink: '', uploadRouterlink: '', viewRouterlink: '/manager/investor-group' },
    { id: 11, name: 'input_configuration', auth: '', viewRouterlink: '/manager/input-configuration', uploadRouterlink: '', createRouterlink: '' },
    { id: 12, name: 'data_import_status', auth: 'EXCHANGERATE_VIEW', createRouterlink: '', viewRouterlink: '/manager/workflow-status', uploadRouterlink: '', authUpload: 'EXCHANGERATE_CREATE', authCreate: 'EXCHANGERATE_CREATE' },
    { id: 13, name: 'all_sm_upload', auth: '', viewRouterlink: '/manager/all-sm-upload/upload', uploadRouterlink: '', createRouterlink: '' },
    { id: 13, name: 'vendors_update', auth: 'SEAMLESS_VENDOR_UPLOAD', viewRouterlink: 'vendorcreate', uploadRouterlink: '/manager/uploadVendors', createRouterlink: '/manager/vendorcreate', authUpload: 'SEAMLESS_VENDOR_UPLOAD', },
    { id: 14, name: 'setup_je_transfer_investor_level', auth: 'DERIBITDATA_VIEW', createRouterlink: '/customization/setup-investor-je-transfer-entry', viewRouterlink: '/customization/setup-investor-je-transfer', uploadRouterlink: '/customization/upload-setup-investor-je-transferr', authUpload: '', authCreate: '' },
    //Settings//
    {
      name: "lookup",
      viewRouterlink: "/setting/lookups",
      createRouterlink: "",
      uploadRouterlink: '',
      auth: "LOOKUP_VIEW",
      authCreate: '',
      authUpload: ''
    },
    {
      name: "currencies",
      viewRouterlink: "/setting/currencies",
      createRouterlink: "/setting/currencies",
      uploadRouterlink: '',
      auth: "CURRENCY_VIEW",
      authCreate: 'CURRENCY_CREATE',
      authUpload: ''
    },
    {
      name: "regulators",
      viewRouterlink: "/setting/regulators",
      uploadRouterlink: '',
      createRouterlink: "/setting/regulators",
      auth: "SECURITYREGULATOR_VIEW",
      authCreate: 'SECURITYREGULATOR_CREATE',
      authUpload: ''
    },
    {
      name: "calendar",
      viewRouterlink: "/setting/calendar",
      createRouterlink: "/setting/calendar",
      uploadRouterlink: '',
      auth: "CALENDAR_VIEW",
      authCreate: 'CALENDAR_CREATE',
      authUpload: ''
    },
    {
      name: "vendors",
      viewRouterlink: "/setting/vendors",
      createRouterlink: "/setting/vendors",
      uploadRouterlink: '/setting/vendors/upload',
      auth: "VENDOR_VIEW",
      authCreate: 'VENDOR_CREATE',
      authUpload: 'VENDOR_CREATE'
    },
    {
      name: "fund_addresses",
      createRouterlink: "/setting/fund-addresses",
      viewRouterlink: "/setting/fund-addresses",
      uploadRouterlink: '',
      auth: "FUNDADDRESS_VIEW",
      authCreate: 'FUNDADDRESS_CREATE',
      authUpload: ''
    },
    {
      name: "trading_programs",
      createRouterlink: "/setting/trading-programs",
      viewRouterlink: "/setting/trading-programs",
      uploadRouterlink: '',
      auth: "TRADINGPROGRAM_VIEW",
      authCreate: 'TRADINGPROGRAM_CREATE',
      authUpload: ''
    },
    {
      name: "asset_class",
      viewRouterlink: "/setting/asset-class",
      createRouterlink: "/setting/asset-class",
      uploadRouterlink: '',
      auth: "ASSETCLASS_VIEW",
      authCreate: 'ASSETCLASS_CREATE',
      authUpload: ''
    },
    {
      name: "commision",
      createRouterlink: "/setting/commission",
      viewRouterlink: "/setting/commission",
      uploadRouterlink: '',
      auth: "COMMISSION_VIEW",
      authCreate: 'COMMISSION_CREATE',
      authUpload: ''
    },

    {
      name: "trade_allocation",
      createRouterlink: "/setting/trade-allocation",
      viewRouterlink: "/setting/trade-allocation",
      uploadRouterlink: '',
      auth: "TRADEALLOCATION_VIEW",
      authCreate: 'TRADEALLOCATION_CREATE',
      authUpload: ''
    },
    {
      name: "closing_methods",
      viewRouterlink: "/setting/closing-methods",
      createRouterlink: "/setting/closing-methods",
      uploadRouterlink: '',
      auth: "TAXLOTCLOSINGMETHOD_VIEW",
      authCreate: 'TAXLOTCLOSINGMETHOD_CREATE',
      authUpload: ''
    },
    {
      name: "risk_master",
      createRouterlink: "/setting/risk-master",
      viewRouterlink: "/setting/risk-master",
      uploadRouterlink: '/setting/risk-master/upload',
      auth: "RISKMASTER_VIEW",
      authCreate: 'RISKMASTER_CREATE',
      authUpload: 'RISKMASTER_CREATE'
    },
    {
      name: "sm_update",
      viewRouterlink: "/setting/sm-update",
      createRouterlink: "",
      uploadRouterlink: '/setting/sm-update',
      auth: "SETTINGS_PORTFOLIO_SMUPDATE",
      authCreate: '',
      authUpload: 'SETTINGS_PORTFOLIO_SMUPDATE'
    },
    {
      name: "asset_based_fees",
      viewRouterlink: "/setting/asset-based-fee",
      createRouterlink: "/setting/asset-based-fee",
      uploadRouterlink: '',
      auth: "ASSETBASEDFEE_VIEW",
      authCreate: 'ASSETBASEDFEE_CREATE',
      authUpload: ''
    },
    {
      name: "performance_based_fee",
      createRouterlink: "/setting/performanc-based-fee",
      viewRouterlink: "/setting/performanc-based-fee",
      uploadRouterlink: '',
      auth: "MANAGERPERFBASEDFEE_VIEW",
      authCreate: 'MANAGERPERFBASEDFEE_CREATE',
      authUpload: ''
    },
    {
      name: "income_distribution_base",
      createRouterlink: "/setting/income-distribution-base",
      viewRouterlink: "/setting/income-distribution-base",
      uploadRouterlink: '',
      auth: "INCOMEDISTRIBUTIONBASE_VIEW",
      authCreate: 'INCOMEDISTRIBUTIONBASE_CREATE',
      authUpload: ''
    },
    {
      name: "fee_sharing",
      createRouterlink: "/setting/Fee-sharing",
      viewRouterlink: "/setting/Fee-sharing",
      auth: "FEESHARINGSETUP_VIEW",
      uploadRouterlink: '',
      authCreate: 'FEESHARINGSETUP_CREATE',
      authUpload: ''
    },
    {
      name: "recurring_transaction",
      viewRouterlink: "/setting/recurring-transaction",
      createRouterlink: "/setting/recurring-transaction",
      uploadRouterlink: '',
      auth: "RECURRINGTRANSACTION_VIEW",
      authCreate: 'RECURRINGTRANSACTION_CREATE',
      authUpload: ''
    },

    {
      name: "set_up_expense_amortization",
      createRouterlink: "/setting/setup-expense-amortization",
      viewRouterlink: "/setting/setup-expense-amortization",
      uploadRouterlink: '',
      auth: "SETUPEXPENSEAMORTIZATION_VIEW",
      authCreate: 'SETUPEXPENSEAMORTIZATION_CREATE',
      authUpload: ''
    },
    {
      name: "chart_of_accounts",
      createRouterlink: "/setting/chart-of-accounts-entry",
      viewRouterlink: "/setting/chart-of-accounts",
      uploadRouterlink: '/setting/chart-of-accounts-upload',
      auth: "CHARTOFACCOUNTS_VIEW",
      authCreate: 'CHARTOFACCOUNTS_CREATE',
      authUpload: 'CHARTOFACCOUNTS_CREATE'
    },
    {
      name: "review_chart_of_accounts",
      cardAuthName: "DIV_SETTINGS_ACCOUNTING",
      createRouterlink: "",
      viewRouterlink: "/setting/review-chart-of-accounts",
      uploadRouterlink: '/setting/review-chart-of-accounts-upload',
      auth: "SEAMLESS_SETTINGS_REVIEW_CHARTOFACCOUNTS_VIEW",
      authCreate: '',
      authUpload: 'SEAMLESS_SETTINGS_REVIEW_CHARTOFACCOUNTS_UPLOAD'
    },
    {
      name: "hurdle",
      createRouterlink: "/setting/hurdle",
      viewRouterlink: "/setting/hurdle",
      auth: "HURDLE_VIEW",
      uploadRouterlink: '',
      authCreate: 'HURDLE_CREATE',
      authUpload: ''
    },
    {
      name: "share_classes",
      viewRouterlink: "/setting/share-classes",
      createRouterlink: "/setting/share-classes",
      uploadRouterlink: '',
      auth: "FUNDCLASS_VIEW",
      authCreate: 'FUNDCLASS_CREATE',
      authUpload: ''
    },
    {
      name: "share_series",
      createRouterlink: "/setting/share-series",
      viewRouterlink: "/setting/share-series",
      uploadRouterlink: '',
      auth: "SHARESERIES_VIEW",
      authCreate: 'SHARESERIES_CREATE',
      authUpload: ''
    },
    {
      name: "redemption_fees",
      createRouterlink: "/setting/redemption-fees",
      viewRouterlink: "/setting/redemption-fees",
      uploadRouterlink: '',
      auth: "REDEMPTION_VIEW",
      authCreate: 'REDEMPTION_CREATE',
      authUpload: ''
    },
    {
      name: "investor_update",
      cardAuthName: "DIV_SETTINGS_INVESTOR",
      viewRouterlink: "/setting/investor-update",
      createRouterlink: '',
      uploadRouterlink: '/setting/investor-update',
      auth: "SETTINGS_INVESTORSETTINGS_INVESTOR_UPDATE_TAX",
      authCreate: 'SETTINGS_INVESTORSETTINGS_INVESTOR_UPDATE_TAX',
      authUpload: 'SETTINGS_INVESTORSETTINGS_INVESTOR_UPDATE_TAX'
    },
    {
      name: "upload_data_config",
      viewRouterlink: "/setting/upload-data-config",
      createRouterlink: "",
      uploadRouterlink: '',
      auth: "DATACONFIGURATION_VIEW",
      authCreate: '',
      authUpload: ''
    },
    {
      name: "doc_management",
      viewRouterlink: "/setting/docManagement",
      createRouterlink: "",
      uploadRouterlink: '',
      auth: "SETTING_CONFIG_DOC_MANAGEMENT",
      authCreate: '',
      authUpload: ''
    },
    {
      name: "Report Delivery Configuration",
      viewRouterlink: "/setting/report-delivery-config",
      createRouterlink: "/setting/report-delivery-config",
      uploadRouterlink: '/setting/report-delivery-config/upload',
      auth: "ENT_WORKFLOW_CONFIG",
      authCreate: 'ENT_WORKFLOW_CONFIG',
      authUpload: 'ENT_WORKFLOW_CONFIG'
    },
    {
      name: "investor_statement_footer_customization",
      viewRouterlink: "/setting/investor-statement-footer-customization",
      createRouterlink: "/setting/investor-statement-footer-customization-entry",
      uploadRouterlink: '',
      auth: "SETTING_CONFIG_INVESTOR_STATEMENT_FOOTER_CUSTOMIZATION",
      authCreate: 'SETTING_CONFIG_INVESTOR_STATEMENT_FOOTER_CUSTOMIZATION',
      authUpload: ''
    },
    {
      name: "api_key",
      cardAuthName: "DIV_SETTINGS_API_MANAGEMENT",
      viewRouterlink: "/setting/api-key",
      createRouterlink: '/setting/api-key-entry',
      uploadRouterlink: '',
      auth: "SEAMLESS_SETTINGS_API_KEY_VIEW",
      authCreate: 'SEAMLESS_SETTINGS_API_KEY_CREATE',
      authUpload: ''
    },
    {
      name: "connect_key_plan",
      cardAuthName: "DIV_SETTINGS_API_MANAGEMENT",
      viewRouterlink: "/setting/api-key-usage/create",
      createRouterlink: '/setting/api-key-usage',
      uploadRouterlink: '',
      auth: "SEAMLESS_SETTINGS_API_USAGE_PLAN_MAPPING",
      authCreate: 'SEAMLESS_SETTINGS_API_USAGE_PLAN_MAPPING',
      authUpload: ''
    },
    {
      name: "api_reference",
      cardAuthName: "DIV_SETTINGS_API_MANAGEMENT",
      viewRouterlink: "/api-reference",
      createRouterlink: '',
      uploadRouterlink: '',
      auth: "SEAMLESS_SETTINGS_API_DOC_VIEW",
      authCreate: '',
      authUpload: ''
    },
    {
      name: "add_wallet_exchange",
      viewRouterlink: "/manager/exchange-wallet",
      createRouterlink: "/manager/exchange-wallet",
      uploadRouterlink: '',
      auth: "",
      authCreate: '',
      authUpload: ''
    },
    {
      name: "fetch_data",
      viewRouterlink: "/manager/fetch-live-data",
      createRouterlink: "",
      uploadRouterlink: '/manager/fetch-live-data',
      auth: "",
      authCreate: '',
      authUpload: '',
      tooltip: 'export_data_tooltip'
    },
    {
      name: "data_scheduler",
      viewRouterlink: "/manager/data-scheduler",
      createRouterlink: "/manager/data-scheduler",
      uploadRouterlink: '',
      auth: "",
      authCreate: '',
      authUpload: '',
      tooltip: 'data_scheduler_tooltip'
    },
    {
      name: "data_set",
      viewRouterlink: "/manager/view-data-collection",
      createRouterlink: "",
      uploadRouterlink: '',
      auth: "",
      authCreate: '',
      authUpload: '',
      tooltip: 'data_set_tooltip'
    },
  ];

  systemAdminTabs = [
    { id: 1, name: 'enterprises', auth: '', createRouterlink: '/systemmanager/enterprises-list', viewRouterlink: '/systemmanager/enterprises-list', uploadRouterlink: '', authUpload: 'LOOK_THRU_LIST_CREATE' },
    { id: 2, name: 'all_Users', auth: '', createRouterlink: '', viewRouterlink: '/systemmanager/all-users-list', uploadRouterlink: '', authUpload: 'LOOK_THRU_LIST_CREATE' },
    { id: 3, name: 'functions', auth: 'SMFXFORWARD_VIEW', createRouterlink: '/systemmanager/functions-list', viewRouterlink: '/systemmanager/functions-list', uploadRouterlink: '', authUpload: 'SMFXFORWARD_CREATE', authCreate: 'SMFXFORWARD_CREATE' },
    { id: 4, name: 'roles', auth: 'SMFUTURES_VIEW', createRouterlink: '/systemmanager/roles-list', viewRouterlink: '/systemmanager/roles-list', uploadRouterlink: '', authUpload: 'SMFUTURES_CREATE', authCreate: 'SMFUTURES_CREATE' },
    { id: 5, name: 'modules', auth: '', createRouterlink: '/systemmanager/modules-list', viewRouterlink: '/systemmanager/modules-list', uploadRouterlink: '', authUpload: 'LOOK_THRU_LIST_CREATE' },
    { id: 6, name: 'domain_Configuration', auth: 'GETMARKETDATA_FROMGOLBALMARKETDATA', viewRouterlink: '/systemmanager/domain-configuration', createRouterlink: '/systemmanager/domain-configuration', uploadRouterlink: '', authUpload: '', authCreate: '' },
    { id: 7, name: 'lookups', auth: 'SMEQUITIES_VIEW', createRouterlink: '', viewRouterlink: '/systemmanager/lookups', uploadRouterlink: '', authUpload: '', authCreate: '' },
    { id: 8, name: 'currencies', auth: 'SMEQUITIESOPTIONS_VIEW', createRouterlink: '/systemmanager/currencies', viewRouterlink: '/systemmanager/currencies', uploadRouterlink: '', authUpload: 'SMEQUITIESOPTIONS_CREATE', authCreate: 'SMEQUITIESOPTIONS_CREATE' },
    { id: 9, name: 'regulators', auth: 'SMFXFORWARD_VIEW', createRouterlink: '/systemmanager/regulators', viewRouterlink: '/systemmanager/regulators', uploadRouterlink: '', authUpload: 'SMFXFORWARD_CREATE', authCreate: 'SMFXFORWARD_CREATE' },
    { id: 10, name: 'all_fundsClient_accounts', auth: 'ENTERPRISE_VIEW', createRouterlink: '', viewRouterlink: '/systemmanager/fund-client-account', uploadRouterlink: '' },
    { id: 1, name: 'automated_report_delivery_log', auth: 'SMPRIVATEFUND_VIEW', createRouterlink: '', viewRouterlink: '/systemmanager/report-notification-status', uploadRouterlink: '', authUpload: 'SMPRIVATEFUND_CREATE', authCreate: 'SMPRIVATEFUND_CREATE', },
    { id: 2, name: 'data_import_status', auth: 'DERIBITDATA_VIEW', createRouterlink: '', viewRouterlink: '/systemmanager/workflow-status', uploadRouterlink: '', authUpload: '', authCreate: '' },
    { id: 3, name: 'view_audit_log', auth: 'Removed', createRouterlink: '', viewRouterlink: '/systemmanager/audit-log-list', uploadRouterlink: '', authUpload: 'LOOK_THRU_LIST_CREATE' },
    { id: 4, name: 'alert_threshold_log', auth: 'SMEQUITIESOPTIONS_VIEW', createRouterlink: '', viewRouterlink: '/systemmanager/alert-threshold-log', uploadRouterlink: '', authUpload: 'SMEQUITIESOPTIONS_CREATE', authCreate: 'SMEQUITIESOPTIONS_CREATE' },
    { id: 5, name: 'one_button_processing_log', auth: 'FUNCTION_CREATE', viewRouterlink: "/systemmanager/daily-processing-status", createRouterlink: '', uploadRouterlink: '', authUpload: '', authCreate: '' },
    { id: 6, name: 'input_data_integrity_check', auth: '', viewRouterlink: "/systemmanager/input-data-integrity-check", createRouterlink: '', uploadRouterlink: '', authUpload: '', authCreate: 'INVESTORGROUP_VIEW' },
    { id: 7, name: 'sys_adm_seq_configuration', auth: '', createRouterlink: '', viewRouterlink: '/systemmanager/sequenceConfiguration', uploadRouterlink: '', authUpload: '', authCreate: '' },

    { id: 1, name: 'source_Configuration', auth: 'ALERTTHRESHOLD_VIEW', createRouterlink: '/systemmanager/source-configuration', uploadRouterlink: '', viewRouterlink: '/systemmanager/source-configuration', authUpload: '', authCreate: 'ALERTTHRESHOLD_CREATE' },
    { id: 2, name: 'SFTP_Configuration', auth: 'PORTFOLIO_SEAMLESS_MARKET_DATA_VIEW', createRouterlink: '/systemmanager/sftp-configuration', viewRouterlink: '/systemmanager/sftp-configuration', uploadRouterlink: '', authUpload: 'PORTFOLIO_SEAMLESS_MARKET_DATA_CREATE', authCreate: '', },
    { id: 3, name: 'workflow_Management', auth: 'GETMARKETDATA_FROMGOLBALMARKETDATA', viewRouterlink: "/systemmanager/workflow-management", uploadRouterlink: '', createRouterlink: '/systemmanager/workflow-management', authUpload: '', authCreate: '' },

    { id: 1, name: 'connect_user_fund', auth: 'SMEQUITIES_VIEW', createRouterlink: '/systemmanager/connect-user-fund', viewRouterlink: '/systemmanager/connect-user-fund', uploadRouterlink: '/systemmanager/upload-connect-user-fund', authUpload: '', authCreate: '' },
    { id: 2, name: 'tax_classification', auth: 'SYSADMIN_TAXATION_TAXCLASSIFICATION_VIEW', createRouterlink: '/systemmanager/tax-classification', viewRouterlink: '/systemmanager/tax-classification', uploadRouterlink: '/systemmanager/tax-classification-upload', authUpload: 'SYSADMIN_TAXATION_TAXCLASSIFICATION_UPLOAD', authCreate: 'SYSADMIN_TAXATION_TAXCLASSIFICATION_CREAT_1' },

    { id: 2, name: 'K_1_box_configuration', auth: 'SYSADMIN_TAXATION_K1BOXCONFIGURATION_VIEW', createRouterlink: '/systemmanager/tax-K1-box-configuration', viewRouterlink: '/systemmanager/tax-K1-box-configuration', uploadRouterlink: '/systemmanager/tax-K1-box-configuration-upload', authUpload: 'SYSADMIN_TAXATION_K1BOXCONFIGURATION_UPLOAD', authCreate: 'SYSADMIN_TAXATION_K1BOXCONFIGURATION_CREATE_1' },
    { id: 3, name: 'mapping_default_coa_tax_classification', auth: 'SYSADMIN_TAXATION_DEFAULTCOATAXCLASSIFICATION_VIEW', createRouterlink: '/systemmanager/mapping-default-coa-tax-classification', viewRouterlink: '/systemmanager/mapping-default-coa-tax-classification', uploadRouterlink: '', authUpload: '', authCreate: 'SYSADMIN_TAXATION_DEFAULTCOATAXCLASSIFICATION_CREATE' },
    { id: 3, name: 'partnership_passthrough_configuration', auth: 'SYSADMIN_TAXATION_PASSTHROUGHCONFIGURATION_VIEW', createRouterlink: '/systemmanager/partnership-passthrough-configuration', viewRouterlink: '/systemmanager/partnership-passthrough-configuration', uploadRouterlink: '', authUpload: '', authCreate: 'SYSADMIN_TAXATION_PASSTHROUGHCONFIGURATION_CREATE' },

    { id: 2, name: 'upload_data_configuration', auth: 'SYSADMIN_OTHERS_UPLOAD_DATA_CONFIG', createRouterlink: '', viewRouterlink: '/systemmanager/upload-data-configuration', uploadRouterlink: '', authUpload: '', authCreate: '' },
    { id: 3, name: 'download_new_sm', auth: 'GETMARKETDATA_FROMGOLBALMARKETDATA', viewRouterlink: '/systemmanager/download-new-sm', createRouterlink: '', uploadRouterlink: '', authUpload: '', authCreate: '' },
    { id: 3, name: 'doc_management', auth: 'SMFIXEDINCOME_VIEW', createRouterlink: '', viewRouterlink: '/systemmanager/doc-management', uploadRouterlink: '', authUpload: 'SMFIXEDINCOME_CREATE', authCreate: 'SMFIXEDINCOME_CREATE' },
    { id: 4, name: 'eneterprise_mapped_workflow', auth: '', viewRouterlink: "/systemmanager/workflow-mapped", uploadRouterlink: '', createRouterlink: '', authUpload: '', authCreate: '' },
    { id: 5, name: 'default_chartOfAccount', auth: '', createRouterlink: '/systemmanager/default-chart-of-accounts', viewRouterlink: '/systemmanager/default-chart-of-accounts', uploadRouterlink: '/systemmanager/default-chart-of-account-upload', authUpload: '', authCreate: '' },
    { id: 2, name: 'all_sm_upload', auth: 'SEAMLESS_SYSADMIN_SECURITYMASTER_ALL_SM_UPLOAD', createRouterlink: '', viewRouterlink: '/global-security-master/sm-update', uploadRouterlink: '/global-security-master/sm-update', authUpload: '', authCreate: '' },
    { id: 3, name: 'enterprise_update', auth: 'SEAMLESS_SYSADMIN_SECURITYMASTER_ENTERPRISE_UPDATE', createRouterlink: '', viewRouterlink: '/global-security-master/enterprise-update', uploadRouterlink: '', authUpload: '', authCreate: '' },
    //product buttons
    // { id: 1, name: 'Lookups', auth: 'SMEQUITIES_VIEW', createRouterlink: '', viewRouterlink: '/product/lookups', uploadRouterlink: '', authUpload: '', authCreate: '' },
    // { id: 2, name: 'Currencies', auth: 'SMEQUITIESOPTIONS_VIEW', createRouterlink: '/product/currencies-entry', viewRouterlink: '/product/currencies', uploadRouterlink: '', authUpload: 'SMEQUITIESOPTIONS_CREATE', authCreate: 'SMEQUITIESOPTIONS_CREATE' },
    // { id: 3, name: 'Doc Management', auth: 'SMFIXEDINCOME_VIEWsssss', createRouterlink: '', viewRouterlink: '/product/docmanagement', uploadRouterlink: '', authUpload: 'SMFIXEDINCOME_CREATE', authCreate: 'SMFIXEDINCOME_CREATE' },
    // { id: 4, name: 'Regulators', auth: 'SMFXFORWARD_VIEW', createRouterlink: '/product/regulators-entry', viewRouterlink: '/product/regulators', uploadRouterlink: '', authUpload: 'SMFXFORWARD_CREATE', authCreate: 'SMFXFORWARD_CREATE' },

  ];
  misTabs = [
    { id: 1, name: 'mis_funds_client_accounts', auth: 'SEAMLESS_MIS_FUND', viewRouterlink: "/MIS/fund", createRouterlink: '', uploadRouterlink: '/MIS/fund/upload', authUpload: 'SEAMLESS_MIS_FUND_UPDATE', authCreate: '', catViewAuth: 'SEAMLESS_MIS_FUND_VIEW', cardAuthName: 'SEAMLESS_SIDEMENU_MIS', },

  ];
  entTabsRapid = [{ id: 1, name: 'amc_im_gp', auth: '', createRouterlink: 'setup/amc-im-program-entry', viewRouterlink: 'setup/amc-im-program/', uploadRouterlink: 'setup/amc-im-program-upload', authUpload: '', authCreate: 'ASSETMANAGEMENT_CREATE' },
  { id: 2, name: 'funds_client_accounts', auth: '', createRouterlink: 'setup/fund-account', viewRouterlink: 'setup/fund-client-account-list/', uploadRouterlink: 'setup/fund-client-account-upload', authUpload: '', authCreate: 'SMFXFORWARD_CREATE' },
  { id: 3, name: 'custodian_exchange_wallet_bank', auth: '', createRouterlink: 'setup/custodianst-entry', viewRouterlink: 'setup/custodians-list/', uploadRouterlink: 'setup/custodians-upload', authUpload: '', authCreate: 'SMPRIVATEFUND_CREATE' },
  { id: 4, name: 'investors', auth: '', createRouterlink: 'setup/investor-entry', viewRouterlink: 'setup/investor-list/', uploadRouterlink: 'setup/investor-upload', authUpload: '', authCreate: 'INVESTOR_CREATE' },
  { id: 5, name: 'assest_based_fee', auth: '', createRouterlink: 'setup/asset-based-fee-entry', viewRouterlink: 'setup/asset-based-fee/', uploadRouterlink: '', authUpload: '', authCreate: 'ASSETBASEDFEE_CREATE' },
  { id: 6, name: 'performance_based_fee', auth: '', createRouterlink: 'setup/performanc-based-fee-entry', viewRouterlink: 'setup/performanc-based-fee/', uploadRouterlink: '', authUpload: '', authCreate: 'MANAGERPERFBASEDFEE_CREATE' },
  //user management
  { id: 7, name: 'seamless', moduleName: 'usermanagement', subComponent: 'Seamless', auth: '', createRouterlink: 'user-management/user-entry', viewRouterlink: 'user-management/user-list/Seamless/', uploadRouterlink: 'user-management/upload-connect-user-fund', authUpload: 'SEAMLESS_USERMANAGEMENT_USER_AND_FUND_MAPPING', authCreate: 'USER_CREATE' },
  { id: 8, name: 'sro', moduleName: 'usermanagement', subComponent: 'SRO', auth: '', createRouterlink: 'user-management/user-entry', viewRouterlink: 'user-management/user-list/SRO/', uploadRouterlink: 'user-management/upload-reporting-portal-user', authUpload: '', authCreate: 'REPORTING_PORTAL_USER_CREATE' },
  { id: 9, name: 'sio', moduleName: 'usermanagement', subComponent: 'SIO', auth: '', createRouterlink: 'user-management/user-entry', viewRouterlink: 'user-management/user-list/SIO/', uploadRouterlink: 'user-management/upload-investor-portal-user', authUpload: '', authCreate: 'INVESTORPORTALUSER_CREATE' },
  { id: 10, name: 'connectUserInvestor', moduleName: 'usermanagement', subComponent: 'connectUserInvestor', auth: '', createRouterlink: 'user-management/user-mapping/connectUserInvestor', viewRouterlink: 'user-management/user-mapping/connectUserInvestor/', uploadRouterlink: '', authUpload: '', authCreate: 'Enterprise_UserAndInvestor_create' },
  { id: 11, name: 'connectCustodianInvestor', moduleName: 'usermanagement', subComponent: 'connectCustodianInvestor', auth: '', createRouterlink: 'user-management/user-mapping/connectCustodianInvestor', viewRouterlink: 'user-management/user-mapping/connectCustodianInvestor/', uploadRouterlink: '', authUpload: '', authCreate: 'Enterprise_custodianAndInvestor_create' },
  //customization
  { id: 12, name: 'fund_group', auth: '', moduleName: 'usermanagement', createRouterlink: 'customization/fund-Group-entry', viewRouterlink: 'customization/fund-Group/', uploadRouterlink: '', authUpload: '', authCreate: 'FUNDGROUP_CREATE' },
  { id: 13, name: 'notes_comment', auth: '', moduleName: 'usermanagement', createRouterlink: 'customization/notes-comments-entry', viewRouterlink: 'customization/notes-comments-entry', uploadRouterlink: '', authUpload: '', authCreate: '' },

  { id: 14, name: 'investor_group', auth: '', createRouterlink: 'customization/investor-Group-entry', viewRouterlink: 'customization/investor-Group/', uploadRouterlink: '', authUpload: '', authCreate: 'INVESTORGROUP_CREATE' },
  { id: 15, name: 'non_custodian_workflow', auth: '', createRouterlink: 'customization/non-custodian-workflow-entry', viewRouterlink: 'customization/non-custodian-workflow/', uploadRouterlink: '', authUpload: '', authCreate: 'Enterprise_nonCustodianWorkflow_create' },
  { id: 16, name: 'sys_adm_seq_configuration', auth: '', createRouterlink: '', viewRouterlink: 'customization/sequence-config/', uploadRouterlink: '', authUpload: '', authCreate: '' },
  { id: 17, name: 'input_configuration', auth: '', createRouterlink: '', viewRouterlink: 'customization/input-config/', uploadRouterlink: '', authUpload: '', authCreate: '' },
  { id: 18, name: 'data_import_status', auth: '', createRouterlink: '', viewRouterlink: 'customization/data-import-status/', uploadRouterlink: '', authUpload: '', authCreate: '' },
  { id: 19, name: 'connections', auth: '', createRouterlink: '', viewRouterlink: 'customization/connections/', uploadRouterlink: '', authUpload: '', authCreate: '' },
  { id: 20, name: 'delivery_process', auth: '', createRouterlink: '', viewRouterlink: 'customization/delivery-process/', uploadRouterlink: '', authUpload: '', authCreate: '' },
  { id: 21, name: 'checker_pending_request', auth: '', createRouterlink: '', viewRouterlink: 'customization/checker-pending-request/', uploadRouterlink: '', authUpload: '', authCreate: '' },
  { id: 22, name: 'notification_status', auth: '', createRouterlink: '', viewRouterlink: 'customization/notification-status/', uploadRouterlink: '', authUpload: '', authCreate: '' },
  { id: 23, name: 'report_approval_rule', auth: '', createRouterlink: 'customization/report-approval-rule-entry', viewRouterlink: 'customization/report-approval-rule/', uploadRouterlink: '', authUpload: '', authCreate: 'Enterprise_setupJeTransfer_create' },
  { id: 24, name: 'ror_configuration', auth: '', createRouterlink: 'customization/ror-config-entry', viewRouterlink: 'customization/ror-config/', uploadRouterlink: '', authUpload: '', authCreate: 'Enterprise_RORCinfiguration_create' },
  { id: 25, name: 'setup_je_transfer_fund_level', auth: '', createRouterlink: 'customization/setup-je-transfer-entry', viewRouterlink: 'customization/setup-je-transfer/', uploadRouterlink: '', authUpload: '', authCreate: 'Enterprise_setupJeTransfer_create' },
  { id: 26, name: 'Document_Management', auth: '', createRouterlink: '', viewRouterlink: 'customization/doc-management/', uploadRouterlink: '', authUpload: '', authCreate: '' },
  { id: 27, name: 'data_scheduler', auth: '', createRouterlink: 'customization/data-scheduler-entry', viewRouterlink: 'customization/data-scheduler/', uploadRouterlink: '', authUpload: '', authCreate: 'Enterprise_dataScheduler_create' },
  { id: 28, name: 'data_set', auth: '', createRouterlink: '', viewRouterlink: 'customization/view-data-collection/', uploadRouterlink: '', authUpload: '', authCreate: '' },
  { id: 29, name: 'securities_master_lower', auth: '', createRouterlink: '', viewRouterlink: 'customization/securities-master/', uploadRouterlink: '', authUpload: '', authCreate: '' },
  //{ id: 30, name: 'vendors_update', auth: '', createRouterlink: '', viewRouterlink: '', uploadRouterlink: 'customization/vendors-upload/', authUpload: '', authCreate: '' },
  { id: 31, name: 'fetch_crypto_data', auth: '', createRouterlink: '', viewRouterlink: 'customization/fetch-live-data', uploadRouterlink: 'customization/fetch-live-data', authUpload: '', authCreate: '' },
  { id: 2, name: 'je_transfer_logs', auth: 'SEAMLESS_CUSTOMIZATION_DIV_OTHERCONFIGURATION_ROLLUPCONSOLIDATION_LOG', createRouterlink: '', viewRouterlink: 'customization/je-transfer-log', uploadRouterlink: '', authUpload: '', authCreate: '' },
  { id: 14, name: 'setup_je_transfer_investor_level', auth: 'SEAMLESS_CUSTOMIZATION_OTHERCONFIGURATION_SETUP_JETRANSFER_INVESTOR_LEVEL', createRouterlink: '/customization/setup-investor-je-transfer-entry', viewRouterlink: '/customization/setup-investor-je-transfer', uploadRouterlink: '/customization/upload-setup-investor-je-transfer', authUpload: 'SEAMLESS_CUSTOMIZATION_OTHERCONFIGURATION_SETUP_JETRANSFER_INVESTOR_LEVEL_CREATE', authCreate: 'SEAMLESS_CUSTOMIZATION_OTHERCONFIGURATION_SETUP_JETRANSFER_INVESTOR_LEVEL_CREATE' },
  {
    name: "chart_of_accounts_fs_update",
    cardAuthName: "DIV_SETTINGS_ACCOUNTING",
    viewRouterlink: "/setting/fs-chart-of-accounts-upload",
    uploadRouterlink: '/setting/fs-chart-of-accounts-upload',
    createRouterlink: '',
    auth: "FS_CHARTOFACCOUNTS_CREATE",
    authUpload: 'FS_CHARTOFACCOUNTS_CREATE',
    authCreate: 'undefined'
  },
  {
    name: "fs_transaction_code_update",
    cardAuthName: "DIV_SETTINGS_CONFIGURATION",
    viewRouterlink: "",
    createRouterlink: "",
    uploadRouterlink: '/setting/fs-transaction-code-update',
    auth: "SEAMLESS_SETTINGS_FS_TRAXN_UPDATE_UPLOAD",
    authCreate: 'undefined',
    authUpload: 'SEAMLESS_SETTINGS_FS_TRAXN_UPDATE_UPLOAD',
    isUploadAuth: true,
  },
  {
    name: "api_key",
    cardAuthName: "DIV_SETTINGS_API_MANAGEMENT",
    viewRouterlink: "/setting/api-key",
    createRouterlink: '/setting/api-key-entry',
    uploadRouterlink: '',
    auth: "SEAMLESS_SETTINGS_API_KEY_VIEW",
    authCreate: 'SEAMLESS_SETTINGS_API_KEY_CREATE',
    authUpload: ''
  },
  {
    name: "connect_key_plan",
    cardAuthName: "DIV_SETTINGS_API_MANAGEMENT",
    viewRouterlink: "/setting/api-key-usage/create",
    createRouterlink: '/setting/api-key-usage',
    uploadRouterlink: '',
    auth: "SEAMLESS_SETTINGS_API_USAGE_PLAN_MAPPING",
    authCreate: 'SEAMLESS_SETTINGS_API_USAGE_PLAN_MAPPING',
    authUpload: ''
  },
  {
    name: "api_reference",
    cardAuthName: "DIV_SETTINGS_API_MANAGEMENT",
    viewRouterlink: "/api-reference",
    createRouterlink: '',
    uploadRouterlink: '',
    auth: "SEAMLESS_SETTINGS_API_DOC_VIEW",
    authCreate: '',
    authUpload: ''
  },
  {
    name: "export_data_collection",
    viewRouterlink: "input/view/automation-data",
    cardAuthName: "SEAMLESS_INPUT_VIEW_CRYPTOEXCHANGEDATA_EXPORTDATACOLLECTION",
    createRouterlink: "",
    uploadRouterlink: '',
    auth: "SEAMLESS_INPUT_VIEW_CRYPTOEXCHANGEDATA_EXPORTDATACOLLECTION",
    authCreate: '',
    authUpload: ''
  },
  {
    name: "data_monitoring",
    viewRouterlink: "input/view/data-monitoring",
    cardAuthName: "SEAMLESS_INPUT_VIEW_CRYPTOEXCHANGEDATA_DATAMONITORING",
    createRouterlink: "",
    uploadRouterlink: '',
    auth: "SEAMLESS_INPUT_VIEW_CRYPTOEXCHANGEDATA_DATAMONITORING",
    authCreate: '',
    authUpload: ''
  },
  {
    name: "endpoint_scheduler",
    viewRouterlink: "input/view/endpoint-scheduler",
    cardAuthName: "SEAMLESS_INPUT_VIEW_CRYPTOEXCHANGEDATA_ENDPOINTSCHEDULER",
    createRouterlink: "",
    uploadRouterlink: '',
    auth: "SEAMLESS_INPUT_VIEW_CRYPTOEXCHANGEDATA_ENDPOINTSCHEDULER",
    authCreate: '',
    authUpload: ''
  },
  {
    name: "common-income-expense-config",
    createRouterlink: "/setting/common-income-expense-config-entry",
    viewRouterlink: "/setting/common-income-expense-config",
    auth: "SEAMLESS_SETTINGS_ACCOUNTING_COMMON_INCOME_EXPENSE_CONFIG_VIEW",
    uploadRouterlink: '/setting/upload-common-income-expense-config',
    authCreate: 'SEAMLESS_SETTINGS_ACCOUNTING_COMMON_INCOME_EXPENSE_CONFIG_CREATE',
    authUpload: 'SEAMLESS_SETTINGS_ACCOUNTING_COMMON_INCOME_EXPENSE_CONFIG_CREATE'
  },


  ]

  usertab = [
    { id: 1, name: 'Manager', auth: 'DIV_PORTFOLIO_RISK_SECURITIES_MASTER', Children: this.userTabs },
  ];

  enttab = [
    { id: 1, name: 'Manager', auth: 'DIV_PORTFOLIO_RISK_SECURITIES_MASTER', Children: this.entTabs },
  ];

  ststeAdmintab = [
    { id: 1, name: 'Manager', auth: 'DIV_PORTFOLIO_RISK_SECURITIES_MASTER', Children: this.systemAdminTabs },
  ];

  query = [
    {
      Button: [
        {
          id: 1,
          name: "cash_transaction",
          auth: "QUERY_CASHTRANSCATION_VIEW",
          link: "/query/cash-transaction",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 2,
          name: "export_income_statement_with_multiple_fund",
          auth: "REPORTS_ACCOUNTING_INCOME_STATEMENT_MULTI_FUND",
          link: "/query/export-income-statement-multifund",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',


        },
        {
          id: 3,
          name: "investor_statement2",
          auth: "INVESTOR_STATEMENT_REPORTS_2_VIEW",
          link: "/query/investor-statement-report2",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 4,
          name: "income_allocation_summary",
          auth: "REPORTS_ACCOUNTING_INVESTORCAPITALSUMMARY_PE",
          link: "/query/income-allocation-summary",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },

        {
          id: 2,
          name: "ticker_ledger_A/c",
          auth: "REPORTS_ACCOUNTING_AMOUNT_DAY_TICKER_COA",
          link: "/query/ticker-ledger-acc",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 2,
          name: "investor_tag_monthly_summary",
          auth: "INVESTOR_TAG_MONTHLY_SUMMARY",
          link: "/query/investor-tag-summary",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 1,
          name: "posted_fund_transactions",
          auth: "REPORTS_QUERY_POSTED_FUND_TRANSACTION",
          link: "/query/posted-fund-transaction",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 2,
          name: "fund_activity_by_fund",
          auth: "QUERY_REPORT_FUNDACTIVITYBYFUND",
          link: "/query/fund-activity-fund",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 3,
          name: "fund_activity_by_dealer",
          auth: "QUERY_REPORT_FUNDACTIVITYBYDEALER",
          link: "/query/fund-activity-dealer",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 1,
          name: "BS",
          auth: "QUERY_REPORT_BALANCESHEETNFM_VIEW",
          link: "/query/balance-sheet-report-nfm",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 2,
          name: "IS",
          auth: "QUERY_REPORT_INCOME_STATEMENTNFM",
          link: "/query/income-sheet-report-nfm",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',


        },
        {
          id: 3,
          name: "DST_fanmail_output",
          auth: "SIDEMENU_QUERY_FANMAILOUTPUT",
          link: "/query/dst-fanmail-output",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 4,
          name: "investor_income_summary",
          auth: "SIDEMENU_QUERY_INVESTOR_INCOME_SUMMARY",
          link: "/query/investor-income-summary",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 1,
          name: "daily_activity_by_fund",
          auth: "REPORTS_QUERY_DAILY_ACTIVITY_BY_FUND_VIEW",
          link: "/query/daily-activity-by-fund",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 1,
          name: "TB",
          auth: "QUERY_REPORT_TRIALBANANCENFM",
          link: "/query/trial-balance-nfm",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',
        },
        {
          id: 2,
          name: "asset_summary_frontier",
          auth: "SIDEMENU_QUERY_REPORT_ASSETSUMMARY_VIEW",
          link: "/query/asset-summary-frontier",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 2,
          name: "statement_Of_income_loss",
          auth: "QUERY_REPORT_STATEMENTOFINCOMELOSS",
          link: "/query/statement-of-income",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 1,
          name: "424B_report",
          auth: "SIDEMENU_QUERY_424BREPORT",
          link: "/query/b-report",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },

        {
          id: 2,
          name: "daily_performance_report",
          auth: "SIDEMENU_QUERY_DAILY_PERFORMANCE_REPORT",
          link: "/query/daily-performance-report",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 3,
          name: "download_investor_statement",
          auth: "DOWNLOAD_INVESTOR_STATEMENT_REPORTS_VIEW",
          link: "/query/download-investor-statement",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '/query/download-investor-statement-upload',
          authUpload: 'DOCUMENTMANAGEMENT_UPLOAD',

        },
        {
          id: 2,
          name: "policy_gain_loss_report",
          auth: "SIDEMENU_QUERY_POLICYGAINLOSS",
          link: "/query/policy-gainloss-report",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 2,
          name: "notes",
          auth: "REPORTS_ACCOUNTING_NOTESDETAILS_VIEW",
          link: "/query/notes-list",
          createRouterlink: '/query/notes-entry',
          authCreate: 'REPORTS_ACCOUNTING_NOTESDETAILS_CREATE',
          uploadRouterlink: '/query/notes-upload',
          authUpload: '',

        },

        {
          id: 1,
          name: "investor_capital_summary_custom",
          auth: "QUERY_INVESTOR_CAPITAL_SUMMARY_CUSTOM",
          link: "/query/investor-capital-summary-custom",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '',
          authUpload: '',

        },
        {
          id: 2,
          name: "historical_investor_capital_summary",
          auth: "QUERY_HISTORICAL_INVESTOR_CAPITAL_SUMMARY_UPLOAD",
          link: "/query/historical-investor-capital-summary",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '/query/historical-investor-capital-summary-upload',
          authUpload: 'QUERY_HISTORICAL_INVESTOR_CAPITAL_SUMMARY_UPLOAD',

        },
        {
          id: 3,
          name: "historical_investor_capital_summary_upload",
          auth: "QUERY_HISTORICAL_INVESTOR_CAPITAL_SUMMARY_UPLOAD",
          link: "/query/historical-investor-capital-summary-upload",
          createRouterlink: '',
          authCreate: '',
          uploadRouterlink: '/query/historical-investor-capital-summary-upload',
          authUpload: 'QUERY_HISTORICAL_INVESTOR_CAPITAL_SUMMARY_UPLOAD',

        },


      ], authsibebar: 'SIDEMENU_QUERY'
    }
  ]
}