/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/

import { Injectable } from '@angular/core';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor() { }
  // fs = [
  //   {
  //     id: 1,
  //     name: "Financial Statement",
  //     auth: "REPORTS_FINANCIAL_STATEMENT",
  //     link: "/fs",
  //     createRouterlink: '',
  //     authCreate: '',
  //     uploadRouterlink: '',
  //     authUpload: '',
  //   },
  // ];
  accounting = [
    {
      id: 1,
      name: "balance_sheet",
      auth: "REPORTS_ACCOUNTING_BALANCESHEET_ALT_VER",
      link: "/reports/balance-sheet",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 2,
      name: "income_statement",
      auth: "REPORTS_ACCOUNTING_INCOME_STATEMENT_ALT_VER",
      link: "/reports/income-statement",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 3,
      name: "trial_balance",
      auth: "REPORTS_ACCOUNTING_TRIAL_BALANCE_ALT_VER",
      link: "/reports/trial-balance",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 3,
      name: "account_trial_balance",
      auth: "SEAMLESS_RE_ACCOUNT_TRIAL_BALANCE_REPORT",
      link: "/reports/account-trial-balance",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 3,
      name: "Variance Report",
      auth: "SEAMLESS_RE_VARIANCE_REPORT",
      link: "/reports/variance-report",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 4,
      name: "account_statement",
      auth: "REPORTS_ACCOUNTING_ACCOUNT_STATEMENT",
      link: "/reports/account-statement",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 5,
      name: "management_fee",
      auth: "REPORTS_ACCOUNTING_MANAGEMENTFEE",
      link: "/reports/management-fee",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 6,
      name: "perfromance_fee",
      auth: "REPORTS_ACCOUNTING_PERFORMANCEFEE",
      link: "/reports/perfromance-fee",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 7,
      name: "return",
      auth: "REPORTS_ACCOUNTING_DAILYROR_VIEW",
      link: "/reports/return",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 8,
      name: "cash_ledger",
      auth: "REPORTS_ACCOUNTING_CASHLEDGER_REPORT",
      link: "/reports/cash-ledger",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 9,
      name: "glreport",
      auth: "REPORTS_ACCOUNTING_GENERAL_LEDGER_REPORT",
      link: "/reports/gl-report",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 9,
      name: "gl_ac",
      auth: "REPORTS_ACCOUNTING_GENERAL_LEDGER_CUSTOM_REPORT",
      link: "/reports/gl-ac",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 10,
      name: "operating_expense",
      auth: "REPORTS_ACCOUNTING_OPERATINGEXPENSES_VIEW",
      link: "/reports/operating-expense",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 11,
      name: "commission",
      auth: "REPORTS_ACCOUNTING_COMMISSION",
      link: "/reports/commision",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },

    {
      id: 12,
      name: "performance-fee-report-hurdle",
      auth: "SEAMLESS_REPORTS_ACCOUNTING_PERFORMANCEFEE_REPORT_HURDLE",
      link: "/reports/performance-fee-report-hurdle",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 18,
      name: "Transaction Drill Down",
      auth: "SEAMLESS_RE_DRILLDOWN",
      link: "/reports/transaction-drillDown",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 13,
      name: "general_ledger_account",
      auth: "SEAMLESS_RE_GENERAL_LEDGER_ACCOUNT_REPORT",
      link: "/reports/general-ledger-account",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 14,
      name: "account_general_ledger",
      auth: "SEAMLESS_RE_ACCOUNT_GENERAL_LEDGER_REPORT",
      link: "/reports/account-general-ledger",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 15,
      name: "account_cash_ledger",
      auth: "SEAMLESS_RE_ACCOUNT_CASH_LEDGER_REPORT",
      link: "/reports/account-cash-ledger",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 16,
      name: "account_operting_expense",
      auth: "SEAMLESS_RE_ACCOUNT_OPERATING_EXPENSES_REPORT",
      link: "/reports/account-operating-expense",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    }, {
      id: 17,
      name: "account_activity_report",
      auth: "SEAMLESS_RE_ACCOUNT_ACTIVITY_REPORT",
      link: "/reports/activity-account",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 18,
      name: "account_position_report",
      auth: "SEAMLESS_RE_ACCOUNT_POSITION_REPORT",
      link: "/reports/account-postion-report",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 18,
      name: "Account Position Report with Realized",
      auth: "SEAMLESS_RE_ACCOUNT_POSITION_REPORT2",
      link: "/reports/account-position-report-realized-gain",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 20,
      name: "income_statement_account",
      auth: "SEAMLESS_RE_ACCOUNT_INCOME_STATEMENT",
      link: "/reports/account-income-statement",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 1,
      name: "account_balance_sheet",
      auth: "SEAMLESS_RE_ACCOUNT_BALANCE_SHEET",
      link: "/reports/account-balance-sheet",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },

    {
      id: 4,
      name: "account_statement_report",
      auth: "SEAMLESS_RE_ACCOUNT_STATEMENT_REPORT",
      link: "/reports/account-statement-report",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 17,
      name: "navs_package_report",
      auth: "SEAMLESS_ACCOUNTING_REPORT_PACKAGE",
      link: "/reports/navpackagereport",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 18,
      name: "Entity Relationship",
      auth: "SEAMLESS_RE_ENTITY_RELATIONSHIP",
      link: "/reports/entity-relationship",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 18,
      name: "daily_cash",
      auth: "SEAMLESS_RE_DAILY_CASH_REPORT",
      link: "/reports/daily-cash",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 19,
      name: "TL_Performance_report",
      auth: "SEAMLESS_REPORTS_TL_PERFORMANCE",
      link: "/reports/TL-performance-report",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    }
  ]
  portfolio = [
    {
      id: 1,
      name: "position_report",
      auth: "SEAMLESS_PORTFOLIO_POSITION_REPORT",
      link: "/reports/position-report",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',

    },
    {
      id: 1,
      name: "position_report_detailed",
      auth: "REPORTS_PORTFOLIO_ POSITIONREPORTDETAILED",
      link: "/reports/position-report-detailed",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 2,
      name: "Investment Details - Lot Wise",
      auth: "REPORTS_PORTFOLIO_INVESTMENTDETAILSREPORT_LOTWISE",
      link: "/reports/investment-details-lot-wise",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 2,
      name: "open_tax_lots_report",
      auth: "IREPORTS_QUERY_OPENTAX_VIEW",
      link: "/reports/open-tax-lots-report",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 3,
      name: "tax_lot_appraisal_report",
      auth: "TAX_LOT_APPRAISAL_VIEW",
      link: "/reports/tax-lot-appraisal-report",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',

    },
    {
      id: 4,
      name: "port_data",
      auth: "IREPORTS_PORTFOLIO_PORTDATA",
      link: "/reports/port-data",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',

    },
    {
      id: 4,
      name: "position_summary",
      auth: "IREPORTS_PORTFOLIO_POSITION_SUMMARY",
      link: "/reports/position-summary-report",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',

    },
    {
      id: 4,
      name: "totalgainloss_with_fx_gain_loss_on_cash",
      auth: "SEAMLESS_PORTFOLIO_FXGLONCASH_REPORT",
      link: "/reports/total-gain-loss-fx",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',

    },
    {
      id: 5,
      name: "portfolio_turnover_ratio",
      auth: "IREPORTS_Portfolio_Portfolio_Turnover_Ratio",
      link: "/reports/portfolio-turnover-ratio",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',

    },
    {
      id: 6,
      name: "performance_detailed",
      auth: "IREPORTS_PORTFOLIO_PERFORMANCE_DETAILED",
      link: "/reports/performance-detailed",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',

    },
    {
      id: 7,
      name: "margin_by_equity_by_fund",
      auth: "SEAMLESS_PORTFOLIO_MARGINBYEQUITYBYFUND",
      link: "/reports/margin-by-equity-by-fund",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 8,
      name: "assest_allocation_report",
      auth: "REPORTS_PORTFOLIO_ASSET_ALLOCATION_DB",
      link: "/reports/assest-allocation-report",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 9,
      name: "dividend",
      auth: "SEAMLESS_PORTFOLIO_DIVIDEND",
      link: "/reports/dividend",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 10,
      name: "aum_allocation_report",
      auth: "SEAMLESS_REPORTS_PORTFOLIO_AUMALLOCATION_ALT_VER",
      link: "/reports/aum-allocation-report",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 11,
      name: "price_analysis",
      auth: "SEAMLESS_PRICE_ANALYTICS_REPORT",
      link: "/reports/price-analysis",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 10,
      name: "market_value_by_sector",
      auth: "SEAMLESS_MARKET_VALUE_BY_SECTOR_SUBSECTOR",
      link: "/reports/market-value-by-sector",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 12,
      name: "position-analytics",
      auth: "SEAMLESS_POSITION_ANALYTICS_REPORT",
      link: "/reports/position-analytics",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 13,
      name: "attribution_report",
      auth: "SEAMLESS_ATTRIBUTION_REPORT",
      link: "/reports/attribution-report",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 11,
      name: "position_report_fixed_income",
      auth: "SEAMLESS_POSITION_REPORT_FIXED_INCOME",
      link: "/reports/position-report-fixed-income",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 12,
      name: "interest_report",
      auth: "SEAMLESS_REPORTS_PORTFOLIO_INTEREST_REPORT",
      link: "/reports/interest-report",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',

    },
    {
      id: 13,
      name: "Fx Gain Loss",
      auth: "SEAMLESS_REPORTS_PORTFOLIO_FXGAINLOSS",
      link: "/reports/fxgainloss-report",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',

    },
    {
      id: 13,
      name: "portfolio_exposer_report",
      auth: "SEAMLESS_PORTFOLIO_EXPOSURE_REPORT",
      link: "/reports/portfolio-exposure-report",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 14,
      name: "deal_summary_report",
      auth: "SEAMLESS_REPORTS_PORTFOLIO_DEAL_SUMMARY",
      link: "/reports/deal-summary-report",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 15,
      name: "cost_roll_forward",
      auth: "REPORTS_ACCOUNTING_COST_ROLL_FORWARD",
      link: "/reports/cost_roll_forward",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 16,
      name: "Quantity and Cost Roll Forward - Deal Wise",
      auth: "SEAMLESS_REPORTS_PORTFOLIO_QUANTITYANDCOSTROLLFORWARD_DEALWISE",
      link: "/reports/cost-roll-forward-deal-wise",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 16,
      name: "notes_payable",
      auth: "SEAMLESS_REPORT_PORTFOLIO_NOTES_PAYABLE",
      link: "/reports/notes-payable",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 17,
      name: "Performance Attributes",
      auth: "SEAMLESS_REPORTS_PORTFOLIO_PERFORMANCE_ATTRIBUTES",
      link: "/reports/perfromance-attributes",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    }
  ]

  investor = [
    {
      id: 1,
      name: "investor_capital_summary",
      auth: "REPORTS_INVESTOR_INVESTOR_CAPITAL_SUMMARY",
      link: "/reports/investor-capital-summary",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',

    },
    {
      id: 2,
      name: "investor_statement_dynamic",
      auth: "INVESTOR_STATEMENT_MULTIPLE_DOWNLOADS",
      link: "/reports/investor-statement",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',


    },
    {
      id: 3,
      name: "investor_statment_aif_ror",
      auth: "IREPORT_INVESTOR_INVESTORSTATEMENTAIFWITHROR_VIEW",
      link: "/reports/investor-statement-aif-ror",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 3,
      name: "investor_statment_aif_ror2",
      auth: "SEAMLESS_REPORTS_INVESTORSTATEMENTAIFWITHROR2_VIEW",
      link: "/reports/investor-statement-aif-ror2",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 18,
      name: "investor_statment_aif_with_class",
      auth: "SEAMLESS_REPORTS_INVESTORSTATEMENTAIFWITHCLASS",
      link: "/reports/investor-statement-aif-with-class",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 4,
      name: "investor_statement_aif_new",
      auth: "IREPORTS_INVESTORS_INVESTOR_STATEMENT_AIF_NEW",
      link: "/reports/investor-statement-aif-new",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 5,
      name: "investor_statement_consolidate",
      auth: "IREPORT_INVESTOR_INVESTORSTATEMENTAIFCONSOLIDATE_VIEW",
      link: "/reports/investor-statement-consolidate",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 6,
      name: "investor_statement_aif_by_fund",
      auth: "IREPORT_INVESTOR_INVESTOR_STATEMENT_AIF_BY_FUND",
      link: "/reports/investor-statement-aif-by-fund",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 7,
      name: "Investor_capital_schedule",
      auth: "IREPORTS_INVESTOR_INVESTORCAPITALSCHEDULE",
      link: "/reports/Investor-capital-schedule",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',

    },
    {
      id: 8,
      name: "capital_schedule",
      auth: "IREPORTS_INVESTOR_CAPITALSCHEDULE",
      link: "/reports/capital-schedule",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',

    },
    {
      id: 9,
      name: "capital_pl_investor",
      auth: "REPORTS_CAPITAL_AND_PL_BY_INVESTOR",
      link: "/reports/capital-pl-investor",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 10,
      name: "investor_summary_reports",
      auth: "REPORTS_ACCOUNTING_INVESTMENTSUMMARYREPORTS",
      link: "/reports/investor-summary-reports",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 11,
      name: "equity_allocation",
      auth: "IREPORTS_CTA_EQTYALLCTION",
      link: "/reports/equity-allocation",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 12,
      name: "econmic_allocation_report",
      auth: "SEAMLESS_INVESTOR_ECONOMICALLOCATIONDETAIL",
      link: "/reports/econmic-allocation-report",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },

    {
      id: 13,
      name: "dividend_report",
      auth: "IREPORTS_DIVIDEND",
      link: "/reports/dividend-report",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',

    },
    {
      id: 14,
      name: "investor_roll_forward_report",
      auth: "SEAMLESS_INVESTOR_ROLL_FORWARD_REPORT",
      link: "/reports/investor-roll-forward-report",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',

    },
    {
      id: 15,
      name: "Investor Statement Consolidate Detailed",
      auth: "SEAMLESS_REPORT_INVESTOR_INVESTOR_STATEMENT_CONSOLIDATE_DETAILED",
      link: "/reports/investor-statement-consolidatedetailed",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 15,
      name: " Investor Statement Consolidated Portfolio Level",
      auth: "SEAMLESS_REPORTS_INVESTOR_INVESTOR_STATEMENT_CONSOLIDATED_PORTFOLIO_LEVEL",
      link: "/reports/investor-statement-consolidatedetailed-portfolio-level",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 16,
      name: "Investor Transaction Report",
      auth: "SEAMLESS_REPORTS_INVESTOR_TRANSACTIONREPORT",
      link: "/reports/investor-transaction-report",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 17,
      name: "Equity Schedule",
      auth: "SEAMLESS_REPORTS_INVESTOR_EQUITY_SCHEDULE_SUMMARY",
      link: "/reports/equity",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',

    },
    {
      id: 18,
      name: "investor_statement_for_individual_share_class",
      auth: "SEAMLESS_REPORTS_INVESTORSTATEMENTFORINDIVIDUALSHARECLASS",
      link: "/reports/investor-statement-for-individual-share-class",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',

    }
  ]
  gainLoss = [
    {
      id: 1,
      name: "realized_gl",
      auth: "REPORTS_TRADING_GAIN_LOSS_REALIZED_GAIN_LOSS",
      link: "/reports/realized-gl",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 2,
      name: "unrealized_gl",
      auth: "REPORTS_TRADING GAIN_LOSS_UNREALIZED_GAIN_LOSS",
      link: "/reports/unrealized-gl",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 3,
      name: "transaction_report",
      auth: "SEAMLESS_TRADING_GAINLOSS_TRANSACTION_HISTORY_VIEW",
      link: "/reports/transaction-report",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 3,
      name: "trade_report_tax",
      auth: "SEAMLESS_REPORTS_GAIN/LOSS_TRADEREPORT_TAX",
      link: "/reports/trade-report-tax",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 3,
      name: "realized_tax_lot_report",
      auth: "SEAMLESS_REPORTS_GAIN/LOSS_REALIZEDTAXLOTREPORT",
      link: "/reports/realized-tax-lot-report",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 4,
      name: "pnl_attribution",
      auth: "REPORTS_INTERACTIVE_PNLATTRIBUTION",
      link: "/reports/pnl-attribution",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 5,
      name: "pnl_contribution_ror",
      auth: "REPORTS_ACCOUNTING_PNLCONTRIBUTIONWITHROR",
      link: "/reports/pnl-contribution-ror",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 6,
      name: "pl_attribution_feeders",
      auth: "REPORTS_INTERACTIVE_PNLATTRIBUTIONFEEDER",
      link: "/reports/pl-attribution-feeders",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 7,
      name: "comprehensive_pl",
      auth: "SEAMLESS_TRADING_GAIN_LOSS_COMPREHENSIVE_PNL",
      link: "/reports/comprehensive-pl",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 8,
      name: "pnl_by_symbol",
      auth: "REPORTS_ACCOUNTING_PNLBYSYMBOL",
      link: "/reports/pnl-by-symbol",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 9,
      name: "comprehensive_p_and_l_by_symbol",
      auth: "SEAMLESS_REPORTS_GAINLOSS_COMPREHENSIVE_P&L_BY_SYMBOL",
      link: "/reports/comprehensive-p-and-l-by-symbol",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
  ]

  checkReport = [
    {
      id: 1,
      name: "Daily Checks",
      auth: "IREPORTS_CHECKS_DAILY_CHECKS_VIEW",
      link: "/reports/daily-checks",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 2,
      name: "EOD Position",
      auth: "IREPORTS_QUERY_EODPOSITION_VIEW",
      link: "/reports/eod-position",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 3,
      name: "EOD Checks Sheet",
      auth: "REPORTS_ACCOUNTING_EOD_CHECKS",
      link: "/reports/eod-checks-sheet",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 4,
      name: "EOD Checks by Account",
      auth: "REPORTS_ACCOUNTING_EOD_CHECKS_BY_AACOUNT",
      link: "/reports/eod-checks-account",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    { id: 5, name: 'input_data_integrity_check', auth: 'USER_Input_Data_Integrity_Check', link: "/reports/input-data-integrity-check", createRouterlink: '', uploadRouterlink: '', authUpload: '', authCreate: 'INVESTORGROUP_VIEW' },


  ]
  reconciliationReport = [
    {
      id: 1,
      name: "Position Rec Summary",
      auth: "REPORTS_RECONCILIATIONREPORTS_POSITIONRECREPORT_SUMMARY",
      link: "/reports/position-rec-summary",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },

    {
      id: 1,
      name: "Position Rec Report",
      auth: "REPORTS_RECONCILIATIONREPORTS_POSITIONRECREPORT",
      link: "/reports/position-rec-report",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 2,
      name: "Cash Balance Reconciliation Report",
      auth: "REPORTS_RECONCILIATIONREPORTS_CASHBALANCERECREPORT",
      link: "/reports/cash-balance-rec",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 3,
      name: "trade_break_report",
      auth: "IREPORTS_RECONCILIATION_TRADE_BREAK_REPORT",
      link: "/reports/trade-break-report",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 4,
      name: "cash_recon_multi_party",
      auth: "REPORTS_RECONCILIATIONREPORTS_CASH_REC_MULTIPARTY",
      link: "/reports/cash-recon-multi-trade",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 4,
      name: "position_recon_multi_party",
      auth: "SEAMLESS_POSITION_RECON_MULTIPARTY",
      link: "/reports/position-recon-multi-party",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },

  ]

  perfReport = [
    {
      id: 1,
      name: "Performance Table",
      auth: "REPORTS_CTA_COMPOSITECUSTOM_VIEW",
      link: "/reports/perf-table",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 2,
      name: "Invoice",
      auth: "I-REPORTS_CTA_INVOICE_VIEW",
      link: "/reports/invoice",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 2,
      name: "CTA Invoice",
      auth: "SEAMLESS_CTA_INVOICE_REPORT",
      link: "/reports/cta-invoice",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 3,
      name: "Performance Table Account",
      auth: "MULTIPLE_FUND_REPORT_VIEW",
      link: "/reports/performance-table-account",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 4,
      name: "CTA Invoice QTD",
      auth: "SEAMLESS_CTA_INVOICE_QTD_REPORT",
      link: "/reports/cta-invoice-qtd",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 5,
      name: "Date Range Table All ACCT",
      auth: "REPORTS_PERFORMANCE_CUSTOM_DATE_RANGE_ALL_ACCT",
      link: "/reports/date-range-all-acct-report",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    }
  ]
  customReport = [
    {
      id: 1,
      name: "Investor Statement AIF",
      auth: "IREPORTS_INVESTORS_INVESTOR_STATEMENT_AIF",
      link: "/reports/investor-statment-aif",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 2,
      name: "fund_nav_daily",
      auth: "IREPORTS_OTHER_FUNDNAVDAILY",
      link: "/reports/fund-nav-daily",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 2,
      name: "roundTurnPerMillion",
      auth: "SEAMLESS_CUSTOM_ROUND_TURN_PER_MILLION_REPORT",
      link: "/reports/round-turns-per-millions",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 3,
      name: "holding_maturity",
      auth: "IREPORTS_QUERY_HOLDING_MATURITY",
      link: "/reports/holding-maturity",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 4,
      name: "man_fee_report_preq",
      auth: "REPORTS_ACCOUNTING_MANAGEMENTFEE_PREQ",
      link: "/reports/management-fee-report-preq",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 5,
      name: "customized_position",
      auth: "IREPORTS_PORTFOLIO_CUSTOMIZED_POSITION",
      link: "/reports/customized-position",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 6,
      name: "Holding Performance Report",
      auth: "REPORTS_PORTFOLIO_HOLDING_PERFORMANCE_REPORT",
      link: "/reports/holding-performance-report",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 7,
      name: "pnl_allocation",
      auth: "IREPORTS_CTA_P&LALLOCATION",
      link: "/reports/pndl-allocation",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 8,
      name: "man_fee_report_commitment",
      auth: "REPORTS_QUERY_MANAGEMENTFEEREPORT",
      link: "/reports/management-fee-report-commitment",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 9,
      name: "Performance Capsule - Program",
      auth: "AMC_CAPSULE_REPORT_VIEW",
      link: "/reports/performance-capsule-program",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 10,
      name: "Performance Table Program",
      auth: "COMPOSITE_PERFORMANCE_TABLE_VIEW",
      link: "/reports/performance-table-program",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 11,
      name: "Side Pocket Summary",
      auth: "REPORTS_ACCOUNTING_SIDE_POCKET_SUMMARY",
      link: "/reports/side-pocket-summary",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 12,
      name: "Investor Capital Summary Side Pocket",
      auth: "INVESTOR_CAPITAL_SUMMARY_SIDE_POCKET",
      link: "/reports/investor-capital-summary-side-pocket",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 13,
      name: "factsheet_fund",
      auth: "FACTSHEET_FUND_SRO",
      link: "/reports/factsheet-fund-report",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 14,
      name: "factsheet_investor",
      auth: "FACTSHEET_INVESTOR_SRO",
      link: "/reports/factsheet-investor-report",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 15,
      name: "financial_leverage",
      auth: "REPORTS_FINANCIAL_LEVERAGE_REPORT",
      link: "/reports/financial-leverage",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 16,
      name: "investment_summary",
      auth: "REPORTS_INVESTMENT_SUMMARY",
      link: "/reports/investment-summary",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 20,
      name: "equity_schedule_pe",
      auth: "SEAMLESS_REPORTS_CUSTOM_Equity_Schedule_PE",
      link: "/reports/equity-schedule-pe",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 17,
      name: "maturity_aging",
      auth: "REPORTS_MATURITY_AGING_REPORT",
      link: "/reports/maturity-aging",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 19,
      name: "composite-perf",
      auth: "COMPOSITE_PERF",
      link: "/reports/composite-perf",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 20,
      name: "call_and_dist",
      auth: "SEAMLESS_REPORTS_CUSTOM_CALL_AND_DIST",
      link: "/reports/call-and-dist",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 20,
      name: "loan_report",
      auth: "REPORTS_CUSTOM_LOAN_REPORT",
      link: "/reports/loan-report",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 21,
      name: "capital_call_report",
      auth: "SEAMLESS_REPORTS_CUSTOM_CAPITAL_CALL_REPORT",
      link: "/reports/capital-call-report",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },

    {
      id: 22,
      name: "capital-summary-commitment",
      auth: "SEAMLESS_CUSTOM_CAPITAL_SUMMARY_COMMITMENT_REPORT",
      link: "/reports/capital-summary-commitment",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },


  ]
  taxReport = [
    {
      id: 1,
      name: "1099-Report",
      auth: "IREPORTS_TAX_1099REPORT",
      link: "/reports/report1099",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },

    {
      id: 2,
      name: "product_tax_category_classification",
      auth: "IREPORTS_TAX_PRODUCTTAXCATEGORYCLASSIFICATION_VIEW",
      link: "/reports/product-tax-category-classification",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {

      id: 4,
      name: "Monthly columnar Report",
      auth: "SEAMLESS_TAX_MONTHLYCOLUMNAR_REPORT",
      link: "/reports/monthly-columnar-report",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {

      id: 4,
      name: "wash_sale_report",
      auth: "SEAMLESS_TAX_WASHSALE_REPORT",
      link: "/reports/wash-sale",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {

      id: 4,
      name: "wash_sale_adjusted_openlot",
      auth: "SEAMLESS_TAX_WASHSALEADJUSTEDOPENLOTS_REPORT",
      link: "/reports/wash-sale-adjusted",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 2,
      name: "dividend_distribution",
      auth: "IREPORTS_TAX_DIVIDENDISTRIBUTION_VIEW",
      link: "/reports/distribution-report",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 4,
      name: "ytd_distribution_base",
      auth: "SEAMLESS_REPORTS_TAXATION_YTDDISTRIBUTIONBASE",
      link: "/reports/ytd-distribution-base",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
    {
      id: 3,
      name: "Taxation",
      auth: "SEAMLESS_REPORTS_TAXATION_TAXATION",
      link: "/ta",
      createRouterlink: '',
      authCreate: '',
      uploadRouterlink: '',
      authUpload: '',
    },
  ]
  reportTabs = [
    {
      id: 1,
      name: 'accounting',
      auth: "DIV_REPORTS_ACCOUNTING_REPORTS",
      children: this.accounting
    },
    {
      id: 2,
      name: 'portfolio',
      auth: "DIV_REPORTS_PORTFOLIO_REPORTS",
      children: this.portfolio
    },
    {
      id: 5,
      name: 'investor',
      auth: "DIV_REPORTS_INVESTOR_REPORTS",
      children: this.investor
    },
    {
      id: 5,
      name: 'gain_loss',
      auth: "DIV_REPORTS_TRADES&TRADING_GAINLOSS_REPORTS",
      children: this.gainLoss
    },
    {
      id: 6,
      name: 'Checks',
      auth: "DIV_REPORTS_CHECKS_REPORTS",
      children: this.checkReport
    },
    {
      id: 7,
      name: 'Performance',
      auth: "DIV_REPORTS_CHECKS_REPORTS",
      children: this.perfReport
    },
    // {
    //   id: 9,
    //   name: 'Financial Statement',
    //   auth: "REPORTS_FINANCIAL_STATEMENT",
    //   children: this.fs
    // },
    {
      id: 7,
      name: 'Reconciliation',
      auth: "DIV_REPORTS_CHECKS_REPORTS",
      children: this.reconciliationReport
    },
    {
      id: 8,
      name: 'Custom',
      auth: "DIV_REPORTS_CHECKS_REPORTS",
      children: this.customReport
    },
    {
      id: 10,
      name: 'Taxation',
      auth: "SEAMLESS_REPORTS_TAXATION_REPORTS",
      children: this.taxReport
    },
  ];

  getFilterDate(frequency) {
    var retDate = "";
    switch (frequency) {
      case 'Daily':
        return this.getPreviousDay();
      case 'Quarterly':
        return this.getPreviousQuarterLastDay();
      case 'Monthly':
        return this.getPreviousMonthLastDay();
      case 'Halfyearly':
        return this.getHalfyearlyLastDay();
      case 'Yearly':
        return this.getlastyearlyLastDay();
      case 'NotFound':
        return "";
    }
    return retDate;
  }

  getFilterDateStart(frequency) {
    var retStartDate = "";
    switch (frequency) {
      case 'Daily':
        return this.getPreviousDay();
      case 'Quarterly':
        return this.getPreviousQuarterFirstDay();
      case 'Monthly':
        return this.getPreviousMonthFirstDay();
      case 'Halfyearly':
        return this.getHalfyearlyFirstDay();
      case 'Yearly':
        return this.getlastyearlyFirstDay();
      case 'NotFound':
        return "";
    }
    return retStartDate;
  }


  getFilterDates(frequency) {
    var retDate = "";
    switch (frequency) {
      case 'Daily':
        return this.getPreviousDay();
      case 'Quarterly':
        return this.getPreviousQuarterLastDay();
      case 'Monthly':
        return this.getPreviousMonthLastDay();
      case 'Halfyearly':
        return this.getHalfyearlyLastDay();
      case 'Yearly':
        return this.getlastyearlyLastDay();
      case 'NotFound':
        return "";
    }
    return retDate;
  }
  getlastyearlyFirstDay() {
    var yeardate = new Date();
    return moment(new Date(yeardate.getFullYear() - 1, 0, 1)).format('YYYY-MM-DD');
  }
  getlastyearlyLastDay() {
    var yeardate = new Date();
    return moment(new Date(yeardate.getFullYear() - 1, 11, 31)).format('YYYY-MM-DD');
  }
  getHalfyearlyLastDay() {
    var currMonth = new Date();
    if (currMonth.getMonth() >= 6) {
      return currMonth.getFullYear() + "-06-30";
    } else {
      return (currMonth.getFullYear() - 1) + "-12-31";
    }
  }
  getHalfyearlyFirstDay() {
    var currMonth = new Date();
    if (currMonth.getMonth() >= 6) {

      return currMonth.getFullYear() + "-01-01";
    } else {

      return (currMonth.getFullYear() - 1) + "-07-01";
    }
  }
  getPreviousDay() {
    var currday = new Date();
    if (currday.getDate() > 1) {
      return currday.getFullYear() + "-" + ("0" + (currday.getMonth() + 1)).slice(-2) + "-" + ("0" + (currday.getDate() - 1)).slice(-2);
    } else return this.getPreviousMonthLastDay();
  }

  getPreviousMonthFirstDay() {
    var StartDay = new Date();
    StartDay.setDate(-1);
    StartDay.setDate(1);
    return StartDay.getFullYear() + "-" + (StartDay.getMonth() + 1) + "-" + (StartDay.getDate());
  }
  getPreviousMonthLastDay() {
    var currday = new Date();
    var currday = new Date(currday.setDate(0));
    return currday.getFullYear() + "-" + ("0" + (currday.getMonth() + 1)).slice(-2) + "-" + ("0" + (currday.getDate())).slice(-2);
  }
  getPreviousQuarterFirstDay() {
    var StartDay = new Date();
    var quater = Math.floor((StartDay.getMonth() + 3) / 3);
    switch (quater) {
      case 4:
        return StartDay.getFullYear() + "-07-1";
      case 3:
        return StartDay.getFullYear() + "-04-1";
      case 2:
        return StartDay.getFullYear() + "-01-1";
      case 1:
        return (StartDay.getFullYear() - 1) + "-10-1";

    }

  }
  getPreviousQuarterLastDay() {
    var currday = new Date();
    var quater = Math.floor((currday.getMonth() + 3) / 3);
    switch (quater) {
      case 4:
        return currday.getFullYear() + "-09-30";
      case 3:
        return currday.getFullYear() + "-06-30";
      case 2:
        return currday.getFullYear() + "-03-31";
      case 1:
        return (currday.getFullYear() - 1) + "-12-31";
    }
  }
  getReportTabs() {
    return this.reportTabs;
  }
}
