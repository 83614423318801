/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/

// Import --------------------------------------------
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import moment from 'moment';
import { apiConstants } from '../../../../../src/app/constants/apiConstants';
import { environment } from '../../../../../src/environments/environment';
import { ConfirmationDialog } from '../../dialogs/confirmation-dialog.component';
import { CommonService } from '../../services/commonServices/common.service';
import { GlobalMessageService } from '../../services/commonServices/global-message.service';
import { GridService } from '../../services/gridServices/grid.service';
import { GridFilterDropdownService } from './gridFilterDropdown.service';
import { Observable, forkJoin } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

const api_url = environment['api_url'];


// Import End--------------------------------------------
@Injectable()
export class GridFilterService {
  public httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
    })
  };
  // Define ---------------------------------------- 
  public fundList = [];
  public httplocal: any;
  public custodianAccounts = [];
  public custodianList = [];
  public accountTickerList = [];
  public assetList = [];
  public statusBar: any;
  public filteredData: any[] = [];
  public filteredDataevt: any;
  public pageNumber: number = 1;
  public page: number = 0;
  @Output() isfilter: EventEmitter<any> = new EventEmitter();
  filterData: boolean;
  public gridApi: any;
  public searchObjectTemp: any = {};
  @Input() componentName: any;
  public orderFilter: boolean = false;
  public filterUrl: any;
  @Input() rowData: any[];
  public lookups: any = [];
  public totalRecords: number;
  public totalCount: any;
  public totalPages: number;
  public isFryFilterAct: boolean = false;
  gridDataUrl: any;
  gridDeleteUrl: any;
  moduleName: any;
  gridExportUrl: any;
  isZoneName: any;
  gridFilteredUrl: any;
  filterDeleteUrl: any;
  routeUrl: any;
  method: any;
  customFilterUrl: any;
  customFilterUrls: any;
  getCustomTypeUrl: any;
  public formDataTemp: any;
  LookupList: any;
  selectedRows: any[];
  result: boolean;
  public display: boolean = false;
  req: any;
  columnDefs: any;
  exportModuleName: any;
  currentUser: any;
  exchangeList: any;
  dataMonitoringclientList: any;
  clientList: any;
  realItems: any = [
    { label: 'trade', value: 'Trade' },
    { label: 'market_data', value: 'MarketData' },
    { label: 'position', value: 'Position' },
    { label: 'balance', value: 'Balance' },
    { label: 'generic_data', value: 'GenericData' },
    { label: 'transfers', value: 'Transfers' }
  ];
  enterpriseStatus: any = [
    { label: 'Active', value: 'active' },
    { label: 'Deactive', value: 'inactive' },
    { label: 'Pending', value: 'init' }
  ];

  enterpriseType: any = [
    { label: 'Live', value: 'Live' },
    { label: 'Saas', value: 'Saas' }
  ];
  statusList: any = [
    { label: 'All', value: 'All' },
    { label: 'Pending', value: 'Pending' },
    { label: 'In Progress', value: 'In Progress' },
    { label: 'Completed', value: 'Completed' },
    { label: 'Error', value: 'Error' },
  ];
  headerUrl: any;
  enterpriseList: any = [];
  accountList: any;
  csdBankTransFundList: any[];
  // End ---------------------------------------- 

  constructor(private gridFilterDropdownService: GridFilterDropdownService, private translateService: TranslateService, private dialog: MatDialog, private messageService: GlobalMessageService, private router: Router, public gridService: GridService,
    private commonService: CommonService, private httpClient: HttpClient, handler: HttpBackend, private cookieService :CookieService) {
    this.httplocal = new HttpClient(handler);
  }

  // Grid Details (Properties and Functions)

  gridProperties = {

    statusBar: {
      statusPanels: [
        {
          statusPanel: 'agTotalRowCountComponent',
          align: 'left',
          key: 'totalRowComponent',
        },
        {
          statusPanel: 'agFilteredRowCountComponent',
          align: 'left',
        },
        {
          statusPanel: 'agSelectedRowCountComponent',
          align: 'center',
        },
        {
          statusPanel: 'agAggregationComponent',
          align: 'right',
        },
      ],
    },
    defaultColDef: {
      enableValue: true,
      enableRowGroup: true,
      enablePivot: true,
      sortable: true,
      filter: true,
      menuTabs: ["filterMenuTab", "generalMenuTab", "columnsMenuTab"]
    },
    sideBar: {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressSideButtons: true,
            suppressColumnFilter: true,
            suppressColumnSelectAll: true,
            suppressColumnExpandAll: true,
          },
        },
      ],
      defaultToolPanel: 'filters',
    }

  }


  getGridHeader(gridHeaders, generic, cbs, cbe) {
    if (this.componentName == 'viewDataCollection' && generic != undefined) {
      this.headerUrl = apiConstants[this.componentName][generic]['headers'];
    } else {
      this.headerUrl = apiConstants[this.componentName]['headers'];
    }
    return this.httplocal.get(this.headerUrl)
      .pipe(res => res)
      .subscribe(result => {
        cbs(this.commonService.formatDataUsingGridHeaders(result));
      }, error => {
        cbe(error);
      })
  }


  // Form Details (Properties and Functions)

  runViewOpenTaxlots() {
    return [
      { id: 'startDate', controlType: 3, label: 'start_date', viewname: 'toDate', name: 'toDate', order: 7, value: null, required: false, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'end_date', viewname: 'toDate', name: 'toDate', order: 7, value: null, required: false, class: "col-lg-6" },
      { id: 'fundName', controlType: 2, label: 'fund', viewname: 'fundName', name: 'fundName', order: 4, value: null, required: false, fill: this.fundList, readonly: false, class: "col-lg-6" },
      { id: 'custodianBroker', controlType: 2, label: 'broker_account', viewname: 'custodianBroker', name: 'custodianBroker', order: 6, value: null, required: false, fill: this.custodianAccounts, class: "col-lg-6" },
      { id: 'ticker', controlType: 1, label: 'ticker', viewname: 'ticker', name: 'ticker', order: 5, value: null, required: false, readonly: false, class: "col-lg-12" },
    ];
  }
  tradeList() {
    return [
      { id: 'startDate', controlType: 3, label: 'start_date', viewname: 'toDate', name: 'toDate', order: 7, value: null, required: false, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'end_date', viewname: 'toDate', name: 'toDate', order: 7, value: null, required: false, class: "col-lg-6" },
      { id: 'TransientFund', controlType: 2, label: 'fund', viewname: 'fundName', name: 'fundName', order: 4, value: null, required: false, fill: this.fundList, readonly: false, class: "col-lg-6" },
      { id: 'TransientBrokerAccount', controlType: 2, label: 'broker_account', viewname: 'custodianBroker', name: 'custodianBroker', order: 6, value: null, required: false, fill: this.custodianAccounts, class: "col-lg-6" },
      { id: 'ticker', controlType: 1, label: 'ticker', viewname: 'ticker', name: 'ticker', order: 5, value: null, required: false, readonly: false, class: "col-lg-12" },
    ];
  }
  rapidTradeList() {
    return [
      { id: 'startDate', controlType: 3, label: 'start_date', viewname: 'toDate', name: 'toDate', order: 7, value: null, required: false, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'end_date', viewname: 'toDate', name: 'toDate', order: 7, value: null, required: false, class: "col-lg-6" },
      { id: 'TransientFund', controlType: 2, label: 'fund', viewname: 'fundName', name: 'fundName', order: 4, value: null, required: false, fill: this.fundList, readonly: false, class: "col-lg-6" },
      { id: 'TransientBrokerAccount', controlType: 2, label: 'broker_account', viewname: 'custodianBroker', name: 'custodianBroker', order: 6, value: null, required: false, fill: this.custodianAccounts, class: "col-lg-6" },
      { id: 'ticker', controlType: 1, label: 'ticker', viewname: 'ticker', name: 'ticker', order: 5, value: null, required: false, readonly: false, class: "col-lg-12" },
    ];
  }
  viewclosedtaxLots() {
    return [
      { id: 'startDate', controlType: 3, label: 'start_date', viewname: 'toDate', name: 'toDate', order: 7, value: null, required: false, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'end_date', viewname: 'toDate', name: 'toDate', order: 7, value: null, required: false, class: "col-lg-6" },
      { id: 'fundName', controlType: 2, label: 'fund', viewname: 'fundName', name: 'fundName', order: 4, value: null, required: false, fill: this.fundList, readonly: false, class: "col-lg-6" },
      { id: 'accountNumber', controlType: 2, label: 'broker_account', viewname: 'custodianBroker', name: 'custodianBroker', order: 6, value: null, required: false, fill: this.custodianAccounts, class: "col-lg-6" },
      { id: 'ticker', controlType: 1, label: 'ticker', viewname: 'ticker', name: 'ticker', order: 5, value: null, required: false, readonly: false, class: "col-lg-12" },
    ];
  }
  viewtotalgl() {
    return [
      { id: 'startDate', controlType: 3, label: 'start_date', viewname: 'toDate', name: 'toDate', order: 7, value: null, required: false, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'end_date', viewname: 'toDate', name: 'toDate', order: 7, value: null, required: false, class: "col-lg-6" },
      { id: 'fundName', controlType: 2, label: 'fund', viewname: 'fundName', name: 'fundName', order: 4, value: null, required: false, fill: this.fundList, readonly: false, class: "col-lg-6" },
      { id: 'custodianBrokerAccount', controlType: 2, label: 'broker_account', viewname: 'custodianBroker', name: 'custodianBroker', order: 6, value: null, required: false, fill: this.custodianAccounts, class: "col-lg-6" },
      { id: 'ticker', controlType: 1, label: 'ticker', viewname: 'ticker', name: 'ticker', order: 5, value: null, required: false, readonly: false, class: "col-lg-12" },
    ];
  }
  rapidViewtotalgl() {
    return [
      { id: 'startDate', controlType: 3, label: 'start_date', viewname: 'toDate', name: 'toDate', order: 7, value: null, required: false, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'end_date', viewname: 'toDate', name: 'toDate', order: 7, value: null, required: false, class: "col-lg-6" },
      { id: 'fundName', controlType: 2, label: 'fund', viewname: 'fundName', name: 'fundName', order: 4, value: null, required: false, fill: this.fundList, readonly: false, class: "col-lg-6" },
      { id: 'custodianBrokerAccount', controlType: 2, label: 'broker_account', viewname: 'custodianBroker', name: 'custodianBroker', order: 6, value: null, required: false, fill: this.custodianAccounts, class: "col-lg-6" },
      { id: 'ticker', controlType: 1, label: 'ticker', viewname: 'ticker', name: 'ticker', order: 5, value: null, required: false, readonly: false, class: "col-lg-12" },
    ];
  }
  journalentry() {
    return [
      { id: 'startDate', controlType: 3, label: 'start_date', viewname: 'toDate', name: 'toDate', order: 7, value: null, required: false, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'end_date', viewname: 'toDate', name: 'toDate', order: 7, value: null, required: false, class: "col-lg-6" },
      { id: 'fund', controlType: 2, label: 'fund', viewname: 'fundModule', name: 'fundName', order: 4, value: null, required: false, fill: this.fundList, readonly: false, class: "col-lg-6" },
      { id: 'moduleName', controlType: 7, label: 'module_name', viewname: 'custodianBroker', name: 'custodianBroker', order: 6, value: [{ label: 'manualJE', value: 'manualJE' }], required: true, fill: this.LookupList, class: "col-lg-6  ml-3 dropdown-bgcolors" },
    ];
  }
  RapidJournalentry() {
    return [
      { id: 'startDate', controlType: 3, label: 'start_date', viewname: 'toDate', name: 'toDate', order: 7, value: null, required: false, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'end_date', viewname: 'toDate', name: 'toDate', order: 7, value: null, required: false, class: "col-lg-6" },
      { id: 'fund', controlType: 2, label: 'fund', viewname: 'fundModule', name: 'fundName', order: 4, value: null, required: false, fill: this.fundList, readonly: false, class: "col-lg-6" },
      { id: 'custodian', controlType: 7, label: 'custodian', viewname: 'custodianNumber', name: 'custodianNumber', value: [], order: 4, required: false, fill: this.custodianList, readonly: false, appearance: "fill", class: "col-lg-6 pl-5 pt-0 pr-1" },
      { id: 'moduleName', controlType: 7, label: 'module_name', viewname: 'custodianBroker', name: 'custodianBroker', order: 6, value: [{ label: 'manualJE', value: 'manualJE' }], required: true, fill: this.LookupList, class: "col-lg-12  ml-3 dropdown-bgcolors pt-0" },
    ];
  }
  reBudgets() {
    return [
      { id: 'startDate', controlType: 43, label: 'start_date', viewname: 'toDate', name: 'toDate', order: 7, value: null, required: true, class: "col-lg-6 date-picker" },
      { id: 'endDate', controlType: 43, label: 'end_date', viewname: 'toDate', name: 'toDate', order: 7, value: null, required: true, class: "col-lg-6 date-picker" },
      { id: 'entityName', controlType: 2, label: 'entity', viewname: 'entityName', name: 'entityName', order: 4, value: null, required: false, fill: this.fundList, readonly: false, class: "col-lg-6" },
      { id: 'bookNo', controlType: 2, label: 'book_no.', viewname: 'bookNo.', name: 'bookNo', order: 4, value: null, required: false, fill: this.custodianList, readonly: false, class: "col-lg-6" },
    ];
  }
  rapidReBudgets() {
    return [
      { id: 'startDate', controlType: 43, label: 'start_date', viewname: 'toDate', name: 'toDate', order: 7, value: null, required: true, class: "col-lg-6 date-picker" },
      { id: 'endDate', controlType: 43, label: 'end_date', viewname: 'toDate', name: 'toDate', order: 7, value: null, required: true, class: "col-lg-6 date-picker" },
      { id: 'entityName', controlType: 2, label: 'entity', viewname: 'entityName', name: 'entityName', order: 4, value: null, required: false, fill: this.fundList, readonly: false, class: "col-lg-6" },
      { id: 'bookNo', controlType: 2, label: 'book_no.', viewname: 'bookNo.', name: 'bookNo', order: 4, value: null, required: false, fill: this.custodianList, readonly: false, class: "col-lg-6" },
    ];
  }

  nonTradeActivity() {
    return [
      { id: 'startDate', controlType: 3, label: 'start_date', viewname: 'toDate', name: 'toDate', order: 7, value: null, required: false, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'end_date', viewname: 'toDate', name: 'toDate', order: 7, value: null, required: false, class: "col-lg-6" },
      { id: 'fund', controlType: 2, label: 'fund', viewname: 'fundName', name: 'fundName', order: 4, value: null, required: false, fill: this.fundList, readonly: false, class: "col-lg-6" },
    ];
  }
  rapidNonTradeActivity() {
    return [
      { id: 'startDate', controlType: 3, label: 'start_date', viewname: 'toDate', name: 'toDate', order: 7, value: null, required: false, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'end_date', viewname: 'toDate', name: 'toDate', order: 7, value: null, required: false, class: "col-lg-6" },
      { id: 'fund', controlType: 2, label: 'fund', viewname: 'fundName', name: 'fundName', order: 4, value: null, required: false, fill: this.fundList, readonly: false, class: "col-lg-6" },
      { id: 'custodian', controlType: 7, label: 'custodian', viewname: 'custodianNumber', name: 'custodianNumber', value: [], order: 4, required: false, fill: this.custodianList, readonly: false, appearance: "fill", class: "col-lg-6 pl-5 pt-0 pr-1" },
    ];
  }
  fetchBankTransaction() {
    return [
      { id: 'startDate', controlType: 3, label: 'start_date', viewname: 'toDate', name: 'toDate', order: 7, value: null, required: false, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'end_date', viewname: 'toDate', name: 'toDate', order: 7, value: null, required: false, class: "col-lg-6" },
      { id: 'fund', controlType: 2, label: 'fund', viewname: 'fundName', name: 'fundName', order: 4, value: null, required: false, fill: this.fundList, readonly: false, class: "col-lg-6" },
      { id: 'custodian', controlType: 7, label: 'custodian', viewname: 'custodianNumber', name: 'custodianNumber', value: [], order: 4, required: false, fill: this.custodianList, readonly: false, appearance: "fill", class: "col-lg-6 pl-5 pt-0 pr-1" },
    ];
  }
  viewDataCollection() {
    return [
      { id: 'genericdata', controlType: 2, label: 'generic_data', viewname: 'genericdata', name: 'genericdata', order: 1, value: '', required: false, appearance: "fill", fill: this.realItems, readonly: false, class: "col-lg-12  custominput genricdata" },
      { id: 'clientName', controlType: 2, label: 'fund_name', name: 'clientName', viewName: 'clientName', order: 3, value: '', required: false, appearance: "fill", fill: this.clientList, disabled: false, class: "col-lg-6  custominput", style: "display:block;" },
      { id: 'exchangeName', controlType: 2, label: 'exchange', viewname: 'exchangeName', name: 'exchangeName', order: 2, value: '', required: false, appearance: "fill", fill: this.exchangeList, readonly: false, class: "col-lg-6  custominput" },
      { id: 'startDate', controlType: 3, label: 'start_date', viewname: 'startDate', name: 'startDate', order: 3, value: null, required: true, class: "col-lg-6  custominput" },
      { id: 'endDate', controlType: 3, label: 'end_date', viewname: 'endDate', name: 'endDate', order: 4, value: null, required: true, class: "col-lg-6  custominput" }
    ]
  }

  dataMonitoring() {
    return [
     // { id: 'enterpriseName', controlType: 2, label: 'enterprise_name', viewname: 'enterpriseName', name: 'enterpriseName', order: 1, value: '', required: false, appearance: "fill", fill: this.enterpriseList, readonly: false, class: "col-lg-12  custominput genricdata" },
      { id: 'fundName', controlType: 2, label: 'fund_name', name: 'fundName', viewName: 'fundName', order: 3, value: '', required: false, appearance: "fill", fill: this.accountList, disabled: false, class: "col-lg-6  custominput", style: "display:block;" },
      { id: 'api', controlType: 2, label: 'exchange', viewname: 'api', name: 'api', order: 2, value: '', required: false, appearance: "fill", fill: this.clientList, readonly: false, class: "col-lg-6  custominput" },
      { id: 'status', controlType: 2, label: 'status', viewname: 'status', name: 'status', order: 2, value: this.statusList[4].value, required: true, appearance: "fill", fill: this.statusList, readonly: false, class: "col-lg-6  custominput" },
      { id: 'clientName', controlType: 7, label: 'client_name', viewname: 'clientName', name: 'clientName', order: 2, value: [], required: false, appearance: "fill", fill: this.dataMonitoringclientList, readonly: false, class: "col-lg-6  ml-3 dropdown-bgcolors" },
      { id: 'startDate', controlType: 12, label: 'start_date', viewname: 'startDate', name: 'startDate', order: 3, value: null, required: false, class: "col-lg-6  custominput" },
      { id: 'endDate', controlType: 12, label: 'end_date', viewname: 'endDate', name: 'endDate', order: 4, value: null, required: false, class: "col-lg-6  custominput" },
      { id: 'datePeriod', controlType: 9, label: 'data_period', viewname: 'datePeriod', name: 'datePeriod', value: true, binary: false, order: 12, disabled: false, class: "col-lg-6 " }

    ]
  }
  dataMonitoringinput() {
    return [
     // { id: 'enterpriseName', controlType: 2, label: 'enterprise_name', viewname: 'enterpriseName', name: 'enterpriseName', order: 1, value: '', required: false, appearance: "fill", fill: this.enterpriseList, readonly: false, class: "col-lg-12  custominput genricdata" },
      { id: 'fundName', controlType: 2, label: 'fund_name', name: 'fundName', viewName: 'fundName', order: 3, value: '', required: false, appearance: "fill", fill: this.accountList, disabled: false, class: "col-lg-6  custominput", style: "display:block;" },
      { id: 'api', controlType: 2, label: 'exchange', viewname: 'api', name: 'api', order: 2, value: '', required: false, appearance: "fill", fill: this.clientList, readonly: false, class: "col-lg-6  custominput" },
      { id: 'status', controlType: 2, label: 'status', viewname: 'status', name: 'status', order: 2, value: this.statusList[4].value, required: true, appearance: "fill", fill: this.statusList, readonly: false, class: "col-lg-6  custominput" },
      { id: 'clientName', controlType: 7, label: 'client_name', viewname: 'clientName', name: 'clientName', order: 2, value: [], required: false, appearance: "fill", fill: this.dataMonitoringclientList, readonly: false, class: "col-lg-6  ml-3 dropdown-bgcolors" },
      { id: 'startDate', controlType: 12, label: 'start_date', viewname: 'startDate', name: 'startDate', order: 3, value: null, required: false, class: "col-lg-6  custominput" },
      { id: 'endDate', controlType: 12, label: 'end_date', viewname: 'endDate', name: 'endDate', order: 4, value: null, required: false, class: "col-lg-6  custominput" },
      { id: 'datePeriod', controlType: 9, label: 'data_period', viewname: 'datePeriod', name: 'datePeriod', value: true, binary: false, order: 12, disabled: false, class: "col-lg-6 " }

    ]
  }
  seamlessmarketdata() {
    return [
      { id: 'startDate', controlType: 3, label: 'start_date', viewname: 'startDate', name: 'startDate', order: 3, value: null, required: false, class: "col-lg-6  custominput" },
      { id: 'endDate', controlType: 3, label: 'end_date', viewname: 'endDate', name: 'endDate', order: 4, value: null, required: false, class: "col-lg-6  custominput" },
      { id: 'dataSourceIdentifier', controlType: 1, label: 'Identifier', viewname: 'dataSourceIdentifier', name: 'dataSourceIdentifier', order: 1, value: '', required: false, appearance: "fill", readonly: false, class: "col-lg-6 custominput" },
      { id: 'dataSource', controlType: 2, label: 'source', name: 'dataSource', viewName: 'dataSource', order: 3, value: null, required: false, appearance: "fill", fill: this.commonService.dataSource, disabled: false, class: "col-lg-6  custominput", style: "display:block;" }

    ]
  }

  RapidSeamlessmarketdata() {
    return [
      { id: 'startDate', controlType: 3, label: 'start_date', viewname: 'startDate', name: 'startDate', order: 3, value: null, required: false, class: "col-lg-6  custominput" },
      { id: 'endDate', controlType: 3, label: 'end_date', viewname: 'endDate', name: 'endDate', order: 4, value: null, required: false, class: "col-lg-6  custominput" },
      { id: 'dataSourceIdentifier', controlType: 1, label: 'Identifier', viewname: 'dataSourceIdentifier', name: 'dataSourceIdentifier', order: 1, value: '', required: false, appearance: "fill", readonly: false, class: "col-lg-6 custominput" },
      { id: 'dataSource', controlType: 2, label: 'source', name: 'dataSource', viewName: 'dataSource', order: 3, value: null, required: false, appearance: "fill", fill: this.commonService.dataSource, disabled: false, class: "col-lg-6  custominput", style: "display:block;" }

    ]
  }

  globalSMEquitiesList() {
    return [
      { id: 'enterpriseStatusGlobal', controlType: 7, label: 'enterprise_status', viewname: 'enterprise_status', name: 'enterpriseStatusGlobal', order: 1, value: [{ label: 'Active', value: 'active' }], required: false, appearance: "fill", fill: this.enterpriseStatus, readonly: false, class: "col-lg-6  ml-3 dropdown-bgcolors" },
      { id: 'enterpriseTypeGlobal', controlType: 7, label: 'type_of_enterprise', viewname: 'type_of_enterprise', name: 'enterpriseTypeGlobal', order: 1, value: '', required: false, appearance: "fill", fill: this.enterpriseType, readonly: false, class: "col-lg-6  ml-3 dropdown-bgcolors" },
      //{ id: 'startDate', controlType: 3, label: 'Date of Creation', viewname: 'Date of Creation', name: 'Date of Creation', order: 3, value: null, required: false, class: "col-lg-6  custominput" },
      { id: 'endDate', controlType: 3, label: 'Date of Updation', viewname: 'Date of Updation', name: 'Date of Updation', order: 4, value: new Date(), required: false, class: "col-lg-6  custominput" },
      { id: 'missingFields', controlType: 9, label: 'Missing Reuters ID and QUODD ID', viewname: 'Missing Reuters ID and QUODD ID', name: 'missingFields', value: true, binary: true, order: 12, disabled: false, class: "col-lg-6 " },
    ]
  }
  globalSmOptionsList() {
    return [
      { id: 'enterpriseStatusGlobal', controlType: 7, label: 'enterprise_status', viewname: 'enterprise_status', name: 'enterpriseStatusGlobal', order: 1, value: [{ label: 'Active', value: 'active' }], required: false, appearance: "fill", fill: this.enterpriseStatus, readonly: false, class: "col-lg-6  ml-3 dropdown-bgcolors" },
      { id: 'enterpriseTypeGlobal', controlType: 7, label: 'type_of_enterprise', viewname: 'type_of_enterprise', name: 'enterpriseTypeGlobal', order: 1, value: '', required: false, appearance: "fill", fill: this.enterpriseType, readonly: false, class: "col-lg-6  ml-3 dropdown-bgcolors" },
      //{ id: 'startDate', controlType: 3, label: 'Date of Creation', viewname: 'Date of Creation', name: 'Date of Creation', order: 3, value: null, required: false, class: "col-lg-6  custominput" },
      { id: 'endDate', controlType: 3, label: 'Date of Updation', viewname: 'Date of Updation', name: 'Date of Updation', order: 4, value: new Date(), required: false, class: "col-lg-6  custominput" },
      { id: 'missingFields', controlType: 9, label: 'Missing Reuters ID and QUODD ID', viewname: 'Missing Reuters ID and QUODD ID', name: 'missingFields', value: true, binary: true, order: 12, disabled: false, class: "col-lg-6 " },
    ]
  }
  globalSmFixedIncomeList() {
    return [
      { id: 'enterpriseStatusGlobal', controlType: 7, label: 'enterprise_status', viewname: 'enterprise_status', name: 'enterpriseStatusGlobal', order: 1, value: [{ label: 'Active', value: 'active' }], required: false, appearance: "fill", fill: this.enterpriseStatus, readonly: false, class: "col-lg-6  ml-3 dropdown-bgcolors" },
      { id: 'enterpriseTypeGlobal', controlType: 7, label: 'type_of_enterprise', viewname: 'type_of_enterprise', name: 'enterpriseTypeGlobal', order: 1, value: '', required: false, appearance: "fill", fill: this.enterpriseType, readonly: false, class: "col-lg-6  ml-3 dropdown-bgcolors" },
      //{ id: 'startDate', controlType: 3, label: 'Date of Creation', viewname: 'Date of Creation', name: 'Date of Creation', order: 3, value: null, required: false, class: "col-lg-6  custominput" },
      { id: 'endDate', controlType: 3, label: 'Date of Updation', viewname: 'Date of Updation', name: 'Date of Updation', order: 4, value: new Date(), required: false, class: "col-lg-6  custominput" },
      { id: 'missingFields', controlType: 9, label: 'Missing Reuters ID and QUODD ID', viewname: 'Missing Reuters ID and QUODD ID', name: 'missingFields', value: true, binary: true, order: 12, disabled: false, class: "col-lg-6 " },
    ]
  }
  globalSmFxForwardList() {
    return [
      { id: 'enterpriseStatusGlobal', controlType: 7, label: 'enterprise_status', viewname: 'enterprise_status', name: 'enterpriseStatusGlobal', order: 1, value: [{ label: 'Active', value: 'active' }], required: false, appearance: "fill", fill: this.enterpriseStatus, readonly: false, class: "col-lg-6  ml-3 dropdown-bgcolors" },
      { id: 'enterpriseTypeGlobal', controlType: 7, label: 'type_of_enterprise', viewname: 'type_of_enterprise', name: 'enterpriseTypeGlobal', order: 1, value: '', required: false, appearance: "fill", fill: this.enterpriseType, readonly: false, class: "col-lg-6  ml-3 dropdown-bgcolors" },
      //{ id: 'startDate', controlType: 3, label: 'Date of Creation', viewname: 'Date of Creation', name: 'Date of Creation', order: 3, value: null, required: false, class: "col-lg-6  custominput" },
      { id: 'endDate', controlType: 3, label: 'Date of Updation', viewname: 'Date of Updation', name: 'Date of Updation', order: 4, value: new Date(), required: false, class: "col-lg-6  custominput" },
      { id: 'missingFields', controlType: 9, label: 'Missing Reuters ID and QUODD ID', viewname: 'Missing Reuters ID and QUODD ID', name: 'missingFields', value: true, binary: true, order: 12, disabled: false, class: "col-lg-6 " },
    ]
  }
  globalSmPrivateFundList() {
    return [
      { id: 'enterpriseStatusGlobal', controlType: 7, label: 'enterprise_status', viewname: 'enterprise_status', name: 'enterpriseStatusGlobal', order: 1, value: [{ label: 'Active', value: 'active' }], required: false, appearance: "fill", fill: this.enterpriseStatus, readonly: false, class: "col-lg-6  ml-3 dropdown-bgcolors" },
      { id: 'enterpriseTypeGlobal', controlType: 7, label: 'type_of_enterprise', viewname: 'type_of_enterprise', name: 'enterpriseTypeGlobal', order: 1, value: '', required: false, appearance: "fill", fill: this.enterpriseType, readonly: false, class: "col-lg-6  ml-3 dropdown-bgcolors" },
      //{ id: 'startDate', controlType: 3, label: 'Date of Creation', viewname: 'Date of Creation', name: 'Date of Creation', order: 3, value: null, required: false, class: "col-lg-6  custominput" },
      { id: 'endDate', controlType: 3, label: 'Date of Updation', viewname: 'Date of Updation', name: 'Date of Updation', order: 4, value: new Date(), required: false, class: "col-lg-6  custominput" },
      { id: 'missingFields', controlType: 9, label: 'Missing Reuters ID and QUODD ID', viewname: 'Missing Reuters ID and QUODD ID', name: 'missingFields', value: true, binary: true, order: 12, disabled: false, class: "col-lg-6 " },
    ]
  }
  globalSmFutureList() {
    return [
      { id: 'enterpriseStatusGlobal', controlType: 7, label: 'enterprise_status', viewname: 'enterprise_status', name: 'enterpriseStatusGlobal', order: 1, value: [{ label: 'Active', value: 'active' }], required: false, appearance: "fill", fill: this.enterpriseStatus, readonly: false, class: "col-lg-6  ml-3 dropdown-bgcolors" },
      { id: 'enterpriseTypeGlobal', controlType: 7, label: 'type_of_enterprise', viewname: 'type_of_enterprise', name: 'enterpriseTypeGlobal', order: 1, value: '', required: false, appearance: "fill", fill: this.enterpriseType, readonly: false, class: "col-lg-6  ml-3 dropdown-bgcolors" },
      //{ id: 'startDate', controlType: 3, label: 'Date of Creation', viewname: 'Date of Creation', name: 'Date of Creation', order: 3, value: null, required: false, class: "col-lg-6  custominput" },
      { id: 'endDate', controlType: 3, label: 'Date of Updation', viewname: 'Date of Updation', name: 'Date of Updation', order: 4, value: new Date(), required: false, class: "col-lg-6  custominput" },
      { id: 'missingFields', controlType: 9, label: 'Missing Reuters ID and QUODD ID', viewname: 'Missing Reuters ID and QUODD ID', name: 'missingFields', value: true, binary: true, order: 12, disabled: false, class: "col-lg-6 " },
    ]
  }

  globalSmRealestateList() {
    return [
      { id: 'enterpriseStatusGlobal', controlType: 7, label: 'enterprise_status', viewname: 'enterprise_status', name: 'enterpriseStatusGlobal', order: 1, value: [{ label: 'Active', value: 'active' }], required: false, appearance: "fill", fill: this.enterpriseStatus, readonly: false, class: "col-lg-6  ml-3 dropdown-bgcolors" },
      { id: 'enterpriseTypeGlobal', controlType: 7, label: 'type_of_enterprise', viewname: 'type_of_enterprise', name: 'enterpriseTypeGlobal', order: 1, value: '', required: false, appearance: "fill", fill: this.enterpriseType, readonly: false, class: "col-lg-6  ml-3 dropdown-bgcolors" },
      //{ id: 'startDate', controlType: 3, label: 'Date of Creation', viewname: 'Date of Creation', name: 'Date of Creation', order: 3, value: null, required: false, class: "col-lg-6  custominput" },
      { id: 'endDate', controlType: 3, label: 'Date of Updation', viewname: 'Date of Updation', name: 'Date of Updation', order: 4, value: new Date(), required: false, class: "col-lg-6  custominput" }
    ]
  }
  globalSmPools() {
    return [
      { id: 'enterpriseStatusGlobal', controlType: 7, label: 'enterprise_status', viewname: 'enterprise_status', name: 'enterpriseStatusGlobal', order: 1, value: [{ label: 'Active', value: 'active' }], required: false, appearance: "fill", fill: this.enterpriseStatus, readonly: false, class: "col-lg-6  ml-3 dropdown-bgcolors" },
      { id: 'enterpriseTypeGlobal', controlType: 7, label: 'type_of_enterprise', viewname: 'type_of_enterprise', name: 'enterpriseTypeGlobal', order: 1, value: '', required: false, appearance: "fill", fill: this.enterpriseType, readonly: false, class: "col-lg-6  ml-3 dropdown-bgcolors" },
      //{ id: 'startDate', controlType: 3, label: 'Date of Creation', viewname: 'Date of Creation', name: 'Date of Creation', order: 3, value: null, required: false, class: "col-lg-6  custominput" },
      { id: 'endDate', controlType: 3, label: 'Date of Updation', viewname: 'Date of Updation', name: 'Date of Updation', order: 4, value: new Date(), required: false, class: "col-lg-6  custominput" },
      { id: 'missingFields', controlType: 9, label: 'Missing Reuters ID and QUODD ID', viewname: 'Missing Reuters ID and QUODD ID', name: 'missingFields', value: true, binary: true, order: 12, disabled: false, class: "col-lg-6 " },
    ]
  }
  globalCrypto() {
    return [
      { id: 'enterpriseStatusGlobal', controlType: 7, label: 'enterprise_status', viewname: 'enterprise_status', name: 'enterpriseStatusGlobal', order: 1, value: [{ label: 'Active', value: 'active' }], required: false, appearance: "fill", fill: this.enterpriseStatus, readonly: false, class: "col-lg-6  ml-3 dropdown-bgcolors" },
      { id: 'enterpriseTypeGlobal', controlType: 7, label: 'type_of_enterprise', viewname: 'type_of_enterprise', name: 'enterpriseTypeGlobal', order: 1, value: '', required: false, appearance: "fill", fill: this.enterpriseType, readonly: false, class: "col-lg-6  ml-3 dropdown-bgcolors" },
      //{ id: 'startDate', controlType: 3, label: 'Date of Creation', viewname: 'Date of Creation', name: 'Date of Creation', order: 3, value: null, required: false, class: "col-lg-6  custominput" },
      { id: 'endDate', controlType: 3, label: 'Date of Updation', viewname: 'Date of Updation', name: 'Date of Updation', order: 4, value: new Date(), required: false, class: "col-lg-6  custominput" },
      { id: 'missingFields', controlType: 9, label: 'Missing Reuters ID and QUODD ID', viewname: 'Missing Reuters ID and QUODD ID', name: 'missingFields', value: true, binary: true, order: 12, disabled: false, class: "col-lg-6 " },
    ]
  }
  capActivityTypeList = [
    { label: "Disable Auto Update Via Allocation Update", value: { "id": "Disable Auto Update Via Allocation Update" } },
    { label: "Redemption Activity Update", value: { "id": "Redemption Activity Update" } },
    { label: "Subscription Activity Update", value: { "id": "Subscription Activity Update" } }
  ];
  capActivityFundList: [];
  capActivityTransactionList = [
    { "label": "Subscription - First Time", "value": { "id": "Subscription - First Time" } },
    { "label": "Subscription - Additional", "value": { "id": "Subscription - Additional" } },
    { "label": "Subscription - Transfer", "value": { "id": "Subscription - Transfer" } },
    { "label": "Redemption - Full", "value": { "id": "Redemption - Full" } },
    { "label": "Distribution - Return of Capital", "value": { "id": "Distribution - Return of Capital" } },
    { "label": "Distribution - Return of Capital (Recallable)", "value": { "id": "Distribution - Return of Capital (Recallable)" } },
    { "label": "Distributions - Others", "value": { "id": "Distributions - Others" } },
    { "label": "Redemption - Partial - by share or Unit", "value": { "id": "Redemption - Partial - by share or Unit" } },
    { "label": "Redemption - Partial by Amount", "value": { "id": "Redemption - Partial by Amount" } },
    { "label": "Redemption - Partial - by % value of Account", "value": { "id": "Redemption - Partial - by % value of Account" } },
    { "label": "Redemption - Transfer", "value": { "id": "Redemption - Transfer" } },
    { "label": "Transfer - Taxable", "value": { "id": "Transfer - Taxable" } },
    { "label": "Transfer - Tax free", "value": { "id": "Transfer - Tax free" } },
    { "label": "Assignment - taxable", "value": { "id": "Assignment - taxable" } },
    { "label": "Assignment - tax free", "value": { "id": "Assignment - tax free" } },
    { "label": "Distribution - Income", "value": { "id": "Distribution - Income" } },
    { "label": "Distribution - capital", "value": { "id": "Distribution - capital" } },
    { "label": "Commitment", "value": { "id": "Commitment" } },
    { "label": "Notional Addition", "value": { "id": "Notional Addition" } },
    { "label": "Notional Withdrawal", "value": { "id": "Notional Withdrawal" } },
    { "label": "Distribution - Principal", "value": { "id": "Distribution - Principal" } },
    { "label": "Distribution - Interest", "value": { "id": "Distribution - Interest" } },
    { "label": "Capital Call", "value": { "id": "Capital Call" } },
    { "label": "Capital Call - Expense", "value": { "id": "Capital Call - Expense" } },
    { "label": "Capital Call - Others", "value": { "id": "Capital Call - Others" } },
    { "label": "Reinvestment", "value": { "id": "Reinvestment" } },

  ];
  disableAutoUpdateViaAllocationUpdate() {
    return [
      { id: 'startDate', controlType: 3, label: 'start_date', viewname: 'toDate', name: 'toDate', order: 7, value: null, required: false, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'end_date', viewname: 'toDate', name: 'toDate', order: 7, value: null, required: false, class: "col-lg-6" },
      { id: 'fund', controlType: 2, label: 'fund', viewname: 'fund', name: 'fund', order: 4, value: null, required: false, fill: this.capActivityFundList, readonly: false, class: "col-lg-6" },
      { id: 'transactionType', controlType: 2, label: 'trans_type', viewname: 'transactionType', name: 'transactionType', order: 6, value: null, required: false, fill: [], class: "col-lg-6" },
      // { id: 'activityType', controlType: 2, label: 'act_type', viewname: 'activityType', name: 'activityType', order: 6, value: null, required: false, disabled: false, fill: this.capActivityTypeList, class: "col-lg-6" },
    ];
  }

  redemptionActivityUpdate() {
    return [
      { id: 'startDate', controlType: 3, label: 'start_date', viewname: 'toDate', name: 'toDate', order: 7, value: null, required: false, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'end_date', viewname: 'toDate', name: 'toDate', order: 7, value: null, required: false, class: "col-lg-6" },
      { id: 'fund', controlType: 2, label: 'fund', viewname: 'fund', name: 'fund', order: 4, value: null, required: false, fill: this.capActivityFundList, readonly: false, class: "col-lg-6" },
      { id: 'transactionType', controlType: 2, label: 'trans_type', viewname: 'transactionType', name: 'transactionType', order: 6, value: null, required: false, fill: [], class: "col-lg-6" },
      // { id: 'activityType', controlType: 2, label: 'act_type', viewname: 'activityType', name: 'activityType', order: 6, value: null, required: false, disabled: false, fill: this.capActivityTypeList, class: "col-lg-6" },
    ];
  }

  subscriptionActivityUpdate() {
    return [
      { id: 'startDate', controlType: 3, label: 'start_date', viewname: 'toDate', name: 'toDate', order: 7, value: null, required: false, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'end_date', viewname: 'toDate', name: 'toDate', order: 7, value: null, required: false, class: "col-lg-6" },
      { id: 'fund', controlType: 2, label: 'fund', viewname: 'fund', name: 'fund', order: 4, value: null, required: false, fill: this.capActivityFundList, readonly: false, class: "col-lg-6" },
      { id: 'transactionType', controlType: 2, label: 'trans_type', viewname: 'transactionType', name: 'transactionType', order: 6, value: null, required: false, fill: [], class: "col-lg-6" },
      // { id: 'activityType', controlType: 2, label: 'act_type', viewname: 'activityType', name: 'activityType', order: 6, value: null, required: false, disabled: false, fill: this.capActivityTypeList, class: "col-lg-6" },
    ];
  }

  fetchCsdBankTransactionPendingReview() {
    return [
      { id: 'startDate', controlType: 3, label: 'start_date', viewname: 'toDate', name: 'toDate', order: 7, value: null, required: false, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'end_date', viewname: 'toDate', name: 'toDate', order: 7, value: null, required: false, class: "col-lg-6" },
      { id: 'fund', controlType: 7, label: 'fund', viewname: 'fundName', name: 'fundName', order: 4, value: null, required: true, fill: this.fundList, readonly: false, class: "col-lg-6 ml-3 dropdown-bgcolors" },
      { id: 'custodian', controlType: 2, label: 'custodian', viewname: 'custodianNumber', name: 'custodianNumber', value: [], order: 4, required: false, fill: this.custodianList, readonly: false, appearance: "fill", class: "col-lg-6 pl-5 pt-0 pr-1" },
    ];
  }


  getformControlsDetail(componentName) {
    this.componentName = componentName;
    return new Promise(resolve => {
      this.gridFilterDropdownService.fetchMultipleApiBasic().subscribe((response) => {
        response[0].resultList = response[0].resultList || [];
        response[1].resultList = response[1].resultList || [];
        response[2].resultList = response[2].resultList || [];
        this.fundList = response[0].resultList.length ? response[0].resultList.map(element => ({ label: element.fundName == null ? element.fundAlias : element.fundName, value: element.id, alias: element.fundAlias })) : [];
        let fundList1 = [];
        this.fundList.forEach(element => {
          fundList1.push(element.value);
        });
        var data = {
          "fundlist": _.map(fundList1, function (element, key) { return element }),
        }
        this.gridFilterDropdownService.fetchCustodianList(data).subscribe(res => {
          this.custodianList = res.length != 0 ? res.map(element => ({ label: element.accountNumber != null ? element.accountNumber : '', value: { "id": element.id } })) : [];
          this.custodianAccounts = response[1].map(element => ({ label: element, value: element }));
          this.LookupList = response[2].resultList.map(element => ({ label: element.fields[0].value, value: element.fields[0].value }));
          let createOpenTaxLotsData = this[this.componentName]();
          resolve(createOpenTaxLotsData);

        })
      })
    })
  }
  cryptoformControlsDetail(componentName) {
    this.componentName = componentName;
    return new Promise(resolve => {
      this.gridFilterDropdownService.cryptoMultipleApiBasic().subscribe((response) => {
        var response3 = response[2].length ? response[2].map(element => ({ label: element.name, value: element.name, clientId: element._id })) : [];
        var response4 = response[3].length ? response[3].map(element => ({ label: element.fundAlias, value: element.id })) : [];
        var response0 = response[0].length ? response[0].map(element => ({ label: element.fundAlias, value: element.id })) : [];
        var response1 = response[1].length ? response[1].map(element => ({ label: element.name, value: element.name, clientId: element._id })) : [];
        this.exchangeList = _.uniqWith(response1.concat(response3), _.isEqual)
        this.clientList = _.uniqWith(response0.concat(response4), _.isEqual)
        let createOpenTaxLotsData = this[this.componentName]();
        resolve(createOpenTaxLotsData);
      })
    })
  }
  budgetformControlsDetail(componentName) {
    this.componentName = componentName;
    return new Promise(resolve => {
      this.gridFilterDropdownService.budgetMultipleApiBasic().subscribe((response) => {
        response[0].resultList = response[0].resultList || [];
        response[1].data = response[1].data || [];
        this.fundList = response[0].resultList.length ? response[0].resultList.map(element => ({ label: element.fundName == null ? element.fundAlias : element.fundName, value: element.id, alias: element.fundAlias })) : [];
        this.fundList = this.fundList.sort((a, b) => a.label.localeCompare(b.label));
        this.custodianList = response[1].data.map(element => ({ label: element.account, value: element.id }));
        let createBudgetData = this[this.componentName]();
        resolve(createBudgetData);
        
      })
    })
  }
  dataMonitoringFormControlsDetail(componentName) {
    this.componentName = componentName;
    return new Promise(resolve => {
      this.gridFilterDropdownService.dataMonitoringMultipleApiBasic().subscribe((response) => {
       // this.enterpriseList = response[0].length ? response[0].map(element => ({ label: element.enterpriseName, value: element.enterpriseId })) : [];
        this.accountList = response[0].resultList.length ? response[0].resultList.map(element => ({ label: element.fundName == null ? element.fundAlias : element.fundName, value: element.id, alias: element.fundAlias })) : [];
        this.clientList = response[1].length ? response[1].map(element => ({ label: element.name, value: element._id })) : [];
        this.dataMonitoringclientList = response[2] && (response[2].length ? response[2].map(element => ({ label: element.name, value: element._id })) : []) || [];
        let dataMonitoringData = this[this.componentName]();
        resolve(dataMonitoringData);

      })
    })
  }

  capitalActivityformControlsDetail(componentName) {
    this.componentName = componentName;
    return new Promise(resolve => {
      this.commonService.getSubCategory().subscribe((res) => {
        if (res == "Disable Auto Update Via Allocation Update") {
          this.gridFilterDropdownService.fetchFundList().subscribe((response) => {
            let filteredTransactionType = []
            this.capActivityFundList = response && response.resultList && response.resultList.length ? response.resultList.map(element => ({ label: element.fundName == null ? element.fundAlias : element.fundName, value: element.id, alias: element.fundAlias })) : [];
            let createFundActivityData = this[this.componentName]();

            createFundActivityData.forEach(element => {
              if (element.id == "transactionType") {
                const substring = "Redemption";
                filteredTransactionType = this.capActivityTransactionList.filter(ele => ele.value?.id.includes(substring));
                createFundActivityData[3] = { id: 'transactionType', controlType: 2, label: 'trans_type', viewname: 'transactionType', name: 'transactionType', order: 6, value: null, required: false, fill: filteredTransactionType, class: "col-lg-6" }
                resolve(createFundActivityData);
              }
            });
          });
        }
      })
    })
  }

  redemptionActivityformControlsDetail(componentName) {
    this.componentName = componentName;
    return new Promise(resolve => {
      this.commonService.getSubCategory().subscribe((res) => {
        if (res == "Redemption Activity Update") {
          this.gridFilterDropdownService.fetchFundList().subscribe((response) => {
            let filteredTransactionType = []
            this.capActivityFundList = response && response.resultList && response.resultList.length ? response.resultList.map(element => ({ label: element.fundName == null ? element.fundAlias : element.fundName, value: element.id, alias: element.fundAlias })) : [];
            let createFundActivityData = this[this.componentName]();
            createFundActivityData.forEach(element => {
              if (element.id == "transactionType") {
                const substring = "Redemption";
                filteredTransactionType = this.capActivityTransactionList.filter(ele => ele.value?.id.includes(substring));
                createFundActivityData[3] = { id: 'transactionType', controlType: 2, label: 'trans_type', viewname: 'transactionType', name: 'transactionType', order: 6, value: null, required: false, fill: filteredTransactionType, class: "col-lg-6" }
                resolve(createFundActivityData);
              }
            });
          })
        }
      })

    })
  }

  subscriptionActivityformControlsDetail(componentName) {
    this.componentName = componentName;
    return new Promise(resolve => {
      this.commonService.getSubCategory().subscribe((res) => {
        if (res == "Subscription Activity Update") {
          this.gridFilterDropdownService.fetchFundList().subscribe((response) => {
            let filteredTransactionType = []
            this.capActivityFundList = response && response.resultList && response.resultList.length ? response.resultList.map(element => ({ label: element.fundName == null ? element.fundAlias : element.fundName, value: element.id, alias: element.fundAlias })) : [];
            let createFundActivityData = this[this.componentName]();
            createFundActivityData.forEach(element => {
              if (element.id == "transactionType") {
                const substring = "Subscription";
                filteredTransactionType = this.capActivityTransactionList.filter(ele => ele.value?.id.includes(substring));
                createFundActivityData[3] = { id: 'transactionType', controlType: 2, label: 'trans_type', viewname: 'transactionType', name: 'transactionType', order: 6, value: null, required: false, fill: filteredTransactionType, class: "col-lg-6" }
                resolve(createFundActivityData);
              }
            });
          })
        }
      })
    })
  }

  csdBankTransactionFormControls(componentName) {
    this.componentName = componentName;
    return new Promise(resolve => {
      this.gridFilterDropdownService.fetchMultipleApiBasic().subscribe((response) => {
        response[0].resultList = response[0].resultList || [];
        response[1].resultList = response[1].resultList || [];
        response[2].resultList = response[2].resultList || [];
        this.fundList = response[0].resultList.length ? response[0].resultList.map(element => ({ label: element.fundName == null ? element.fundAlias : element.fundName, value: element.id, alias: element.fundAlias, csdFundId: element.csdFundId })) : [];
        this.csdBankTransFundList = this.fundList;
        let fundList1 = [];
        this.fundList.forEach(element => {
          fundList1.push(element.value);
        });
        var data = {
          "fundlist": _.map(fundList1, function (element, key) { return element }),
        }
        this.gridFilterDropdownService.fetchCustodianList(data).subscribe(res => {
          this.custodianList = res.length != 0 ? res.map(element => ({ label: element.accountNumber != null ? element.accountNumber : '', value: { "id": element.id } })) : [];
          this.custodianAccounts = response[1].map(element => ({ label: element, value: element }));
          this.LookupList = response[2].resultList.map(element => ({ label: element.fields[0].value, value: element.fields[0].value }));
          let csdBankTransPendingReview = this[this.componentName]();
          resolve(csdBankTransPendingReview);

        })
      })
    })
  }

  getStartEndDate(val: any) {
    let temp = val.split('LHRXJ10EWRdateZRHXJ10JFK');
    let startDate = new Date(temp[0]);
    let endDate = new Date(temp[1]);
    return { "processEpochDate": { 'startDate': moment(startDate).format('x'), 'endDate': moment(endDate).format('x') } };
  }

  getFilteredDataGridReq(evt?, gridApi?, topSearchClick?, clientFundId?) {
    if (!this.filteredData.length) {
      if (this.filteredDataevt == null) {
        this.pageNumber = 1;
        this.page = 0;
      }
      this.filteredDataevt = evt;
      this.filteredData = evt.api.rowModel.rowsToDisplay;
      this.filterData = true;

      this.isfilter.emit(this.filterData);
    }
    const filtredModel = evt.api.getFilterModel();
    if (this.hasOwnProperty('formDataTemp')) {
      if (this.componentName == 'fetchCsdBankTransactionPendingReview') {
        this.formDataTemp['custodianAccNo'] = this.formDataTemp['custodianAccNo'];
      }
      else {
        if (this.formDataTemp['custodianAccNo'] || this.formDataTemp['custodianName']) {
          let brokerName = [];
          let value = [];
          if (this.formDataTemp['custodianAccNo']) {
            value = this.formDataTemp['custodianAccNo']
          }
          else {
            value = this.formDataTemp['custodianName']
          }
          this.custodianList.forEach(el => {
            value.forEach(vale => {
              if (el.label == vale) {
                brokerName.push(el.value.id)
              }
            })
          })
          if (this.formDataTemp['custodianAccNo']) {
            this.formDataTemp['custodianAccNo'] = brokerName;
          }
          else {
            this.formDataTemp['custodianName'] = brokerName;
          }
        }
      }
    }

    let x = Object.entries(filtredModel);
    // var reqObj = {};
    if (x.length) {
      var key = x[0][0];
      let value = x[0][1]['filter'];
      let searchObject = this.commonService.getDataToFilter(evt, gridApi);
      let searchObject1 = this.commonService.getDataToFilter(evt, gridApi);
      if (Object.keys(this.searchObjectTemp).length != Object.keys(searchObject1).length) {
        this.pageNumber = 1;
        this.page = 0;
      }
      this.searchObjectTemp = searchObject1;
      if (this.componentName == 'viewDataCollection') {
        searchObject['filterList'] = [];
        searchObject
      }
      Object.keys(searchObject).forEach(x => {
        switch (x) {

          case "brokerAccount.accountNumber": {
            searchObject["TransientBrokerAccount"] = searchObject[x];
            delete searchObject[x];
            x = "TransientBrokerAccount"
            break;
          }
          case "fund.fundName": {
            if (this.componentName == 'nonTradeActivity' || this.componentName == 'rapidNonTradeActivity' || this.componentName == 'fetchBankTransaction' || this.componentName == 'incomeDistribution' || this.componentName == 'marketdata' || this.componentName == "rapidMarketdata" || this.componentName == 'viewCapitalCall' || this.componentName == 'rapidViewCapitalCall' || this.componentName == 'customRatio' || this.componentName == 'viewDistribution' || this.componentName == 'rapidViewDistribution' || this.componentName == 'viewclosedtaxLots' || this.componentName == 'journalentry' || this.componentName == 'RapidJournalentry' || this.componentName == 'custodianList' || this.componentName == 'custodianListLegacy') {
              searchObject["fund"] = searchObject[x];
              delete searchObject[x];
              x = "fund"
              break;
            }
            else if (this.componentName == "tradeList" || this.componentName == "rapidTradeList") {
              searchObject["TransientFund"] = searchObject[x];
              delete searchObject[x];
              x = "TransientFund"
              break;
            }
          }
          case "processISODate": {
            if (this.componentName != 'disableAutoUpdateViaAllocationUpdate' && this.componentName != 'redemptionActivityUpdate' && this.componentName != 'subscriptionActivityUpdate') {
              let date = this.getStartEndDate(searchObject.processISODate);
              searchObject['filterList'].push(date);
              delete searchObject[x];
              x = "processISODate"
              break;
            }
          }
          case "exchangeName": {
            if (this.componentName == 'viewDataCollection') {
              //  searchObject['filterList'].push({ "exchangeName": searchObject.exchangeName });
              this.exchangeList?.forEach(item => {
                if (item.value == searchObject.exchangeName) {
                  searchObject['filterList'].push({ "exchangeId": item.clientId });
                }
              })
              delete searchObject[x];
            }
            break;
          }
          case "clientName": {
            if (this.componentName == 'viewDataCollection') {
              searchObject['filterList'].push({ "fundId": searchObject.clientName });
              delete searchObject[x];
            }
            break;
          }
          case "exchanges.exchangeName": {
            searchObject["exchanges"] = searchObject[x];
            delete searchObject[x];
            x = "exchanges"
            break;
          }
          case "currency.isoCode": {
            searchObject["currency"] = searchObject[x];
            delete searchObject[x];
            x = "currency"
            break;
          }
          case "assetClasses.name": {
            searchObject["assetClasses"] = searchObject[x];
            delete searchObject[x];
            x = "assetClasses"
            break;
          }
          case "assetClass.name": {
            searchObject["TransientAssetClass"] = searchObject[x];
            delete searchObject[x];
            x = "TransientAssetClass"
            break;
          }
          case "clientLookup.value": {
            searchObject["primaryAssetClass"] = searchObject[x];
            delete searchObject[x];
            x = "primaryAssetClass"
            break;
          }
          case "fundName": {
            if (this.componentName != 'viewtotalgl' && this.componentName != 'rapidViewtotalgl' && this.componentName != 'viewPnlDistribution' && this.componentName != 'benchmarkirr' && this.componentName != 'rapidBenchmarkirr' && this.componentName != 'reportApproval' && this.componentName != 'runViewOpenTaxlots' && this.componentName != 'viewclosedtaxLots' && this.componentName != 'dailyMargin' && this.componentName != 'rapidDailyMargin'
              && this.componentName != 'FundList' && this.componentName != 'TLRegime' && this.componentName != 'rapidTLRegime' && this.componentName != 'Manager' && this.componentName != 'externalROR' && this.componentName != 'rapidExternalROR' && this.componentName != 'investorgroup' && this.componentName != "rapidInvestorgroup" && this.componentName != 'inputDataIntegritySummery' && this.componentName != 'reBudgets' && this.componentName != "rapidReBudgets") {
              searchObject["fund"] = searchObject[x];
              delete searchObject[x];
              x = "fund"
              break;
            }
            else if (this.componentName == 'reBudgets' || this.componentName == "rapidReBudgets") {
              searchObject["fundName"] = searchObject[x];
              x = "fundName"
              break;
            }
            else {
              searchObject["fundName"] = searchObject[x];
              x = "fundName"
              break;
            }
          }
          case "custodianAccNumber": {
            searchObject["custodianAccNumber"] = searchObject[x];
            x = "custodianAccNumber"
            break;
          }
          case "client_Ip": {
            searchObject["Client_Ip"] = searchObject[x];
            delete searchObject[x];
            x = "Client_Ip"
            break;
          }
          case "fundManager.firstName": {
            searchObject["fundManager"] = searchObject[x];
            delete searchObject[x];
            x = "fundManager"
            break;
          }

          default: {
            x = key
            break;
          }
        }
      });
      topSearchClick ? this.page = 0 : this.page;
      searchObject['page'] = this.page;
      if (this.orderFilter) {
        searchObject['orderFilter'] = this.orderFilter
      }
      else if (this.componentName == "runViewOpenTaxlots") {
        if (searchObject['taxLotDate']) {
          searchObject['tradeEntry.taxLotDate'] = searchObject['taxLotDate']
          delete searchObject['taxLotDate'];
        }
        if (searchObject['createdOn']) {
          searchObject['createdDate'] = searchObject['createdOn']
          delete searchObject['createdOn'];
        }
      }
      else if (this.componentName == 'tradeList' || this.componentName == "rapidTradeList") {
        searchObject['ticketChargesCurrency'] = searchObject['ticketChargesCurrency.id']

      }
      else if (this.componentName == 'viewDataCollection' && clientFundId) {
        searchObject['filterList'].push({ "fundId": clientFundId });
      }

      var reqObj = searchObject;
    }
    if (this.componentName == "fetchCsdBankTransactionPendingReview") {
      let reqObject = {};
      Object.assign(reqObject, reqObj);
      reqObject['fund'] = this.formDataTemp['fund'];
      this.req = reqObject;
      return reqObject;
    }
    if (reqObj == undefined && (!this.hasOwnProperty('formDataTemp') || !this.formDataTemp['custodianAccNo']) && (!this.hasOwnProperty('formDataTemp') || !this.formDataTemp['custodianName'])) {
      this.page = 0;
      this.pageNumber = 1;
      this.filterData = false;
      this.filteredData = [];
    }
    this.req = reqObj;
    return reqObj;
  }

  getGridData(lookups, cbs, cbe, subCategory?) {
    this.getGridDataUrl();
    let gridUrl
    if (this.componentName != 'RapidJournalentry' && this.componentName != "disableAutoUpdateViaAllocationUpdate" && this.componentName != 'redemptionActivityUpdate' && this.componentName != 'subscriptionActivityUpdate') {
      gridUrl = this.componentName != "journalentry" ? this.gridDataUrl + this.page : this.gridDataUrl + this.page + "/moduleName=" + lookups;
    }
    else if (this.componentName == 'RapidJournalentry') {
      gridUrl = this.componentName != 'RapidJournalentry' ? this.gridDataUrl + this.page : this.gridDataUrl + this.page + "/moduleName=" + lookups;
    }
    else if (this.componentName == "disableAutoUpdateViaAllocationUpdate" || this.componentName == 'redemptionActivityUpdate' || this.componentName == 'subscriptionActivityUpdate') {
      gridUrl = this.gridDataUrl + this.page + "?subCategory=" + subCategory
    }

    this.httpClient.get(gridUrl, this.httpOptions)
      .pipe(res => res)
      .subscribe(result => {
        cbs(result);
      }, error => {
        cbe(error);
      })
  }
  getFilteredData(cbs, cbe, data) {
    this.getGridDataUrl()
    this.httpClient.post<any>(this.filterUrl, data, this.httpOptions)
      .pipe(res => res)
      .subscribe(result => {
        cbs(result);
      }, error => {
        cbe(error);
      })
  }

  getFilteredReq(formData) {
    this.formDataTemp = formData;
    Object.keys(formData).forEach((key) => {
      if (formData[key] === "" || formData[key] === null || (typeof (formData[key]) == 'object' && Object.keys(formData[key]).length === 0)) {
        delete formData[key];
      }
    });
    Object.keys(formData).forEach(x => {
      switch (x) {
        case "startDate":
          if (this.componentName == 'runViewOpenTaxlots' || this.componentName == 'viewtotalgl' || this.componentName == 'rapidViewtotalgl') {
            formData['pnlDate'] = this.commonService.getStartDate(this.formDataTemp['startDate']) + 'LHRXJ10EWRdateZRHXJ10JFK' + (this.formDataTemp['endDate'] == undefined ? this.commonService.getEndDate(this.formDataTemp['startDate']) : this.commonService.getEndDate(this.formDataTemp['endDate']))
          }
          else if (this.componentName == 'tradeList' || this.componentName == "rapidTradeList") {
            formData['tradeDateTime'] = this.commonService.getStartDate(this.formDataTemp['startDate']) + 'LHRXJ10EWRdateZRHXJ10JFK' + (this.formDataTemp['endDate'] == undefined ? this.commonService.getEndDate(this.formDataTemp['startDate']) : this.commonService.getEndDate(this.formDataTemp['endDate']))
          }
          else if (this.componentName == 'seamlessmarketdata' || this.componentName == 'RapidSeamlessmarketdata') {
            formData['valuationDateAndTime'] = this.commonService.getStartDate(this.formDataTemp['startDate']) + 'LHRXJ10EWRdateZRHXJ10JFK' + (this.formDataTemp['endDate'] == undefined ? this.commonService.getEndDate(this.formDataTemp['startDate']) : this.commonService.getEndDate(this.formDataTemp['endDate']))
          }
          else if (this.componentName == 'viewclosedtaxLots') {
            formData['closeTrade.tradeDateTime'] = this.commonService.getStartDate(this.formDataTemp['startDate']) + 'LHRXJ10EWRdateZRHXJ10JFK' + (this.formDataTemp['endDate'] == undefined ? this.commonService.getEndDate(this.formDataTemp['startDate']) : this.commonService.getEndDate(this.formDataTemp['endDate']))
          }
          else if (this.componentName == 'nonTradeActivity' || this.componentName == 'rapidNonTradeActivity' || this.componentName == 'fetchBankTransaction' || this.componentName == "fetchCsdBankTransactionPendingReview") {
            formData['date'] = this.commonService.getStartDate(this.formDataTemp['startDate']) + 'LHRXJ10EWRdateZRHXJ10JFK' + (this.formDataTemp['endDate'] == undefined ? this.commonService.getEndDate(this.formDataTemp['startDate']) : this.commonService.getEndDate(this.formDataTemp['endDate']))
          }
          else if (this.componentName == 'journalentry' || this.componentName == 'RapidJournalentry') {
            formData['jedate'] = this.commonService.getStartDate(this.formDataTemp['startDate']) + 'LHRXJ10EWRdateZRHXJ10JFK' + (this.formDataTemp['endDate'] == undefined ? this.commonService.getEndDate(this.formDataTemp['startDate']) : this.commonService.getEndDate(this.formDataTemp['endDate']))
          }
          else if (this.componentName == "globalSMEquitiesList" || this.componentName == "globalSmOptionsList" || this.componentName == "globalSmFixedIncomeList" || this.componentName == "globalSmFxForwardList" || this.componentName == "globalSmPrivateFundList" || this.componentName == "globalSmFutureList" || this.componentName == "globalSmRealestateList" || this.componentName == "globalSmPools" || this.componentName == "globalCrypto") {
            formData['dateOfRecordCreation'] = this.commonService.getStartDate(this.formDataTemp['startDate']) + 'LHRXJ10EWRdateZRHXJ10JFK' + (this.formDataTemp['endDate'] == undefined ? this.commonService.getEndDate(this.formDataTemp['startDate']) : this.commonService.getEndDate(this.formDataTemp['endDate']))
          }
          else if (this.componentName == 'reBudgets' || this.componentName == 'rapidReBudgets') {
            formData['startDate'] = this.commonService.getStartDate(this.formDataTemp['startDate'])
          }
          else if (this.componentName == 'disableAutoUpdateViaAllocationUpdate' || this.componentName == 'redemptionActivityUpdate' || this.componentName == 'subscriptionActivityUpdate') {
            formData['effectivedate'] = this.commonService.getStartDate(this.formDataTemp['startDate']) + 'LHRXJ10EWRdateZRHXJ10JFK' + (this.formDataTemp['endDate'] == undefined ? this.commonService.getEndDate(this.formDataTemp['startDate']) : this.commonService.getEndDate(this.formDataTemp['endDate']))
          }
          break;
        case "endDate":
          if (this.componentName == 'runViewOpenTaxlots' || this.componentName == 'viewtotalgl' || this.componentName == 'rapidViewtotalgl') {
            formData['pnlDate'] = (this.formDataTemp['startDate'] == undefined ? this.commonService.getStartDate(this.formDataTemp['endDate']) : this.commonService.getStartDate(this.formDataTemp['startDate'])) + 'LHRXJ10EWRdateZRHXJ10JFK' + this.commonService.getEndDate(this.formDataTemp['endDate'])
          }
          else if (this.componentName == 'tradeList' || this.componentName == "rapidTradeList") {
            formData['tradeDateTime'] = (this.formDataTemp['startDate'] == undefined ? this.commonService.getStartDate(this.formDataTemp['endDate']) : this.commonService.getStartDate(this.formDataTemp['startDate'])) + 'LHRXJ10EWRdateZRHXJ10JFK' + this.commonService.getEndDate(this.formDataTemp['endDate'])
          }
          else if (this.componentName == 'seamlessmarketdata' || this.componentName == 'RapidSeamlessmarketdata') {
            formData['valuationDateAndTime'] = this.commonService.getStartDate(this.formDataTemp['startDate']) + 'LHRXJ10EWRdateZRHXJ10JFK' + (this.formDataTemp['endDate'] == undefined ? this.commonService.getEndDate(this.formDataTemp['startDate']) : this.commonService.getEndDate(this.formDataTemp['endDate']))
          }
          else if (this.componentName == 'viewclosedtaxLots') {
            formData['closeTrade.tradeDateTime'] = (this.formDataTemp['startDate'] == undefined ? this.commonService.getStartDate(this.formDataTemp['endDate']) : this.commonService.getStartDate(this.formDataTemp['startDate'])) + 'LHRXJ10EWRdateZRHXJ10JFK' + this.commonService.getEndDate(this.formDataTemp['endDate'])
          }
          else if (this.componentName == 'nonTradeActivity' || this.componentName == 'rapidNonTradeActivity' || this.componentName == 'fetchBankTransaction' || this.componentName == "fetchCsdBankTransactionPendingReview") {
            formData['date'] = (this.formDataTemp['startDate'] == undefined ? this.commonService.getStartDate(this.formDataTemp['endDate']) : this.commonService.getStartDate(this.formDataTemp['startDate'])) + 'LHRXJ10EWRdateZRHXJ10JFK' + this.commonService.getEndDate(this.formDataTemp['endDate'])
          }
          else if (this.componentName == 'journalentry' || this.componentName == 'RapidJournalentry') {
            formData['jedate'] = (this.formDataTemp['startDate'] == undefined ? this.commonService.getStartDate(this.formDataTemp['endDate']) : this.commonService.getStartDate(this.formDataTemp['startDate'])) + 'LHRXJ10EWRdateZRHXJ10JFK' + this.commonService.getEndDate(this.formDataTemp['endDate'])
          }
          else if (this.componentName == "globalSMEquitiesList" || this.componentName == "globalSmOptionsList" || this.componentName == "globalSmFixedIncomeList" || this.componentName == "globalSmFxForwardList" || this.componentName == "globalSmPrivateFundList" || this.componentName == "globalSmFutureList" || this.componentName == "globalSmRealestateList" || this.componentName == "globalSmPools" || this.componentName == "globalCrypto") {
            formData['updatedOn'] = (this.formDataTemp['startDate'] == undefined ? this.commonService.getStartDate(this.formDataTemp['endDate']) : this.commonService.getStartDate(this.formDataTemp['startDate'])) + 'LHRXJ10EWRdateZRHXJ10JFK' + this.commonService.getEndDate(this.formDataTemp['endDate'])
          }
          else if (this.componentName == 'reBudgets' || this.componentName == 'rapidReBudgets') {
            formData['endDate'] = this.commonService.getEndDate(this.formDataTemp['endDate'])
          }
          else if (this.componentName == 'disableAutoUpdateViaAllocationUpdate' || this.componentName == 'redemptionActivityUpdate' || this.componentName == 'subscriptionActivityUpdate') {
            formData['effectivedate'] = (this.formDataTemp['startDate'] == undefined ? this.commonService.getStartDate(this.formDataTemp['endDate']) : this.commonService.getStartDate(this.formDataTemp['startDate'])) + 'LHRXJ10EWRdateZRHXJ10JFK' + this.commonService.getEndDate(this.formDataTemp['endDate'])
          }
          break;
          case "fundName":
          formData[x] = this.componentName=="dataMonitoringinput"?formData[x]: this.getFundName(formData[x]);
          break;
        case "TransientFund":
          formData[x] = this.getFundName(formData[x]);
          break;
        case "fund":
          if (this.componentName == "fetchCsdBankTransactionPendingReview") {
            formData[x] = this.getFundidsList(formData[x]);
          } else {
            formData[x] = this.getFundName(formData[x]);
          }
          break;
        case "bookNo":
          formData["custodianAccNumber"] = this.getCustodianName(formData[x]);
          break;
        case "entityName":
          formData["fundName"] = this.getFundName(formData[x]);
          break;
        case "transactionType":
          formData["transactionType"] = this.getTransactionType(formData[x]);
          break;
        case "activityType":
          formData["activityType"] = this.getActivityType(formData[x]);
          break;
        case "custodian":
          if (this.componentName == 'rapidNonTradeActivity' || this.componentName == 'fetchBankTransaction') {
            formData["custodianAccNo"] = this.getCustodianListName(formData[x]);
          }
          if (this.componentName == 'RapidJournalentry') {
            formData["custodianName"] = this.getCustodianListName(formData[x]);
          }
          if (this.componentName == "fetchCsdBankTransactionPendingReview") {
            formData["custodianAccNo"] = this.getCustodianName(formData[x]);
          }
          break;
      }
    });
    formData['page'] = 0;
    if (this.componentName != 'reBudgets' && this.componentName != "rapidReBudgets") {
      delete formData['startDate'];
      delete formData['endDate'];
    }
    this.req = formData;
    return formData;
  };


  pageShift(totalPages, page, type, pageNumberEnter?) {
    switch (type) {
      case "onBtNext": {
        if (page < (Number(totalPages) - 1)) {
          this.page = page = Number(page) + 1;
          let pageNumber = Number(page) + 1;
          let isFilter = this.filteredData.length || this.filterData == true ? true : false;
          return { page, isFilter, pageNumber };
        }
        break;
      }
      case "onBtLast": {
        if (page < Number(totalPages) - 1) {
          this.page = page = Number(totalPages) - 1;
          let pageNumber = totalPages;
          let isFilter = this.filteredData.length || this.filterData == true ? true : false;
          return { page, isFilter, pageNumber };
        }
        break;
      }
      case "onBtFirst": {
        if (page > 0) {
          this.page = page = 0;
          let pageNumber = 1;
          let isFilter = this.filteredData.length || this.filterData == true ? true : false;
          page = 0;
          return { page, isFilter, pageNumber };
        }
        break;
      }
      case "onBtPrevious": {
        if (this.page > 0) {
          this.page = page = Number(page) - 1;
          let pageNumber = Number(page) + 1;
          let isFilter = this.filteredData.length || this.filterData == true ? true : false;
          return { page, isFilter, pageNumber };
        }
        break;
      }
      case "selectedPagePagibation": {
        this.page = page = pageNumberEnter - 1;
        if (this.page <= 0) {
          return;
        } else if (pageNumberEnter > totalPages) {
          this.page = page = 0;
          let pageNumber = 1;
          let isFilter = this.filteredData.length || this.filterData == true ? true : false;
          return { page, isFilter, pageNumber };
        }
        else {
          let isFilter = this.filteredData.length || this.filterData == true ? true : false;
          let pageNumber = pageNumberEnter;
          return { page, isFilter, pageNumber };
        }
        break;
      }
    }
  }
  downloadCSV(totalRecords, lookups?) {
    this.totalCount = totalRecords;
    let zone_name: any;
    let zone_name1 = moment.tz.guess();
    zone_name = zone_name1.replace("/", "_");
    var type = 'Csv';

    let link;
    link = this.isZoneName ? this.gridExportUrl + encodeURI(JSON.stringify(this.totalCount)) + '/' + zone_name : this.gridExportUrl + encodeURI(JSON.stringify(this.totalCount));
    if (this.componentName == 'journalentry' || this.componentName == 'RapidJournalentry') {
      link = link = this.gridExportUrl + encodeURI(JSON.stringify(this.totalCount)) + '/' + lookups;
    }
    if (
      this.componentName == 'tradeList' || this.componentName == "rapidTradeList"
      || this.componentName == 'tradeArchiveList' || this.componentName == 'runViewOpenTaxlots'
      || this.componentName == 'viewclosedtaxLots'
      || this.componentName == 'viewtotalgl'
      || this.componentName == 'rapidViewtotalgl'
      || this.componentName == 'marketdata'
      || this.componentName == "rapidMarketdata"
      || this.componentName == 'viewjesummary'
      || this.componentName == 'nonTradeActivity'
      || this.componentName == 'rapidNonTradeActivity'
      || this.componentName == 'fetchBankTransaction'
      || this.componentName == 'capitalActivities'
      || this.componentName == 'rapidCapitalActivities'
      || this.componentName == 'rapidItdSubscriptionAndRedemption'
      || this.componentName == 'itdSubscriptionAndRedemption'
      || this.componentName == "fetchCsdBankTransactionPendingReview"
    ) {
      link = this.gridExportUrl;
    }

    if (this.componentName == 'journalentry' || this.componentName == 'RapidJournalentry') {
      let reqObj = "moduleName=" + lookups;
      link = this.gridExportUrl + reqObj;
    }

    if (this.componentName != 'sourceConfiguration') {
      if (window.location.hostname == "localhost") {
        link = link.replace("4200", "8081");
      }

      if (this.componentName == 'viewclosedtaxLots') {
        link = this.gridExportUrl;
      }
      let url = environment.api_url + link;
      this.commonService.GenericDownload(url);
    }
  }

  getGridDataUrl() {
    this.gridDataUrl = apiConstants[this.componentName]['gridDataUrl'];
    this.gridDeleteUrl = apiConstants[this.componentName]['gridDeleteUrl'];
    this.moduleName = apiConstants[this.componentName]['moduleName'];
    this.exportModuleName = apiConstants[this.componentName]['exportModuleName'];
    this.gridExportUrl = apiConstants[this.componentName]['gridExportUrl'] ? apiConstants[this.componentName]['gridExportUrl'].url : "";
    this.isZoneName = apiConstants[this.componentName]['gridExportUrl'] ? apiConstants[this.componentName]['gridExportUrl'].isZoneName : "";
    this.gridFilteredUrl = apiConstants[this.componentName]['gridFilteredUrl'] ? apiConstants[this.componentName]['gridFilteredUrl'].url : "";
    this.orderFilter = apiConstants[this.componentName]['filterUrl'] ? apiConstants[this.componentName]['filterUrl'].orderFilter : "";
    this.filterUrl = apiConstants[this.componentName]['filterUrl'] ? apiConstants[this.componentName]['filterUrl'].url : "";
    if (this.componentName == 'viewDataCollection') {
      this.filterUrl = apiConstants[this.componentName].Balance['filterUrl'] ? apiConstants[this.componentName].Balance['filterUrl'].url : "";
    }
    this.filterDeleteUrl = apiConstants[this.componentName]['filterDeleteUrl'] ? apiConstants[this.componentName]['filterDeleteUrl'].url : "";
    this.routeUrl = apiConstants[this.componentName]['routeUrl'] ? apiConstants[this.componentName]['routeUrl'] : {};
    this.method = apiConstants[this.componentName]['method'] ? apiConstants[this.componentName]['method'] : 'get';
    this.customFilterUrl = apiConstants[this.componentName]['customFilterUrl']
    this.customFilterUrls = apiConstants[this.componentName]['customfilterurls']
    this.getCustomTypeUrl = apiConstants[this.componentName]['getCustomTypeUrl']
  }

  getDownloadCsvFilteredData(gridApi, totalFltRecords, lookups?) {
    this.totalCount = totalFltRecords;
    this.gridApi = gridApi;
    const filtredModel = this.gridApi.getFilterModel();
    let x = Object.entries(filtredModel);
    var key = x[0][0];
    let value = x[0][1]['filter'];
    let gridOptions = { api: this.gridApi };
    let searchObject = this.commonService.getDataToFilter(gridOptions, this.gridApi);
    searchObject = this.getSearchObj(searchObject, key);
    if (this.componentName != 'journalentry' && this.componentName != 'RapidJournalentry') {
      searchObject['page'] = this.page;
    }
    var reqObj = searchObject;
    var type = 'Excel';
    let zone_name = moment.tz.guess();
    if (this.componentName != 'dataImportStatus' && this.componentName != 'dataImportStatusManager'
      && this.componentName != 'workflowStatus' && this.componentName != 'executeWorkflow') {
      zone_name = zone_name.replace("/", "_");
    }
    let link;
    link = this.isZoneName ? this.gridFilteredUrl + encodeURI(JSON.stringify(this.totalCount)) + "/" + encodeURI(this.readObject(JSON.stringify(reqObj))) + '/' + zone_name
      : this.componentName != 'newIssues' ? this.gridFilteredUrl + encodeURI(JSON.stringify(this.totalCount)) + "/" + encodeURI(this.readObject(JSON.stringify(reqObj)))
        : this.gridFilteredUrl + encodeURI(JSON.stringify(this.totalCount)) + "/" + btoa(JSON.stringify(reqObj));
    if (
      this.componentName == 'tradeList'
      || this.componentName == "rapidTradeList"
      || this.componentName == 'tradeArchiveList'
      || this.componentName == 'viewclosedtaxLots' || this.componentName == 'runViewOpenTaxlots'
      || this.componentName == 'viewtotalgl'
      || this.componentName == 'rapidViewtotalgl'
      || this.componentName == 'marketdata'
      || this.componentName == "rapidMarketdata"
      || this.componentName == 'viewjesummary'
      || this.componentName == 'nonTradeActivity'
      || this.componentName == 'rapidNonTradeActivity'
      || this.componentName == 'fetchBankTransaction'
      || this.componentName == 'capitalActivities'
      || this.componentName == 'rapidCapitalActivities'
      || this.componentName == 'rapidItdSubscriptionAndRedemption'
      || this.componentName == 'itdSubscriptionAndRedemption'
      || this.componentName == "fetchCsdBankTransactionPendingReview"
    ) {
      link = this.gridFilteredUrl + encodeURI(this.readObject(JSON.stringify(reqObj)));
    }
    if (this.componentName == 'journalentry' || this.componentName == 'RapidJournalentry') {
      reqObj['moduleName'] = lookups;
      reqObj['page'] = this.page.toString();
      let filterObj = {
        searchObj: reqObj,
        count: this.totalCount
      };
      link = this.gridFilteredUrl + encodeURI(this.readObject(JSON.stringify(filterObj)));
    }
    let url = environment.api_url + link;
    this.commonService.GenericDownload(url);
  }
  getSearchObj(searchObject, key) {
    Object.keys(searchObject).forEach(x => {
      switch (x) {
        case "brokerAccount.accountNumber": {
          searchObject["TransientBrokerAccount"] = searchObject[x];
          delete searchObject[x];
          x = "TransientBrokerAccount"
          break;
        }
        case "assetClass.name": {
          searchObject["TransientAssetClass"] = searchObject[x];
          delete searchObject[x];
          x = "TransientAssetClass"
          break;
        }
        case "clientLookup.value": {
          if (this.componentName != 'exchanges' && this.componentName != 'assetClass') {
            searchObject["clientLookup"] = "lookupselect##ratingAgency";
            searchObject["ratingAgency"] = { 'type': "RatingAgencies", "fields.name": "Name", "fields.value": searchObject[x] };
            delete searchObject[x];
            x = "clientLookup.value"
          }
          else {
            searchObject["clientLookup"] = "lookupselect##primaryAssetClass";
            searchObject["primaryAssetClass"] = { 'type': "PrimaryAssetClasses", "fields.name": "Primary Asset Class", "fields.value": searchObject[x] };
            delete searchObject[x];
            x = "clientLookup.value"
          }
          break;
        }
        case "fund.fundName": {
          if (this.componentName == 'nonTradeActivity' || this.componentName == 'rapidNonTradeActivity' || this.componentName == 'fetchBankTransaction' || this.componentName == 'journalentry' || this.componentName == 'RapidJournalentry' || this.componentName == 'incomeDistribution' || this.componentName == 'viewCapitalCall' || this.componentName == 'rapidViewCapitalCall' || this.componentName == 'viewjesummary' || this.componentName == 'customRatio' || this.componentName == 'viewDistribution' || this.componentName == 'rapidViewDistribution' || this.componentName == 'custodianList' || this.componentName == 'custodianListLegacy') {
            searchObject["fund"] = searchObject[x];
            delete searchObject[x];
            x = "fund"
            break;
          } else if (this.componentName == 'uploadallocationhistory' || this.componentName == 'historicalInvestorCapitalSummary') {
            searchObject["fundName"] = searchObject[x];
            delete searchObject[x];
            x = "fundName"
            break;
          }
          else if (this.componentName == 'hurdleRate' || this.componentName == "disableAutoUpdateViaAllocationUpdate" || this.componentName == 'redemptionActivityUpdate' || this.componentName == 'subscriptionActivityUpdate') {
            searchObject["fund.fundName"] = searchObject[x];
            // delete searchObject[x];
            x = "fund.fundName"
            break;
          }
          else {

            searchObject["TransientFund"] = searchObject[x];
            delete searchObject[x];
            x = "TransientFund"
            break;

          }
        }
        case "custodianAccNumber": {
          searchObject["custodianAccNumber"] = searchObject[x];
          x = "custodianAccNumber"
          break;
        }
        case "source.isoCode": {
          searchObject["source"] = searchObject[x];
          delete searchObject[x];
          x = "source"
          break;
        }
        case "transactionType": {
          if (this.componentName == 'tradingLevelChange' || this.componentName == 'rapidTradingLevelChange' || this.componentName == 'cashTransaction') {
            searchObject["transactionTypeValue"] = searchObject[x];
            delete searchObject[x];
            x = "transactionTypeValue"
            break;
          }
        }
        case "countryMaster.name": {
          if (this.componentName == 'regulatorsForSetting' || this.componentName == 'regulators') {
            searchObject["countryMaster"] = searchObject[x];
            delete searchObject[x];
            x = "countryMaster"
          }
          break;
        }
        case "PAssetClass": {
          if (this.componentName == 'workflowManagement') {
            searchObject["primaryAssetClass"] = searchObject[x];
            delete searchObject[x];
            x = "primaryAssetClass"
          }
          break;
        }
        case "uploadData": {
          if (this.componentName == 'workflowManagement') {
            searchObject["isUploadData"] = searchObject[x] == 'yes' ? true : searchObject[x] == 'no' ? false : searchObject[x];
            delete searchObject[x];
            x = "isUploadData"
          }
          break;
        }
        case "isPreviewData": {
          if (this.componentName == 'workflowManagement') {
            searchObject["isPreviewData"] = searchObject[x] == 'yes' ? true : searchObject[x] == 'no' ? false : searchObject[x];
            delete searchObject[x];
            x = "isPreviewData"
          }
          break;
        }
        case "ProcessName": {
          if (this.componentName == 'workflowManagement') {
            searchObject["processName"] = searchObject[x];
            delete searchObject[x];
            x = "processName"
          }
          break;
        }
        case "AssetClass": {
          if (this.componentName == 'workflowManagement') {
            searchObject["assetClass"] = searchObject[x];
            delete searchObject[x];
            x = "assetClass"
          }
          break;
        }
        case "inputMode": {
          if (this.componentName == 'workflowManagement') {
            searchObject["inputModeType"] = searchObject[x];
            delete searchObject[x];
            x = "inputModeType"
          }
          break;
        }
        case "exchanges.exchangeName": {
          searchObject["exchanges"] = searchObject[x];
          delete searchObject[x];
          x = "exchanges"
          break;
        }
        case "currency.isoCode": {
          searchObject["currency"] = searchObject[x];
          delete searchObject[x];
          x = "currency"
          break;
        }
        case "assetClasses.name": {
          searchObject["assetClasses"] = searchObject[x];
          delete searchObject[x];
          x = "assetClasses"
          break;
        }
        case "clientLookup.value": {
          searchObject["primaryAssetClass"] = searchObject[x];
          delete searchObject[x];
          x = "primaryAssetClass"
          break;
        }
        case "fundName": {
          //   if (this.componentName != 'Manager' && this.componentName != 'viewPnlDistribution') {
          //     searchObject["fund"] = searchObject[x];
          //     delete searchObject[x];
          //     x = "fund"
          //   }
          //   break;
          // }
          if (this.componentName != 'viewtotalgl' && this.componentName != 'rapidViewtotalgl' && this.componentName != 'viewPnlDistribution' && this.componentName != 'benchmarkirr' && this.componentName != 'rapidBenchmarkirr' && this.componentName != 'reportApproval' && this.componentName != 'runViewOpenTaxlots' && this.componentName != 'viewclosedtaxLots' && this.componentName != 'dailyMargin' && this.componentName != 'rapidDailyMargin'
            && this.componentName != 'FundList' && this.componentName != 'reBudgets' && this.componentName != 'rapidReBudgets' && this.componentName != 'TLRegime' && this.componentName != 'rapidTLRegime' && this.componentName != 'Manager' && this.componentName != 'externalROR' && this.componentName != 'rapidExternalROR' && this.componentName != 'investorgroup' && this.componentName != "rapidInvestorgroup" && this.componentName != 'inputDataIntegritySummery' && this.componentName != 'fetchCsdBankTransactionPendingReview') {
            searchObject["fund"] = searchObject[x];
            delete searchObject[x];
            x = "fund"
            break;
          }
          else if (this.componentName == 'reBudgets' || this.componentName == 'rapidReBudgets') {
            searchObject["fundName"] = searchObject[x];
            x = "fundName"
            break;
          }
          else {
            searchObject["fundName"] = searchObject[x];
            x = "fundName"
            break;
          }
        }
        case "country.name": {
          if (this.componentName != 'exchanges') {
            searchObject["country"] = searchObject[x];
            delete searchObject[x];
            x = "country"
          }
          break;
        }
        case "client_Ip": {
          searchObject["Client_Ip"] = searchObject[x];
          delete searchObject[x];
          x = "country"
          break;
        }
        case "fundManager.firstName": {
          searchObject["fundManager"] = searchObject[x];
          delete searchObject[x];
          x = "fundManager"
          break;
        }
        case "fundGroup.fundGroup": {
          if (this.componentName == 'FundList') {
            searchObject["fundGroupName"] = searchObject[x];
            delete searchObject[x];
            x = "fundGroupName"
          }
          break;
        }
        case "enterprise.enterpriseName": {
          searchObject["enterprise"] = searchObject[x];
          delete searchObject[x];
          x = "enterprise"
          break;
        }
        case "brokerName.accountNumber": {
          if (this.componentName == 'dailyMargin' || this.componentName == 'rapidDailyMargin') {
            searchObject["accountNumber"] = searchObject[x];
            delete searchObject[x];
            x = "accountNumber"
            break;
          }
        }
        case "brokerName.nameOfCustodian": {
          if (this.componentName == 'dailyMargin' || this.componentName == 'rapidDailyMargin') {
            searchObject["brokerName"] = searchObject[x];
            delete searchObject[x];
            x = "brokerName"
            break;
          }
        }
        case "brokerName.nameOfCustodian": {
          if (this.componentName == 'dailyMargin' || this.componentName == 'rapidDailyMargin') {
            searchObject["brokerName"] = searchObject[x];
            delete searchObject[x];
            x = "brokerName"
            break;
          }
        }
        case "tradeAdvisor.nameOfAdvisor": {
          if (this.componentName == 'custodianList' || this.componentName == 'custodianListLegacy') {
            searchObject["tradeAdvisor"] = searchObject[x];
            delete searchObject[x];
            x = "tradeAdvisor"
            break;
          }
        }
        case "accountNumber": {
          if (this.componentName == 'custodianList' || this.componentName == 'custodianListLegacy') {
            searchObject["accountNumberValue"] = searchObject[x];
            delete searchObject[x];
            x = "accountNumberValue"
            break;
          }
        }
        case "dataCount": {
          if (this.componentName == 'dataImportStatus' || this.componentName == 'dataImportStatusManager'
            || this.componentName == 'workflowStatus' || this.componentName == 'executeWorkflow') {
            let srchObj = searchObject[x] + "LHRXJ10EWRnumberZRHXJ10JFK";
            searchObject["dataCount"] = srchObj;
            //delete searchObject[x];
            x = "dataCount"
            break;
          }
        }
        case "successCount": {
          if (this.componentName == 'dataImportStatus' || this.componentName == 'dataImportStatusManager'
            || this.componentName == 'workflowStatus' || this.componentName == 'executeWorkflow') {
            let srchObj = searchObject[x] + "LHRXJ10EWRnumberZRHXJ10JFK";
            searchObject["successCount"] = srchObj;
            //delete searchObject[x];
            x = "successCount"
            break;
          }
        }
        case "executionPeriod": {
          if (this.componentName == 'dataImportStatus' || this.componentName == 'dataImportStatusManager'
            || this.componentName == 'workflowStatus' || this.componentName == 'executeWorkflow') {
            searchObject["alteryxWorkflowMngt.executionPeriod"] = searchObject[x];
            delete searchObject[x];
            x = "alteryxWorkflowMngt.executionPeriod"
            break;
          }
        }
        case "frequency": {
          if (this.componentName == 'dataImportStatus' || this.componentName == 'dataImportStatusManager'
            || this.componentName == 'workflowStatus' || this.componentName == 'executeWorkflow') {
            searchObject["alteryxWorkflowMngt.frequency"] = searchObject[x];
            delete searchObject[x];
            x = "alteryxWorkflowMngt.frequency"
            break;
          }
        }
        default: {
          x = key
          break;
        }
      }
    });
    return searchObject;
  }

  readObject(searchObj) {
    return searchObj.replaceAll("/", "ZZZZZZZZZZ");
  }
  getFundName(id) {
    let fundName;
    let fundList = [];
    if (this.componentName == 'disableAutoUpdateViaAllocationUpdate' || this.componentName == 'redemptionActivityUpdate' || this.componentName == 'subscriptionActivityUpdate') {
      fundList = this.capActivityFundList;
    } else {
      fundList = this.fundList;
    }
    fundList.forEach(el => {
      if (el.value == id) {
        fundName = el.label
      }
    })
    return fundName;
  }
  getCustodianName(id) {
    let brokerName;
    let custodianList = this.custodianList;
    custodianList.forEach(el => {
      if (el.value == id) {
        brokerName = el.label
      }
    })
    return brokerName;
  }

  getTransactionType(id) {
    let transactionName;
    let transactionList = this.capActivityTransactionList;
    transactionList.forEach(el => {
      if (el.value == id) {
        transactionName = el.label
      }
    })
    return transactionName;
  }

  getActivityType(id) {
    let activityName;
    let activityTypeList = this.capActivityTypeList;
    activityTypeList.forEach(el => {
      if (el.value == id) {
        activityName = el.label
      }
    })
    return activityName;
  }

  getCustodianListName(id) {
    let brokerName = [];
    id.forEach(el => {
      brokerName.push(el.label)
    })
    return brokerName;
  }

  getFundidsList(id) {
    let fundIds = [];
    id.forEach(el => {
      fundIds.push(el.value)
    })
    return fundIds;
  }
  deleteAll() {
    this.gridDeleteUrl = "deleteController/deleteAll"
    let data = { moduleName: this.moduleName, }
    return { gridDeleteUrl: this.gridDeleteUrl, deldata: data };
  }
  deleteSelected(gridApi, selectedRows) {
    this.gridApi = gridApi;
    let response = true
    let focusedNode = this.gridApi.getSelectedRows();
    let selectedList = [];
    let x = new Object();
    if (this.componentName == 'reBudgets' || this.componentName == 'rapidReBudgets') {
      this.gridDeleteUrl = "reBudgetDetails/deleteSelected";
    } else {
      this.gridDeleteUrl = "deleteController/deleteSelected";
    }
    selectedRows.forEach(element => {
      if (this.componentName == 'journalentry' || this.componentName == 'RapidJournalentry') {
        selectedList.push(element.jeId)
      }
      else if (this.componentName == 'reBudgets' || this.componentName == 'rapidReBudgets') {
        x = {
          fundId: element["fundId"],
          custodianId: element["custodianId"],
          chartofAccId: element["chartofAccId"],
          year: element["year"]
        }
        selectedList.push(x);
      }
      else {
        selectedList.push(element.id)
      }
    });
    let deldata;
    if (this.componentName == 'journalentry' || this.componentName == 'RapidJournalentry') {
      deldata = {
        moduleName: this.moduleName,
        id: selectedList
      }
    }
    else if (this.componentName == 'reBudgets' || this.componentName == "rapidReBudgets") {


      deldata = selectedList
    }
    else {
      deldata = {
        moduleName: this.moduleName,
        id: selectedList
      }
    }
    return { gridDeleteUrl: this.gridDeleteUrl, deldata: deldata };
  }

  deleteFilter(rowData, tempInstanceDataValue?) {
    let reOb;
    let selectedList = [];
    rowData.forEach(element => {
      selectedList.push(element.id)
    });
    let data;
    if (this.componentName == 'reBudgets' || this.componentName == "rapidReBudgets") {
      reOb = this.req || {}
      reOb['endDate'] = tempInstanceDataValue['endDate']
      reOb['startDate'] = tempInstanceDataValue['startDate']
      delete reOb['page'];
      data = reOb
    }
    else {
      data = {
        moduleName: this.moduleName,
        id: selectedList,
        filter: this.req,
        totalCount: selectedList.length
      }
    }
    if (this.componentName != 'reBudgets' && this.componentName != "rapidReBudgets") {
      Object.entries(this.req).forEach((key, value) => {
        if (key[1] != undefined) {
          data[key[0]] = key[1];
        }
      })
    }
    if (this.componentName == 'journalentry' || this.componentName == 'RapidJournalentry') {
      delete data['moduleName'];
      data['page'] = this.req['page'].toString();
      data['moduleName'] = this.moduleName;
    }
    delete data['id'];
    delete data['filter'];
    if (this.componentName == 'reBudgets' || this.componentName == "rapidReBudgets") {
      this.filterDeleteUrl = "reBudgetDetails/deleteFilterByKeys"
    }
    else {
      this.filterDeleteUrl = "deleteController/deleteFilterByKeys"
    }
    return { gridDeleteUrl: this.filterDeleteUrl, deldata: data };
  }


  downloadCsvFilteredData(lookups, gridApi, page, totalFltRecords, heading, tempInstanceDataValue?) {
    if (heading) {
      this.translateService.get(heading).subscribe((traval) => {
        heading = traval;
      });
    }
    this.gridApi = gridApi;
    const filtredModel = this.gridApi.getFilterModel();
    let x = Object.entries(filtredModel);
    let searchObject = {};
    if ((this.componentName == "reBudgets" || this.componentName == "rapidReBudgets") && x.length == 0) {
      searchObject = {};
    }
    else {
      if (x.length) {
        var key = x[0][0];
        let value = x[0][1]['filter'];
        let gridOptions = { api: this.gridApi };
        searchObject = this.commonService.getDataToFilter(gridOptions, this.gridApi);
        searchObject = this.getSearchObj(searchObject, key);
      }
    }

    if (this.componentName == 'rapidNonTradeActivity' && this.formDataTemp && this.formDataTemp.hasOwnProperty('custodianAccNo')) {
      searchObject['custodianAccNo'] = this.formDataTemp['custodianAccNo']
    }
    if (this.componentName == 'fetchBankTransaction' && this.formDataTemp && this.formDataTemp.hasOwnProperty('custodianAccNo')) {
      searchObject['custodianAccNo'] = this.formDataTemp['custodianAccNo']
    }
    if (this.componentName == 'RapidJournalentry' && this.formDataTemp && this.formDataTemp.hasOwnProperty('custodianName')) {
      searchObject['custodianName'] = this.formDataTemp['custodianName']
    }

    if (this.componentName == 'fetchCsdBankTransactionPendingReview') {
      searchObject['custodianAccNo'] = this.formDataTemp['custodianAccNo'];
      let csdIds = [];
      let funds = tempInstanceDataValue['fundName']['filter'];
      let fundArr = this.csdBankTransFundList.filter(item => funds.includes(item.value));
      let fundIds = fundArr.map(item => item.value);
      fundArr.forEach(item => {
        if (item.csdFundId) {
          csdIds.push(item.csdFundId);
        }
      });
      searchObject['fundIds'] = fundIds;
      searchObject['csdFundIds'] = csdIds;
    }

    searchObject['page'] = page;
    if (this.componentName == "reBudgets" || this.componentName == "rapidReBudgets") {
      searchObject = searchObject || {}
      searchObject['endDate'] = tempInstanceDataValue['endDate']
      searchObject['startDate'] = tempInstanceDataValue['startDate']

    }
    if (this.componentName == "globalSMEquitiesList" || this.componentName == "globalSmOptionsList" || this.componentName == "globalSmFixedIncomeList" || this.componentName == "globalSmFxForwardList" || this.componentName == "globalSmPrivateFundList" || this.componentName == "globalSmFutureList" || this.componentName == "globalSmRealestateList" || this.componentName == "globalSmPools" || this.componentName == "globalCrypto") {
      let enterpriseStatusGlobal = []
      let enterpriseType = []
      searchObject = searchObject || {}
      searchObject['updatedOn'] = this.commonService.getStartDate(this.formDataTemp['endDate']) + 'LHRXJ10EWRdateZRHXJ10JFK' + this.commonService.getEndDate(this.formDataTemp['endDate'])
      this.formDataTemp['enterpriseStatusGlobal']?.forEach(el => {
        enterpriseStatusGlobal.push(el.value);
      })
      searchObject['enterpriseStatusGlobal'] = enterpriseStatusGlobal;
      if (this.formDataTemp['enterpriseTypeGlobal']) {
        this.formDataTemp['enterpriseTypeGlobal']?.forEach(el => {
          enterpriseType.push(el.value);
        });
      }

      searchObject['enterpriseTypeGlobal'] = enterpriseType;
      searchObject['missingFields'] = this.formDataTemp['missingFields']?.toString();
      searchObject['page'] = this.page;
      delete searchObject['endDate'];
    }
    if (this.componentName == "globalSmRealestateList") {
      delete searchObject['missingFields']
      searchObject['count'] = totalFltRecords
    }
    if (this.componentName == 'seamlessmarketdata' || this.componentName == 'RapidSeamlessmarketdata') {
      let link;
      link = this.gridFilteredUrl + totalFltRecords + '/' + encodeURI(this.readObject(JSON.stringify(searchObject)));
      let url = environment.api_url + link;
      this.commonService.GenericDownload(url);
      return true;
    }
    if (this.orderFilter) {
      searchObject['orderFilter'] = this.orderFilter
    }
    if ((this.componentName == 'journalentry' || this.componentName == 'RapidJournalentry') && searchObject['fund'] != undefined) {
      searchObject['fund'] = this.isConvert(JSON.stringify(searchObject['fund']))
    }
    if (this.componentName == 'journalentry' || this.componentName == 'RapidJournalentry') {
      searchObject['moduleName'] = lookups
      searchObject = { searchObj: searchObject };
      searchObject['count'] = totalFltRecords;
    }
    var res = searchObject;
    // res['moduleName'] = this.exportModuleName;

    let url;
    url = api_url + this.gridFilteredUrl + "?moduleName=" + this.exportModuleName;
    var request = new XMLHttpRequest();
    var access_token = this.cookieService.get('access_token');
    request.open('POST', url, true);
    request.setRequestHeader('Content-Type', 'application/zip');
    request.setRequestHeader('Authorization', 'Bearer ' + access_token);
    request.responseType = 'arraybuffer';
    request.onload = function () { };
    request.addEventListener("loadend", () => {
      if (request.status === 200) {
        if (!request.response) {
          this.messageService.add({ closable: true, sticky: true, severity: 'error', summary: heading, detail: 'Something went wrong with download, please try again.' });
        } else {
          var fileName = this.moduleName + "Filtered.zip";
          var disposition = request.getResponseHeader("Content-Disposition");
          if (disposition && disposition.indexOf("filename") !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              fileName = matches[1].replace(/['"]/g, "");
            }
          }
          var blob = request.response;
          var downloadLink = window.document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(new Blob([blob], { type: 'application/zip' }));
          downloadLink.download = fileName;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      } else {
        this.messageService.add({ closable: true, sticky: true, severity: 'error', summary: heading, detail: 'Something went wrong with download, please try again.' });
      }
    })
    this.messageService.add({ closable: true, sticky: true, severity: 'success', summary: 'Confirmed', detail: 'Download Request taken. Your download will start soon.' });
    request.send(JSON.stringify(res));
  }
  createdateSet(date) {
    let splitDate = date.split('LHRXJ10EWRdateZRHXJ10JFK');
    let startDate = moment(splitDate[0]).format('YYYY-MM-DD');
    let endDate = moment(splitDate[1]).format('YYYY-MM-DD');
    let req = {};
    startDate == endDate ? req = { type: 'contains', dateFrom: startDate } : req = { type: 'inRange', dateFrom: startDate, dateTo: endDate };
    return req;
  }
  setComponentName(componentName) {
    this.componentName = componentName;
  }
  isConvert(data) {
    // Create a TextEncoder instance
    const encoder = new TextEncoder();
    // Encode the string as a Uint8Array
    const encodedData = encoder.encode(data);
    // Convert the Uint8Array to base64
    const binaryArray = Array.from(encodedData, byte => String.fromCharCode(byte));
    const binaryString = binaryArray.join('');
    const base64String = btoa(binaryString);
    return base64String;
  }

  getComponentName() {

    return this.componentName;
  }

  getEditableAPI(list, nodeParam) {
    let apiList = [];
    list.forEach(item => {
      let response;
      let params = item.params;
      if (item.type == 'get') {
        let paramsObj = {};
        if (params) {
          let nodeData = nodeParam.data;
          params.forEach(element => {
            let paramData;
            if (element.dataKey && element.dataSubKey) {
              paramData = nodeData[element.dataKey][element.dataSubKey];
            } else {
              paramData = nodeData[element.dataKey];
            }
            paramsObj[element.param] = paramData;
          });
          response = this.httpClient.get<any>(item.url, { params: paramsObj });
        } else {
          response = this.httpClient.get<any>(item.url);
        }
        apiList.push(response);
      } else {
        let data = item.data;
        response = this.httpClient.post<any>(item.url, data);
        apiList.push(response);
      }
    });
    return forkJoin(apiList);
  }


  exportExcel(data?) {
    let gridUrl = this.gridExportUrl;
    var request = new XMLHttpRequest();
    request.open('POST', environment.api_url + gridUrl, true);
    request.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
    request.responseType = 'blob';
    request.onload = function () { };
    request.addEventListener("loadend", () => {
      if (request.status === 200) {
        var fileName: any = 'default' + '.xlsx';
        var disposition: any = request.getResponseHeader('Content-Disposition');
        if (disposition && disposition.indexOf('attachment') !== -1) {
          var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          var matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, '');
          }
        }
        var blob = request.response;
        var downloadLink: any = window.document.createElement('a');
        var contentTypeHeader: any = request.getResponseHeader("Content-Type");
        downloadLink.href = window.URL.createObjectURL(new Blob([blob], { type: contentTypeHeader }));
        downloadLink.download = fileName;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

      }
    })
    request.send(JSON.stringify(data));
  }

  updateData(url, data): Observable<any> {
    return this.httpClient.put<any>(url, data);
  }

  postToCSDBankTransactions(data): Observable<any> {
    return this.httpClient.post<any>("fetchCSDErrorsController/postToCSDBankTransactions", data);
  }



}





