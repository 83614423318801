<!-- /*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/  -->
<div class="setup-process">
<!-- <div class="content" fxLayout="column" fxLayoutGap="8px">
  <app-dynamic-form [data]="basicDetailData" (status)="formValidation($event)"
      ></app-dynamic-form>
      <div>
        <button class="btn-logout" (click)="submit()" [disabled]="formValid" > {{'yes'|translate}} </button>
        <button class="btn-logout" (click)="dialogClose()" > {{'no'|translate}} </button>
      </div>
</div> -->

<div fxLayout="column" fxLayoutAlign="start center">
  <div style="width: 100%">
      <mat-vertical-stepper #verticalStepper="matVerticalStepper">          
          <mat-step >
              <ng-template matStepLabel>{{heading|translate}}
                  <span class="fs-btn">
                      <!-- <ng-template *ngTemplateOutlet="ButtonTemplate"></ng-template> -->
                  </span>
              </ng-template>
              <div class="content" fxLayout="column" fxLayoutGap="8px">
                <app-dynamic-form [data]="basicDetailData" (status)="formValidation($event)"
                ></app-dynamic-form>
                <div>
                  <button class="btn-logout align-right" (click)="dialogClose()" > {{'no'|translate}} </button>
                  <button class="btn-logout align-right" (click)="submit()" [disabled]="formValid" > {{'yes'|translate}} </button>
                </div>
              </div>
          </mat-step>
      </mat-vertical-stepper>
  </div>
</div>
</div>